import React from "react";
import dateFns from "date-fns";
import ja from "date-fns/locale/ja";
import styled from "styled-components";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ButtonOutlinedSecondary from "../../atoms/buttons/ButtonOutlinedSecondary";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import FontPCaution from "../../atoms/fonts/FontPCaution";

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`;

const Header = styled(Row)`
  text-transform: uppercase;
  padding: 0.5em 0;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Col = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  font-size: ${(p) => p.theme.font.size.s};
  text-align: center;

  &.col-center {
    flex: 1;
    text-align: center;
    line-height: 1.8;
  }
`;

const NavButton = styled(ButtonOutlinedSecondary)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  padding: 5px 15px;
  font-weight: 700;
  outline: none;
  border: 1px solid ${(p) => p.theme.color.font};

  > svg {
    font-size: 14px;
    vertical-align: middle;
  }

  &.nav-previous-btn {
    border: thin solid ${(p) => p.theme.color.fontVariant};
    padding: 5px 15px 5px 10px;
    > svg {
      margin-left: 5px;
    }
  }
  &.nav-next-btn {
    padding: 5px 10px 5px 15px;
    > svg {
      margin-left: 5px;
    }
  }
  &.disabled-btn {
    color: ${(p) => p.theme.color.fontVariant};
  }
`;

const HeaderText = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: ${(p) => p.theme.color.font};
`;

const Body = styled.div`
  margin: -1px -1px 0 0;
`;

const Days = styled.div`
  text-transform: uppercase;
  color: ${(p) => p.theme.color.font};

  ${Col} {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    font-size: ${(p) => p.theme.font.size.s};
  }
`;

const AvailableLabel = styled.span`
  background-color: ${(p) => p.theme.color.fontInvert};
  border: 3px solid ${(p) => p.theme.color.primary};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const Day = styled.p`
  position: relative;
  height: 3em;
  line-height: 3em;
  margin: 0;
  z-index: 1;
  &.today {
    font-weight: 600;
    color: ${(p) => p.theme.color.font};
  }

  &.available {
    font-weight: 600;
    color: ${(p) => p.theme.color.font};
  }
`;

const Cell = styled(Col)`
  position: relative;
  height: 3em;
  border: 1px solid ${(p) => p.theme.color.fontInvert};
  border-bottom: 0;
  border-left: 0;
  overflow: hidden;
  cursor: pointer;
  background-color: ${(p) => p.theme.color.secondaryVariant};
  transition: 0.25s ease-out;
  text-align: center;
  color: ${(p) => p.theme.color.secondary};
  font-size: ${(p) => p.theme.font.size.s};
  -webkit-tap-highlight-color: transparent;

  &.disabled {
    cursor: default;
  }
  &.selected {
    background-color: ${(p) => p.theme.color.primary};
  }

  &.selected ${AvailableLabel} {
    background-color: ${(p) => p.theme.color.primary};
  }

  &.selected ${Day} {
    color: ${(p) => p.theme.color.fontInvert};
    font-weight: 700;
  }
`;

const SlashIcon = styled.span`
  position: absolute;
  transform: rotate(135deg);
  width: 0.5px;
  background-color: ${(p) => p.theme.color.secondary};
  height: 30px;
  top: 50%;
  bottom: 50%;
  margin-top: -${(p) => p.theme.space.normal};
  z-index: 2;
`;

const TelephoneAppointmentPanel = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.panel.background.color.default};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 90%;
  max-width: 350px;
  height: 120px;
  padding: 20px;
  z-index: 3;
  text-align: center;
  display: table;
`;
const TelephoneAppointmentTextArea = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
class Calendar extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: null,
    slots: [],
  };

  // Life Cycle
  componentDidMount() {
    this.setState({
      selectedDate: this.props.selectedDate,
    });
  }
  // End Life Cycle

  // Events
  isAvailableDate = (date) => {
    const formattedDate = dateFns.format(date, "YYYY-MM-DD");
    return (
      this.props.slots &&
      this.props.slots.length &&
      this.props.slots.filter(
        (item) => dateFns.format(item.from, "YYYY-MM-DD") === formattedDate
      ).length > 0
    );
  };

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
    this.props.loadTimeOfSelectedDate(day);
  };

  nextMonth = () => {
    const currentMonth = dateFns.addMonths(this.state.currentMonth, 1);
    this.setState({
      currentMonth,
    });
    this.props.getSlots(currentMonth);
  };

  prevMonth = () => {
    const currentMonth = dateFns.subMonths(this.state.currentMonth, 1);
    this.setState({
      currentMonth,
    });
    this.props.getSlots(currentMonth);
  };
  // End Events

  // Render View methods
  renderHeader() {
    const renderHeaderResources =
      japaneseList.molecules.calendar.Calendar.renderHeader;
    const dateFormat = renderHeaderResources.j001;
    const isCurrentMonth = dateFns.isSameMonth(
      this.state.currentMonth,
      new Date()
    );
    return (
      <Header>
        <NavButton
          id="schedule-last-month-button"
          className={`nav-previous-btn ${isCurrentMonth ? "disabled-btn" : ""}`}
          onClick={!isCurrentMonth ? this.prevMonth : null}
        >
          <ArrowBackIos />
          <span>{renderHeaderResources.Header.Col.NavButton.j001}</span>
        </NavButton>
        <Col className="col-center">
          <HeaderText>
            {dateFns.format(this.state.currentMonth, dateFormat, {
              locale: ja,
            })}
          </HeaderText>
        </Col>
        <NavButton
          id="schedule-next-month-button"
          className="nav-next-btn"
          onClick={this.nextMonth}
        >
          <span>{renderHeaderResources.Header.Col.NavButton.j002}</span>
          <ArrowForwardIos />
        </NavButton>
      </Header>
    );
  }

  renderDays() {
    const dateFormat = "dd";
    const days = [];
    let startDate = dateFns.startOfWeek(this.state.currentMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <Col key={i}>
          <span>
            {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
              locale: ja,
            })}
          </span>
        </Col>
      );
    }
    return (
      <Days>
        <Row>{days}</Row>
      </Days>
    );
  }

  isSlotsEmpty(slots) {
    return slots ? Object.keys(slots).length === 0 : true;
  }

  renderCells() {
    const renderCellsResources =
      japaneseList.molecules.calendar.Calendar.renderCells;
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";

    // Get current date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // END get current date without time

    let days = [];
    let day = startDate;
    let formattedDate = "";

    const rows = [];
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat, {
          locale: ja,
        });

        const cloneDay = day;
        const isPreviousDay = day < today;
        const isAvailableDateInThisMOnth =
          this.isAvailableDate(day) && dateFns.isSameMonth(day, currentMonth);

        const cellOfDay = (
          <React.Fragment>
            <Day
              className={`${dateFns.isToday(day) ? "today" : ""} ${
                isAvailableDateInThisMOnth ? "available" : null
              }`}
            >
              {formattedDate}
            </Day>
            {isAvailableDateInThisMOnth ? <AvailableLabel /> : null}
            {isPreviousDay ? <SlashIcon /> : null}
          </React.Fragment>
        );

        let cellData = null;
        if (
          isAvailableDateInThisMOnth &&
          dateFns.isSameDay(day, selectedDate)
        ) {
          cellData = (
            <Cell
              className="selected"
              key={day}
              onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
            >
              {cellOfDay}
            </Cell>
          );
        } else if (isAvailableDateInThisMOnth) {
          cellData = (
            <Cell
              key={day}
              onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
            >
              {cellOfDay}
            </Cell>
          );
        } else {
          cellData = (
            <Cell key={day} className="disabled">
              {cellOfDay}
            </Cell>
          );
        }

        days.push(cellData);
        day = dateFns.addDays(day, 1);
      }
      rows.push(<Row key={day}>{days}</Row>);
      days = [];
    }
    return (
      <React.Fragment>
        <Body>
          {rows}
          {this.props.canTelephoneAppointment &&
            this.isSlotsEmpty(this.props.slots) && (
              <TelephoneAppointmentPanel>
                <TelephoneAppointmentTextArea>
                  <FontPCaution>
                    {renderCellsResources.TelephoneAppointmentPanel.j001}
                  </FontPCaution>
                  <FontP size="ss">
                    {renderCellsResources.TelephoneAppointmentPanel.j002}
                  </FontP>
                </TelephoneAppointmentTextArea>
              </TelephoneAppointmentPanel>
            )}
        </Body>
      </React.Fragment>
    );
  }
  // End Render View methods

  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </Container>
    );
  }
}

export default Calendar;
