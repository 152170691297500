import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { IconInfo } from "../atoms/icons/IconInfo";
import { ConfirmationPopup } from "../molecules/popup/ConfirmationPopup";
import ButtonOutlined from "../atoms/buttons/ButtonOutlined";
import FontPSmall from "../atoms/fonts/FontPSmall";
import FontPSS from "../atoms/fonts/FontPSS";
import LinkAnoUnder from "../atoms/links/LinkAnoUnder";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource =
  japaneseList.organisms.HomePageCheck.myNumberInsuranceCard;

const Container = styled.div`
  text-align: center;
  margin: 20px 0;
  padding: 0 20px;
`;
const WideOutlinedButton = styled(ButtonOutlined)`
  width: 100%;
  height: 41px;
`;
const WideOutlinedButtonText = styled.span`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallTextWrapper = styled.div`
  padding: 12px 0;
`;
const Small = styled.small`
  font-size: 0.7rem;
`;

const Link = styled(LinkAnoUnder)`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: left;
  width: fit-content;
  & > img {
    margin-right: 4px;
    margin-bottom: 2px;
  }
`;
const UnderLine = styled.span`
  text-decoration: underline;
`;

export const HomeMyNumberInsuranceCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleUploadInsuranceCard = async () => {
    history.push("/my-page/insurance-card");
  };

  const modalResource = renderResource.modal.text;
  const dialogContentText = (
    <>
      <FontPSmall>
        {modalResource.j001.j001}
        <strong>{modalResource.j001.j002_bold}</strong>
        {modalResource.j001.j003}
        <strong>{modalResource.j001.j004_bold}</strong>
        {modalResource.j001.j005}
      </FontPSmall>
      <FontPSmall>
        {modalResource.j002.j001}
        <Small>{modalResource.j002.j002_small}</Small>
        {modalResource.j002.j003}
      </FontPSmall>
      <SmallTextWrapper>
        <FontPSS>{modalResource.j003}</FontPSS>
      </SmallTextWrapper>
      <Link
        href={process.env.REACT_APP_OKBIZ_MY_NUMBER_CARD_HELP_URL}
        target="_blank"
        color="black"
      >
        <IconInfo />
        <UnderLine>{modalResource.j004}</UnderLine>
      </Link>
    </>
  );
  return (
    <>
      <Container>
        <WideOutlinedButton
          onClick={handleOpen}
          id="open-insurance-card-notification-button"
        >
          <WideOutlinedButtonText>
            {renderResource.title}
            <ChevronRightIcon />
          </WideOutlinedButtonText>
        </WideOutlinedButton>
      </Container>
      <ConfirmationPopup
        isOpen={isOpen}
        handleClickConfirm={handleUploadInsuranceCard}
        handleClosePopup={handleClose}
        confirmResource={renderResource.modal}
        buttonGAid={{ confirmButtonId: "upload-insurance-card-page-button" }}
        dialogContentText={dialogContentText}
      />
    </>
  );
};
