import styled from "styled-components";
import { SearchFromHospitalHistoriesDetail } from "./SearchFromHospitalHistoriesDetail";
import iconSeeMore from "../../../images/icon/icon-see-more.svg";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";

interface Props {
  appointments: Appointment[];
  loadMore: () => void;
  continueLoad: boolean;
}

interface Appointment {
  hospitalId: string;
  status: string;
  hospitalName: string;
  from: string;
  to: string;
  canCreateAppointment: boolean;
}

const renderResource = japaneseList.organisms.AppointmentHistoryPage.render;

const LoadMore = styled.div`
  padding: 15px 15px 35px 0;
  margin-bottom: 20px;
  cursor: pointer;
`;

const LoadMoreText = styled.div`
  display: flex;
  float: right;
`;

const FontPCustom = styled(FontP)`
  color: #393939;
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  line-height: 21px;
`;

const Img = styled.img`
  margin-left: 8px;
`;

export const SearchFromHospitalHistoriesDetailAndMore = (props: Props) => {
  const { appointments, continueLoad, loadMore } = props;

  return (
    <div>
      <SearchFromHospitalHistoriesDetail appointments={appointments} />
      {continueLoad && (
        <LoadMore id="see-more-appointment-history-button" onClick={loadMore}>
          <LoadMoreText>
            <FontPCustom>{renderResource.j001}</FontPCustom>
            <Img src={iconSeeMore} />
          </LoadMoreText>
        </LoadMore>
      )}
    </div>
  );
};
