import React from "react";

const SiteContext = React.createContext({
  /**
   * Site UI display mode.
   *
   * - (null) : default.
   * - "lnln" : "LunaLuna app".
   */
  ui: null,
  /** token */
  token: null,

  /** clinic id (used for transition to lnln site after reservation) */
  lnln_clinicId: null,

  /** reserveData (used for after login ,the information entered in the reservation page is set */
  reserveData: null,
  /** reserveInput (used for after login ,the information entered in the reservation page is set */
  reserveInput: null,
});

/**
 * This context is used by global state
 */
export default SiteContext;
