import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import AppointmentDetailContent from "../organisms/appointmentDetail/AppointmentDetailContent";
import { AppointmentDetailHeader } from "../organisms/appointmentDetail/AppointmentDetailHeader";
import { InterviewSheetAnswerContent } from "../organisms/appointmentDetail/InterviewSheetAnswerContent";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  GetAppointment,
  InterviewSheetAnswer,
  ReadPersonalNotification,
  Account,
  ConsentStatus,
} from "../../Utils/GraphQLQueries";
import { updateConsentStatusToComplete } from "../../Utils/ConsentRegistration";
import Titles from "../../Resources/Titles";
import { japaneseList } from "../../Resources/japaneseList";
import Heading1 from "../atoms/headings/Heading1";
import {
  APPOINTMENT_STATUS,
  INTERVIEW_SHEET_ANSWER_STATUS,
  PATIENT_ATTACHMENT,
} from "../../Utils/Constant";
import { isAfter, addHours, endOfDay } from "date-fns";
import { TabTitles } from "../molecules/TabTitles";
import { AppointmentFileSharing } from "../organisms/appointmentDetail/AppointmentFileSharing";
import { PatientAttachmentError } from "../organisms/PatientAttachmentError";
import { PatientMedicineDeliveryError } from "../organisms/PatientMedicineDeliveryError";
import { MedicineDeliveryEdit } from "../organisms/medicineDelivery/MedicineDeliveryEdit";
import Guard from "../../Utils/Guard";
import _ from "lodash";

const CONTENT = {
  APPOINTMENT_DETAIL: "appointmentDetail",
  INTERVIEW_SHEET: "interviewSheet",
  FILE_SHARING: "fileSharing",
  FILE_SHARING_ERROR: "fileSharingError",
  MEDICINE_DELIVERY_ERROR: "medicineDeliveryError",
};

const renderResource = japaneseList.pages.AppointmentDetailPage.render;

const { MAX_FILE_SIZE, VALID_FILE_EXTENSION, ERROR } = PATIENT_ATTACHMENT;

class AppointmentDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: null,
      isShowAppointmentDetail: true,
      isShowInterviewSheet: false,
      isShowFileSharing: false,
      isShowInterviewSheetAnswerEditButton: false,
      title: "appointmentDetail",
      thumbnailList: [],
      doctorThumbnailList: [],
      isLoading: true,
      patientInfo: null,
      isEditMedicineDelivery: false,
      onlineQualificationConsentStatus: {},
    };
    const { createTime } = this.props.match.params;
    const queryParams = new URLSearchParams(this.props.history.location.search);
    this.externalResultCode = queryParams.get("result_code");
    this.externalErrorCode = queryParams.get("error_code");
    this.params = { createTime };
  }

  readPersonalNotification = async (notificationId, notificationType) => {
    const { data, errors } = await GraphQLClient.mutate({
      mutation: ReadPersonalNotification,
      variables: { notificationId, notificationType },
    });
    if (errors) {
      console.error("ReadPersonalNotification errors:", JSON.stringify(errors));
    }

    if (data && data.readPersonalNotification) {
      Hub.dispatch("auth", { event: "updateNotificationNumber" });
    }
  };

  async componentDidMount() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isShowAppointmentDetail === false
    ) {
      this.setState({
        isShowAppointmentDetail: false,
      });
    }
    await this.load();
    if (
      this.props.location.state !== undefined &&
      this.props.history.location.state.isRead === true &&
      this.props.history.location.state.isUnreadNotification === true
    ) {
      await this.readPersonalNotification(
        this.props.history.location.state.notificationId,
        this.props.history.location.state.notificationType
      );
    }
    await this.returnFromOnlineQualification();
  }

  loadAppointment = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: GetAppointment,
      variables: { createTime: this.params.createTime },
    });
    if (errors) {
      console.log(errors);
    } else {
      const appointment = data.appointment;
      this.setState({
        appointment,
        thumbnailList: appointment.patientAttachments
          ? appointment.patientAttachments
          : [],
      });
      if (
        appointment &&
        (appointment.interviewSheetAnswerStatus ===
          INTERVIEW_SHEET_ANSWER_STATUS.UNANSWERED ||
          appointment.interviewSheetAnswerStatus ===
            INTERVIEW_SHEET_ANSWER_STATUS.ANSWERED)
      ) {
        this.loadInterviewSheetAnswer();
        this.judgeAppointmentStatus();
      }
      return appointment;
    }
  };

  loadInterviewSheetAnswer = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: InterviewSheetAnswer,
      variables: { createTime: this.params.createTime },
    });
    if (errors) {
      console.error("InterviewSheetAnswer errors:", JSON.stringify(errors));
    }
    this.setState({
      interviewSheetAnswer: data ? data.interviewSheetAnswer : null,
    });
  };

  loadConsentStatus = async (appointment) => {
    const { hospitalId, to } = appointment;
    const { data, errors } = await GraphQLClient.query({
      query: ConsentStatus,
      variables: { hospitalId: hospitalId, appointmentDate: to },
    });
    if (errors) {
      console.error("ConsentStatus errors:", JSON.stringify(errors));
    }
    this.setState({
      onlineQualificationConsentStatus: data ? data.consentStatus : {},
    });
  };

  shouldShowConsentRegistration = () => {
    const { to } = this.state.appointment;
    return (
      this.state.onlineQualificationConsentStatus
        ?.isSupportMyNumberInsuranceCard &&
      this.state.appointment?.menu?.medicalMethod !== "offline" &&
      this.isBeforeAppointmentDate(to)
    );
  };

  isBeforeAppointmentDate = (appointmentDate) => {
    const now = new Date();
    return endOfDay(now) <= endOfDay(appointmentDate);
  };

  // オンライン資格確認サイトからの遷移についての処理
  // オンライン資格確認から戻ってきた場合、同意登録ステータスを更新する
  returnFromOnlineQualification = async () => {
    const isReturnFromOnlineQualification = !!this.externalResultCode;
    if (!isReturnFromOnlineQualification) return;

    await updateConsentStatusToComplete(
      this.state.appointment,
      this.externalResultCode,
      this.externalErrorCode
    );
  };

  judgeAppointmentStatus = () => {
    const appointment = this.state.appointment;
    const now = new Date();
    if (
      appointment &&
      isAfter(appointment.from, addHours(now, 1).toISOString()) &&
      (appointment.status === APPOINTMENT_STATUS.BEFORE_EXAM ||
        appointment.status === APPOINTMENT_STATUS.UNAPPROVED)
    ) {
      this.setState({ isShowInterviewSheetAnswerEditButton: true });
    }
  };

  showAppointmentDetail = () => {
    this.setState({
      title: CONTENT.APPOINTMENT_DETAIL,
      reSelectFile: undefined,
      fileErrorType: undefined,
      isEditMedicineDelivery: false,
      errorType: undefined,
    });
    window.scrollTo(0, 0);
  };

  showInterrogation = () => {
    this.setState({
      title: CONTENT.INTERVIEW_SHEET,
    });
  };

  showFileSharing = () => {
    this.setState({
      title: CONTENT.FILE_SHARING,
    });
  };

  handleSelectImage = (selectImage) => {
    const invalidFileType = !VALID_FILE_EXTENSION.includes(selectImage.type);
    const invalidFileSize = selectImage.size > MAX_FILE_SIZE;
    if (invalidFileType || invalidFileSize) {
      this.setState({
        title: CONTENT.FILE_SHARING_ERROR,
        fileErrorType:
          invalidFileType && invalidFileSize
            ? ERROR.WRONG_TYPE_AND_SIZE
            : invalidFileType
            ? ERROR.WRONG_TYPE
            : ERROR.WRONG_SIZE,
      });
    } else {
      this.setState({
        title: CONTENT.FILE_SHARING,
        reSelectFile: selectImage,
        fileErrorType: undefined,
      });
    }
  };

  handleFailedUpdateMedicineDelivery = (errorType) => {
    this.setState({
      title: CONTENT.MEDICINE_DELIVERY_ERROR,
      isLoading: false,
      errorType: errorType,
    });
  };

  handleGoBackFileSharing = () => {
    this.setState({
      title: CONTENT.FILE_SHARING,
      reSelectFile: undefined,
      fileErrorType: undefined,
    });
  };

  handleEditButtonClick = () => {
    const appointment = this.state.appointment;
    this.props.history.push({
      pathname: `/interview/update/${appointment.createTime}`,
      state: {
        fromAppointmentDetail: true,
      },
    });
  };

  handleThumbnailList = (thumbnailList) => {
    this.setState({
      thumbnailList,
    });
  };

  handleDoctorThumbnailList = (doctorThumbnailList) => {
    this.setState({
      doctorThumbnailList,
    });
  };

  showEditMedicineDelivery = () => {
    this.setState({
      isEditMedicineDelivery: true,
      isLoading: true,
    });
    window.scrollTo(0, 0);
  };

  reloadAppointment = async (isUpdateSuccess) => {
    if (isUpdateSuccess) {
      await this.loadAppointment();
      this.setState({
        isLoading: false,
        isEditMedicineDelivery: false,
      });
      window.scrollTo(0, 0);
    }
  };

  loadAccount = async () => {
    const { data, errors } = await GraphQLClient.query({ query: Account });
    if (errors) {
      console.error("loadAccount errors:", JSON.stringify(errors));
    }
    this.setState({
      patientInfo: data.account,
    });
  };

  setIsLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  handleErrorGoBack = async () => {
    this.showAppointmentDetail();
    await this.loadAppointment();
    this.setState({
      isLoading: false,
    });
  };

  load = async () => {
    const [appointment, _account] = await Promise.all([
      this.loadAppointment(),
      this.loadAccount(),
    ]);

    await this.loadConsentStatus(appointment);
    this.setState({
      isLoading: false,
    });
  };

  showContent = () => {
    const {
      title,
      appointment,
      thumbnailList,
      doctorThumbnailList,
      interviewSheetAnswer,
      isShowInterviewSheetAnswerEditButton,
    } = this.state;
    switch (title) {
      case CONTENT.APPOINTMENT_DETAIL:
        return (
          <React.Fragment>
            {!this.state.isEditMedicineDelivery && (
              <AppointmentDetailContent
                appointment={appointment}
                patientInfo={this.state.patientInfo}
                showEditMedicineDelivery={this.showEditMedicineDelivery}
                shouldShowConsentRegistration={this.shouldShowConsentRegistration()}
                onlineQualificationConsentStatus={
                  this.state.onlineQualificationConsentStatus
                }
              />
            )}
            {this.state.isEditMedicineDelivery && (
              <MedicineDeliveryEdit
                appointment={appointment}
                patientInfo={this.state.patientInfo}
                reloadAppointment={this.reloadAppointment}
                showAppointmentDetail={this.showAppointmentDetail}
                setIsLoading={this.setIsLoading}
                handleFailedUpdateMedicineDelivery={
                  this.handleFailedUpdateMedicineDelivery
                }
              />
            )}
          </React.Fragment>
        );
      case CONTENT.INTERVIEW_SHEET:
        return (
          <InterviewSheetAnswerContent
            interviewSheetAnswer={interviewSheetAnswer}
            isShowInterviewSheetAnswerEditButton={
              isShowInterviewSheetAnswerEditButton
            }
            handleEditButtonClick={this.handleEditButtonClick}
          />
        );
      case CONTENT.FILE_SHARING:
        return (
          <AppointmentFileSharing
            handleThumbnailList={this.handleThumbnailList}
            handleDoctorThumbnailList={this.handleDoctorThumbnailList}
            thumbnailList={thumbnailList}
            doctorThumbnailList={doctorThumbnailList}
            reSelectFile={this.state.reSelectFile}
            appointment={appointment}
            showFileSharingError={this.handleSelectImage}
          />
        );
      case CONTENT.FILE_SHARING_ERROR:
        return (
          <PatientAttachmentError
            errorType={this.state.fileErrorType}
            handleSelectImage={this.handleSelectImage}
            handleGoBack={this.handleGoBackFileSharing}
          />
        );
      case CONTENT.MEDICINE_DELIVERY_ERROR:
        return (
          <PatientMedicineDeliveryError
            errorType={this.state.errorType}
            handleErrorGoBack={this.handleErrorGoBack}
          />
        );
      default:
        break;
    }
  };

  render() {
    if (!this.state.appointment) {
      return null;
    }
    const { classes } = this.props;
    const { appointment, title, isLoading } = this.state;
    const isShowInterrogation =
      appointment &&
      (appointment.interviewSheetAnswerStatus ===
        INTERVIEW_SHEET_ANSWER_STATUS.UNANSWERED ||
        appointment.interviewSheetAnswerStatus ===
          INTERVIEW_SHEET_ANSWER_STATUS.ANSWERED);
    return (
      <AuthenticatedTemplate
        isLoading={isLoading}
        title={Titles.appointment}
        main={
          <React.Fragment>
            <Heading1>{renderResource.Head.j001}</Heading1>
            <AppointmentDetailHeader
              appointment={appointment}
              classes={classes}
            />
            {appointment && (
              <TabTitles
                title={
                  title === CONTENT.FILE_SHARING_ERROR
                    ? CONTENT.FILE_SHARING
                    : title === CONTENT.MEDICINE_DELIVERY_ERROR
                    ? CONTENT.APPOINTMENT_DETAIL
                    : title
                }
                isShowInterrogation={isShowInterrogation}
                showAppointmentDetail={this.showAppointmentDetail}
                showInterrogation={this.showInterrogation}
                showFileSharing={this.showFileSharing}
                content={CONTENT}
              />
            )}
            {this.showContent()}
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(Guard(AppointmentDetailPage));
