import React, { Component } from "react";
import styled from "styled-components";
import Heading1 from "../atoms/headings/Heading1";
import InputText from "../atoms/forms/InputText";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import InfoMessage from "../molecules/InfoMessage";
import Typography2 from "../atoms/fonts/Typography2";
import OnlineChatDialogHeader from "./online-chat/OnlineChatDialogHeader";
import OnlineChatDialogBody from "./online-chat/OnlineChatDialogBody";
import { japaneseList } from "../../Resources/japaneseList";
import GraphQLClient from "../../Utils/GraphQLClient";
import { UpdatePatientEmail } from "../../Utils/GraphQLQueries";

const Container = styled.div`
  padding: 0 15px;
`;

const InputTextCode = styled(InputText).attrs({ type: "tel" })`
  margin: 0 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  border: 2px solid ${(p) => p.theme.color.secondary};
  color: ${(p) => p.theme.color.font};
  font-size: 1rem;
`;

const Content = styled.div`
  color: ${(p) => p.theme.color.font};
  size: 0.9rem;
  line-height: 1.5em;
  text-align: center;
  padding-bottom: 30px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.color.font};
  size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 30px 0;
`;

const InputContainer = styled.div`
  padding: 0 0 30px 0;
  text-align: center;
`;

const ResendCode = styled.div`
  color: ${(p) => p.theme.color.primary};
  size: 0.8rem;
  text-decoration: underline;
  text-align: center;
  padding-bottom: 15px;
  cursor: pointer;
`;

const Error = styled.div`
  color: ${(p) => p.theme.color.warning};
  size: 0.8rem;
  line-height: 1.5em;
  padding-bottom: 15px;
  text-align: center;
`;

const DialogActions = styled.div`
  justify-content: "space-around";
  border-top: 1px solid ${(p) => p.theme.color.secondary};
  margin: 0;
`;

const DialogButton = styled.div`
  font-weight: bold;
  color: ${(p) => p.theme.color.primary};
  padding: 15px 0;
  text-align: center;
  cursor: pointer;
`;

class VerifyEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayError: false,
      backgroundColorError: "#ffffff",
      openDialog: false,
    };
  }

  handleInputChange = async (evt) => {
    const { name, value } = evt.target;
    await this.setState({
      [name]: value,
    });
    if (value) {
      let next = this.refs[name].nextSibling;
      if (next && next.tagName === "INPUT") {
        this.refs[name].nextSibling.focus();
      }
    }
    let code = "";
    for (let i = 1; i < 7; i++) {
      code += this.refs["code_" + i].value;
    }
    if (code.length === 6) {
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", code);
        const { errors } = await GraphQLClient.mutate({
          mutation: UpdatePatientEmail,
          variables: { email: this.props.location.state.email },
        });
        if (errors) throw new Error(errors[0].errorType);

        let from = this.props.location.state && this.props.location.state.from;
        if (from === "mypage")
          this.props.history.push({
            pathname: "/my-page",
            state: { status: "succeed" },
          });
        else this.props.history.push("/my-page/email/complete");
      } catch (error) {
        this.setState({
          displayError: true,
          backgroundColorError: "#fff0f0",
        });
        for (let i = 1; i < 7; i++) {
          this.refs["code_" + i].value = "";
        }
        this.refs["code_1"].focus();
      }
    }
  };

  async resendCode() {
    try {
      await Auth.verifyCurrentUserAttribute("email");
      this.setState({
        openDialog: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleCloseDialog() {
    this.setState({
      openDialog: false,
    });
  }

  render() {
    const renderResource = japaneseList.organisms.VerifyEmailForm.render;
    return (
      <div>
        <Heading1>{renderResource.div.PrimaryHeading.j001}</Heading1>
        <Container>
          <Title>{renderResource.div.Container.Title.j001}</Title>
          <Content>
            {renderResource.div.Container.Content.j001}
            <br />
            {renderResource.div.Container.Content.j002}
          </Content>
          {this.state.displayError && (
            <Error>
              {renderResource.div.Container.Error.j001} <br />
              {renderResource.div.Container.Error.j002}
            </Error>
          )}
          <InputContainer>
            <InputTextCode
              maxLength="1"
              name="code_1"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_1"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
            <InputTextCode
              maxLength="1"
              name="code_2"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_2"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
            <InputTextCode
              maxLength="1"
              name="code_3"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_3"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
            <InputTextCode
              maxLength="1"
              name="code_4"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_4"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
            <InputTextCode
              maxLength="1"
              name="code_5"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_5"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
            <InputTextCode
              maxLength="1"
              name="code_6"
              value={this.state.email}
              onChange={(e) => this.handleInputChange(e)}
              ref="code_6"
              style={{
                backgroundColor: this.state.backgroundColorError,
              }}
            />
          </InputContainer>
          <ResendCode
            onClick={() => this.props.history.push(`/my-page/email/register`)}
          >
            {renderResource.div.Container.ResendCode.j001}
          </ResendCode>
          <ResendCode onClick={() => this.resendCode()}>
            {renderResource.div.Container.ResendCode.j002}
          </ResendCode>
        </Container>
        <Dialog
          open={this.state.openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <OnlineChatDialogHeader>
            {renderResource.div.Dialog.OnlineChatDialogHeader.j001}
          </OnlineChatDialogHeader>
          <OnlineChatDialogBody>
            <InfoMessage>
              <Typography2>
                {
                  renderResource.div.Dialog.OnlineChatDialogBody.InfoMessage
                    .Typography2.j001
                }
              </Typography2>
            </InfoMessage>
          </OnlineChatDialogBody>
          <DialogActions>
            <DialogButton onClick={() => this.handleCloseDialog()}>
              OK
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(VerifyEmailForm);
