import React, { Component } from "react";
import styled from "styled-components";
import FontP from "../atoms/fonts/FontP";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource =
  japaneseList.molecules.RejectedCommentAppointmentDetail.render;
const Panel = styled.div`
  margin-bottom: 15px;
`;

class RejectedCommentAppointmentDetail extends Component {
  render() {
    const { appointment } = this.props;
    let rejectComment;
    switch (appointment.rejectSelectedOption) {
      case -1:
        rejectComment = appointment.rejectComment;
        break;

      case 1:
        rejectComment = renderResource.Container.rejectComment.j001;
        break;

      default:
        rejectComment = "";
        break;
    }
    return (
      appointment.status === "rejected" && (
        <Panel>
          <FontP color="accent" weight="bold">
            {renderResource.Container.Title.j001}
          </FontP>
          <FontP style={{ wordBreak: "break-all" }}>{rejectComment}</FontP>
        </Panel>
      )
    );
  }
}
export default RejectedCommentAppointmentDetail;
