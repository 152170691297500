import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import gql from "graphql-tag";
import { format } from "date-fns";
import * as Sentry from "@sentry/browser";

import client from "../../../Utils/GraphQLClient";
import { isLnln } from "../../../Utils/checkLnln";
import PaymentDialog from "./PaymentDialog";
import ErrorList from "./PaymentErrorList";
import RecaptchaErrorList from "../recaptcha/RecaptchaErrorList";

import InputTel from "../../atoms/forms/InputTel";
import FontP from "../../atoms/fonts/FontP";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import SelectMonth from "../../molecules/forms/SelectMonth";
import SelectYear from "../../molecules/forms/SelectYear";
import InvisibleRecaptcha from "../recaptcha/InvisibleRecaptcha";

import { japaneseList } from "../../../Resources/japaneseList";

import ImgVisaPath from "../../../images/carada/visa.png";
import ImgVisaPathLnln from "../../../images/lnln/visa.png";
import imgMasterPath from "../../../images/carada/master.png";
import imgMasterPathLnln from "../../../images/lnln/master.png";
import imgJcbPath from "../../../images/carada/jcb.png";
import imgJcbPathLnln from "../../../images/lnln/jcb.png";
import imgAmexPath from "../../../images/carada/amex.png";
import imgAmexPathLnln from "../../../images/lnln/amex.png";
import imgDinersPath from "../../../images/carada/diners.png";
import imgDinersPathLnln from "../../../images/lnln/diners.png";
import { Account } from "../../../Utils/GraphQLQueries";

const imgVisa = isLnln() ? ImgVisaPathLnln : ImgVisaPath;
const imgMaster = isLnln() ? imgMasterPathLnln : imgMasterPath;
const imgJcb = isLnln() ? imgJcbPathLnln : imgJcbPath;
const imgAmex = isLnln() ? imgAmexPath : imgAmexPathLnln;
const imgDiners = isLnln() ? imgDinersPath : imgDinersPathLnln;

const PaymentCardUpdateResource =
  japaneseList.organisms.payment.PaymentCardUpdate;

const ImageBlock = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 38px;
`;

const CustomFontP = styled(FontP)`
  margin: 0 0 0.5em 0;
`;
const CustomFontPBold = styled(FontP)`
  margin: 0;
  font-weight: bold;
`;

const InputTelFull = styled(InputTel)`
  width: 100%;
  margin-bottom: 20px;
`;

const Spacer = styled.span`
  display: inline-block;
  padding: 4px;
`;

const Question = styled.p`
  text-align: right;
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  cursor: pointer;
`;

class PaymentCardUpdate extends Component {
  static defaultProps = {
    handleClickRegisterButton: function () {},
    showError: function () {},
    completeRegisterCard: function () {},
    cardSeq: null,
  };

  state = {
    cardNo: "",
    expireMonth: "01",
    expireYear: format(new Date(), "YYYY"),
    securityCode: "",
    holderName: "",
    openDialog: false,
    isCardRegistered: false,
  };

  constructor(props) {
    super(props);

    // Append Script tag for Payment Service's library
    if (!document.getElementById("payment-lib-script")) {
      const script = document.createElement("script");
      script.id = "payment-lib-script";
      script.src = process.env.REACT_APP_PAYMENT_LIB_PATH;
      document.head.appendChild(script);
    }
  }

  async componentDidMount() {
    this.isCardRegistered();
  }

  handleChange = (event) => {
    const target = event.currentTarget;
    this.setState({
      [target.name]: target.value,
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  showTokenError(errorCode) {
    const message = ErrorList[errorCode] || errorCode;
    this.props.showError(message);
  }

  registerCreditCard = async (gmoTokens, recaptchaToken) => {
    const RegisterCreditCard = gql`
      mutation RegisterCard(
        $gmoToken: String!
        $cardSeq: Int
        $recaptchaToken: String!
      ) {
        registerCreditCard(
          gmoToken: $gmoToken
          cardSeq: $cardSeq
          recaptchaToken: $recaptchaToken
        )
      }
    `;

    const { errors } = await client
      .mutate({
        mutation: RegisterCreditCard,
        variables: {
          gmoToken: gmoTokens[0],
          cardSeq: this.props.cardSeq,
          recaptchaToken,
        },
      })
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      });

    if (errors) {
      const RecaptchaErrorTypes = Object.keys(RecaptchaErrorList);
      const errorMessages = errors.map((error) => {
        const errorType = error.errorType;
        if (RecaptchaErrorTypes.includes(errorType)) {
          return RecaptchaErrorList[errorType];
        } else {
          return PaymentCardUpdateResource.onGetToken.j001;
        }
      });
      this.props.showError(errorMessages[0]);
      return;
    }

    Sentry.captureMessage("credit-card-create", Sentry.Severity.Log);

    this.props.completeRegisterCard();
  };

  isCardRegistered = async () => {
    const {
      data: { account },
    } = await client.query({ query: Account });
    if (account && account.cardStatus === "registered") {
      this.setState({ isCardRegistered: true });
    }
  };

  recaptchaTokenCallback = (recaptchaToken) => {
    this.props.handleClickRegisterButton();
    if (!this.props.cardSeq && this.state.isCardRegistered) {
      this.props.showError(
        japaneseList.pages.payment.RegisterPage.validateLimitOneCard.j001
      );
      return;
    }
    window.tokenCallback = (response) => {
      if (response.resultCode !== "000") {
        this.showTokenError(response.resultCode);
        return;
      }

      const gmoToken = response.tokenObject.token;
      this.registerCreditCard(gmoToken, recaptchaToken);
    };

    const { cardNo, expireYear, expireMonth, securityCode, holderName } =
      this.state;
    const cardInfo = {
      cardno: cardNo,
      expire: expireYear + expireMonth,
      securitycode: securityCode,
      holdername: holderName,
      tokennumber: 1,
    };

    window.Multipayment.init(process.env.REACT_APP_PAYMENT_SHOP_ID);
    window.Multipayment.getToken(cardInfo, window.tokenCallback);
  };

  render() {
    const { cardNo, expireYear, expireMonth, securityCode } = this.state;
    return (
      <React.Fragment>
        <CustomFontP>{PaymentCardUpdateResource.render.Desc.j001}</CustomFontP>
        <ImageBlock>
          <Image src={imgVisa} />
          <Image src={imgMaster} />
          <Image src={imgJcb} />
          <Image src={imgAmex} />
          <Image src={imgDiners} style={{ marginLeft: "5px" }} />
        </ImageBlock>
        <Grid container>
          <Grid item xs={12}>
            <CustomFontPBold>
              {PaymentCardUpdateResource.render.TitleText.j001}
            </CustomFontPBold>
            <InputTelFull
              name="cardNo"
              placeholder={PaymentCardUpdateResource.render.Placeholder.j001}
              maxLength={16}
              value={cardNo}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomFontPBold>
              {PaymentCardUpdateResource.render.TitleText.j002}
            </CustomFontPBold>
            <SelectMonth
              name="expireMonth"
              value={expireMonth}
              onChange={this.handleChange}
            />
            <Spacer>/</Spacer>
            <SelectYear
              name="expireYear"
              value={expireYear}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomFontPBold>
              {PaymentCardUpdateResource.render.TitleText.j003}
            </CustomFontPBold>
            <InputTelFull
              name="securityCode"
              placeholder={PaymentCardUpdateResource.render.Placeholder.j003}
              maxLength={4}
              value={securityCode}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        <Question onClick={this.handleOpen}>
          {PaymentCardUpdateResource.render.Question.j001}
        </Question>
        <InvisibleRecaptcha
          recaptchaTokenCallback={this.recaptchaTokenCallback}
          showError={this.props.showError}
        >
          <ButtonFullWidth>
            {this.props.cardSeq
              ? PaymentCardUpdateResource.render.Button.j001
              : PaymentCardUpdateResource.render.Button.j002}
          </ButtonFullWidth>
        </InvisibleRecaptcha>
        <PaymentDialog
          openDialog={this.state.openDialog}
          handleClose={this.handleClose}
        />
      </React.Fragment>
    );
  }
}

export default PaymentCardUpdate;
