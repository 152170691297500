import React, { useEffect, useState, useCallback } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import Heading1 from "../atoms/headings/Heading1";
import { PatientAttachmentError } from "../organisms/PatientAttachmentError";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import { PATIENT_ATTACHMENT } from "../../Utils/Constant";

const renderResource = japaneseList.pages.ReservationUploadFileErrorPage.render;

const { MAX_FILE_SIZE, VALID_FILE_EXTENSION, ERROR } = PATIENT_ATTACHMENT;

const Heading1Custom = styled(Heading1)`
  font-size: 17px;
`;

export const ReservationUploadFileErrorPage = (props) => {
  const [errorType, setErrorType] = useState(null);
  const [errorFile, setErrorFile] = useState(
    props.location.state ? props.location.state.errorFile : null
  );

  const handleSelectImage = useCallback((selectImage) => {
    const invalidFileType = !VALID_FILE_EXTENSION.includes(selectImage.type);
    const invalidFileSize = selectImage.size > MAX_FILE_SIZE;
    if (invalidFileType || invalidFileSize) {
      setErrorFile(selectImage);
    } else {
      props.history.push(props.location.state.from, {
        ...props.location.state,
        reUploadedFile: selectImage, // Select file again after error
      });
    }
  });

  const handleGoBack = useCallback(() => {
    props.history.push(props.location.state.from, {
      ...props.location.state,
    });
  });

  useEffect(() => {
    if (errorFile) {
      const invalidFileType = !VALID_FILE_EXTENSION.includes(errorFile.type);
      const invalidFileSize = errorFile.size > MAX_FILE_SIZE;
      setErrorType(
        invalidFileType && invalidFileSize
          ? ERROR.WRONG_TYPE_AND_SIZE
          : invalidFileType
          ? ERROR.WRONG_TYPE
          : ERROR.WRONG_SIZE
      );
    }
  });

  const main = (
    <React.Fragment>
      <Heading1Custom>{renderResource.Titles.j001}</Heading1Custom>
      <PatientAttachmentError
        errorType={errorType}
        handleSelectImage={handleSelectImage}
        handleGoBack={handleGoBack}
      ></PatientAttachmentError>
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate title={renderResource.Titles.j001} main={main} />
  );
};
