import { japaneseList } from "../../../Resources/japaneseList";

const PaymentErrorListResource =
  japaneseList.organisms.payment.PaymentErrorList;
const ErrorList = {
  "000": PaymentErrorListResource.j001,
  "100": PaymentErrorListResource.j002,
  "101": PaymentErrorListResource.j003,
  "102": PaymentErrorListResource.j004,
  "110": PaymentErrorListResource.j005,
  "111": PaymentErrorListResource.j006,
  "112": PaymentErrorListResource.j007,
  "113": PaymentErrorListResource.j008,
  "121": PaymentErrorListResource.j009,
  "122": PaymentErrorListResource.j010,
  "131": PaymentErrorListResource.j011,
  "132": PaymentErrorListResource.j012,
  "141": PaymentErrorListResource.j013,
  "142": PaymentErrorListResource.j014,
  "150": PaymentErrorListResource.j015,
  "160": PaymentErrorListResource.j016,
  "161": PaymentErrorListResource.j017,
  "162": PaymentErrorListResource.j018,
  "170": PaymentErrorListResource.j019,
  "180": PaymentErrorListResource.j020,
  "190": PaymentErrorListResource.j021,
  "191": PaymentErrorListResource.j022,
  "501": PaymentErrorListResource.j023,
  "502": PaymentErrorListResource.j024,
  "511": PaymentErrorListResource.j025,
  "512": PaymentErrorListResource.j026,
  "521": PaymentErrorListResource.j027,
  "522": PaymentErrorListResource.j028,
  "531": PaymentErrorListResource.j029,
  "541": PaymentErrorListResource.j030,
  "551": PaymentErrorListResource.j031,
  "552": PaymentErrorListResource.j032,
  "901": PaymentErrorListResource.j033
};

export default ErrorList;
