import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.space.short} 8px ${(p) => p.theme.space.short}
    ${(p) => p.theme.space.normal};
  color: ${(p) => p.theme.color.secondary};
  line-height: 1.5;
`;

const CardContent = styled.div`
  color: ${(p) => p.theme.color.font};
`;

export default ({ children, disabled, className = undefined }) => (
  <Card className={className}>
    <CardContent>{children}</CardContent>
    {!disabled && <ChevronRightIcon />}
  </Card>
);
