import styled from "styled-components";

export default styled.input.attrs({ type: "file" })`
  height: 35px;
  color: ${(p) => p.theme.color.font};
  border: 1px solid ${(p) => p.theme.color.fontVariant};
  padding: 6px 5px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: solid 1px #c3c3c3;
  border-radius: 0;
  -webkit-appearance: none;
  :focus {
    background-color: #fff;
    outline: 0;
  }
`;
