import { Auth, Hub } from "aws-amplify";
import { AuthContext } from "./AuthContext";
import { useState, useEffect } from "react";

export const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadIsAuthenticated();
    loadAuthenticatedUser();

    // Auth.Hub listenner setting
    const authListener = (data) => {
      switch (data.payload.event) {
        case "signOut":
          signOut();
          break;
        case "signIn":
          signIn();
          break;
        default:
          break;
      }
    };
    Hub.listen("auth", authListener);

    // Auth.Hub listenner clean up
    return () => Hub.remove("auth", authListener);
  }, []);

  const loadIsAuthenticated = async () => {
    setIsLoading(true);
    return Auth.currentSession()
      .then((data) => {
        if (data) setIsAuthenticated(true);
      })
      .catch((err) => console.log("current session error", err))
      .finally(() => setIsLoading(false));
  };

  const loadAuthenticatedUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => console.log("current authenticated user error", err));
  };

  const signIn = async () => {
    return Promise.all([loadIsAuthenticated(), loadAuthenticatedUser()]);
  };

  const signOut = () => {
    setIsAuthenticated(false);
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        signIn,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
