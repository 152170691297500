import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import ApplicationPrivacyPolicy from "../organisms/ApplicationPrivacyPolicy";
import { withRouter } from "react-router-dom";
import Titles from "../../Resources/Titles";

class ApplicationPrivacyPolicyPage extends Component {
  render() {
    return <AuthenticatedTemplate title={Titles.applicationPrivacyPolicy} main={<ApplicationPrivacyPolicy />} />;
  }
}

export default withRouter(ApplicationPrivacyPolicyPage);
