import React from "react";
import styled from "styled-components";
import ButtonTopChild from "../atoms/buttons/ButtonTopChild";
import { Link } from "react-router-dom";

const TopButton = styled.div`
  padding: 30px 40px;
  margin-bottom: 0;
  background-color: ${(p) => p.theme.color.secondaryVariant};
`;

export default ({ children, ...props }) => (
  <TopButton>
    <Link to="/" style={{ textDecoration: "none" }}>
      <ButtonTopChild id="top-button">{children}</ButtonTopChild>
    </Link>
  </TopButton>
);
