import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { PATIENT_ATTACHMENT } from "../../../Utils/Constant";

const renderResource = japaneseList.pages.ReservationUploadFileErrorPage.render;

const ERROR = PATIENT_ATTACHMENT.ERROR;

const Text = styled.p`
  font-size: 16px;
  margin: 10px 18px;
  line-height: 27px;
  color: #333333;
`;

export const FileError = (props) => {
  const { errorType } = props;
  return (
    <React.Fragment>
      {errorType !== ERROR.WRONG_SIZE && (
        <Text padding="l">
          {renderResource.Errors.j001}
          <br />
          {renderResource.Errors.j002}
        </Text>
      )}
      {errorType !== ERROR.WRONG_TYPE && (
        <Text padding="l">
          {renderResource.Errors.j003}
          <br />
          {renderResource.Errors.j004}
        </Text>
      )}
    </React.Fragment>
  );
};
