import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: inline-block;
  border: none;
  box-sizing: border-box;
  background: none;
  color: ${p => p.theme.color.primary};
  text-align: center;
`;

export default ({ children, ...props }) => <Root {...props}>{children}</Root>;
