import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Dialog, DialogActions } from "@material-ui/core";
import AccountBox from "@material-ui/icons/AccountBox";

import { withPolling } from "../../../contexts/PollingContext";

import GraphQLClient from "../../../Utils/GraphQLClient";
import { GetTwilioToken } from "../../../Utils/GraphQLQueries";

import FontP from "../../atoms/fonts/FontPCenter";
import Button from "../../atoms/buttons/Button";

import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";

const ONLINE_CHAT_URL = "/telemedicine/chat";
const ONLINE_CHAT_URL_LNLN = "/telemedicine/chat?lnln_ob=1";
const ONLINE_CHAT_STANDBY_URL = "/telemedicine/standby";
const styles = (theme) => ({
  icon: {
    width: "76px",
    height: "76px",
    marginTop: "20px",
    marginBottom: "15px",
  },
  dialogDescription: {
    fontSize: "14px",
    color: "#f26666",
  },
});

const Title = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
`;

const DialogContent = styled.div`
  flex: 1 1 auto;
  padding: 24px;
  overflow-y: auto;
`;

const CustomDialogActions = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 0 40px 0",
    margin: 0,
  },
})(DialogActions);

class AppointmentReminder extends Component {
  state = {
    openNotificationDialog: false,
    intervalId: 0,
    open: false,
    twilioData: null,
  };

  componentDidMount() {
    const intervalId = setInterval(this.detectVideoChatIsCalled, 5000);
    const { addPollingId } = this.props.pollingContext;
    addPollingId("AppointmentReminder", intervalId);
    this.setState({
      intervalId: intervalId,
    });
  }

  componentWillUnmount() {
    this.restInterval();
  }

  handlePopup = async () => {
    this.setState({
      openNotificationDialog: false,
    });

    const { ui } = this.props;
    if (ui === "lnln") {
      const {
        data: { twilio },
      } = await GraphQLClient.query({
        query: GetTwilioToken,
      });
      if (twilio && twilio.token) {
        window.location.href = ONLINE_CHAT_URL_LNLN;
        return;
      }
    }
    this.props.history.push(ONLINE_CHAT_URL);
  };

  detectVideoChatIsCalled = async () => {
    if (
      this.props.history.location.pathname.includes(ONLINE_CHAT_URL) ||
      this.state.openNotificationDIalog
    ) {
      return;
    }

    const {
      data: { twilio },
    } = await GraphQLClient.query({
      query: GetTwilioToken,
    });

    if (twilio && twilio.token) {
      this.restInterval();
      if (
        this.props.history.location.pathname.includes(ONLINE_CHAT_STANDBY_URL)
      ) {
        this.setState({
          open: false,
          twilioData: twilio,
        });
        Sentry.captureMessage("video-chat-popup-start", Sentry.Severity.Log);
        this.handlePopup();
      } else {
        this.setState({
          open: true,
          twilioData: twilio,
        });
        Sentry.captureMessage("video-chat-popup-start", Sentry.Severity.Log);
      }
    }
  };

  restInterval() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const renderResource = japaneseList.Components.AppointmentReminder;
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.open}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <FontP>
            <AccountBox color={"primary"} className={classes.icon} />
            <Title>{renderResource.Dialog.DialogTitle.j001}</Title>
          </FontP>
          <FontP className={classes.dialogDescription}>
            {renderResource.Dialog.DialogDescription.j002}
          </FontP>
        </DialogContent>
        <CustomDialogActions>
          <Button onClick={this.handlePopup}>
            {renderResource.Dialog.Button.j001}
          </Button>
        </CustomDialogActions>
      </Dialog>
    );
  }
}

export default withPolling(withRouter(withStyles(styles)(AppointmentReminder)));
