import styled from "styled-components";
import { lighten } from "polished";

const Button = styled.button`
  padding: ${(p) =>
    p.padding && p.theme.button.padding[p.padding]
      ? p.theme.button.padding[p.padding]
      : p.theme.button.padding.default};
  box-sizing: border-box;
  color: ${(p) => p.theme.button.font.color.default};
  font-size: 1rem;
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  background-color: ${(p) => p.theme.button.background.color.default};
  transition: background-color 0.3s ease;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};

  :active {
    background-color: ${(p) => lighten(0.1, p.theme.color.primary)};
  }

  :disabled {
    background-color: ${(p) => p.theme.button.background.color.disabled};
    color: ${(p) => p.theme.button.font.color.disabled};
  }
`;

export default Button;
