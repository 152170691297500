import React from "react";
import styled from "styled-components";
import InfoMessage from "../../molecules/InfoMessage";
import { japaneseList } from "../../../Resources/japaneseList";
import { NOTIFICATION_BY } from "../../../Utils/Constant";

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short};
`;

const NotificationSettingsNote = styled.div`
  margin: 20px 0 4px;
`;

const MainMessage = styled(InfoMessage)`
  font-size: 0.8em;
`;

const BoldMessage = styled(InfoMessage)`
  font-weight: bold;
  display: inline;
`;

const SecondaryInfoMessage = styled(InfoMessage)`
  font-size: 0.8em;
  color: ${(p) => p.theme.color.fontVariant};
  margin-top: 5px;
`;

/**
 * @param {string} notificationBy
 */

export const NotificationDescription = ({ notificationBy }) => {
  const RenderSelectFormResource =
    japaneseList.organisms.reservationForm.ReservationForm.RenderSelectForm;

  return (
    <FormGroup>
      <NotificationSettingsNote>
        <MainMessage>
          {
            RenderSelectFormResource.Fragment.Fragment.FormGroup
              .NotificationSettingsNote.MainMessage.j001
          }
          <BoldMessage>
            {notificationBy === NOTIFICATION_BY.SMS
              ? RenderSelectFormResource.Fragment.Fragment.FormGroup
                  .NotificationSettingsNote.MainMessage.BoldMessage.j001
              : RenderSelectFormResource.Fragment.Fragment.FormGroup
                  .NotificationSettingsNote.MainMessage.BoldMessage.j002}
          </BoldMessage>
          {
            RenderSelectFormResource.Fragment.Fragment.FormGroup
              .NotificationSettingsNote.MainMessage.j002
          }
        </MainMessage>
        {notificationBy === NOTIFICATION_BY.SMS && (
          <SecondaryInfoMessage>
            {
              RenderSelectFormResource.Fragment.Fragment.FormGroup
                .NotificationSettingsNote.SecondaryInfoMessage.j001
            }
          </SecondaryInfoMessage>
        )}
      </NotificationSettingsNote>
    </FormGroup>
  );
};
