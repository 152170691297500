import React, { Component } from "react";

import HeadingForm from "../../molecules/headings/HeadingForm";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import Radio from "../../atoms/forms/Radio";
import RadioGroup from "../../molecules/RadioGroup";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.RadioSex;

class RadioSex extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadingForm>{renderResource.Title}</HeadingForm>
        <PanelFormArea>
          <RadioGroup name="gender" {...this.props}>
            <Radio name="gender" value={renderResource.Radio.j001}>
              {renderResource.Radio.j001}
            </Radio>
            <Radio name="gender" value={renderResource.Radio.j002}>
              {renderResource.Radio.j002}
            </Radio>
          </RadioGroup>
        </PanelFormArea>
      </React.Fragment>
    );
  }
}

export default RadioSex;
