import React from "react";
import styled from "styled-components";

const TextStyle = styled.div`
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  word-break: break-all;
`;

const ThumbnailItemText = (props) => {
  return <TextStyle>{props.text}</TextStyle>;
};

export default ThumbnailItemText;
