import React from 'react';
import { Helmet } from 'react-helmet';
import { japaneseList } from '../../../Resources/japaneseList';

const Favicon = (props) => {
  const servicePath=props.servicePath;
  const serviceName = japaneseList.service.name;
  return (
    <Helmet>
      <link rel='apple-touch-icon' sizes='57x57' href={`/${servicePath}/favicon/apple-icon-57x57.png`} />
      <link rel='apple-touch-icon' sizes='60x60' href={`/${servicePath}/favicon/apple-icon-60x60.png`} />
      <link rel='apple-touch-icon' sizes='72x72' href={`/${servicePath}/favicon/apple-icon-72x72.png`} />
      <link rel='apple-touch-icon' sizes='76x76' href={`/${servicePath}/favicon/apple-icon-76x76.png`} />
      <link rel='apple-touch-icon' sizes='114x114' href={`/${servicePath}/favicon/apple-icon-114x114.png`} />
      <link rel='apple-touch-icon' sizes='120x120' href={`/${servicePath}/favicon/apple-icon-120x120.png`} />
      <link rel='apple-touch-icon' sizes='144x144' href={`/${servicePath}/favicon/apple-icon-144x144.png`} />
      <link rel='apple-touch-icon' sizes='152x152' href={`/${servicePath}/favicon/apple-icon-152x152.png`} />
      <link rel='apple-touch-icon' sizes='180x180' href={`/${servicePath}/favicon/apple-icon-180x180.png`} />
      <link rel='icon' type='image/png' sizes='192x192' href={`/${servicePath}/favicon/android-icon-192x192.png`} />
      <link rel='icon' type='image/png' sizes='144x144' href={`/${servicePath}/favicon/android-icon-144x144.png`} />
      <link rel='icon' type='image/png' sizes='96x96' href={`/${servicePath}/favicon/favicon-96x96.png`} />
      <link rel='icon' type='image/png' sizes='72x72' href={`/${servicePath}/favicon/android-icon-72x72.png`} />
      <link rel='icon' type='image/png' sizes='48x48' href={`/${servicePath}/favicon/android-icon-48x48.png`} />
      <link rel='icon' type='image/png' sizes='32x32' href={`/${servicePath}/favicon/favicon-32x32.png`} />
      <link rel='icon' type='image/png' sizes='16x16' href={`/${servicePath}/favicon/favicon-16x16.png`} />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='msapplication-TileImage' content={`/${servicePath}/favicon/ms-icon-144x144.png`} />
      <meta name='theme-color' content='#ffffff' />
      <meta property='og:site_name' content={`${serviceName}`} />
      <link rel='manifest' href={`/${servicePath}/manifest.json`} />
      <link type='image/x-icon' rel='shortcut icon' href={`/${servicePath}/favicon.ico`} />
    </Helmet>
  )
}

Favicon.defaultProps={
  servicePath:"carada"
}

export default Favicon;
