import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import styled from "styled-components";
import { isLnln } from "../../../Utils/checkLnln";
import Button from "../../atoms/buttons/Button";

import { japaneseList } from "../../../Resources/japaneseList";

import ImgCardPath from "../../../images/carada/card.png";
import ImgCardPathLnln from "../../../images/lnln/card.png";
const imgCard = isLnln() ? ImgCardPathLnln : ImgCardPath;

const Title = styled.span`
  font-weight: bold;
  color: ${(p) => p.theme.color.primary};
`;

class PaymentDialog extends Component {
  render() {
    const renderResource = japaneseList.organisms.payment.PaymentDialog.render;
    const { openDialog, handleClose } = this.props;
    return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title>{renderResource.Dialog.DialogTitle.Title.j001}</Title>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {renderResource.Dialog.DialogContent.DialogContentText.j001}
            <br />
            <div style={{ textAlign: "center" }}>
              <img
                src={imgCard}
                alt={imgCard}
                style={{ width: "80%", marginTop: "25px" }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {renderResource.Dialog.DialogActions.CloseButton.j001}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PaymentDialog;
