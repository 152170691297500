import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import WarningMessage from "../../molecules/WarningMessage";
import InfoMessage from "../../molecules/InfoMessage";
import Typography2 from "../../atoms/fonts/Typography2";
import OnlineChatDialogButtons from "./OnlineChatDialogButtons";
import OnlineChatDialogHeader from "./OnlineChatDialogHeader";
import { japaneseList } from "../../../Resources/japaneseList";

const DialogContent = styled.div`
  flex: 1 1 auto;
  padding:24px;
  overflow-y: auto;
`;

export default props => (
  <Dialog
    open={true}
    onClose={props.onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <OnlineChatDialogHeader>
      {
        japaneseList.organisms.onlineChat.OnlineChatDialog.export.Dialog
          .OnlineChatDialogHeader.j001
      }
    </OnlineChatDialogHeader>
    <DialogContent>
        <WarningMessage>
          <Typography2>
            {
              japaneseList.organisms.onlineChat.OnlineChatDialog.export.Dialog
                .OnlineChatDialogBody.WarningMessage.Typography2.j001
            }
          </Typography2>
        </WarningMessage>
        <InfoMessage>
          <Typography2>
            {
              japaneseList.organisms.onlineChat.OnlineChatDialog.export.Dialog
                .OnlineChatDialogBody.InfoMessage.Typography2.j001
            }
          </Typography2>
        </InfoMessage>
    </DialogContent>
    <OnlineChatDialogButtons
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    />
  </Dialog>
);
