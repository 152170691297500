import React, { Component } from "react";
import { prefectureAreas } from "../../../Utils/PrefectureCode";
import PrefectureCard from "./PrefectureCard";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = () => ({
  areaWrapper: {
    borderTop: "thin solid #ccc",
    padding: "10px 6px",
    backgroundColor: "#eee",
  },
  icon: {
    margin: 0,
    color: "#ccc",
  },
});

const AreaPanel = withStyles({
  root: {
    border: "none",
    borderBottom: "thin solid #ccc",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(ExpansionPanel);

const AreaPanelSummary = withStyles({
  root: {
    minHeight: "auto",
    padding: "10px 8px 10px 15px",
    color: "#ccc",
    "&$expanded": {
      minHeight: "auto",
    },
  },
  content: {
    margin: "auto",
    padding: 0,
    "&$expanded": {
      margin: "auto",
    },
  },
  expandIcon: {
    width: "auto",
  },
  expanded: {},
})(ExpansionPanelSummary);

const AreaName = styled.div`
  color: ${(p) => p.theme.color.font};
`;

const AreaPrefectures = withStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    border: "thin solid #ccc",
    padding: "0",
    backgroundColor: "#fff",
  },
}))(ExpansionPanelDetails);

class PrefectureAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openArea: null,
    };
  }

  render() {
    const { searchResultPageUrl, hospitalCounts, classes } = this.props;
    const handleChange = (areaKey) => (_event, newExpanded) => {
      this.setState({ openArea: newExpanded ? areaKey : null });
    };

    return (
      <React.Fragment>
        {prefectureAreas.map((area, areaKey) => (
          <AreaPanel
            key={areaKey}
            square={true}
            expanded={this.state.openArea === areaKey}
            onChange={handleChange(areaKey)}
          >
            <AreaPanelSummary
              aria-controls="panel2d-content"
              id={`area-${areaKey}`}
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
            >
              <AreaName>{area.jpName}</AreaName>
            </AreaPanelSummary>
            <div className={classes.areaWrapper}>
              <AreaPrefectures>
                {area.prefectures.map((prefecture, index) => {
                  const hospitalCount =
                    hospitalCounts.find(
                      (item) => item.prefectureCode === prefecture.code
                    ) || 0;
                  return (
                    <PrefectureCard
                      prefecture={prefecture}
                      hospitalCount={hospitalCount}
                      searchResultPageUrl={searchResultPageUrl}
                      index={index}
                    />
                  );
                })}
              </AreaPrefectures>
            </div>
          </AreaPanel>
        ))}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PrefectureAreas);
