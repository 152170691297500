// Graphql
import { Auth } from "aws-amplify";
import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Reload from "./Reload";

const GraphQlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const RELOAD_INTERVAL_TIME = 6000;

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    try {
      if (
        networkError &&
        (networkError.statusCode === 403 || networkError.statusCode === 503)
      ) {
        if (window.location.pathname.startsWith("/not-access")) return;

        let reloadTimestamp = localStorage.getItem("reloadTimestamp");
        if (
          reloadTimestamp &&
          Date.now() - reloadTimestamp <= RELOAD_INTERVAL_TIME
        )
          return;

        localStorage.setItem("reloadTimestamp", Date.now());
        Reload();
      }
    } catch (e) {
      console.log(e);
    }
  }
);

const httpLink = createHttpLink({
  uri: GraphQlEndpoint,
});
const authLink = setContext(async (_, { headers }) => {
  const authHeaders = await Auth.currentUserPoolUser()
    .then((user) => {
      return {
        Authorization: user.signInUserSession.idToken.jwtToken,
      };
    })
    .catch(() => {
      return {};
    });
  return {
    headers: {
      ...headers,
      ...authHeaders,
      "client-version": process.env.REACT_APP_CLIENT_VERSION,
    },
  };
});

const removeReloadCountLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    localStorage.removeItem("forceReloadCount");
    return response;
  });
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, removeReloadCountLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
