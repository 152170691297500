import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Dialog, DialogActions } from "@material-ui/core";
import ButtonAlert from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import { japaneseList } from "../../../Resources/japaneseList";

const ConfirmPopupResource = japaneseList.molecules.Popup.ConfirmPopup;

const styles = () => ({
  dialogAction: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "14px",
    margin: "15px 0 0 0",
  },
  ButtonPopup: {
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    padding: "18px 0 16px 0",
  },
  ButtonRed: {
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "#FE4D4D",
    padding: "18px 0 16px 0",
    marginTop: "15px",
  },
  dialogPaper: {
    margin: "0px",
  },
});

const DialogTitleText = styled.span`
  font-size: 18px;
  color: ${(p) => p.theme.color.primary};
  font-weight: bold;
  line-height: 23px;
`;

const DialogBody = styled.div`
  padding: 30px 20px;
  width: 303px;
  text-align: center;
`;

const popup = (props) => {
  const { classes, isOpen, handleDeleFile, handleClosePopup } = props;

  //--- Callbacks declaration
  const handleClickConfirm = useCallback((e) => {
    // default event stop
    e.preventDefault();
    handleDeleFile();
  });

  const handleClose = useCallback((e) => {
    // default event stop
    e.preventDefault();
    handleClosePopup();
  });

  return (
    <React.Fragment>
      <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
        <DialogBody>
          <DialogTitleText>{ConfirmPopupResource.j001}</DialogTitleText>
          <DialogActions className={classes.dialogAction}>
            <ButtonAlert
              className={classes.ButtonRed}
              onClick={handleClickConfirm}
            >
              {ConfirmPopupResource.j002}
            </ButtonAlert>
          </DialogActions>
          <DialogActions className={classes.dialogAction}>
            <ButtonOutlined
              className={classes.ButtonPopup}
              onClick={handleClose}
            >
              {ConfirmPopupResource.j003}
            </ButtonOutlined>
          </DialogActions>
        </DialogBody>
      </Dialog>
    </React.Fragment>
  );
};

export const ConfirmPopup = withStyles(styles)(popup);
