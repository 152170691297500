import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import styled from "styled-components";
import { isNumber } from "lodash";
import isWithinRange from "date-fns/is_within_range";
import { Grid } from "@material-ui/core";
import { ConfirmationPopup } from "../../molecules/popup/ConfirmationPopup";
import { ConfirmationPopupWithSubText } from "../../molecules/popup/ConfirmationPopupWithSubText";
import Panel from "../../atoms/panels/Panel";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import TimeStatusAppointmentDetail from "../../molecules/TimeStatusAppointmentDetail";
import MedicalDetailAppointmentDetail from "../../molecules/MedicalDetailAppointmentDetail";
import MedicalMenuAppointmentDetail from "../../molecules/MedicalMenuAppointmentDetail";
import RejectedCommentAppointmentDetail from "../../molecules/RejectedCommentAppointmentDetail";
import PaymentDetailAppointmentDetail from "../../molecules/PaymentDetailAppointmentDetail";
import CancelPolicy from "../CancelPolicy";
import { ConsentRegistrationForm } from "../ConsentRegistration/ConsentRegistrationForm";

import attentionPath from "../../../images/standby/attention.svg";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import Button from "../../atoms/buttons/Button";
import {
  APPOINTMENT_STATUS,
  FACILITY_TYPE,
  SETTLEMENT_ERROR,
  MEDICAL_MENU,
} from "../../../Utils/Constant";
import GraphQLClient from "../../../Utils/GraphQLClient";
import {
  CancelAppointment,
  GetTwilioToken,
} from "../../../Utils/GraphQLQueries";
import {
  updateConsentStatusToProgress,
  buildExternalUrlToUpdate,
  reopenDefaultBrowser,
} from "../../../Utils/ConsentRegistration";
import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";
import { MedicineDeliveryDetail } from "../medicineDelivery/MedicineDeliveryDetail";
import { MEDICINE_DELIVERY_METHOD } from "../../../Utils/Constant";
import SiteContext from "../../../SiteContext";

const DISPLAYSITES = {
  NONE: "none",
};
const ACCOUNTSTATUS = {
  ENABLED: "enabled",
};

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentDetailContent.render;

const CancelButton = styled(Button)`
  width: 92%;
  margin: 15px;
`;

const VideoStartButton = styled(Button)`
  width: 92%;
  margin: 15px;
`;

const HospitalDetailButton = styled(Button)`
  width: 92%;
  margin: 15px;
`;
const ButtonPopup = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  cursor: pointer;
`;
const FormGroup = styled.div`
  padding: 0 0 15px 0;
`;
const WarningIcon = styled.img`
  width: 25px;
  height: 25px;
`;
const PanelTabs = styled(Panel)`
  padding: 21px 15px 0px 15px;
`;
const PanelBody = styled(Panel)`
  padding: 0px 15px 15px 15px;
`;
const FontPColor = styled(FontP)`
  color: #f26666;
`;
const FontPBoldColor = styled(FontPBold)`
  color: #f26666;
`;
const GridMargin = styled(Grid)`
  margin: 0px 0px 10px 0px;
`;

const styles = () => ({
  textCenter: {
    top: "50%",
    transform: "translateY(-50%)",
    position: "absolute",
    padding: "0",
    margin: "0 auto",
    whiteSpace: "nowrap",
    left: "0",
    right: "0",
  },
});

class AppointmentDetailContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCancelDialog: false,
      openCancelConsentDialog: false,
      displayStartVideoChat: "none",
    };
  }

  componentDidMount() {
    this.checkAppointmentTime();
  }

  checkAppointmentTime() {
    const appointment = this.props.appointment;
    const now = new Date();
    const start = new Date(appointment.from);
    const end = new Date(appointment.to);
    if (
      appointment.status === APPOINTMENT_STATUS.BEFORE_EXAM &&
      isWithinRange(now, start, end) &&
      appointment.roomName
    ) {
      this.setState({
        displayStartVideoChat: "block",
      });
    }
  }

  isLnlnOfficeMenu = (menu) => {
    return (
      menu.medicalMenu === MEDICAL_MENU.EXAMINATION_EWP ||
      menu.medicalMenu === MEDICAL_MENU.CONSULTATION_EWP ||
      menu.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION_EWP
    );
  };

  isShowCancelPolicy = (appointment) => {
    if (appointment && appointment.menu && appointment.status) {
      const cancelableStatuses = [
        APPOINTMENT_STATUS.BEFORE_EXAM,
        APPOINTMENT_STATUS.UNAPPROVED,
        APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
        APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_DAY,
        APPOINTMENT_STATUS.CANCELEDBEFOREAPPROVING,
      ];
      return (
        appointment.menu.showPaymentDetailAndCancelPolicy &&
        appointment.menu.cancelPolicyContent &&
        cancelableStatuses.includes(appointment.status)
      );
    }
    return false;
  };

  handleAppointmentCancelConfirm = () => {
    this.setState({ openCancelDialog: true });
  };

  handleAfterAppointmentCancel = async () => {
    window.location.reload();
  };

  handleClose = () => {
    this.setState({ openCancelDialog: false });
  };
  handleCancelConsentClose = () => {
    this.setState({ openCancelConsentDialog: false });
    this.handleAfterAppointmentCancel();
  };

  handlePaymentCardUpdateClick = () => {
    this.props.history.push("/my-page/payment/card-info");
  };

  getToken = async () => {
    const createTime = this.props.appointment.createTime;
    const {
      data: { twilio },
    } = await GraphQLClient.query({
      query: GetTwilioToken,
      variables: { createTime },
    });
    if (twilio && twilio.token) {
      if (window.Android) {
        window.Android.startVideo(twilio.token);
      } else {
        this.props.history.push("/telemedicine/chat");
      }
    }
  };

  handleCancelConsent = async () => {
    {
      const { ui } = this.context;
      const isLnln = ui === "lnln";
      if (isLnln) return reopenDefaultBrowser();
    }
    // iOS13以降はユーザー操作の直後でないと新規タブを開けない
    // そのため、先に新規タブを開いておきAPIリクエストが失敗した場合は新規タブを閉じる
    const currentUrl = window.location.href;
    const externalUrl = buildExternalUrlToUpdate(
      this.props.onlineQualificationConsentStatus
        ?.onlineQualificationSystemCode,
      this.props.appointment?.to,
      currentUrl
    );
    const newTab = window.open(externalUrl, "_blank");
    const result = await updateConsentStatusToProgress(this.props.appointment);
    if (!result) {
      if (newTab) newTab.close();
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: renderResource.ConsentRegistration.Error.j001,
            level: "error",
          },
        },
        "ConsentRegistration"
      );
      return;
    }
    this.handleAfterAppointmentCancel();
  };

  // 1. キャンセル処理を実行
  // 2. 同意登録を取り消させる必要があるか
  // ないなら終わり(再読み込み)
  // あるなら同意登録取消モーダルを表示
  handleCancel = async () => {
    const { consentStatus, canCancelConsent } =
      this.props.onlineQualificationConsentStatus;
    // キャンセル処理
    const { hasErrors } = await this.cancel();
    // キャンセル処理でエラーがあった場合、何もしない
    if (hasErrors) return;

    // 同意登録を取り消させる必要がない場合、リロードして終了
    if (
      !this.shouldCancelConsentRegistration(consentStatus, canCancelConsent)
    ) {
      this.handleAfterAppointmentCancel();
      return;
    }

    this.setState({
      openCancelDialog: false,
      openCancelConsentDialog: true,
    });
  };

  shouldCancelConsentRegistration = (consentStatus, canCancelConsent) => {
    const isPossiblyRegistered =
      consentStatus === "registered" || consentStatus === "registering";
    return isPossiblyRegistered && canCancelConsent;
  };

  cancel = async () => {
    const errorMessage = renderResource.cancelAppointment.errors;
    const appointment = this.props.appointment;
    let hasErrors = false;
    try {
      const body = {
        createTime: appointment.createTime,
        medicalMethod: appointment.menu.medicalMethod,
        fromOfSlot: appointment.fromOfSlot || appointment.from,
      };

      const { errors } = await GraphQLClient.mutate({
        mutation: CancelAppointment,
        variables: body,
      });

      Sentry.captureMessage("appointment-delete", Sentry.Severity.Log);

      if (errors) {
        hasErrors = true;
        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: {
              message: errorMessage[errors[0].errorType]
                ? errorMessage[errors[0].errorType]
                : errorMessage["E01"],
              level: "error",
            },
          },
          "AppointmentDetail"
        );
        this.setState({ openCancelDialog: false });
      }
    } catch (error) {
      hasErrors = true;
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: errorMessage["E01"],
            level: "error",
          },
        },
        "AppointmentDetail"
      );
      this.setState({ openCancelDialog: false });
    }
    return { hasErrors };
  };

  goToHospitalDetail = (hospitalId) => {
    this.props.history.push(`/hospital/${hospitalId}`);
  };

  handleGoToLinK = () => {
    const appointment = this.props.appointment;
    if (
      appointment.medicineDelivery.deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.DELIVERY &&
      appointment.medicineDelivery.accountStatus === ACCOUNTSTATUS.ENABLED &&
      appointment.medicineDelivery.displaySites !== DISPLAYSITES.NONE
    ) {
      this.props.history.push({
        pathname: `/hospital/${appointment.medicineDelivery.deliveryHospitalId}`,
      });
    } else if (
      appointment.medicineDelivery.deliveryMethod ===
      MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
    ) {
      this.props.history.push({
        pathname: "/my-page/address/detail",
      });
    }
  };

  render() {
    const { appointment, patientInfo, showEditMedicineDelivery } = this.props;
    const isCancelableTime = new Date() < new Date(appointment.from);
    const isSelfFundedAppoimtment =
      appointment.payment && isNumber(appointment.payment.selfFundedFee)
        ? true
        : false;
    const isSelfFundedPaymentError =
      isSelfFundedAppoimtment && appointment.payment.errorCode;

    return (
      <React.Fragment>
        <PanelTabs>
          <TimeStatusAppointmentDetail appointment={appointment} />
          <MedicalDetailAppointmentDetail appointment={appointment} />
          {appointment.facilityType !== FACILITY_TYPE.PHARMACY && (
            <MedicalMenuAppointmentDetail appointment={appointment} />
          )}
          <RejectedCommentAppointmentDetail appointment={appointment} />
        </PanelTabs>
        {((appointment.payment.errorCode === SETTLEMENT_ERROR.CREDIT_CARD &&
          appointment.status === APPOINTMENT_STATUS.EXAM_COMPLETED) ||
          isSelfFundedPaymentError) && (
          <PanelBody>
            <Grid container>
              <Grid item xs={1}>
                <WarningIcon src={attentionPath} alt={""} />
              </Grid>
              <Grid item xs={11}>
                <FontPBoldColor>
                  {renderResource.paymentError.title}
                </FontPBoldColor>
              </Grid>
              <GridMargin item xs={12}>
                <FontPColor>{renderResource.paymentError.text}</FontPColor>
              </GridMargin>
              <ButtonFullWidth onClick={this.handlePaymentCardUpdateClick}>
                {renderResource.paymentError.CreditCardUpdateButton.j001}
              </ButtonFullWidth>
            </Grid>
          </PanelBody>
        )}

        {appointment.status !== APPOINTMENT_STATUS.REJECTED &&
          appointment.menu.showPaymentDetailAndCancelPolicy && (
            /* WARN: showPaymentDetailAndCancelPolicyの変更時は注意が必要(#22989) */
            <PaymentDetailAppointmentDetail
              appointment={appointment}
              isLnlnOfficeMenu={this.isLnlnOfficeMenu(appointment.menu)}
            />
          )}
        {this.props.shouldShowConsentRegistration && (
          <ConsentRegistrationForm
            appointment={appointment}
            onlineQualificationConsentStatus={
              this.props.onlineQualificationConsentStatus
            }
          />
        )}
        {appointment.status !== APPOINTMENT_STATUS.REJECTED && (
          <>
            {appointment.medicineDelivery && (
              <MedicineDeliveryDetail
                appointment={appointment}
                patientInfo={patientInfo}
                handleGoToLinK={this.handleGoToLinK}
                showEditMedicineDelivery={showEditMedicineDelivery}
              />
            )}
            {this.isShowCancelPolicy(appointment) && (
              <CancelPolicy
                facilityType={appointment.facilityType}
                menu={appointment.menu}
                cancelPolicyExamOnlineCreateByPatient={
                  appointment.status === APPOINTMENT_STATUS.UNAPPROVED
                }
                hospitalId={appointment && appointment.hospitalId}
                gtmId="hospital-detail-link"
                isLnlnOfficeMenu={this.isLnlnOfficeMenu(appointment.menu)}
                isShowFooter={true}
              />
            )}
          </>
        )}
        <FormGroup>
          <VideoStartButton
            style={{ display: this.state.displayStartVideoChat }}
            onClick={this.getToken}
          >
            {renderResource.div.FormGroup.CancelButton.j001}
          </VideoStartButton>
          {appointment.status === APPOINTMENT_STATUS.BEFORE_EXAM ||
          appointment.status === APPOINTMENT_STATUS.UNAPPROVED ? (
            appointment.menu.cancelAppointmentByPatient ? (
              <CancelButton
                id="cancel-appointment-button"
                onClick={this.handleAppointmentCancelConfirm}
                disabled={!isCancelableTime}
              >
                {renderResource.div.FormGroup.CancelButton.j003}
              </CancelButton>
            ) : (
              <HospitalDetailButton
                onClick={() => this.goToHospitalDetail(appointment.hospitalId)}
              >
                {renderResource.div.FormGroup.CancelButton.j002}
              </HospitalDetailButton>
            )
          ) : (
            ""
          )}
        </FormGroup>
        <ConfirmationPopup
          isOpen={this.state.openCancelDialog}
          handleClickConfirm={this.handleCancel}
          handleClosePopup={this.handleClose}
          confirmResource={renderResource.div.Dialog}
          buttonGAid={{
            confirmButtonId: "cancel-appointment-confirm-button",
            closeBtnId: "cancel-appointment-return-button",
          }}
        />
        <ConfirmationPopupWithSubText
          isOpen={this.state.openCancelConsentDialog}
          handleClickConfirm={this.handleCancelConsent}
          handleClosePopup={this.handleCancelConsentClose}
          confirmResource={renderResource.div.CancelConsentDialog}
          buttonGAid={{
            confirmButtonId: "update-consent-after-cancel-button",
            modalId: "complete-cancellation-appointment-modal",
          }}
        />
      </React.Fragment>
    );
  }
}

AppointmentDetailContent.contextType = SiteContext;
export default withStyles(styles)(withRouter(AppointmentDetailContent));
