import React from "react";
import styled from "styled-components";

import FontP from "../../atoms/fonts/FontP";
import Panel from "../../atoms/panels/Panel";

import { isLnln } from "../../../Utils/checkLnln";
import IconFile from "../../../images/carada/icon/icon-file.svg";
import IconFileLnln from "../../../images/lnln/icon/icon-file.svg";

import { japaneseList } from "../../../Resources/japaneseList";
const Resource = japaneseList.molecules.FileUploadDetail;

const icon = isLnln() ? IconFileLnln : IconFile;

const CustomFontP = styled(FontP)`
  display: flex;
  font-size: 14px;
`;

const CustomPeriod = styled(FontP)`
  font-size: 10px;
`;

const CustomFileName = styled(FontP)`
  display: flex;
  padding-bottom: 5px;
  font-size: 14px;
`;

const Img = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 3px;
  margin-top: 2px;
`;

const FileUploadDetailItemDeleteByPeriod = props => {
  const { fileName, period } = props;
  return (
    <Panel padding={"ts"}>
      <CustomFileName size={"s"}>
        <Img src={icon} />
        {fileName}
      </CustomFileName>
      <CustomFontP size={"s"}>{Resource.deleteByPeriod}</CustomFontP>
      <CustomPeriod size={"sss"} color={"gray"}>
        {Resource.period}: {period}
      </CustomPeriod>
    </Panel>
  );
};
FileUploadDetailItemDeleteByPeriod.defaultProps = {
  status: "deletePeriod",
  period: "2019/08/09 10:15～2019/08/16 23:59"
};

export default FileUploadDetailItemDeleteByPeriod;
