import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { StepperForm } from "./StepperForm";
import { InterviewSheetAnswerDetail } from "../interviewSheet/InterviewSheetAnswerDetail";
import Heading1 from "../../atoms/headings/Heading1";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import InputFormHeaderNoIcon from "../../molecules/InputFormHeaderNoIcon";
import PaymentDetailConfirmPage from "../../molecules/PaymentDetailConfirmPage";
import CancelPolicy from "../CancelPolicy";
import CheckboxLabel from "../../molecules/checkboxLabels/CheckboxLabel";
import ThumbnailList from "../../molecules/patient-upload-file/ThumbnailList";
import { AddressDetail } from "../../molecules/AddressDetail";
import {
  FACILITY_TYPE,
  APPOINTMENT_SIDEEFFECT,
  CONTENT_TYPE,
  MEDICINE_DELIVERY_METHOD,
  RESERVE_STEP,
  MEDICAL_MENU,
} from "../../../Utils/Constant";
import GraphQLClient from "../../../Utils/GraphQLClient";
import {
  RegisterAppointment,
  AttachmentPresignedUrlForPutObject,
} from "../../../Utils/GraphQLQueries";
import { formatPeriod } from "../../../Utils/Utility";
import SiteContext from "../../../SiteContext";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";

const onSubmitResource =
  japaneseList.organisms.reservationForm.ReservationConfirmForm.onSubmit;

const Container = styled.div`
  padding: 20px 15px 0px 15px;
  line-height: 1.3;
  margin-bottom: -10px;
`;

const ButtonAreaContainer = styled(Container)`
  padding-top: 15px;
`;

const CheckBoxContainer = styled.div`
  padding: 15px 0 0 15px;
`;

const Detail = styled.div`
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 10px 0;
`;

const Title = styled.div`
  padding: 0 0 3px 0;
  color: ${(p) => p.theme.color.primary};
  font-weight: bold;
  font-size: 14px;
`;

const CheckboxSMSLabel = styled.span`
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const TextSectionBody = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: 2px;
`;

const DeliveryMethodTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #393939;
  padding: 20px 15px 0px 15px;
  font-family: Hiragino Kaku Gothic ProN;
`;

const DeliveryMethodText = styled.div`
  color: #393939;
  font-size: 14px;
  padding: 10px 15px 0px 15px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const DescripTextColorBottom = styled.div`
  padding: 0 15px;
  margin-top: 20px;
  color: #999999;
  font-size: 12px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const WarningText = styled.span`
  color: #ff4d4d;
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  word-break: break-word;
  white-space: pre-wrap;
`;

const DeliveryMethodWarningTextBox = styled(DeliveryMethodText)`
  color: #ff4d4d;
`;

const DeliveryMethodWarningText = styled.div`
  font-size: 12px;
  line-height: 25px;
  font-family: Hiragino Kaku Gothic ProN;
`;

const DeliveryMethodWarningText2 = styled(DeliveryMethodWarningText)`
  font-weight: bolder;
  border: 1px solid #ff4d4d;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
`;

const DeliveryMethodWarningBottom = styled(FontP)`
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  color: #000000;
  font-family: Hiragino Kaku Gothic ProN;
  padding: 10px 15px 0px 15px;
`;

const DeliveryContainer = styled.div`
  margin: 10px 15px 20px 15px;
  border: 1px solid rgba(204, 204, 204, 1);
`;

const FullWidthButtonSub = styled(FullWidthButton)`
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: 1.5px solid ${(p) => p.theme.color.primary};
  font-size: 16px;
  padding: 1px 5px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;
  height: 50px;
  margin-bottom: 20px;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const InputFormHeaderNoIconCustom = styled(InputFormHeaderNoIcon)`
  background-color: ${(p) => p.theme.color.primaryVariant3};
`;

const PrefaceTitle = styled.div`
  margin-top: 21px;
  padding: 0px 15px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #393939;
  background-color: #f1f3f3;
  font-family: Hiragino Kaku Gothic ProN;
`;

const Preface = styled.div`
  margin: 20px 15px 0px 15px;
  height: 42px;
  font-size: 14px;
  color: #393939;
  font-weight: 300;
  line-height: 20px;
`;

const styles = (theme) => ({
  textSection: {
    padding: "14px 18px",
    margin: "20px 15px 20px 15px",
    backgroundColor: "#F7F7F7",
  },
  textHeader: {
    "text-align": "center",
    "font-size": "16px",
    "font-weight": "bold",
    margin: 0,
    "padding-bottom": "10px",
  },
  textBody: {
    "text-align": "justify",
    "font-size": "12px",
    margin: 0,
    "line-height": "20px",
  },
  heading1: {
    height: "40px",
    "font-size": "17px",
    "font-weight": "bold",
  },
  heading2: {
    height: "60px",
    "font-size": "15px",
    "font-weight": "bold",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
  },
});

class ReservationConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    this.reserveData =
      this.props.location.state && this.props.location.state.reserveData;
    this.reserveInterviewSheetAnswer =
      this.props.location.state &&
      this.props.location.state.reserveInterviewSheetAnswer;
    this.fileList =
      this.props.location.state && this.props.location.state.fileList;
    this.medicineDelivery =
      this.props.location.state && this.props.location.state.medicineDelivery;
    this.reserveSteps =
      this.props.location.state && this.props.location.state.reserveSteps;
    this.currentStep =
      this.reserveSteps &&
      this.reserveSteps.find(
        (step) => step.reserveStep === RESERVE_STEP.CONFIRM
      );
    const isNotCancelPolicyMenu =
      this.reserveData &&
      this.reserveData.menu &&
      !this.reserveData.menu.showPaymentDetailAndCancelPolicy;
    this.state = {
      agreed: isNotCancelPolicyMenu,
      isSubmitting: false,
      fileList: this.fileList,
    };
  }

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "SignUp"
    );
  };

  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(signedRequest, file, options);
  };

  toastError = (errorMessage) => {
    Hub.dispatch("msg", {
      event: "open",
      data: {
        message: errorMessage,
        level: "error",
      },
    });
  };

  uploadAttachments = async () => {
    try {
      const preSignedURLs = await Promise.all(
        this.fileList.map((file) => {
          return GraphQLClient.query({
            query: AttachmentPresignedUrlForPutObject,
            variables: {
              s3FileKey: `patient/origin/${file.fileId}.${file.extensionFile}`,
              fileType: file.fileType,
            },
          });
        })
      );
      await Promise.all(
        preSignedURLs.map((res, index) =>
          this.uploadToS3(
            this.fileList[index].originalFile,
            res.data.attachmentPresignedUrlForPutObject.url
          )
        )
      );
    } catch (error) {
      this.toastError(onSubmitResource.j005);
      return false;
    }
    return true;
  };

  handleAgreeCancelPolicyChange = (event) => {
    this.setState({ agreed: event.target.checked });
  };

  onSubmit = async () => {
    if (this.state.isSubmitting) {
      return false;
    }
    if (
      this.reserveData &&
      this.reserveData.menu.showPaymentDetailAndCancelPolicy &&
      Boolean(this.reserveData.menu.cancelPolicyContent) &&
      !this.state.agreed
    ) {
      this.snackBar(onSubmitResource.j001);
      return false;
    }
    const { selectedReserve, hospitalId, menuId, content, companyName } =
      this.reserveInput;
    const { sendSms, sendMail } = this.reserveInput;
    const interviewSheetAnswer = this.getInterviewSheetAnswers();
    let patientAttachments = null;
    let canRegisterApp = true;
    try {
      this.setState({ isSubmitting: true });
      if (this.fileList && this.fileList.length > 0) {
        // Upload file to S3
        canRegisterApp = await this.uploadAttachments();
        if (canRegisterApp) {
          patientAttachments = this.state.fileList.map((file) => {
            return {
              fileName: file.fileName,
              fileType: file.fileType,
              filePath: `patient/origin/${file.fileId}.${file.extensionFile}`,
              thumbnailFilePath: `patient/thumbnail/${file.fileId}.${file.extensionFile}`,
              uploadedTime: file.uploadedTime,
              updatedTime: file.updatedTime,
            };
          });
        } else {
          this.setState({ isSubmitting: false });
          return;
        }
      }
      const body = {
        fromOfSlot: selectedReserve.fromOfSlot,
        from: selectedReserve.from,
        to: selectedReserve.to,
        hospitalId,
        menuId,
        content: content ? content.trim() : "",
        companyName: companyName ? companyName.trim() : "",
        sideEffect: selectedReserve.lockSlot
          ? APPOINTMENT_SIDEEFFECT.UPDATE_AND_LOCK_SLOT
          : APPOINTMENT_SIDEEFFECT.UPDATE_SLOT,
        sendSms: sendSms ? sendSms : false,
        sendMail: sendMail ? sendMail : false,
        doctorId: selectedReserve.doctorId,
        interviewSheetAnswer,
        patientAttachments,
        medicineDelivery: this.medicineDelivery && {
          deliveryMethod: this.medicineDelivery.deliveryMethod,
          deliveryHospitalId: this.medicineDelivery.pharmacy
            ? this.medicineDelivery.pharmacy.hospitalId
            : null,
        },
      };
      const { data, errors } = await GraphQLClient.mutate({
        mutation: RegisterAppointment,
        variables: body,
      });

      if (errors) {
        this.setState({ isSubmitting: false });
        let message;
        if (errors.find((e) => e.errorType === "E01")) {
          // Appointment slot not found
          message = onSubmitResource.j002;
        } else if (errors.find((e) => e.errorType === "E02")) {
          // Hospital not found.
          message = onSubmitResource.j008;
        } else if (errors.find((e) => e.errorType === "E03")) {
          // Menu not found.
          message = onSubmitResource.j008;
        } else if (errors.find((e) => e.errorType === "E04")) {
          // Menu is not createAppointmentByPatient.
          message = onSubmitResource.j008;
        } else if (errors.find((e) => e.errorType === "E05")) {
          // This time frame is in the past.
          message = onSubmitResource.j003;
        } else if (errors.find((e) => e.errorType === "E06")) {
          // This time frame conflict with other appointment of doctor.
          message = onSubmitResource.j004;
        } else if (errors.find((e) => e.errorType === "E07")) {
          // Patient book many appointment at the same time.
          message = onSubmitResource.j009;
        } else if (errors.find((e) => e.errorType === "E08")) {
          // This time period will be maintained by the system. Please select another date.
          message = onSubmitResource.j007;
        } else if (errors.find((e) => e.errorType === "E09")) {
          // Appointment slot is expired
          message = onSubmitResource.j010;
        } else if (
          errors.find((e) => e.errorType === "E100" || e.errorType === "E101")
        ) {
          message = onSubmitResource.j011;
        } else {
          message = onSubmitResource.j005;
        }
        this.toastError(message);
        return;
      }
      Sentry.captureMessage("appointment-create", Sentry.Severity.Log);
      this.reserveInput.createTime = data.registerAppointment.createTime;

      this.props.history.push({
        pathname: this.currentStep && this.currentStep.followingUrl,
        state: {
          reserveInput: this.reserveInput,
          hospital: this.reserveData.hospital,
          menu: this.reserveData.menu,
        },
      });
    } catch (e) {
      this.setState({ isSubmitting: false });

      Sentry.captureException(e);
      if (e.toString() === "appointmentSlotExist") {
        this.snackBar(onSubmitResource.j006);
        return false;
      } else {
        this.props.history.push(`/`);
      }
      if (e instanceof Error) {
        alert(e.message);
      } else {
        alert(e.toString());
      }
    }
  };
  getInterviewSheetAnswers = () => {
    if (this.reserveInterviewSheetAnswer) {
      return {
        interviewSheetId: this.reserveInterviewSheetAnswer.interviewSheetId,
        interviewSheetVersion:
          this.reserveInterviewSheetAnswer.interviewSheetVersion,
        interviewAnswers: this.reserveInterviewSheetAnswer.interviewAnswers.map(
          (interviewAnswer) => {
            return {
              order: interviewAnswer.order,
              idNotation: interviewAnswer.idNotation,
              interviewSheetItemId: interviewAnswer.interviewSheetItemId,
              interviewSheetItemVersion:
                interviewAnswer.interviewSheetItemVersion,
              requiredAnswer: interviewAnswer.requiredAnswer,
              interviewChoiceNos: interviewAnswer.interviewChoiceNos,
              freeText: interviewAnswer.freeText,
            };
          }
        ),
      };
    } else {
      return null;
    }
  };

  onCorrect = () => {
    this.props.history.push({
      pathname: this.currentStep && this.currentStep.previousUrl,
      state: {
        reserveInput: this.reserveInput,
        reserveData: this.reserveData,
        reserveInterviewSheetAnswer: this.reserveInterviewSheetAnswer,
        medicineDelivery: this.medicineDelivery,
        fileList: this.fileList,
        reserveSteps: this.reserveSteps,
      },
    });
  };

  isLnlnOfficeMenu = (menu) => {
    return (
      menu.medicalMenu === MEDICAL_MENU.EXAMINATION_EWP ||
      menu.medicalMenu === MEDICAL_MENU.CONSULTATION_EWP ||
      menu.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION_EWP
    );
  };

  render() {
    const { classes } = this.props;
    const { isSubmitting, agreed } = this.state;
    const renderResource =
      japaneseList.organisms.reservationForm.ReservationConfirmForm.render;
    const { menu, hospital } = this.props;
    const sendSms = this.reserveInput && this.reserveInput.sendSms;
    const sendMail = this.reserveInput && this.reserveInput.sendMail;
    const menuTypeText = menu && menu.displayName;
    const pharmacy = this.medicineDelivery && this.medicineDelivery.pharmacy;
    const patientInfo =
      this.medicineDelivery && this.medicineDelivery.patientInfo;
    return (
      <div>
        <Heading1 className={classes.heading1}>
          {renderResource.Fragment.PrimaryHeading.j001}
        </Heading1>
        <StepperForm
          reserveSteps={this.reserveSteps}
          currentStep={this.currentStep}
        />
        <InputFormHeaderNoIconCustom className={classes.heading2}>
          {renderResource.Fragment.InputFormHeaderNoIcon.j001}
        </InputFormHeaderNoIconCustom>
        <Container>
          <Title>{renderResource.Fragment.Container.Title.j004}</Title>
          <Detail>
            {this.reserveInput
              ? formatPeriod(
                  this.reserveInput.selectedReserve.from,
                  this.reserveInput.selectedReserve.to
                ) + renderResource.Fragment.Container.Detail.j001
              : ""}
          </Detail>
          <Title>{renderResource.Fragment.Container.Title.j001}</Title>
          <Detail>
            {hospital ? `${hospital.name}` : <CircularProgress />}
          </Detail>
          <Title>
            {hospital && hospital.facilityType === FACILITY_TYPE.PHARMACY
              ? renderResource.Fragment.Container.Title.j002
              : renderResource.Fragment.Container.Title.j003}
          </Title>
          <Detail>{hospital ? menuTypeText : <CircularProgress />}</Detail>
          {menu && menu.requiredCompanyName && (
            <div>
              <Title>{renderResource.Fragment.Container.div.Title.j001a}</Title>
              <Detail>
                {this.reserveInput && this.reserveInput.companyName}
              </Detail>
            </div>
          )}
          {menu && menu.contentType === CONTENT_TYPE.CONSULTATION_CONTENT && (
            <div>
              <Title>{renderResource.Fragment.Container.div.Title.j001}</Title>
              <Detail>{this.reserveInput && this.reserveInput.content}</Detail>
            </div>
          )}
          <SiteContext.Consumer>
            {(value) => (
              <React.Fragment>
                <React.Fragment>
                  <Title>
                    {
                      renderResource.Fragment.Container.Consumer.Fragment
                        .Fragment.Title.j001
                    }
                  </Title>
                  <Detail>
                    {
                      renderResource.Fragment.Container.Consumer.Fragment
                        .Fragment.Detail.j003
                    }{" "}
                    :{" "}
                    {sendMail
                      ? renderResource.Fragment.Container.Consumer.Fragment
                          .Fragment.Detail.j004
                      : renderResource.Fragment.Container.Consumer.Fragment
                          .Fragment.Detail.j005}
                    <br />
                    SMS :{" "}
                    {sendSms
                      ? renderResource.Fragment.Container.Consumer.Fragment
                          .Fragment.Detail.j001
                      : renderResource.Fragment.Container.Consumer.Fragment
                          .Fragment.Detail.j002}{" "}
                  </Detail>
                </React.Fragment>
              </React.Fragment>
            )}
          </SiteContext.Consumer>
          {/* WARN: showPaymentDetailAndCancelPolicyの変更時は注意が必要(#22989) */}
          {this.reserveData &&
            this.reserveData.menu &&
            this.reserveData.menu.showPaymentDetailAndCancelPolicy &&
            this.isLnlnOfficeMenu(this.reserveData.menu) && (
              <React.Fragment>
                <Title>{renderResource.Fragment.Container.Title.j005}</Title>
                <Detail>{renderResource.Fragment.Container.Detail.j003}</Detail>
              </React.Fragment>
            )}
        </Container>
        {this.reserveData && this.reserveData.menu && (
          <div>
            {/* WARN: showPaymentDetailAndCancelPolicyの変更時は注意が必要(#22989) */}
            {this.reserveData.menu.showPaymentDetailAndCancelPolicy &&
              !this.isLnlnOfficeMenu(this.reserveData.menu) && (
                <PaymentDetailConfirmPage />
              )}
            {this.reserveInterviewSheetAnswer && (
              <React.Fragment>
                <PrefaceTitle>
                  {
                    renderResource.Fragment.InterviewSheetAnswerDetail.Title
                      .j001
                  }
                </PrefaceTitle>
                <Preface>
                  {
                    renderResource.Fragment.InterviewSheetAnswerDetail.Preface
                      .j001
                  }
                </Preface>
                <InterviewSheetAnswerDetail
                  interviewSheetAnswer={this.reserveInterviewSheetAnswer}
                />
              </React.Fragment>
            )}
            {this.reserveData.menu.approvalRequired && (
              <div className={classes.textSection}>
                <p className={classes.textHeader}>
                  {renderResource.Fragment.AttentionSection.Title.j001}
                </p>
                <TextSectionBody className={classes.textBody}>
                  <span>
                    {
                      renderResource.Fragment.AttentionSection.Detail.BeforeText
                        .j001
                    }
                  </span>
                  <span>
                    {renderResource.Fragment.AttentionSection.Detail.j001}
                  </span>
                  <span>
                    {
                      renderResource.Fragment.AttentionSection.Detail.BeforeText
                        .j001
                    }
                  </span>
                  <span>
                    {renderResource.Fragment.AttentionSection.Detail.j002}
                  </span>
                </TextSectionBody>
              </div>
            )}
            {this.reserveData.menu.enableMedicineDelivery && (
              <React.Fragment>
                <PrefaceTitle>
                  {renderResource.Fragment.MedicineDelivery.Title.j001}
                </PrefaceTitle>
                {this.medicineDelivery &&
                  this.medicineDelivery.deliveryMethod ===
                    MEDICINE_DELIVERY_METHOD.DELIVERY && (
                    <React.Fragment>
                      <DeliveryMethodWarningTextBox>
                        <DeliveryMethodWarningText2>
                          {
                            renderResource.Fragment.MedicineDelivery.Message
                              .j001
                          }
                        </DeliveryMethodWarningText2>
                      </DeliveryMethodWarningTextBox>
                      <DeliveryMethodTitle>
                        {
                          renderResource.Fragment.MedicineDelivery
                            .DeliveryMethod.j001
                        }
                      </DeliveryMethodTitle>
                      <DeliveryMethodWarningBottom>
                        {
                          renderResource.Fragment.MedicineDelivery
                            .DeliveryMethod.j004
                        }
                        <WarningText>
                          {
                            renderResource.Fragment.MedicineDelivery
                              .DeliveryMethod.j005
                          }
                        </WarningText>
                        {
                          renderResource.Fragment.MedicineDelivery
                            .DeliveryMethod.j006
                        }
                      </DeliveryMethodWarningBottom>
                      <DeliveryContainer>
                        {pharmacy && (
                          <AddressDetail
                            name={pharmacy.name}
                            address={{
                              postalCode: pharmacy.postalCode,
                              prefectureCode: pharmacy.prefectureCode,
                              addressCity: pharmacy.addressCity,
                              addressStreetNumber: pharmacy.addressStreetNumber,
                              addressBuilding: pharmacy.addressBuilding,
                              tel: pharmacy.tel,
                            }}
                          />
                        )}
                      </DeliveryContainer>
                    </React.Fragment>
                  )}
                {this.medicineDelivery &&
                  this.medicineDelivery.deliveryMethod ===
                    MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME && (
                    <React.Fragment>
                      <DeliveryMethodTitle>
                        {
                          renderResource.Fragment.MedicineDelivery
                            .DeliveryMethod.j002
                        }
                      </DeliveryMethodTitle>
                      <DeliveryMethodText>
                        {renderResource.Fragment.MedicineDelivery.Message.j002}
                      </DeliveryMethodText>
                      <DeliveryContainer>
                        {patientInfo && (
                          <AddressDetail
                            name={`${patientInfo.familyName} ${patientInfo.givenName}`}
                            address={patientInfo.address}
                          />
                        )}
                      </DeliveryContainer>
                    </React.Fragment>
                  )}
                <DescripTextColorBottom>
                  {renderResource.Fragment.MedicineDelivery.DeliveryMethod.j003}
                </DescripTextColorBottom>
              </React.Fragment>
            )}

            {this.state.fileList && this.state.fileList.length > 0 && (
              <React.Fragment>
                <PrefaceTitle>
                  {renderResource.Fragment.ThumbnailList.Title.j001}
                </PrefaceTitle>
                <ThumbnailList
                  thumbnailList={this.state.fileList}
                  isViewOnly={true}
                  isShowPlusButon={false}
                />
              </React.Fragment>
            )}

            {this.reserveData.menu.showPaymentDetailAndCancelPolicy &&
              Boolean(this.reserveData.menu.cancelPolicyContent) && (
                <CancelPolicy
                  facilityType={hospital && hospital.facilityType}
                  menu={menu}
                  isLnlnOfficeMenu={this.isLnlnOfficeMenu(
                    this.reserveData.menu
                  )}
                  cancelPolicyExamOnlineCreateByPatient={
                    this.reserveData.menu.createAppointmentByPatient &&
                    this.reserveData.menu.medicalMenu === "examination" &&
                    this.reserveData.menu.medicalMethod === "online"
                  }
                  hospitalId={hospital && hospital.hospitalId}
                  gtmId="hospital-detail-link"
                />
              )}
            {this.reserveData.menu.showPaymentDetailAndCancelPolicy &&
              Boolean(this.reserveData.menu.cancelPolicyContent) && (
                <CheckBoxContainer>
                  <CheckboxLabel
                    id="agree-cancel-checkbox"
                    style={{ marginBottom: "5px" }}
                    label={
                      this.isLnlnOfficeMenu(this.reserveData.menu) ? (
                        <CheckboxSMSLabel>
                          {
                            renderResource.Fragment.div.CheckBoxContainer
                              .CheckboxLabel.j002
                          }
                        </CheckboxSMSLabel>
                      ) : (
                        <CheckboxSMSLabel>
                          {
                            renderResource.Fragment.div.CheckBoxContainer
                              .CheckboxLabel.j001
                          }
                        </CheckboxSMSLabel>
                      )
                    }
                    checked={this.state.agreed}
                    onChange={this.handleAgreeCancelPolicyChange}
                  />
                </CheckBoxContainer>
              )}
          </div>
        )}
        <ButtonAreaContainer>
          <ButtonContainer>
            <FullWidthButtonCustom
              id="register-appointment-confirm-button"
              onClick={this.onSubmit}
              disabled={isSubmitting || !agreed}
            >
              {
                renderResource.Fragment.Container.ButtonContainer
                  .FullWidthButton.j001
              }
            </FullWidthButtonCustom>
          </ButtonContainer>
          <ButtonContainer>
            <FullWidthButtonSub
              id="register-appointment-back-button"
              onClick={this.onCorrect}
            >
              {
                renderResource.Fragment.Container.ButtonContainer
                  .FullWidthButtonSub.j002
              }
            </FullWidthButtonSub>
          </ButtonContainer>
        </ButtonAreaContainer>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(ReservationConfirmForm));
