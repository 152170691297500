
import styled from "styled-components";
import Button from "./Button";

const ButtonLoadMore = styled(Button)`
  color: ${p => p.theme.color.font};
  background-color: transparent;
  padding: ${p => p.theme.space.normal};
  font-weight: normal;
`;
export default ButtonLoadMore;
