import React, { Component } from "react";
import styled from "styled-components";
import Heading1 from "../atoms/headings/Heading1";
import InputText from "../atoms/forms/InputText";
import FullWidthButton from "../atoms/buttons/ButtonFullWidth";
import { Auth } from "aws-amplify";
import { japaneseList } from "../../Resources/japaneseList";

const Container = styled.div`
  padding: 0 15px;
`;

const Content = styled.div`
  color: ${(p) => p.theme.color.font};
  size: 0.9rem;
  line-height: 1.5em;
  padding: 20px 0 24px 0;
`;

const EmailConfirmLabel = styled.div`
  color: ${(p) => p.theme.color.font};
  size: 0.8rem;
  font-weight: bold;
  padding: 14px 0 10px 0;
`;

const Note = styled.div`
  color: ${(p) => p.theme.color.fontVariant};
  size: 0.8rem;
  line-height: 1.5em;
  padding: 24px 0 20px 0;
`;

const Error = styled.div`
  color: ${(p) => p.theme.color.warning};
  size: 0.8rem;
  line-height: 1.5em;
  padding: 0 0 14px 0;
`;

const CarrierWarningContent = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background: #fff0f0;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ef5e5e;
  white-space: pre-wrap;
`;

class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email || "",
      emailConfirm: "",
      disableButton: true,
      displayError: "none",
      error: "",
      backgroundColorError: "#ffffff",
    };
  }

  handleInputChange = async (evt) => {
    const { name, value } = evt.target;
    await this.setState({
      [name]: value,
    });

    if (this.state.email && this.state.emailConfirm) {
      this.setState({
        disableButton: false,
      });
    } else {
      this.setState({
        disableButton: true,
      });
    }
  };

  checkInput() {
    const checkInputResource = japaneseList.organisms.EmailForm.checkInput;
    const { email, emailConfirm } = this.state;
    this.setState({
      displayError: "none",
      backgroundColorError: "#ffffff",
    });
    if (!email.includes("@")) {
      this.setState({
        error: checkInputResource.j001,
        displayError: "flex",
        backgroundColorError: "#fff0f0",
      });
      return false;
    }
    if (email !== emailConfirm) {
      this.setState({
        error: checkInputResource.j002,
        displayError: "flex",
        backgroundColorError: "#fff0f0",
      });
      return false;
    }

    return true;
  }

  submit = async () => {
    const submitResource = japaneseList.organisms.EmailForm.submit;
    if (!this.checkInput()) {
      return;
    }
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: this.state.email,
      });
      if (this.state.email === this.props.email) {
        await Auth.verifyCurrentUserAttribute("email");
      }
      this.props.history.push("/my-page/email/verify");
      let from = this.props.location.state && this.props.location.state.from;
      this.props.history.push({
        pathname: "/my-page/email/verify",
        state: { from: from, email: this.state.email },
      });
    } catch (error) {
      this.setState({
        error: submitResource.j001,
        displayError: "flex",
      });
    }
  };

  render() {
    const renderResource = japaneseList.organisms.EmailForm.render;
    return (
      <div>
        <Heading1>{renderResource.div.PrimaryHeading.j001}</Heading1>
        <Container>
          <Content>{renderResource.div.Container.Content.j001}</Content>
          <CarrierWarningContent>
            {renderResource.div.Container.WarningContent.j001}
          </CarrierWarningContent>
          <Error style={{ display: this.state.displayError }}>
            <span>{this.state.error}</span>
          </Error>
          <InputText
            style={{
              width: "100%",
              backgroundColor: this.state.backgroundColorError,
            }}
            placeholder="XXXXX@XXXX.com"
            name="email"
            value={this.state.email}
            onChange={(e) => this.handleInputChange(e)}
          />
          <EmailConfirmLabel>
            {renderResource.div.Container.EmailConfirmLabel.j001}
          </EmailConfirmLabel>
          <InputText
            style={{
              width: "100%",
              backgroundColor: this.state.backgroundColorError,
            }}
            placeholder="XXXXX@XXXX.com"
            name="emailConfirm"
            value={this.state.emailConfirm}
            onChange={(e) => this.handleInputChange(e)}
          />
          <Note>{renderResource.div.Container.Note.j001}</Note>
          <FullWidthButton
            disabled={this.state.disableButton}
            onClick={this.submit}
          >
            {renderResource.div.Container.FullWidthButton.j001}
          </FullWidthButton>
        </Container>
      </div>
    );
  }
}

export default EmailForm;
