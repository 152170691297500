import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import JapaneseTermsOfServiceForEnterprise from "../../../Resources/JapaneseTermsOfServiceForEnterprise";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontP from "../../atoms/fonts/FontP";
import HeadingForm from "../../molecules/headings/HeadingForm";
import InputLabel from "../../molecules/forms/InputLabel";
import CheckboxLabel from "../../molecules/checkboxLabels/CheckboxLabel";
import { RegisterEnterpriseAuthorization } from "../../../Utils/GraphQLQueries";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { ENTERPRISEAUTHORIZATION_MAINEFFECT } from "../../../Utils/Constant";
import { useAuthContext } from "../../../contexts/AuthContext";
import { isEqual, isEmpty } from "lodash";

const Explanation2 = styled(FontP)`
  font-size: 14px;
  padding: 10px 14px 20px;
  white-space: pre-wrap;
`;

const CustomPanelFormArea = styled.div`
  padding: 0 14px 20px;
`;

const CustomInputLabel = styled(InputLabel)`
  margin-top: 5px;
`;

const NextButton = styled(ButtonFullWidth)`
  height: 50px;
`;

const ButtonContainer = styled.div`
  padding: 0 30px;
  margin-top: 31px;
`;

const InputError = styled.div`
  color: red;
  font-size: 14px;
`;

const TermsArea = styled.div`
  padding: 0 14px;
`;

const Heading = styled(FontP)`
  font-size: 14px;
  margin-bottom: 10px;
`;

const TermsContent = styled.div`
  padding: 8px;
  margin-bottom: 0px;
  border: 1px solid #b2b2b2;
  overflow-y: scroll;
  height: 150px;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-size: 14px;
`;

const TermsTitle = styled.div`
  text-align: center;
  margin: 8px 0;
`;

const TermsSignAndDate = styled.div`
  text-align: right;
  margin: 8px 0;
`;

const TermsIntroduction = styled.div`
  margin: 8px 0;
`;

const TermsParagraph = styled.p`
  margin: 0;
`;

const TermsOrderedList = styled.ol`
  padding-left: 20px;
  margin: 8px 0 0 0;
`;

const TermsListItem = styled.li`
  padding-left: 1em;
  padding-bottom: 8px;
`;

const TermsIndentedParagraph = styled.p`
  margin: 0;
  margin-left: 0.5em;
  text-indent: -0.5em;
`;

const AcceptCheckboxLabel = styled(CheckboxLabel)`
  margin-top: 20px;
`;

const CheckboxText = styled(FontP)`
  font-size: 12px;
`;

const CustomHeadingForm = styled(HeadingForm)`
  margin-bottom: 10px;
`;

export const EnterpriseAuthorizationForm = withRouter((props) => {
  const [enterpriseAuthorizationCodes, setEnterpriseAuthorizationCodes] =
    useState([""]);
  const [enterpriseAuthorizationCodeErr, setEnterpriseAuthorizationCodeErr] =
    useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [employeeNumberErr, setEmployeeNumberErr] = useState("");
  const [confirmEmployeeNumber, setConfirmEmployeeNumber] = useState("");
  const [confirmEmployeeNumberErr, setConfirmEmployeeNumberErr] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const authContext = useAuthContext();
  const renderResource = japaneseList.pages.EnterpriseAuthorizationPage.render;

  useEffect(() => {
    setEnterpriseAuthorizationCodes(
      (props.location &&
        props.location.state &&
        props.location.state.enterpriseAuthorizationCodes) || [""]
    );
  }, [authContext.user]);

  const handleAgreement = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleNextButtonClick = async () => {
    setEnterpriseAuthorizationCodeErr([]);
    setEmployeeNumberErr("");
    setConfirmEmployeeNumberErr("");

    if (isSubmitting || !isAgreed || !validateForm()) {
      return false;
    }
    try {
      setIsSubmitting(true);
      const { errors, data } = await GraphQLClient.mutate({
        mutation: RegisterEnterpriseAuthorization,
        variables: {
          mainEffect: ENTERPRISEAUTHORIZATION_MAINEFFECT.DRY_RUN,
          enterpriseAuthorizationCodes:
            enterpriseAuthorizationCodes.filter(Boolean),
          termsOfServiceForEnterpriseAgree: isAgreed,
          termsOfServiceForEnterpriseRevisionDate:
            JapaneseTermsOfServiceForEnterprise.date.revision,
          employeeNumber: employeeNumber,
          confirmEmployeeNumber: confirmEmployeeNumber,
        },
      });
      if (errors) {
        setIsSubmitting(false);
        if (errors.find((e) => e.errorType === "E01")) {
          setEnterpriseAuthorizationCodeErr(renderResource.Submit.j001);
        } else if (errors.find((e) => e.errorType === "E02")) {
          toastError(renderResource.Submit.j002);
        } else if (errors.find((e) => e.errorType === "E03")) {
          setEnterpriseAuthorizationCodeErr(renderResource.Submit.j003);
        } else if (errors.find((e) => e.errorType === "E04")) {
          setEnterpriseAuthorizationCodeErr(renderResource.Submit.j004);
        } else if (errors.find((e) => e.errorType === "E05")) {
          toastError(renderResource.Submit.j005);
        } else if (errors.find((e) => e.errorType === "E06")) {
          toastError(renderResource.Submit.j006);
        } else if (errors.find((e) => e.errorType === "E07")) {
          toastError(renderResource.Submit.j007);
        } else if (errors.find((e) => e.errorType === "E08")) {
          toastError(renderResource.Submit.j008);
        } else if (errors.find((e) => e.errorType === "E09")) {
          toastError(renderResource.Submit.j009);
        } else if (errors.find((e) => e.errorType === "E10")) {
          setEmployeeNumberErr(renderResource.Submit.j011);
        } else if (errors.find((e) => e.errorType === "E11")) {
          setEmployeeNumberErr(renderResource.Submit.j012);
        } else if (errors.find((e) => e.errorType === "E12")) {
          setConfirmEmployeeNumberErr(renderResource.Submit.j011);
        } else if (errors.find((e) => e.errorType === "E13")) {
          setConfirmEmployeeNumberErr(renderResource.Submit.j012);
        } else if (errors.find((e) => e.errorType === "E14")) {
          toastError(renderResource.Submit.j013);
        } else {
          toastError(renderResource.Submit.j010);
        }
        return;
      }

      props.history.push({
        pathname: "/enterprise/confirm",
        state: {
          enterprise: data.authorizeEnterprise,
          flow: props.location.state && props.location.state.flow,
          from: props.location.state && props.location.state.from,
          enterpriseAuthorizationCodes: [...enterpriseAuthorizationCodes],
          employeeNumber: employeeNumber,
          isAgreed,
        },
      });
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const toastError = (errorMessage) => {
    Hub.dispatch("msg", {
      event: "open",
      data: {
        message: errorMessage,
        level: "error",
      },
    });
  };

  const validateForm = () => {
    if (!enterpriseAuthorizationCodes.find((item) => item !== "")) {
      setEnterpriseAuthorizationCodeErr(renderResource.Error.j001);
      return false;
    }
    if (enterpriseAuthorizationCodes.find((item) => item.trim().length !== 8)) {
      setEnterpriseAuthorizationCodeErr(renderResource.Error.j002);
      return false;
    }
    if (enterpriseAuthorizationCodes.find((item) => !item.match(/^[0-9]+$/))) {
      setEnterpriseAuthorizationCodeErr(renderResource.Error.j002);
      return false;
    }
    // validate employee number
    if (!employeeNumber.match(/^[a-zA-Z0-9-]*$/)) {
      setEmployeeNumberErr(renderResource.Error.j003);
      return false;
    }
    if (employeeNumber.trim().length > 30) {
      setEmployeeNumberErr(renderResource.Error.j004);
      return false;
    }
    // validate confirm employee number
    if (!confirmEmployeeNumber.match(/^[a-zA-Z0-9-]*$/)) {
      setConfirmEmployeeNumberErr(renderResource.Error.j003);
      return false;
    }
    if (confirmEmployeeNumber.trim().length > 30) {
      setConfirmEmployeeNumberErr(renderResource.Error.j004);
      return false;
    }
    if (!isEqual(employeeNumber, confirmEmployeeNumber)) {
      toastError(renderResource.Error.j005);
      return false;
    }
    return true;
  };

  const setEnterpriseAuthorizationCode = (index, value) => {
    const newEnterpriseAuthorizationCodes = [...enterpriseAuthorizationCodes];
    newEnterpriseAuthorizationCodes[index] = value;
    setEnterpriseAuthorizationCodes(newEnterpriseAuthorizationCodes);
  };

  return (
    <React.Fragment>
      <CustomHeadingForm>{renderResource.Content.j001}</CustomHeadingForm>
      <Explanation2>{renderResource.Content.j002}</Explanation2>
      <CustomPanelFormArea>
        {enterpriseAuthorizationCodeErr && (
          <InputError>{enterpriseAuthorizationCodeErr}</InputError>
        )}
        {enterpriseAuthorizationCodes.map((item, index) => {
          return (
            <CustomInputLabel
              id={`enterprise-authorization-code-input-${index + 1}`}
              key={index}
              value={item}
              onChange={(e) =>
                setEnterpriseAuthorizationCode(index, e.target.value)
              }
              placeholder={renderResource.Content.j003}
            />
          );
        })}
      </CustomPanelFormArea>
      <CustomHeadingForm>{renderResource.Content.j005}</CustomHeadingForm>
      <Explanation2>{renderResource.Content.j006}</Explanation2>
      <CustomPanelFormArea>
        {employeeNumberErr && <InputError>{employeeNumberErr}</InputError>}
        <CustomInputLabel
          value={employeeNumber}
          onChange={(e) => setEmployeeNumber(e.target.value)}
          placeholder={renderResource.Content.j003}
        />
      </CustomPanelFormArea>
      <Explanation2>{renderResource.Content.j007}</Explanation2>
      <CustomPanelFormArea>
        {confirmEmployeeNumberErr && (
          <InputError>{confirmEmployeeNumberErr}</InputError>
        )}
        <CustomInputLabel
          value={confirmEmployeeNumber}
          onChange={(e) => setConfirmEmployeeNumber(e.target.value)}
          placeholder={renderResource.Content.j003}
        />
      </CustomPanelFormArea>
      <TermsArea>
        <Heading>{JapaneseTermsOfServiceForEnterprise.heading}</Heading>
        <TermsContent>
          <TermsTitle>
            <TermsParagraph>
              {JapaneseTermsOfServiceForEnterprise.text.title}
            </TermsParagraph>
          </TermsTitle>
          <TermsSignAndDate>
            <TermsParagraph>
              {JapaneseTermsOfServiceForEnterprise.text.signAndDate.j001}
            </TermsParagraph>
            <TermsParagraph>
              {JapaneseTermsOfServiceForEnterprise.text.signAndDate.j002}
            </TermsParagraph>
            <TermsParagraph>
              {JapaneseTermsOfServiceForEnterprise.text.signAndDate.j003}
            </TermsParagraph>
          </TermsSignAndDate>
          <TermsIntroduction>
            <TermsParagraph>
              {JapaneseTermsOfServiceForEnterprise.text.introduction}
            </TermsParagraph>
          </TermsIntroduction>
          <TermsOrderedList>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li1.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li1.j001}
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li2.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li2.j001}
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j001}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j002}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j003}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j004}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j005}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j006}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li3.j007}
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.j001}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.j002}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.j003}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.j004}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li4.j005}
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li5.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j001}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j002}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j003}
              <br />
              <TermsIndentedParagraph>
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j004}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j005}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j006}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j007}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j008}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j009}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j010}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j011}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j012}
                <br />
              </TermsIndentedParagraph>
              <TermsIndentedParagraph>
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j013}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j014}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j015}
                <br />
              </TermsIndentedParagraph>
              <TermsIndentedParagraph>
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j016}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j017}
                <br />
              </TermsIndentedParagraph>
              <TermsIndentedParagraph>
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j018}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j019}
                <br />
                {JapaneseTermsOfServiceForEnterprise.text.ol.li5.j020}
              </TermsIndentedParagraph>
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li6.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li6.j001}
            </TermsListItem>
            <TermsListItem>
              {JapaneseTermsOfServiceForEnterprise.text.ol.li7.heading}
              <br />
              {JapaneseTermsOfServiceForEnterprise.text.ol.li7.j001}
            </TermsListItem>
          </TermsOrderedList>
        </TermsContent>
        <AcceptCheckboxLabel
          id="accept-terms-checkbox"
          onChange={handleAgreement}
          checked={isAgreed}
          label={<CheckboxText>{renderResource.Content.j004}</CheckboxText>}
        />
      </TermsArea>
      <ButtonContainer>
        <NextButton
          id="confirm-enterprise-plan"
          disabled={
            isSubmitting ||
            !isAgreed ||
            (isAgreed && isEmpty(employeeNumber)) ||
            (isAgreed && isEmpty(confirmEmployeeNumber))
              ? true
              : false
          }
          onClick={handleNextButtonClick}
        >
          {renderResource.Button.j001}
        </NextButton>
      </ButtonContainer>
    </React.Fragment>
  );
});
