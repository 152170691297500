import styled from "styled-components";
import ButtonOutlined from "./ButtonOutlined";

const ButtonOutlinedSecondary = styled(ButtonOutlined)`
  background-color: ${p => p.theme.color.fontInvert};
  border: 1px solid ${p => p.theme.color.secondary};
  color: ${p => p.theme.color.font};

  :disabled {
    color: ${p => p.theme.color.secondary};
  }
`;

export default ButtonOutlinedSecondary;
