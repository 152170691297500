import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BasicTemplate from "../templates/BasicTemplate";
import Titles from "../../Resources/Titles";
import SearchResult from "../organisms/SearchResult";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  ListHospitalsByAddress,
  ListHospitalCountsByAddress,
} from "../../Utils/GraphQLQueries";
import qs from "querystring";
import { isLnln } from "../../Utils/checkLnln";

class SearchResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitals: [],
      hospitalCount: 0,
      isLoading: true,
      isFetching: true,
      listNextToken: null,
    };
  }

  getHospitalList = async (loadedSize = 0) => {
    const { prefecture, type } = qs.parse(window.location.search.substr(1));
    const { data, errors } = await GraphQLClient.query({
      query: ListHospitalsByAddress,
      variables: {
        prefectureCode: prefecture,
        facilityType: type,
        from: isLnln() ? "lnln" : "carada",
        limit: isLnln() ? null : 20,
        startToken: this.state.listNextToken,
      },
    });
    if (errors) return;
    const hospitals = this.state.hospitals.concat(data.hospitals.items);
    this.setState({ hospitals });
    this.setState({ listNextToken: data.hospitals.nextToken });
    // recursive execution before exceeding 20 cases
    const nowLoadedSize = loadedSize + data.hospitals.items.length;
    if (this.state.listNextToken && nowLoadedSize < 20) {
      await this.getHospitalList(nowLoadedSize);
    }
  };

  getHospitalListMore = async () => {
    if (!this.state.listNextToken || this.state.isFetching) return;
    this.setState({ isFetching: true });
    await this.getHospitalList();
    this.setState({ isFetching: false });
  };

  getHospitalCount = async () => {
    const { prefecture, type } = qs.parse(window.location.search.substr(1));
    const { data, errors } = await GraphQLClient.query({
      query: ListHospitalCountsByAddress,
      variables: {
        prefectureCode: prefecture,
        facilityType: type,
        from: isLnln() ? "lnln" : "carada",
      },
    });
    if (errors) return;
    this.setState({ hospitalCount: data.hospitalCounts.items[0].count });
  };

  async componentDidMount() {
    await Promise.all([this.getHospitalList(), this.getHospitalCount()]);
    this.setState({ isLoading: false, isFetching: false });
  }

  render() {
    return (
      <BasicTemplate
        title={Titles.searchResult}
        main={
          <SearchResult
            hospitals={this.state.hospitals}
            hospitalCount={this.state.hospitalCount}
            onMore={this.getHospitalListMore}
            isLoading={this.state.isLoading}
            isFetching={this.state.isFetching}
          />
        }
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(SearchResultPage);
