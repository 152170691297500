import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import FontPBold from "../atoms/fonts/FontPBold";
import FontPSmall from "../atoms/fonts/FontPSmall";
import FontPSS from "../atoms/fonts/FontPSS";
import Card from "./Card";
import { japaneseList } from "../../Resources/japaneseList";
import { formatPeriod } from "../../Utils/Utility";

const styles = (theme) => ({
  rejected: {
    color: "#BBB !important",
  },
  symbolIcon: {
    fontFamily: "none",
  },
});

class Appointment extends Component {
  render() {
    const { appointment, classes, shouldShowConsentRegistrationMessage } =
      this.props;

    let styleClass, smallContent;
    switch (appointment.status) {
      case "rejected":
        styleClass = classes.rejected;
        smallContent = (
          <FontPSmall style={{ color: "#F26666" }}>
            <span className={classes.symbolIcon}>▲</span>
            {japaneseList.molecules.Appointment.textRejected}
          </FontPSmall>
        );
        break;
      case "unapproved":
        smallContent = (
          <FontPSmall style={{ color: "#305D95" }}>
            <span className={classes.symbolIcon}>▲</span>
            {japaneseList.molecules.Appointment.textUnapproved}
          </FontPSmall>
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <Card>
          <FontPBold className={styleClass}>
            {appointment.hospitalName}
          </FontPBold>
          <FontPSmall className={styleClass}>
            {formatPeriod(appointment.from, appointment.to) +
              japaneseList.molecules.Appointment.textAppointmentDateSuffix}
          </FontPSmall>
          {smallContent}
          {shouldShowConsentRegistrationMessage && (
            <>
              <FontPSmall style={{ color: "#305D95" }}>
                <span className={classes.symbolIcon}>▲</span>
                {
                  japaneseList.molecules.Appointment.textConsentUnregistered
                    .main
                }
              </FontPSmall>
              <FontPSS style={{ color: "#305D95" }}>
                {japaneseList.molecules.Appointment.textConsentUnregistered.sub}
              </FontPSS>
            </>
          )}
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Appointment));
