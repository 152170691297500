import React, { useEffect, useState, useRef } from "react";
import * as Sentry from "@sentry/browser";

import { usePollingContext } from "../../contexts/PollingContext";
import { ReloadPopUp } from "../molecules/popup/ReloadPopUp";
import { useAuthContext } from "../../contexts/AuthContext";

const INTERVAL_TIME = 10 * 60 * 1000; // 10分
const TIMEOUT = 2 * 60 * 60 * 1000; // 2時間

const PollingManager: React.FC = () => {
  const [lastPageChangeTime, setLastPageChangeTime] = useState<number>(
    Date.now()
  );
  // setInterval内でstateを参照するためにuseRefを使う
  const lastPageChangeTimeRef = useRef(lastPageChangeTime);

  const [isOpenReloadPopUp, setIsOpenReloadPopUp] = useState<boolean>(false);
  const { addPollingId, stopAllPolling, stopPollingManager } =
    usePollingContext();
  const { isAuthenticated } = useAuthContext();
  useEffect(() => {
    if (isAuthenticated) {
      const id = setInterval(updateElapsedTime, INTERVAL_TIME);
      addPollingId("PollingManager", id);
    }
    return () => {
      // 障害対応のため、このような実装になっている
      // PollingManagerではstopPolling("PollingManager")とkeyを指定するのが望ましい
      stopPollingManager();
    };
  }, [isAuthenticated]);

  // URLが変更されるごとに、最終操作時間を更新する
  useEffect(() => {
    const lastPageChangeTime = Date.now();
    setLastPageChangeTime(lastPageChangeTime);
    lastPageChangeTimeRef.current = lastPageChangeTime;
  }, [location.pathname]);

  const updateElapsedTime = () => {
    const time = Date.now() - lastPageChangeTimeRef.current;
    if (isInactivity(time)) stopPollingProcess();
  };

  const isInactivity: (elapsedTime: number) => boolean = (elapsedTime) => {
    return elapsedTime > TIMEOUT;
  };

  const stopPollingProcess = () => {
    try {
      stopAllPolling();
      setIsOpenReloadPopUp(true);
      Sentry.captureMessage("stop-polling-inactive-user", Sentry.Severity.Log);
    } catch (e) {
      console.error(e);
    }
  };

  return <ReloadPopUp isOpen={isOpenReloadPopUp} />;
};

export default PollingManager;
