import React from "react";
import styled from "styled-components";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import Select from "../../atoms/forms/Select";
import Button from "../../atoms/buttons/Button";
import { prefectureCodes } from "../../../Utils/PrefectureCode";
import { japaneseList } from "../../../Resources/japaneseList";
import HeadingForm from "../../molecules/headings/HeadingForm";
import FontP from "../../atoms/fonts/FontP";
import InfoMessage from "../../molecules/InfoMessage";
import InputLabel from "../../molecules/forms/InputLabel";

const Row = styled.div`
  padding: ${(p) => p.theme.panel.padding.s3} ${(p) => p.theme.panel.padding.s}
    ${(p) => p.theme.panel.padding.s} ${(p) => p.theme.panel.padding.s};
  padding-bottom: 0;

  :last-child {
    padding-bottom: ${(p) => p.theme.space.normal};
  }
`;

const PostalCodeRow = styled(PanelFormArea)`
  padding-bottom: 0;

  :last-child {
    padding-bottom: ${(p) => p.theme.space.normal};
  }
  & > * {
    margin-right: 10px;
  }
  > button {
    margin-top: 26px;
    margin-bottom: auto;
    margin-right: 100px
    padding: 0 10px;
    height: 30px;
    min-width: 90px;
  }
`;

const SelectContainer = styled.div`
  width: 50%;
  font-weight: bold;
  > div {
    width: 100%;
  }
`;

const WarningMessage = styled(InfoMessage)`
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
`;

interface Address {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface Props {
  addressCityError: boolean;
  addressBuildingError: boolean;
  addressStreetNumberError: boolean;
  address: Address;
  handleInputChange: (
    e: React.FormEvent<HTMLInputElement>,
    maxLength?: Number
  ) => void;
  getAddressFromPostalCode: () => void;
}

class AddressArea extends React.Component<Props> {
  render() {
    const renderResource = japaneseList.organisms.auth.AddressArea.render;
    const {
      addressCityError,
      addressBuildingError,
      addressStreetNumberError,
      address: {
        postalCode,
        prefectureCode,
        addressCity,
        addressStreetNumber,
        addressBuilding,
      },
      handleInputChange,
      getAddressFromPostalCode,
    } = this.props;

    return (
      <div>
        <React.Fragment>
          <HeadingForm>{renderResource.div.Title}</HeadingForm>
          <Row>
            <WarningMessage>
              <FontP size="sss">{renderResource.div.note.text.j001}</FontP>
            </WarningMessage>
          </Row>
          <PostalCodeRow>
            <InputLabel
              placeholder={
                renderResource.div.Fragment.PostalCodeRow.InputText.j001
              }
              name="postalCode"
              value={postalCode}
              pattern="\d*"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleInputChange(e, 7)
              }
            >
              {renderResource.div.Fragment.PostalCodeRow.Label}
            </InputLabel>
            <Button onClick={getAddressFromPostalCode}>
              {renderResource.div.Fragment.PostalCodeRow.Button.j001}
            </Button>
          </PostalCodeRow>
          <Row>
            <SelectContainer>
              {renderResource.div.Fragment.Row.FullWidth.Select.Label}
              <Select
                name="prefectureCode"
                value={prefectureCode}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  handleInputChange(e)
                }
              >
                <option value="">
                  {renderResource.div.Fragment.Row.FullWidth.Select.j001}
                </option>
                {prefectureCodes.map(function (code) {
                  return (
                    <option key={code.code} value={code.code}>
                      {code.jpName}
                    </option>
                  );
                })}
              </Select>
            </SelectContainer>
          </Row>
          <Row>
            <InputLabel
              placeholder={
                renderResource.div.Fragment.Row.InputText.AddressCity
                  .Placeholder
              }
              name="addressCity"
              value={addressCity}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleInputChange(e, 70)
              }
              error={addressCityError}
            >
              {renderResource.div.Fragment.Row.InputText.AddressCity.Label}
            </InputLabel>
          </Row>
          <Row>
            <InputLabel
              style={{ width: "100%" }}
              placeholder={
                renderResource.div.Fragment.Row.InputText.addressStreetNumber
                  .Placeholder
              }
              name="addressStreetNumber"
              value={addressStreetNumber}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleInputChange(e, 15)
              }
              error={addressStreetNumberError}
            >
              {
                renderResource.div.Fragment.Row.InputText.addressStreetNumber
                  .Label
              }
            </InputLabel>
          </Row>
          <Row>
            <InputLabel
              style={{ width: "100%" }}
              placeholder={
                renderResource.div.Fragment.Row.InputText.addressBuilding
                  .Placeholder
              }
              name="addressBuilding"
              value={addressBuilding}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleInputChange(e, 30)
              }
              error={addressBuildingError}
            >
              {renderResource.div.Fragment.Row.InputText.addressBuilding.Label}
            </InputLabel>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

export default AddressArea;
