import React, { Component } from "react";
import styled from "styled-components";
import format from "date-fns/format";
import Heading2 from "../atoms/headings/Heading2";
import LinkToNoUnder from "../atoms/links/LinkToNoUnder";
import Appointment from "../molecules/Appointment";
import {
  MEDICAL_METHOD,
  APPOINTMENT_STATUS,
  CONSENT_STATUS,
} from "../../Utils/Constant";

import { japaneseList } from "../../Resources/japaneseList";

const CustomLinkToNoUnder = styled(LinkToNoUnder)`
  display: block;
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
  :last-child {
    border-bottom: none;
  }
`;

class ListAppointment extends Component {
  // ここからオンライン資格確認の判定
  /**
   * 同意登録を促すかどうかを判定する処理
   * @param {*} appointment 判定したい予約
   * @param {*} allAppointments 表示するすべての予約
   * @returns
   */
  shouldShowConsentRegistrationMessage = (appointment, allAppointments) => {
    // TOP以外だと表示させない
    if (!appointment.menu) return false;

    // 該当の予約に対して同意を登録できるか
    const canRegisterConsent =
      this.isConsentRegisterableAppointment(appointment) &&
      this.isConsentRegisterableHospital(
        appointment.isSupportMyNumberInsuranceCard,
        appointment.consentStatus
      );
    if (!canRegisterConsent) return false;

    // 該当の予約が同一医療機関内で最も未来の予約か
    const consentRegistrableAppointments = allAppointments.filter((appo) => {
      return (
        this.isConsentRegisterableAppointment(appo) &&
        appo.hospitalId === appointment.hospitalId
      );
    });
    const isLatestAppointment = this.isLatestAppointment(
      appointment,
      consentRegistrableAppointments
    );

    return canRegisterConsent && isLatestAppointment;
  };

  isConsentRegisterableAppointment = (appointment) => {
    const isOnline = appointment.menu.medicalMethod === MEDICAL_METHOD.ONLINE;
    const isEnable = this.isEnableAppointment(appointment.status);
    return isOnline && isEnable;
  };

  isConsentRegisterableHospital = (
    isSupportMyNumberInsuranceCard,
    consentStatus
  ) => {
    const isUnregistered = consentStatus === CONSENT_STATUS.UNREGISTERED;
    return isSupportMyNumberInsuranceCard && isUnregistered;
  };

  isEnableAppointment = (status) => {
    const ENABLE_APPOINTMENT_STATUSES = [
      APPOINTMENT_STATUS.UNAPPROVED,
      APPOINTMENT_STATUS.BEFORE_EXAM,
      APPOINTMENT_STATUS.EXAM_COMPLETED,
      APPOINTMENT_STATUS.PAYMENT_COMPLETED,
    ];
    return ENABLE_APPOINTMENT_STATUSES.includes(status);
  };

  isLatestAppointment = (targetAppointment, appointments) => {
    // targetAppointment自身がappointmentsに含まれるため、>=で判定する
    return appointments.every((appointment) => {
      return (
        new Date(targetAppointment.from).getTime() >=
        new Date(appointment.from).getTime()
      );
    });
  };
  // ここまでオンライン資格確認の判定

  render() {
    const renderResource = japaneseList.organisms.ListAppointment.render;
    const nowDay = format(new Date(), "YYYYMMDD");
    const { appointments } = this.props;
    const list = [];

    appointments.forEach((item) => {
      if (format(item.to, "YYYYMMDD") < nowDay) return;
      list.push(item);
    });

    return (
      <div>
        {list && list.length > 0 ? (
          <React.Fragment>
            <Heading2>
              {renderResource.div.Fragment.HomeHeadingAlignCenter.j001}
            </Heading2>
            {list.map((item, i) => {
              const url = "/appointment/" + item.createTime;
              return (
                <CustomLinkToNoUnder to={url} key={item.from}>
                  <Appointment
                    appointment={item}
                    shouldShowConsentRegistrationMessage={this.shouldShowConsentRegistrationMessage(
                      item,
                      list
                    )}
                  />
                </CustomLinkToNoUnder>
              );
            })}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ListAppointment;
