import { Auth } from "aws-amplify";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import React, { Component } from "react";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import Titles from "../../Resources/Titles";
import { japaneseList } from "../../Resources/japaneseList";
import { MEDICAL_METHOD, PAYMENT_METHOD } from "../../Utils/Constant";
import GraphQLClient from "../../Utils/GraphQLClient";
import { GetRequiringConsentRegistration } from "../../Utils/GraphQLQueries";
import { formatPeriod } from "../../Utils/Utility";
import Heading1 from "../atoms/headings/Heading1";
import RegisterMail from "../organisms/reservation/RegisterMail";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { ConsentRegistration } from "../organisms/reservation/ConsentRegistration";

const Container = styled.div`
  padding: 0 15px;
  min-height: 400px;
`;

const Circle = styled.div`
  width: 60px;
  height: 60px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.primary};
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  white-space: pre-wrap;
  line-height: 17px;
`;

const ConsentRegistrationContainer = styled.div`
  margin-bottom: 16px;
`;

const InfoContainer = styled.div`
  border: 1.5px solid ${(p) => p.theme.color.primary};
  padding: 17px 20px 1px 20px;
  border-radius: 5px;
  margin: 0;
`;

const Detail = styled.div`
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 10px 0;
`;

const Title = styled.div`
  padding: 0 0 3px 0;
  color: ${(p) => p.theme.color.primary};
  font-weight: bold;
  font-size: 14px;
`;

const Heading2CenterCustome = styled.div`
  background-color: #ffffff;
  color: ${(p) => p.theme.color.primary};
  font-size: 18px;
  font-weight: bold;
  padding: 27px 0 17px 0;
  text-align: center;
`;

const SubStep = styled.div`
  margin: 0;
  padding: 0 0 12px 0;
`;

const TextMessage = styled.span`
  line-height: 15px;
  padding-bottom: 3px;
`;

const TextRemarks = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-top: 7px;
`;

const StepContentCustom = styled.div`
  padding: 0;
  border-left: 6px solid
    ${(p) => (p.connect ? p.theme.color.primaryVariant3 : "#ffffff")};
`;

const InputFormHeaderNoIconCustom = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant3};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 22px 0 20px 0;
`;

const LineBreakWrapper = styled.span`
  word-break: keep-all;
  overflow-wrap: break-word;
`;

const styles = (theme) => ({
  stepContent: {
    "margin-top": "-60px",
    "margin-left": "27px",
    "padding-left": "40px",
    padding: 0,
    "white-space": "pre-wrap",
    "overflow-wrap": "break-word",
  },
  stepDetail: {
    margin: 0,
    "margin-left": "-3px",
    padding: "14px 15px",
    "background-color": "#f7f7f7",
    "border-radius": "5px",
    "white-space": "pre-wrap",
    "overflow-wrap": "break-word",
  },
  subStepTitle: {
    "font-size": "15px",
    "font-weight": "bold",
    margin: 0,
    padding: "0 0 3px 0",
    "white-space": "pre-wrap",
    "overflow-wrap": "break-word",
    "line-height": "26px",
  },
  subStepBody: {
    "font-size": "12px",
    color: "#393939",
    margin: 0,
    "white-space": "pre-wrap",
    "overflow-wrap": "break-word",
    "line-height": "20px",
  },
  subStepNote: {
    "font-size": "12px",
    color: "#999999",
    margin: 0,
    "white-space": "pre-wrap",
    "overflow-wrap": "break-word",
    "line-height": "20px",
  },
  stepper: {
    padding: "0 0 4px 5px",
  },
  heading1: {
    height: "40px",
    "font-size": "17px",
    "font-weight": "bold",
  },
  heading2: {
    "font-size": "15px",
    "font-weight": "bold",
    "margin-bottom": "20px",
    color: "#393939",
  },
  textMiddle: {
    display: "flex",
    "align-items": "center",
  },
  textCenter: {
    display: "flex",
    "justify-content": "center",
  },
  feeDetail: {
    padding: 0,
    margin: 0,
    "white-space": "pre-wrap",
  },
});
class ReservationCompletePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailRegistered: false,
      sendMail: false,
      onlineQualificationConsentStatus: {},
    };

    this.reserveInput = null;
    this.medicineDelivery = null;
    if (this.props.location.state) {
      this.reserveInput = this.props.location.state.reserveInput;
      this.medicineDelivery = this.props.location.state.medicineDelivery;
    } else {
      return this.redirectPage();
    }

    this.hospital =
      this.props.location.state && this.props.location.state.hospital;
    this.menu = this.props.location.state && this.props.location.state.menu;

    if (this.reserveInput && this.reserveInput.sendMail)
      this.setState({
        sendMail: true,
      });
  }

  async componentDidMount() {
    try {
      var user = await Auth.currentUserInfo();
      if (user) {
        this.setState({
          mailRegistered: user.attributes.email_verified ? true : false,
        });
      }
      await this.loadConsentStatus(
        this.reserveInput.hospitalId,
        this.reserveInput.selectedReserve.to
      );
    } catch (err) {
      console.log(err);
    }
  }

  redirectPage = () => {
    this.props.history.push(`/`);
    return {
      render() {
        return null;
      },
    };
  };

  loadConsentStatus = async (hospitalId, to) => {
    const { data, errors } = await GraphQLClient.query({
      query: GetRequiringConsentRegistration,
      variables: { hospitalId: hospitalId, appointmentDate: to },
    });
    if (errors) {
      console.error("ConsentStatus errors:", JSON.stringify(errors));
    }
    this.setState({
      onlineQualificationConsentStatus: data ? data.consentStatus : {},
    });
  };

  getSteps = () => {
    let flows =
      (this.menu &&
        this.menu.enableAppointmentFlow &&
        this.menu.appointmentFlows) ||
      [];

    let selectedFlows = flows.filter(
      (flow) =>
        Boolean(flow.approvalRequired) === Boolean(this.menu.approvalRequired)
    );

    return ((selectedFlows.length && selectedFlows[0].steps) || [])
      .sort((a, b) => {
        return a.order < b.order ? -1 : 0;
      })
      .map((step) => {
        return {
          title: step.name,
          steps: (step.contents || [])
            .sort((a, b) => {
              return a.order < b.order ? -1 : 0;
            })
            .map((content) => {
              return {
                title: content.title,
                detail: content.description,
                note: content.note,
              };
            }),
        };
      });
  };

  // オンライン資格確認の同意登録を促すべきか
  // 予約詳細画面の条件とは異なる
  shouldRequireConsentRegistration = () => {
    const { isSupportMyNumberInsuranceCard, hasFutureAppointment } =
      this.state.onlineQualificationConsentStatus;
    const isOnline = this.menu.medicalMethod === MEDICAL_METHOD.ONLINE;

    return isSupportMyNumberInsuranceCard && !hasFutureAppointment && isOnline;
  };

  formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  render() {
    const { classes } = this.props;
    const renderResource = japaneseList.pages.ReservationCompletePage.render;
    const steps = this.getSteps();
    const sendSms = this.reserveInput && this.reserveInput.sendSms;
    const sendMail = this.reserveInput && this.reserveInput.sendMail;
    const appointment = {
      createTime: new Number(this.reserveInput.createTime),
      hospitalId: this.reserveInput.hospitalId,
      to: this.reserveInput.selectedReserve.to,
    };
    const main = (
      <div>
        <Heading1 className={`${classes.heading1} ${classes.textMiddle}`}>
          {renderResource.Title.j001}
        </Heading1>
        <InputFormHeaderNoIconCustom
          className={`${classes.heading2} ${classes.textMiddle} ${classes.textCenter}`}
        >
          {this.menu.approvalRequired ? (
            <React.Fragment>
              <TextMessage>{renderResource.Title.j003}</TextMessage>
              <TextRemarks>{renderResource.Title.j004}</TextRemarks>
            </React.Fragment>
          ) : (
            <TextMessage>{renderResource.Title.j002}</TextMessage>
          )}
        </InputFormHeaderNoIconCustom>
        <Container>
          {this.shouldRequireConsentRegistration() && (
            <ConsentRegistrationContainer>
              <ConsentRegistration
                appointment={appointment}
                onlineQualificationConsentStatus={
                  this.state.onlineQualificationConsentStatus
                }
              />
            </ConsentRegistrationContainer>
          )}
          <InfoContainer>
            <Title>{renderResource.Information.j001}</Title>
            <Detail>
              {this.reserveInput
                ? formatPeriod(
                    this.reserveInput.selectedReserve.from,
                    this.reserveInput.selectedReserve.to
                  ) + renderResource.DateTimeFormat.j001
                : ""}
            </Detail>
            <Title>{renderResource.Information.j002}</Title>
            <Detail>{this.hospital.name}</Detail>
            <Title>{renderResource.Information.j003}</Title>
            <Detail>{this.menu.displayName}</Detail>
            <React.Fragment>
              <Title>{renderResource.Information.j004}</Title>
              <Detail>
                {renderResource.Information.Mail.j001} :{" "}
                {sendMail
                  ? renderResource.Information.Mail.j002
                  : renderResource.Information.Mail.j003}
                <br />
                SMS :{" "}
                {sendSms
                  ? renderResource.Information.SMS.j001
                  : renderResource.Information.SMS.j002}{" "}
              </Detail>
            </React.Fragment>
            <Title>{renderResource.Information.j005}</Title>
            <Detail>
              {this.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE ? (
                renderResource.Information.Fee.j004
              ) : (
                <p className={classes.feeDetail}>
                  {this.menu.medicalMethod === MEDICAL_METHOD.ONLINE ? (
                    <LineBreakWrapper>
                      {parse(
                        DOMPurify.sanitize(renderResource.Information.Fee.j003)
                      )}
                    </LineBreakWrapper>
                  ) : (
                    <span>{renderResource.Information.Fee.j003a}</span>
                  )}
                </p>
              )}
            </Detail>
          </InfoContainer>
          {steps && steps.length > 0 ? (
            <React.Fragment>
              <Heading2CenterCustome>
                {renderResource.AppointmentFlow.Title.j001}
              </Heading2CenterCustome>
              <Stepper
                orientation="vertical"
                connector={null}
                className={classes.stepper}
              >
                {steps.map((step, index) => (
                  <Step key={index} active={true}>
                    <StepLabel icon={<Circle>{step.title}</Circle>}></StepLabel>
                    <StepContentCustom
                      className={classes.stepContent}
                      connect={Boolean(index < steps.length - 1)}
                    >
                      {step.steps.map((item, index) => {
                        return (
                          <SubStep key={index}>
                            <div className={classes.stepDetail}>
                              {item.title && (
                                <p className={classes.subStepTitle}>
                                  {item.title}
                                </p>
                              )}
                              {item.detail && (
                                <p className={classes.subStepBody}>
                                  {item.detail}
                                </p>
                              )}
                              {item.note && (
                                <p className={classes.subStepNote}>
                                  {item.note}
                                </p>
                              )}
                            </div>
                          </SubStep>
                        );
                      })}
                    </StepContentCustom>
                  </Step>
                ))}
              </Stepper>
            </React.Fragment>
          ) : (
            ""
          )}
          {/* If the patient does not register the email address, display to prompt to register it. */}
          {this.reserveInput && !this.state.mailRegistered && <RegisterMail />}
        </Container>
      </div>
    );
    return <AuthenticatedTemplate title={Titles.reserveComplete} main={main} />;
  }
}
export default withStyles(styles)(ReservationCompletePage);
