import React, { Component } from "react";

import Select from "../../atoms/forms/Select";

class SelectMonth extends Component {
  constructor(props) {
    super(props);
    this.month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
  }

  render(){
    const month = this.month;
    return(
      <Select {...this.props}>
        {month.map(month => (
          <option value={month} key={month}>
            {month}
          </option>
        ))}
      </Select>
    )

  }
}
export default SelectMonth;
