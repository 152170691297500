import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "styled-components";
import { isLnln } from "../../Utils/checkLnln";
import videoPathCarada from "../../images/carada/video.svg";
import videoPathLnln from "../../images/lnln/video.svg";

const videoIcon = isLnln() ? videoPathLnln : videoPathCarada;

const Card = styled.div`
  text-align: center;
  padding-top: 13px;
  padding-bottom: 14px;
  background: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  margin: 0px 30px;
`;

const Logo = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 1px 0px;
  position: relative;
  padding-left: 27px;
  height: 21px;
`;

const CardContent = styled.div`
  color: ${(p) => p.theme.color.primary};
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  padding-left: 11px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-family: "Hiragino Kaku Gothic Pro";
`;

const Right = styled.div`
  height: 24px;
  float: right;
  margin-top: 1px;
  margin-right: 3px;
  color: ${(p) => p.theme.color.primary};
`;

export default ({ children }) => (
  <Card>
    <Logo>
      <img src={videoIcon} alt={""} />
    </Logo>
    <CardContent>{children}</CardContent>
    <Right>
      <ChevronRightIcon />
    </Right>
  </Card>
);
