import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { lighten } from "polished";
import Heading1 from "../../atoms/headings/Heading1";
import List from "../../atoms/lists/List";
import FontPBold from "../../atoms/fonts/FontPBold";
import StyledButton from "../../atoms/buttons/Button";
import StyledInputTel from "../../atoms/forms/InputTel";
import InputTextPassword from "../../atoms/forms/InputTextPassword";
import LinkA from "../../atoms/links/LinkA";
import SignInLnlnCaution from "../../organisms/auth/SignInLnlnCaution";

import { isLnln } from "../../../Utils/checkLnln";
import { Auth, Hub, Logger } from "aws-amplify";
import { japaneseList } from "../../../Resources/japaneseList";

import * as Sentry from "@sentry/browser";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNIN_STEPS,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";

const Note = styled.div`
  color: ${(p) => p.theme.color.font};
  font-size: 0.9rem;
  align-self: center;
`;

const InputLabel = styled(FontPBold)`
  margin-bottom: 2px;
  line-height: 1;
`;

const InputTel = styled(StyledInputTel)`
  width: 100%;
`;

const Button = styled(StyledButton)`
  width: 100%;
  font-size: 1rem;
`;

const SignUpSection = styled.div`
  padding: 30px 15px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  margin-top: 15px;
`;

const SignUpButton = styled(Button)`
  background-color: ${(p) => p.theme.button.background.color.cvbutton};
  :active {
    background-color: ${(p) =>
      lighten(0.1, p.theme.button.background.color.cvbutton)};
  }
`;

const Section = ({ children }) => (
  <div>
    <List
      style={{
        padding: "15px",
        paddingBottom: "30px",
        backgroundColor: "#fff",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.16)",
      }}
    >
      {children}
    </List>
  </div>
);

const Explanation = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
`;

const InputFormHeaderNoIcon = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant3};
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;
class SignInPage extends Component {
  state = {
    phone_number: "",
    password: "",
  };

  constructor(props) {
    super(props);
    this.logger = new Logger("SignIn");
    this.state = {
      isFromEnterprise:
        this.props.location.state &&
        this.props.location.state.from === "enterprise",
      isFlowSignUp:
        this.props.location.state &&
        this.props.location.state.flow === "signup",
    };
  }

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "SignIn"
    );
  };

  signIn = async () => {
    const signInResource = japaneseList.pages.auth.SignInPage.signIn;
    this.props.onLoading(true);
    const { phone_number, password } = this.state;

    let username = phone_number;
    if (username.startsWith("0")) {
      username = "+81" + username.substring(1, username.length);
    }

    Auth.signIn({
      username: username,
      password: password,
    })
      .then((user) => {
        Sentry.setUser({
          id: user.username,
        });
        Sentry.captureMessage("signin", Sentry.Severity.Log);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.props.authcb("NEW_PASSWORD_REQUIRED", user);
        } else {
          // Because we don't allow user update their phone_number, so we don't need implement this now.
          Auth.verifiedContact(user).then((data) => {});
        }
      })
      .catch((err) => {
        Sentry.captureException(err.code ? new Error(err.code) : err);

        if (err.code === "UserNotConfirmedException") {
          this.props.authcb("CONFIRMATION", {
            username: username,
            phone_number: phone_number,
          });
        } else if (
          err.code === "UserNotFoundException" ||
          err.code === "NotAuthorizedException"
        ) {
          this.snackBar(signInResource.j001);
        } else if (
          err.code === "UserLambdaValidationException" &&
          err.message.includes("User not found")
        ) {
          this.snackBar(signInResource.j003);
        } else {
          this.snackBar(signInResource.j002);
        }
      })
      .finally(() => {
        this.props.onLoading(false);
      });
  };

  handleInputBlur = (evt) => {
    this.updateState(evt);
  };

  handleInputChange = (evt) => {
    this.updateState(evt);
  };

  handleClickSignUpButton = () => {
    this.props.history.push({
      pathname: "/auth/signup",
      state: { ...this.props.location.state, action: null },
    });
  };

  updateState(evt) {
    const { name, value } = evt.target;
    this.setState({
      [name]: value,
    });
  }

  checkInput() {
    const { password, phone_number } = this.state;
    if (!phone_number || !password || password.length < 6) return false;
    return true;
  }

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.props.history.push("/");
      })
      .catch((e) => {
        console.error(e);
      });
    let message = this.props.message;
    if (message) {
      this.snackBar(message, "INFO");
    }
  }

  renderStepper() {
    const { isFromEnterprise, isFlowSignUp } = this.state;
    if (!isFromEnterprise) {
      return;
    }
    const currentStep = isFlowSignUp
      ? ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.SIGNIN]
      : ENTERPRISE_SIGNIN_STEPS[ENTERPRISE_REGISTER_STEP.SIGNIN];

    const registerSteps = isFlowSignUp
      ? ENTERPRISE_SIGNUP_STEPS
      : ENTERPRISE_SIGNIN_STEPS;

    return (
      <EnterpriseStepperForm
        registerSteps={registerSteps}
        currentStep={currentStep}
      />
    );
  }

  render() {
    const renderResource = japaneseList.pages.auth.SignInPage.render;
    const { phone_number, password, isFromEnterprise, isFlowSignUp } =
      this.state;
    const disabled =
      !phone_number || !password || password.length < 6 ? "disabled" : "";
    const lnlnNote = isLnln() && !isFlowSignUp ? <SignInLnlnCaution /> : "";
    return (
      <React.Fragment>
        <Heading1>{renderResource.Section.j001}</Heading1>
        {this.renderStepper()}
        {isFlowSignUp && (
          <InputFormHeaderNoIcon>
            {renderResource.Section.InputFormHeaderNoIcon.j001}
          </InputFormHeaderNoIcon>
        )}
        <Section>
          {isFlowSignUp && isFromEnterprise && (
            <Explanation>{renderResource.Section.Description.j001}</Explanation>
          )}
          {isFlowSignUp && !isFromEnterprise && (
            <Explanation>{renderResource.Section.Description.j002}</Explanation>
          )}
          {lnlnNote}
          <InputLabel>{renderResource.Section.label.j001}</InputLabel>
          <InputTel
            name="phone_number"
            value={phone_number}
            onBlur={this.handleInputBlur}
            onChange={this.handleInputChange}
            placeholder={renderResource.Section.InputText.j001}
          />
          <br />
          <InputLabel>{renderResource.Section.label.j002}</InputLabel>
          <InputTextPassword
            name="password"
            value={password}
            onBlur={this.handleInputBlur}
            onChange={this.handleInputChange}
            placeholder={renderResource.Section.InputTextPassword.j001}
          />
          <br />
          <Note>
            {renderResource.Section.Note.j001}
            <LinkA onClick={() => this.props.authcb("FORGOT_PASSWORD")}>
              {renderResource.Section.Note.PrimaryLink.j001}
            </LinkA>
          </Note>
          <br />
          <Button id="sign-in-button" onClick={this.signIn} disabled={disabled}>
            {renderResource.Section.Button.j001}
          </Button>
        </Section>
        {!isFlowSignUp && (
          <SignUpSection>
            <SignUpButton
              id="sign-up-button"
              onClick={this.handleClickSignUpButton}
            >
              {renderResource.SignUpSection.Button.j001}
            </SignUpButton>
          </SignUpSection>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SignInPage);
