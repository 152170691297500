import React from "react";
import styled, { keyframes } from "styled-components";


const typing = keyframes`
  0% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
`;

const Root = styled.div`
  width:62px;
  padding:0;
  margin:10px 0 0 12px;
  color: #f1f1f1;
  font-size: ${p => p.theme.font.size.sss};
  font-weight:bold;
  letter-spacing:0.05em;
`;

const Anime = styled.div`
  overflow:hidden;
  white-space: nowrap;
  animation: ${typing} 0.8s linear infinite;
`;

const LoadingText = () => (
  <Root>
    <Anime>
      Loading...
    </Anime>
  </Root>
);

export default LoadingText;
