import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonDialog from "../../atoms/buttons/ButtonDialog";
import { japaneseList } from "../../../Resources/japaneseList";

const styles = () => ({
  dialogActionRoot: {
    justifyContent: "space-around",
    borderTop: "thin solid #ccc",
    margin: 0
  },
  dialogAction: {
    margin: 0
  }
});

export default withStyles(styles)(props => (
  <DialogActions
    classes={{
      root: props.classes.dialogActionRoot,
      action: props.classes.dialogAction
    }}
  >
    <ButtonDialog onClick={props.onCancel}>
      {
        japaneseList.organisms.onlineChat.OnlineChatDialogButtons.export
          .DialogActions.DialogButton.j001
      }
    </ButtonDialog>
    <ButtonDialog onClick={props.onConfirm}>
      {
        japaneseList.organisms.onlineChat.OnlineChatDialogButtons.export
          .DialogActions.DialogButton.j002
      }
    </ButtonDialog>
  </DialogActions>
));
