import uaParser from "ua-parser-js";
import { UnsupportedDeviceList } from "../Resources/UnsupportedDeviceList";
import { japaneseList } from "../Resources/japaneseList";
import format from "date-fns/format";
import { isLnln, isLnlnApp } from "./checkLnln";
import * as Sentry from "@sentry/browser";

const blockListName = {
  terminal: "terminal",
  browser: "browser",
  osVersion: "osVersion",
  browserVersion: "browserVersion",
};

const blockListType = {
  white: "white",
  black: "black",
};

const conditionType = {
  all: "all",
  before: "before",
  part: "part",
};

const isSkipLnlnApp = function () {
  if (isLnln() && isLnlnApp()) {
    return true;
  }
  return false;
};

const isUnsupportedServiceBlockEnable = function () {
  if (process.env.REACT_APP_BLOCKING_UNSUPPORTED_DEVICE_ENABLE === "true") {
    return true;
  } else {
    return false;
  }
};

const isMatchItem = function (item, device) {
  if (
    (item.os &&
      item.os.name &&
      !isMatchValue(item.os.name, device.os.name, conditionType.all)) ||
    (item.os &&
      item.os.version &&
      !isMatchValue(item.os.version, device.os.version, item.os.condition)) ||
    (item.browser &&
      item.browser.name &&
      !isMatchValue(
        item.browser.name,
        device.browser.name,
        conditionType.all
      )) ||
    (item.browser &&
      item.browser.version &&
      !isMatchValue(
        item.browser.version,
        device.browser.version,
        item.browser.condition
      )) ||
    (item.device &&
      item.device.model &&
      !isMatchValue(
        item.device.model,
        device.device.model,
        conditionType.all
      )) ||
    (item.ua && !isMatchValue(item.ua, device.ua, conditionType.part))
  ) {
    return false;
  }
  return true;
};

const isMatchValue = function (itemValue, deviceValue, cType) {
  if (!itemValue || !deviceValue) {
    return false;
  }
  if (cType === conditionType.before) {
    return deviceValue.toLowerCase().indexOf(itemValue.toLowerCase()) === 0;
  } else if (cType === conditionType.part) {
    return deviceValue.toLowerCase().indexOf(itemValue.toLowerCase()) !== -1;
  } else {
    //all&default
    return deviceValue.toLowerCase() === itemValue.toLowerCase();
  }
};

const postBlock = function (props) {
  Sentry.captureMessage("blocking-unsupported-device", Sentry.Severity.Log);
  props.history.push("/unsupported-device-page");
};

const postHome = function (props) {
  props.history.push("/");
};

const returnSupportedDevice = async function (props) {
  let { isBlock } = await getUnsupportedDeviceInfo();
  if (!isBlock) {
    postHome(props);
  }
};

const formatDate = function (blockStart) {
  if (blockStart) {
    let blockStartDate = format(
      blockStart,
      japaneseList.Utils.ServiceAlertContent.j002
    );
    return (
      japaneseList.Utils.ServiceAlertContent.j001 +
      blockStartDate +
      japaneseList.Utils.ServiceAlertContent.j003
    );
  }
};

const getDeviceInfo = async (userAgent) => {
  const deviceInfoWithUserAgent = uaParser(userAgent);
  const osName = deviceInfoWithUserAgent.os.name;

  const userAgentData = window.navigator.userAgentData;
  // Client Hintsに対応していない場合
  if (!userAgentData || !userAgentData.platform.length)
    return deviceInfoWithUserAgent;

  const osVersion = await getOsVersion(osName, userAgentData);
  const deviceInfoWithClientHints = {
    ...deviceInfoWithUserAgent,
    os: {
      name: osName,
      version: osVersion,
    },
  };
  return deviceInfoWithClientHints;
};

const getOsVersion = async (osName, userAgentData) => {
  try {
    const highEntropyValues = await userAgentData.getHighEntropyValues([
      "platformVersion",
    ]);
    const osVersionWithClientHints = highEntropyValues.platformVersion;
    const osVersion = convertOsVersion(osName, osVersionWithClientHints);
    return osVersion;
  } catch {
    return getLatestOsVersionInSupport(osName);
  }
};

const convertOsVersion = (osName, osVersion) => {
  if (osName === "Windows") {
    return convertWindowsVersion(osVersion);
  } else {
    return osVersion.replace("_", ".");
  }
};

const convertWindowsVersion = (osVersion) => {
  const WINDOWS_8 = "8",
    WINDOWS_10 = "10",
    WINDOWS_11 = "11";
  // <Client Hintsで取得できる値>: <変換先のOSバージョン>
  const windowsVersionMap = {
    0: WINDOWS_8,
    1: WINDOWS_10,
    2: WINDOWS_10,
    3: WINDOWS_10,
    4: WINDOWS_10,
    5: WINDOWS_10,
    6: WINDOWS_10,
    7: WINDOWS_10,
    8: WINDOWS_10,
    9: WINDOWS_10,
    10: WINDOWS_10,
    11: WINDOWS_10,
    12: WINDOWS_10,
    13: WINDOWS_11,
  };
  for (const clientHintsValue of Object.keys(windowsVersionMap)) {
    const isMatchVersion = osVersion === clientHintsValue;
    const isStartWithVersion = osVersion.startsWith(`${clientHintsValue}.`);
    if (isMatchVersion || isStartWithVersion)
      return windowsVersionMap[clientHintsValue];
  }
  return WINDOWS_11;
};

const getLatestOsVersionInSupport = (osName) => {
  const latestVersions = {
    Windows: "10",
    "Mac OS": "12",
    iOS: "16",
    Android: "13",
  };
  return latestVersions[osName] || "";
};

const getUnsupportedDeviceInfo = async function () {
  if (!isUnsupportedServiceBlockEnable()) {
    console.log(
      "Skip UnsupportedDevice : REACT_APP_BLOCKING_UNSUPPORTED_DEVICE_ENABLE is not true"
    );
    return { isBlock: false, alertArray: [] };
  }
  if (isSkipLnlnApp()) {
    console.log("Skip UnsupportedDevice : Lnln App");
    return { isBlock: false, alertArray: [] };
  }
  const userAgent = window.navigator.userAgent;
  let current = await getDeviceInfo(userAgent);
  let checkListAll = UnsupportedDeviceList.lists.map((item) => {
    return item.list;
  });

  let isBlock = false;
  let alertArray = [];
  const nowYmd = format(new Date(), "YYYYMMDD");
  isBlock = checkListAll.some((list) => {
    if (list.type === blockListType.white) {
      let isInWhiteList = false;
      let blockStart = null;
      for (let checkItem of list.items) {
        blockStart = checkItem.item.blockStart;
        if (isMatchItem(checkItem.item, current)) {
          isInWhiteList = true;
          break;
        }
      }
      if (list.isBlock && !isInWhiteList) {
        if (!blockStart || blockStart <= nowYmd) {
          console.error(
            "blocking unsupported device :",
            list.name,
            UnsupportedDeviceList.version,
            userAgent
          );
          //break all
          return true;
        } else {
          alertArray.push({
            isBlock: true,
            blockStart: blockStart,
            priority: list.priority,
            item: null,
          });
        }
      } else if (!list.isBlock && !isInWhiteList) {
        alertArray.push({
          isBlock: false,
          blockStart: null,
          priority: list.priority,
          item: null,
        });
      }
    } else if (list.type === blockListType.black) {
      let blockStart;
      for (let checkItem of list.items) {
        blockStart = checkItem.item.blockStart;
        if (isMatchItem(checkItem.item, current)) {
          if (list.isBlock) {
            if (!blockStart || blockStart <= nowYmd) {
              console.error(
                "blocking unsupported device :",
                list.name,
                UnsupportedDeviceList.version,
                userAgent
              );
              //break all
              return true;
            } else {
              alertArray.push({
                isBlock: true,
                blockStart: blockStart,
                priority: list.priority,
                item: checkItem.item,
              });
            }
          } else {
            alertArray.push({
              isBlock: false,
              blockStart: null,
              priority: list.priority,
              item: checkItem.item,
            });
          }
        }
      }
    }
    return false;
  });
  if (isBlock) {
    return { isBlock: true, alertArray: [] };
  }
  return { isBlock: false, alertArray: alertArray };
};

const blockingUnsupportedDevice = async function (props) {
  if (props.location.pathname === "/unsupported-device-page") {
    returnSupportedDevice(props);
    return { isShowAlert: false, blockStartMessage: null };
  }
  let { isBlock, alertArray } = await getUnsupportedDeviceInfo();
  if (isBlock) {
    postBlock(props);
    return { isShowAlert: false, blockStartMessage: null };
  }
  if (alertArray.length > 0) {
    alertArray.sort((a, b) => {
      if (a.isBlock && !b.isBlock) {
        return -1;
      }
      if (!a.isBlock && b.isBlock) {
        return 1;
      }
      if (a.isBlock && b.isBlock && a.blockStart < b.blockStart) {
        return -1;
      }
      if (a.isBlock && b.isBlock && a.blockStart > b.blockStart) {
        return 1;
      }
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    });
    return {
      isShowAlert: true,
      blockStartMessage: alertArray[0].isBlock
        ? formatDate(alertArray[0].blockStart)
        : null,
    };
  } else {
    return { isShowAlert: false, blockStartMessage: null };
  }
};

export { blockingUnsupportedDevice, getUnsupportedDeviceInfo, blockListName };
