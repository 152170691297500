import React, { Component } from "react";
import styled from "styled-components";

import HeadingForm from "../../molecules/headings/HeadingForm";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import InputLabel from "../../molecules/forms/InputLabel";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.InputNameForm;

const CustomPanelFormArea = styled(PanelFormArea)`
  justify-content: space-between;
  > * {
    flex-basis: 48%;
  }
`;

class InputNameForm extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadingForm>{renderResource.Title}</HeadingForm>
        <CustomPanelFormArea>
          <InputLabel
            name="familyName"
            placeholder={renderResource.FamilyName.placeholder}
            error={this.props.familyNameError}
            {...this.props}
          >
            {renderResource.FamilyName.title}
          </InputLabel>
          <InputLabel
            name="givenName"
            placeholder={renderResource.GivenName.placeholder}
            error={this.props.givenNameError}
            {...this.props}
          >
            {renderResource.GivenName.title}
          </InputLabel>
        </CustomPanelFormArea>
      </React.Fragment>
    );
  }
}

export default InputNameForm;
