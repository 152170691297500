import { useState } from "react";
import styled from "styled-components";

import { InformationPopup } from "../popup/InformationPopup";
import { IconInfo } from "../../atoms/icons/IconInfo";
import FontPSS from "../../atoms/fonts/FontPSS";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import LinkAnoUnder from "../../atoms/links/LinkAnoUnder";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource =
  japaneseList.organisms.HospitalDetail.render.div.MyNumberInsuranceCard;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  width: fit-content;
`;
const Tag = styled(FontPSS)`
  border: 1px solid ${(p) => p.theme.icon.border.color};
  color: ${(p) => p.theme.font.color.gray}
  padding: 1px 6px;
  margin-right: 4px;
`;

const LinkWrapper = styled.div`
  margin: 8px 0;
`;
const Link = styled(LinkAnoUnder)`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: left;
  & > img {
    margin-right: 4px;
    margin-bottom: 2px;
  }
`;
const UnderLine = styled.span`
  text-decoration: underline;
`;

export const MyNumberInsuranceCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const modalText = renderResource.modal.text;
  const dialogContentText = (
    <>
      <FontPSmall>{modalText.j001}</FontPSmall>
      <FontPSmall>{modalText.j002}</FontPSmall>
      <LinkWrapper>
        <Link
          href={process.env.REACT_APP_OKBIZ_MY_NUMBER_CARD_HELP_URL}
          target="_blank"
          color="black"
        >
          <IconInfo />
          <UnderLine>{modalText.j003}</UnderLine>
        </Link>
      </LinkWrapper>
    </>
  );
  return (
    <>
      <Wrapper
        onClick={handleOpen}
        id="open-my-number-insurance-card-info-button"
      >
        <Tag>{renderResource.tag.j001}</Tag>
        <IconInfo />
      </Wrapper>
      <InformationPopup
        isOpen={isOpen}
        handleClosePopup={handleClose}
        confirmResource={renderResource.modal}
        dialogContentText={dialogContentText}
      />
    </>
  );
};
