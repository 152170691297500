import React, { Component } from "react";
import styled from "styled-components";
import Panel from "../../atoms/panels/Panel";
import PanelCaution from "../../atoms/panels/PanelCaution";

import FontP from "../../atoms/fonts/FontP";

import { isLnln } from "../../../Utils/checkLnln";

import IconPath from "../../../images/carada/insuranceCard/cyuui_icon.svg";
import IconPathLnln from "../../../images/lnln/insuranceCard/cyuui_icon.svg";
import IconOkPath from "../../../images/carada/insuranceCard/maru.svg";
import IconOkPathLnln from "../../../images/lnln/insuranceCard/maru.svg";
import IconNgPath from "../../../images/carada/insuranceCard/batsu.svg";
import IconNgPathLnln from "../../../images/lnln/insuranceCard/batsu.svg";
import IconOkExamplePath from "../../../images/carada/insuranceCard/good.png";
import IconOkExamplePathLnln from "../../../images/lnln/insuranceCard/good.png";
import IconNgExample1Path from "../../../images/carada/insuranceCard/bad01.png";
import IconNgExample1PathLnln from "../../../images/lnln/insuranceCard/bad01.png";
import IconNgExample2Path from "../../../images/carada/insuranceCard/bad02.png";
import IconNgExample2PathLnln from "../../../images/lnln/insuranceCard/bad02.png";
import IconNgExample3Path from "../../../images/carada/insuranceCard/bad03.png";
import IconNgExample3PathLnln from "../../../images/lnln/insuranceCard/bad03.png";
import IconNgExample4Path from "../../../images/carada/insuranceCard/bad04.png";
import IconNgExample4PathLnln from "../../../images/lnln/insuranceCard/bad04.png";
import IconNgExsampl5Path from "../../../images/carada/insuranceCard/bad05.png";
import IconNgExample5PathLnln from "../../../images/lnln/insuranceCard/bad05.png";
import IconNgExample6Path from "../../../images/carada/insuranceCard/bad06.png";
import IconNgExample6PathLnln from "../../../images/lnln/insuranceCard/bad06.png";

import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.organisms.mypage.InsuranceCardCaution;

const iconPath = isLnln() ? IconPathLnln : IconPath;
const iconOkPath = isLnln() ? IconOkPathLnln : IconOkPath;
const iconNgPath = isLnln() ? IconNgPathLnln : IconNgPath;
const iconOkExamplePath = isLnln() ? IconOkExamplePathLnln : IconOkExamplePath;
const iconNgExample1Path = isLnln()
  ? IconNgExample1PathLnln
  : IconNgExample1Path;
const iconNgExample2Path = isLnln()
  ? IconNgExample2PathLnln
  : IconNgExample2Path;
const iconNgExample3Path = isLnln()
  ? IconNgExample3PathLnln
  : IconNgExample3Path;
const iconNgExample4Path = isLnln()
  ? IconNgExample4PathLnln
  : IconNgExample4Path;
const iconNgExample5Path = isLnln()
  ? IconNgExample5PathLnln
  : IconNgExsampl5Path;
const iconNgExample6Path = isLnln()
  ? IconNgExample6PathLnln
  : IconNgExample6Path;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;
const CustomPanelCaution = styled(PanelCaution)`
  padding: 15px;
  border: solid 1px ${p => p.theme.panel.border.color.note};
  cursor: pointer;
`;
const CustomFontP = styled(FontP)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    right: 7px;
    display: block;
    width: 10px;
    height: 1px;
    background-color: ${p => p.theme.panel.border.color.note};
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    display: block;
    width: 10px;
    height: 1px;
    background-color: ${p => p.theme.panel.border.color.note};
    transform: rotate(-45deg);
  }
  &.isOpen {
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
`;
const CautionText = styled(FontP)`
  margin: 10px 0;
`;
const Box = styled.div`
  margin: 15px 0;
  padding: 15px;
  color: ${p => p.theme.font.color.default};
  background-color: #fff;
  border-radius: 4px;
`;
const BoxOkTitle = styled(FontP)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${p => p.theme.font.color.accent};
`;
const BoxOkItem = styled.div`
  display: flex;
`;
const BoxOkImg = styled.img`
  display: block;
  flex-basis: 40%;
  width: 40%;
  margin-right: 10px;
`;
const BoxOkText = styled(FontP)`
  flex: 1;
  line-height: 2;
`;

const BoxNgTitle = styled(FontP)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${p => p.theme.font.color.note};
`;

const BoxNgFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
`;
const BoxNgItem = styled.div`
  flex-basis: 30%;
  margin-bottom: 10px;
`;
const BoxNgImg = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 3px;
`;

class InsuranceCardCaution extends Component {
  static defaultProps = {
    isOpen: false
  };

  handleClick = e => {
    this.props.handleChangeIsOpen(!this.props.isOpen);
  };

  render() {
    return (
      <Panel>
        <CustomPanelCaution onClick={this.handleClick}>
          <CustomFontP
            size="s"
            align="center"
            weight="bold"
            color="note"
            className={this.props.isOpen && "isOpen"}
          >
            <Icon src={iconPath} />
            {Resources.title}
          </CustomFontP>
          {this.props.isOpen && (
            <React.Fragment>
              <CautionText size="ss" color="note">
                {Resources.text}
              </CautionText>

              <Box>
                <BoxOkTitle>
                  <Icon src={iconOkPath} />
                  {Resources.ok.title}
                </BoxOkTitle>
                <BoxOkItem>
                  <BoxOkImg src={iconOkExamplePath} />
                  <BoxOkText size="sss">{Resources.ok.text}</BoxOkText>
                </BoxOkItem>
              </Box>

              <Box>
                <BoxNgTitle>
                  <Icon src={iconNgPath} />
                  {Resources.ng.title}
                </BoxNgTitle>
                <FontP size="ss" color="note">
                  {Resources.ng.text}
                </FontP>

                <BoxNgFlex>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample1Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example1}
                    </FontP>
                  </BoxNgItem>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample2Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example2}
                    </FontP>
                  </BoxNgItem>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample3Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example3}
                    </FontP>
                  </BoxNgItem>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample4Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example4}
                    </FontP>
                  </BoxNgItem>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample5Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example5}
                    </FontP>
                  </BoxNgItem>
                  <BoxNgItem>
                    <BoxNgImg src={iconNgExample6Path} />
                    <FontP size="sss" align="center">
                      {Resources.ng.example6}
                    </FontP>
                  </BoxNgItem>
                </BoxNgFlex>
              </Box>
            </React.Fragment>
          )}
        </CustomPanelCaution>
      </Panel>
    );
  }
}

export default InsuranceCardCaution;
