import React, { Component } from "react";

import HeadingForm from "../../molecules/headings/HeadingForm";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import SelectBirthday from "../../molecules/forms/SelectBirthday";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.SelectBirthDate;

class SelectBirthdayArea extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadingForm>{renderResource.Title}</HeadingForm>
        <PanelFormArea>
          <SelectBirthday name="birth_date" {...this.props} />
        </PanelFormArea>
      </React.Fragment>
    );
  }
}

export default SelectBirthdayArea;
