import React, { Component } from "react";
import styled from "styled-components";

import HeadingForm from "../../molecules/headings/HeadingForm";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import InputLabel from "../../molecules/forms/InputLabel";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.InputKanaForm;

const CustomPanelFormArea = styled(PanelFormArea)`
  justify-content: space-between;
  > * {
    flex-basis: 48%;
  }
`;

class InputKanaForm extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadingForm hideRequired={true}>{renderResource.Title}</HeadingForm>
        <CustomPanelFormArea>
          <InputLabel
            name="furiganaFamily"
            placeholder={renderResource.FamilyName.placeholder}
            value={this.props.furiganaFamily}
            error={this.props.furiganaFamilyError}
            {...this.props}
          >
            {renderResource.FamilyName.title}
          </InputLabel>
          <InputLabel
            name="furiganaGiven"
            placeholder={renderResource.GivenName.placeholder}
            value={this.props.furiganaGiven}
            error={this.props.furiganaGivenError}
            {...this.props}
          >
            {renderResource.GivenName.title}
          </InputLabel>
        </CustomPanelFormArea>
      </React.Fragment>
    );
  }
}

export default InputKanaForm;
