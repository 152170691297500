import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RedMessagePanel from "../molecules/RedMessagePanel";
import { japaneseList } from "../../Resources/japaneseList";

const MessagePanel = styled(RedMessagePanel)`
  font-size: 0.8em;
`;

const LinkChat = styled(Link)`
  text-decoration: none;
  float: right;
`;

const BackToChat = styled.span`
  color: ${p => p.theme.color.fontInvert};
`;

class DuringChattingMessage extends Component {
  render() {
    const renderResource = japaneseList.organisms.DuringChattingMessage.render;
    return (
      <MessagePanel>
        <span>{renderResource.MessagePanel.span.j001}</span>
        <LinkChat to={"/telemedicine/chat"}>
          <BackToChat>
            {renderResource.MessagePanel.LinkChat.BackToChat.j001}
          </BackToChat>
        </LinkChat>
      </MessagePanel>
    );
  }
}

export default DuringChattingMessage;
