import React, { Component } from "react";
import MenuItem from "../../molecules/menu/MenuItem";
import ListMenu from "../../atoms/lists/ListMenu";
import { japaneseList } from "../../../Resources/japaneseList";
import { ButtonArea } from "./ButtonsArea";

class UnauthenticatedMenu extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.menu.UnauthenticatedMenu.render;
    return (
      <React.Fragment>
        <ButtonArea />
        <ListMenu>
          <MenuItem to="/terms-of-service">
            {renderResource.MenuList.MenuItem.j001}
          </MenuItem>
          <MenuItem to="/consumer-policy">
            {renderResource.MenuList.MenuItem.j002}
          </MenuItem>
          <MenuItem to="/privacy-policy">
            {renderResource.MenuList.MenuItem.j003}
          </MenuItem>
          <MenuItem
            toExternal={`${process.env.REACT_APP_OKBIZFAQ_URL}`}
            id="menu-unauthenticated-inquiry-link"
          >
            {renderResource.MenuList.MenuItem.j004}
          </MenuItem>
        </ListMenu>
      </React.Fragment>
    );
  }
}

export default UnauthenticatedMenu;
