import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogTitleText from "../../atoms/fonts/DialogTitleText";

export default props => (
  <DialogTitle
    id="alert-dialog-title"
    disableTypography={true}
    style={{ "textSizeAdjust": "100%" }}
  >
    <DialogTitleText>{props.children}</DialogTitleText>
  </DialogTitle>
);
