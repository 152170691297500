import React, { Component } from "react";
import styled from "styled-components";
import format from "date-fns/format";
import { Hub } from "aws-amplify";
import {
  isFullWidthCharacters,
  isKatakanaCharacters,
  isValidName,
} from "../../../Utils/Utility";
import { isLnln } from "../../../Utils/checkLnln";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { Account, UpdatePatient } from "../../../Utils/GraphQLQueries";

import Heading1 from "../../atoms/headings/Heading1";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import Panel from "../../atoms/panels/Panel";
import PanelWellCenter from "../../atoms/panels/PanelWellCenter";
import Radio from "../../atoms/forms/Radio";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import LinkAnoUnder from "../../atoms/links/LinkAnoUnder";
import InputText from "../../atoms/forms/InputText";

import AreaFontCenter from "../../molecules/area/AreaFontCenter";
import HeadingForm from "../../molecules/headings/HeadingForm";

import RadioGroup from "../../molecules/RadioGroup";
import SelectBirthday from "../../molecules/forms/SelectBirthday";

import InputNameForm from "./InputNameForm";
import InputKanaForm from "./InputKanaForm";

import { japaneseList } from "../../../Resources/japaneseList";

const BottomActionPanel = styled(PanelWellCenter)`
  padding: 30px;
  margin-bottom: 0;
`;

const InputEmployeeNumber = styled(InputText)`
  width: 100%;
`;

const UpdateButtonPanel = styled.div`
  padding: 15px 15px 0 15px;
`;

const ErrorMsg = styled.span`
  color: red;
  font-size: 14px;
  padding: 0 15px;
`

const ButtonSubmit = styled(ButtonFullWidth)({
  height: "50px",
})

const msgError = japaneseList.pages.EnterpriseAuthorizationPage.render.Error;

class ProfileUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      familyName: "",
      givenName: "",
      furiganaFamily: "",
      furiganaGiven: "",
      birthdate: "",
      phone_number: "",
      username: "",
      updated: false,
      disabled: true,
      gender: "",
      employeeNumber: "",
      employeeNumberErrorMsg: "",
      hasFamilyNameError: false,
      hasGivenNameError: false,
      hasFuriganaFamilyError: false,
      hasFuriganaGivenError: false,
    };
  }

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "Update"
    );
  };

  handleChangeGender = (event) => {
    this.setState({ gender: event.target.value }, () => {
      this.validateInputsAreEmpties();
    });
  };

  handleBirthdateChange = (value) => {
    this.setState({ birthdate: value }, () => {
      this.validateInputsAreEmpties();
    });
  };

  validateInput = (name, value) => {
    const validateInputResource =
      japaneseList.organisms.profile.ProfileUpdateForm.validateInput;
    const { familyName, givenName, furiganaFamily, furiganaGiven } = this.state;
    switch (name) {
      case "givenName":
        if (!isFullWidthCharacters(givenName)) {
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j008);
          return;
        } else {
          this.setState({
            hasGivenNameError: false,
            disabled: false,
          });
        }
        if (givenName && !isValidName(givenName)) {
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j004);
          return;
        } else {
          this.setState({
            hasGivenNameError: false,
            disabled: false,
          });
        }
        break;
      case "familyName":
        if (!isFullWidthCharacters(familyName)) {
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j007);
          return;
        } else {
          this.setState({
            hasFamilyNameError: false,
            disabled: false,
          });
        }
        if (familyName && !isValidName(familyName)) {
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j003);
          return;
        } else {
          this.setState({
            hasFamilyNameError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaGiven":
        if (!isFullWidthCharacters(furiganaGiven)) {
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j010);
          return;
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isFullWidthCharacters(furiganaFamily)) {
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j009);
          return;
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      case "employeeNumber":
        if (!value) {
          this.setState({
            disabled: true,
            employeeNumberErrorMsg: "",
          });
          return;
        }
        if (!value.match(/^[a-zA-Z0-9-]*$/)) {
          this.setState({
            disabled: false,
            employeeNumberErrorMsg: msgError.j003,
          });
          return;
        }
        if (value.trim().length > 30) {
          this.setState({
            disabled: false,
            employeeNumberErrorMsg: msgError.j004,
          });
          return;
        }
        this.setState({
          disabled: false,
          employeeNumberErrorMsg: "",
        });
        break;
      default:
        break;
    }
  };

  validateInputKatakana = (name) => {
    const validateInputResource =
      japaneseList.organisms.profile.ProfileUpdateForm.validateInput;
    const { furiganaGiven, furiganaFamily } = this.state;
    switch (name) {
      case "furiganaGiven":
        if (!isKatakanaCharacters(furiganaGiven)) {
          this.snackBar(validateInputResource.j012);
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isKatakanaCharacters(furiganaFamily)) {
          this.snackBar(validateInputResource.j011);
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  revalidateInputKatakana = (name) => {
    const { furiganaGiven, furiganaFamily } = this.state;
    switch (name) {
      case "furiganaGiven":
        if (!isKatakanaCharacters(furiganaGiven)) {
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isKatakanaCharacters(furiganaFamily)) {
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  validateInputsAreEmpties = () => {
    const {
      familyName,
      givenName,
      furiganaFamily,
      furiganaGiven,
      birthdate,
      gender,
    } = this.state;
    if (
      !familyName ||
      !givenName ||
      !furiganaFamily ||
      !furiganaGiven ||
      !gender ||
      !birthdate
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  submitValidateInput = () => {
    const { givenName, familyName, furiganaGiven, furiganaFamily } =
      this.inputs;
    const validateInputResource =
      japaneseList.pages.auth.SignUpPage.validateInput;

    if (
      givenName.length > 10 ||
      familyName.length > 10 ||
      furiganaGiven.length > 10 ||
      furiganaFamily.length > 10
    ) {
      this.snackBar(validateInputResource.j002);
      return false;
    }
    return true;
  };

  update = async () => {
    const validateInputResource =
      japaneseList.organisms.profile.ProfileUpdateForm.validateInput;
    this.setState({
      disabled: true,
    });

    const body = {
      familyName: this.state.familyName,
      givenName: this.state.givenName,
      familyNameKana: this.state.furiganaFamily,
      givenNameKana: this.state.furiganaGiven,
      birthdate: format(new Date(this.state.birthdate), "YYYY-MM-DD"),
      gender: this.state.gender,
      employeeNumber: this.state.employeeNumber,
    };

    const { data, errors } = await GraphQLClient.mutate({
      mutation: UpdatePatient,
      variables: body,
    });
    if (errors) {
      switch (errors[0].errorType) {
        case "E102":
          // FamilyName cannot be used.
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j003);
          break;
        case "E103":
          // GivenName cannot be used.
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j004);
          break;
        case "E104":
          // FamilyNameKana cannot be used.
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j005);
          break;
        case "E105":
          // GivenNameKana cannot be used.
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j006);
          break;
        case "E106":
          // FamilyName is not full-width.
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j007);
          break;
        case "E107":
          // GivenName is not full-width
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j008);
          break;
        case "E108":
          // FamilyNameKana is not katakana.
          this.snackBar(validateInputResource.j011);
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
          break;
        case "E109":
          // GivenNameKana is not katakana.
          this.snackBar(validateInputResource.j012);
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
          break;
        case "E131":
          // Employee Number is not match format
          this.setState({
            disabled: false,
            employeeNumberErrorMsg: msgError.j003,
          });
          break;
        case "E132":
          // Employee Number is more than 30 characters
          this.setState({
            disabled: false,
            employeeNumberErrorMsg: msgError.j004,
          });
          break;
        default:
          break;
      }
      return;
    }

    if (data.updatePatient) {
      this.setState({
        disabled: false,
        updated: true,
      });
    }
  };

  handleInputChange = (evt, maxLength) => {
    const { name, value } = evt.target;
    if (maxLength && maxLength < value.length) {
      return;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateInputsAreEmpties();
        this.validateInput(name, value);
        this.revalidateInputKatakana(name);
      }
    );
  };

  handleInputCompositionEnd = (evt) => {
    const { name } = evt.target;
    this.validateInputKatakana(name);
    this.validateInputsAreEmpties();
  };

  async componentDidMount() {
    const { data } = await GraphQLClient.query({ query: Account });
    if (Boolean(data) && data.account) {
      const {
        patientId,
        familyName,
        givenName,
        familyNameKana,
        givenNameKana,
        gender,
        phone_number,
        birthdate,
        employeeNumber,
        enterprise,
      } = data.account;

      const dbirthdate = new Date(birthdate);

      this.setState({
        familyName,
        givenName,
        furiganaFamily: familyNameKana,
        furiganaGiven: givenNameKana,
        birthdate: dbirthdate,
        username: patientId,
        // TODO: phone_numberはそもそもフロントに返していないので削除可能
        phone_number: phone_number,
        gender,
        employeeNumber: employeeNumber,
        enterprise: enterprise,
      });
    }
  }

  getArray = (number, start = 1) => {
    var arr = [];
    while (start <= number) {
      arr.push(start);
      start++;
    }
    return arr;
  };

  render() {
    const renderResource =
      japaneseList.organisms.profile.ProfileUpdateForm.render;
    const {
      username,
      birthdate,
      updated,
      familyName,
      givenName,
      furiganaFamily,
      furiganaGiven,
      gender,
      employeeNumber,
      enterprise,
    } = this.state;
    const disabled =
      this.state.disabled ||
      this.state.hasFamilyNameError ||
      this.state.hasGivenNameError ||
      this.state.hasFuriganaFamilyError ||
      this.state.hasFuriganaGivenError;
    const newLineCode = "%0D%0A";
    const name = username ? renderResource.j001 + username + newLineCode : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:inquiry@caradamedica.co.jp?subject=" +
      renderResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      renderResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      renderResource.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode;

    return updated ? (
      <React.Fragment>
        <Heading1>{renderResource.div.PrimaryHeading.j001}</Heading1>
        <Panel>
          <AreaFontCenter>
            {renderResource.div.Panel.ParagraphCenter.j001}
          </AreaFontCenter>
        </Panel>
        <BottomActionPanel>
          <ButtonFullWidth onClick={() => this.props.history.push("/my-page")}>
            {renderResource.div.BottomActionPanel.FullWidthButton.j001}
          </ButtonFullWidth>
        </BottomActionPanel>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Heading1>
          {renderResource.Fragment.div.div.PrimaryHeading.j001}
        </Heading1>

        <InputNameForm
          familyName={familyName}
          givenName={givenName}
          onChange={(e) => this.handleInputChange(e, 10)}
          familyNameError={this.state.hasFamilyNameError}
          givenNameError={this.state.hasGivenNameError}
        />

        <InputKanaForm
          furiganaFamily={furiganaFamily}
          furiganaGiven={furiganaGiven}
          onChange={(e) => this.handleInputChange(e, 10)}
          onCompositionEnd={this.handleInputCompositionEnd}
          furiganaFamilyError={this.state.hasFuriganaFamilyError}
          furiganaGivenError={this.state.hasFuriganaGivenError}
        />

        <HeadingForm hideRequired={true}>
          {renderResource.Fragment.div.div.InputFormHeader.j003}
        </HeadingForm>
        <PanelFormArea>
          <RadioGroup
            name="gender"
            value={gender}
            onChange={this.handleChangeGender}
          >
            <Radio
              name="gender"
              value={
                renderResource.Fragment.div.div.FormArea.RadioGroup.Radio.j001
              }
            >
              {renderResource.Fragment.div.div.FormArea.RadioGroup.Radio.j001}
            </Radio>
            <Radio
              name="gender"
              value={
                renderResource.Fragment.div.div.FormArea.RadioGroup.Radio.j002
              }
            >
              {renderResource.Fragment.div.div.FormArea.RadioGroup.Radio.j002}
            </Radio>
          </RadioGroup>
        </PanelFormArea>

        <HeadingForm hideRequired={true}>
          {renderResource.Fragment.div.div.InputFormHeader.j004}
        </HeadingForm>
        <PanelFormArea>
          <SelectBirthday
            name="birthdate"
            value={birthdate}
            onDateChanged={this.handleBirthdateChange}
          />
        </PanelFormArea>
        {enterprise && isLnln () && <>
        <HeadingForm hideRequired={true}>
          {renderResource.Fragment.div.div.InputFormHeader.j007}
        </HeadingForm>
        {this.state.employeeNumberErrorMsg && <ErrorMsg>{this.state.employeeNumberErrorMsg}</ErrorMsg>}
        <PanelFormArea>
          <InputEmployeeNumber
            onChange={this.handleInputChange}
            name="employeeNumber"
            value={employeeNumber}
            error={this.state.employeeNumberErrorMsg !== ""}
          />
        </PanelFormArea>
        </>}
        <UpdateButtonPanel>
          <ButtonSubmit disabled={disabled} onClick={this.update}>
            {
              renderResource.Fragment.div.div.UpdateButtonPanel.FullWidthButton
                .j001
            }
          </ButtonSubmit>
        </UpdateButtonPanel>
        <AreaFontCenter>
          {renderResource.Fragment.div.div.ContactParagraph.j001}
          <br />
          <LinkAnoUnder href={msg}>
            {
              renderResource.Fragment.div.div.ContactParagraph
                .LinkWithoutUnderline.j001
            }
          </LinkAnoUnder>
          {renderResource.Fragment.div.div.ContactParagraph.j002}
        </AreaFontCenter>
      </React.Fragment>
    );
  }
}

export default ProfileUpdateForm;