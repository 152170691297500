import styled from "styled-components";

const PanelCaution = styled.div`
  padding: ${p => p.padding && p.theme.panel.padding[p.padding]? p.theme.panel.padding[p.padding] : p.theme.panel.padding.ss};
  color:${p => p.theme.font.color.note}
  ${p => p.size && p.theme.font.size[p.size]? "font-size:"+p.theme.font.size[p.size]+";" : ""}
  line-height:1.4;
  background-color:${p => p.theme.panel.background.color.note}
`;

export default PanelCaution;
