import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import UnauthenticatedMenu from "./UnauthenticatedMenu";
import AuthenticatedMenu from "./AuthenticatedMenu";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import MenuHeader from "../../molecules/menu/MenuHeader";
import SiteContext from "../../../SiteContext";
import LnlnAuthenticatedMenu from "./LnlnAuthenticatedMenu";
import LnlnUnauthenticatedMenu from "./LnlnUnauthenticatedMenu";

const styles = (theme) => ({
  menuModal: {
    zIndex: 20,
    position: "fixed",
    top: 0,
  },
  lnlnPaperAnchorLeft: {
    width: "100%",
  },
});

const Container = styled.div`
  width: 250px;
`;

const LnlnContainer = styled.div`
  height: 100vh;
`;

class LeftMenu extends Component {
  render() {
    const { parent, open, classes, onClose } = this.props;
    const menuHeader = <MenuHeader onClose={onClose}>メニュー</MenuHeader>;
    const menu = parent.state.login ? (
      <AuthenticatedMenu open={open} />
    ) : (
      <UnauthenticatedMenu open={open} />
    );
    const lnlnMenu = parent.state.login ? (
      <LnlnAuthenticatedMenu
        open={open}
        notificationNumber={parent.state.notificationNumber}
      />
    ) : (
      <LnlnUnauthenticatedMenu open={open} />
    );
    return (
      <SiteContext.Consumer>
        {(value) =>
          value.ui === "lnln" ? (
            <Drawer
              open={open}
              onClose={onClose}
              anchor="bottom"
              classes={{
                modal: classes.menuModal,
                paperAnchorLeft: classes.lnlnPaperAnchorLeft,
              }}
            >
              <LnlnContainer>
                {menuHeader}
                {lnlnMenu}
              </LnlnContainer>
            </Drawer>
          ) : (
            <Drawer open={open} onClose={onClose}>
              <Container>
                {menuHeader}
                {menu}
              </Container>
            </Drawer>
          )
        }
      </SiteContext.Consumer>
    );
  }
}

export default withStyles(styles)(LeftMenu);
