import React, { Component } from "react";
import styled from "styled-components";
import MenuItem from "../../molecules/menu/MenuItem";
import { withRouter } from "react-router-dom";
import ListMenu from "../../atoms/lists/ListMenu";
import { japaneseList } from "../../../Resources/japaneseList";

const NotificationNumber = styled.span`
  color: ${(p) => p.theme.color.fontInvert};
  background-color: ${(p) => p.theme.color.warning};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 10px;
  text-align: center;
  line-height: 1;
`;

class LnlnAuthenticatedMenu extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.menu.LnlnAuthenticatedMenu.render;
    return (
      <ListMenu>
        <MenuItem to="/">{renderResource.MenuList.MenuItem.j001}</MenuItem>
        <MenuItem to="/notification-list">
          {renderResource.MenuList.MenuItem.j002}
          {this.props.notificationNumber > 0 && (
            <NotificationNumber>
              {this.props.notificationNumber}
            </NotificationNumber>
          )}
        </MenuItem>
        <MenuItem to="/my-page">
          {renderResource.MenuList.MenuItem.j003}
        </MenuItem>
        <MenuItem to="/terms-of-service">
          {renderResource.MenuList.MenuItem.j004}
        </MenuItem>
        <MenuItem to="/consumer-policy">
          {renderResource.MenuList.MenuItem.j005}
        </MenuItem>
        <MenuItem to="/privacy-policy">
          {renderResource.MenuList.MenuItem.j006}
        </MenuItem>
        <MenuItem to="/application-privacy-policy">
          {renderResource.MenuList.MenuItem.j007}
        </MenuItem>
        <MenuItem
          toExternal={`${process.env.REACT_APP_OKBIZFAQ_URL}`}
          id="menu-authenticated-inquiry-link"
        >
          {renderResource.MenuList.MenuItem.j008}
        </MenuItem>
        <MenuItem to="/sign-out">
          {renderResource.MenuList.MenuItem.j009}
        </MenuItem>
      </ListMenu>
    );
  }
}

export default withRouter(LnlnAuthenticatedMenu);
