import React, { Component } from "react";
import Heading3 from "../atoms/headings/Heading3";
import Appointment from "../molecules/Appointment";
import LinkToNoUnder from "../atoms/links/LinkToNoUnder";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";

const ListCard = styled.div`
  > a > div {
    border-bottom: thin solid ${p => p.theme.color.secondary};
  }
`;

class ListAppointmentMyPage extends Component {
  render() {
    const renderResource = japaneseList.organisms.ListAppointmentMyPage.render;
    let { appointments } = this.props;
    return (
      <div>
        {appointments && appointments.length > 0 ? (
          <React.Fragment>
            <Heading3>
              {renderResource.div.Fragment.SecondaryHeading.j001}
            </Heading3>
            {appointments.map((item, index) => {
              let url = "/appointment/" + item.createTime;
              return (
                <ListCard key={index}>
                  <LinkToNoUnder to={url}>
                    <Appointment appointment={item} />
                  </LinkToNoUnder>
                </ListCard>
              );
            })}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ListAppointmentMyPage;
