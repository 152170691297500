import uaParser from "ua-parser-js";

export const device = {
  mobile: `(max-width: 725px)`,
  tb: `(min-width: 768px)`,
  pc: `(min-width: 1024px)`,
};

export const DeviceOSName = {
  Android: "Android",
  iPhone: "iPhone",
  Windows: "Windows",
  Mac: "Mac",
  iPad: "iPad",
  Others: "Others",
};

export const deviceOS = function () {
  const userAgent = window.navigator.userAgent;
  let current = uaParser(userAgent);
  if (current) {
    if (current.os.name === "Android") {
      return DeviceOSName.Android;
    } else if (current.os.name === "iOS") {
      if (current.device.model === "iPhone") {
        return DeviceOSName.iPhone;
      }
      if (current.device.model === "iPad") {
        return DeviceOSName.iPad;
      }
    } else if (current.os.name === "Windows") {
      return DeviceOSName.Windows;
    } else if (current.os.name === "Mac OS") {
      return DeviceOSName.Mac;
    }
  }
  return DeviceOSName.Others;
};
