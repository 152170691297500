import { Auth } from "aws-amplify";
import Store from "store";
import qs from "querystring";
import ApiWrapper from "./Utils/ApiWrapper";
import { setCookieLnlnApp } from "./Utils/checkLnln";

function removeLnlnQueryParemeters() {
  if (!window.location.search) {
    return;
  }
  const lnlnRelatedParams = ["from", "token", "clinic"];

  const queryStr = qs.parse(window.location.search.substr(1));
  let newQuery = Object.keys(queryStr)
    .filter((q) => !lnlnRelatedParams.includes(q))
    .map((q) => q + "=" + queryStr[q])
    .join("&");
  if (newQuery !== "") {
    newQuery = "?" + newQuery;
  }

  const path = window.location.pathname;
  window.history.replaceState(null, "", path + newQuery);
}

export default async function initializeLnlnProcess(siteContext) {
  try {
    // init checkLnln.js isLnlnApp
    setCookieLnlnApp();
    const loginUser = await Auth.currentUserInfo();

    if (loginUser) {
      removeLnlnQueryParemeters();
      return;
    }

    if (!siteContext.token) {
      return;
    }
    const verifyResponse = await ApiWrapper.Post(
      "lnln-integration",
      "/verifyToken",
      {
        body: {
          token: siteContext.token,
        },
      }
    );

    if (verifyResponse.uniqueId) {
      //not matched patient data
      Store.set("unique_id", verifyResponse.uniqueId);

      removeLnlnQueryParemeters();
      return;
    }

    const challengeResponse = verifyResponse.encryptedData;

    Auth.configure({
      authenticationFlowType: "CUSTOM_AUTH",
    });

    const user = await Auth.signIn(verifyResponse.patientId);

    if (user.challengeName === "CUSTOM_CHALLENGE") {
      // to send the answer of the custom challenge
      await Auth.sendCustomChallengeAnswer(user, challengeResponse);

      removeLnlnQueryParemeters();
    }
  } catch (err) {
    console.error(err);

    let message = "エラーが発生しました。";
    if (err.response && err.response.data) {
      const { error, description } = err.response.data;
      message = `${description}\n\nエラーコード: ${error}`;
    }
    alert(message);
  } finally {
    // restore auth flow
    Auth.configure({
      authenticationFlowType: "USER_SRP_AUTH",
    });
  }
}
