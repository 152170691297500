import React, { Component } from "react";
import styled from "styled-components";
import { Hub } from "aws-amplify";
import format from "date-fns/format";
import store from "store";

import {
  convertToFullWidthCharacters,
  isFullWidthCharacters,
  isKatakanaCharacters,
  isValidAddress,
  isValidName,
} from "../../../Utils/Utility";
import { ERRORS } from "../../../Utils/Errors";

import LinkTo from "../../atoms/links/LinkTo";
import Heading1 from "../../atoms/headings/Heading1";
import FontPAccentCenter from "../../atoms/fonts/FontPAccentCenter";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";

import Checkbox from "../../atoms/forms/Checkbox";
import PanelSection from "../../atoms/panels/Panel";
import PanelWellCenter from "../../atoms/panels/PanelWellCenter";

import InputNameForm from "../../organisms/auth/InputNameForm";
import InputKanaForm from "../../organisms/auth/InputKanaForm";
import RadioSex from "../../organisms/auth/RadioSex";
import SelectBirthdayArea from "../../organisms/auth/SelectBirthdayArea";
import InputTelForm from "../../organisms/auth/InputTelForm";
import InputPasswordForm from "../../organisms/auth/InputPasswordForm";
import { isSmsReceivablePhoneNumber } from "../../../Utils/PhoneNumber";

import { japaneseList } from "../../../Resources/japaneseList";
import countryCallingCodeList from "../../../Utils/countryCallingCodeList";
import AddressArea from "../../organisms/auth/AddressArea";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import { withRouter } from "react-router-dom";

const CustomFontPAccent = styled(FontPAccentCenter)`
  margin-bottom: 10px;
`;

const Policy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const LabelPolicy = styled.div`
  text-align: left;
  padding-left: 10px;
  font-size: 11px;
`;

const Row = styled.div`
  height: 5px;
`;

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      birth_date: "",
      gender: "",
      disabled: true,
      hasFamilyNameError: false,
      hasGivenNameError: false,
      hasFuriganaFamilyError: false,
      hasFuriganaGivenError: false,
      addressCityError: false,
      addressBuildingError: false,
      addressStreetNumberError: false,
      address: {
        postalCode: "",
        prefectureCode: "",
        addressCity: "",
        addressStreetNumber: "",
        addressBuilding: "",
      },
    };

    this.inputs = {
      givenName: "",
      familyName: "",
      furiganaGiven: "",
      furiganaFamily: "",
      gender: "",
      birth_date: "",
      phone_number: "",
      password: "",
      countryCallingCode: countryCallingCodeList.ja, // default: japan
    };

    this.agreement = false;
    this.password_confirm = "";
  }

  // #region Life Cycle
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  // #endregion Life Cycle

  // #region handle Input change events
  handleInputChange = (evt) => {
    const { name, value } = evt.target;
    this.inputs = this.inputs || {};
    this.inputs[name] = value;
    this.validateInputs(name);
    this.validateInputsAreEmpties();
  };

  handleAddressInputChange = (evt, maxLength) => {
    const { name, value } = evt.target;
    if (maxLength && maxLength < value.length) {
      return;
    }

    if (name === "postalCode" && !value.match(/^\d*$/)) {
      return;
    }

    this.setState(
      {
        address: {
          ...this.state.address,
          [name]: value,
        },
      },
      () => {
        this.validateInputs(name);
        this.validateInputsAreEmpties();
      }
    );
  };

  handleInputCompositionEnd = (evt) => {
    const { name } = evt.target;
    this.validateInputs(name);
    this.validateInputsAreEmpties();
  };

  checkInputLength = (event, maxLength) => {
    return event.target.value.length < maxLength || event.preventDefault();
  };

  handlePasswordConfirmChange = (evt) => {
    this.password_confirm = evt.target.value;
    this.validateInputsAreEmpties();
  };

  handleAgreementChange = (evt) => {
    this.agreement = evt.target.checked;
    this.validateInputsAreEmpties();
  };

  handleBirthdateChange = (value) => {
    this.inputs = this.inputs || {};
    this.inputs["birth_date"] = value;
    this.inputs.birthdateFormat = format(value, "YYYY-MM-DD");
    if (this._isMounted) {
      this.setState({ birth_date: value });
    }
    this.validateInputsAreEmpties();
  };
  // #endregion handle Input change events

  handleChangeGender = (event) => {
    if (this._isMounted) {
      this.setState({ gender: event.target.value });
    }
    this.inputs.gender = event.target.value;
    this.validateInputsAreEmpties();
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "SignUp"
    );
  };

  // #region submit and validation
  validateFullWidthCharacterInputs = (name) => {
    const validateInputResource =
      japaneseList.pages.auth.SignUpPage.validateInput;
    const { givenName, familyName, furiganaGiven, furiganaFamily } =
      this.inputs;

    switch (name) {
      case "givenName":
        if (!isFullWidthCharacters(givenName)) {
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j015);
          return;
        } else {
          this.setState({
            hasGivenNameError: false,
            disabled: false,
          });
        }
        if (givenName && !isValidName(givenName)) {
          this.setState({
            hasGivenNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j010);
          return;
        } else {
          this.setState({
            hasGivenNameError: false,
            disabled: false,
          });
        }
        break;
      case "familyName":
        if (!isFullWidthCharacters(familyName)) {
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j014);
          return;
        } else {
          this.setState({
            hasFamilyNameError: false,
            disabled: false,
          });
        }
        if (familyName && !isValidName(familyName)) {
          this.setState({
            hasFamilyNameError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j009);
          return;
        } else {
          this.setState({
            hasFamilyNameError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaGiven":
        if (!isFullWidthCharacters(furiganaGiven)) {
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j017);
          return;
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isFullWidthCharacters(furiganaFamily)) {
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
          this.snackBar(validateInputResource.j016);
          return;
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  validateInputKatakana = (name) => {
    const validateInputResource =
      japaneseList.pages.auth.SignUpPage.validateInput;
    const { furiganaGiven, furiganaFamily } = this.inputs;
    switch (name) {
      case "furiganaGiven":
        if (!isKatakanaCharacters(furiganaGiven)) {
          this.snackBar(validateInputResource.j019);
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isKatakanaCharacters(furiganaFamily)) {
          this.snackBar(validateInputResource.j018);
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  revalidateInputKatakana = (name) => {
    const { furiganaGiven, furiganaFamily } = this.inputs;
    switch (name) {
      case "furiganaGiven":
        if (!isKatakanaCharacters(furiganaGiven)) {
          this.setState({
            hasFuriganaGivenError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaGivenError: false,
            disabled: false,
          });
        }
        break;
      case "furiganaFamily":
        if (!isKatakanaCharacters(furiganaFamily)) {
          this.setState({
            hasFuriganaFamilyError: true,
            disabled: true,
          });
        } else {
          this.setState({
            hasFuriganaFamilyError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  validateInputsAreEmpties = () => {
    const {
      givenName,
      familyName,
      gender,
      phone_number,
      birth_date,
      furiganaGiven,
      furiganaFamily,
      password,
    } = this.inputs;
    const { postalCode, addressCity, prefectureCode } = this.state.address;
    if (
      !givenName ||
      !familyName ||
      !gender ||
      !phone_number ||
      !furiganaGiven ||
      !furiganaFamily ||
      !birth_date ||
      !this.agreement ||
      !password ||
      !this.password_confirm ||
      !postalCode ||
      !addressCity ||
      !prefectureCode
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  validateAddress = (name) => {
    const { addressCity, addressStreetNumber, addressBuilding, postalCode } =
      this.state.address;
    if (
      postalCode.length > 7 ||
      addressCity.length > 70 ||
      addressStreetNumber.length > 15 ||
      addressBuilding.length > 30
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }

    switch (name) {
      case "addressCity":
        if (addressCity && !isValidAddress(addressCity)) {
          this.setState({
            addressCityError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j003);
        } else {
          this.setState({
            addressCityError: false,
            disabled: false,
          });
        }
        break;
      case "addressStreetNumber":
        if (addressStreetNumber && !isValidAddress(addressStreetNumber)) {
          this.setState({
            addressStreetNumberError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j004);
        } else {
          this.setState({
            addressStreetNumberError: false,
            disabled: false,
          });
        }
        break;
      case "addressBuilding":
        if (addressBuilding && !isValidAddress(addressBuilding)) {
          this.setState({
            addressBuildingError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j005);
        } else {
          this.setState({
            addressBuildingError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  validateInputs = (name) => {
    this.validateFullWidthCharacterInputs(name);
    this.validateInputKatakana(name);
    this.validateAddress(name);
  };

  validatePostalCodeAndAddress = (data) => {
    const { prefectureCode, addressCity } = this.state.address;
    return data.some((item) => {
      return (
        prefectureCode === `JP-${item.prefCode}` &&
        addressCity.indexOf(item.city) === 0
      );
    });
  };

  getAddressByPostalCode = async (postalCode) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_POSTCODE_AWS_API_GATEWAY}?postcode=${postalCode}&apiKey=${process.env.REACT_APP_ZIP_CODE_API_KEY}`
      )
    ).json();
  };

  getAddressFromPostalCode = async () => {
    const validationInputNameArray = [
      "addressCity",
      "addressStreetNumber",
      "addressBuilding",
    ];
    const { postalCode } = this.state.address;
    if (!postalCode) {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
      return;
    }
    const result = await this.getAddressByPostalCode(postalCode);

    if (result.data.length > 0) {
      const state = `JP-${result.data[0].prefCode}`;
      const town = result.data[0].city;
      const street = result.data[0].town;
      this.setState({
        address: {
          ...this.state.address,
          prefectureCode: state,
          addressCity: town,
          addressStreetNumber: street,
        },
      });
      validationInputNameArray.forEach((name) => {
        this.validateAddress(name);
      });
    } else {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
    }
  };

  submitValidateInput = async () => {
    const {
      password,
      givenName,
      familyName,
      phone_number,
      birth_date,
      furiganaGiven,
      furiganaFamily,
    } = this.inputs;

    const { addressStreetNumber, postalCode } = this.state.address;
    const validateInputResource =
      japaneseList.pages.auth.SignUpPage.validateInput;

    if (!isSmsReceivablePhoneNumber(phone_number)) {
      this.snackBar(validateInputResource.j013);
      return false;
    }
    if (password === "") {
      this.snackBar(validateInputResource.j003);
      return false;
    }

    if (this.password_confirm === "") {
      this.snackBar(validateInputResource.j004);
      return false;
    }

    if (password === phone_number) {
      this.snackBar(validateInputResource.j005);
      return false;
    }
    if (
      password.includes(phone_number) ||
      password.includes(format(birth_date, "YYYYMMDD"))
    ) {
      this.snackBar(validateInputResource.j006);
      return false;
    }

    if (password !== this.password_confirm) {
      this.snackBar(validateInputResource.j007);
      return false;
    }

    if (
      givenName.length > 10 ||
      familyName.length > 10 ||
      furiganaGiven.length > 10 ||
      furiganaFamily.length > 10
    ) {
      this.snackBar(validateInputResource.j002);
      return false;
    }

    const result = await this.getAddressByPostalCode(postalCode);
    if (result.data.length <= 0) {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
      return false;
    }

    if (!this.validatePostalCodeAndAddress(result.data)) {
      this.snackBar(
        japaneseList.pages.AddressPage.validatePostalCodeAndAddress.j001
      );
      return false;
    }
    if (!addressStreetNumber) {
      this.snackBar(japaneseList.pages.AddressPage.validate.j002);
      return false;
    }
    return true;
  };

  handleButtonClick = async () => {
    if (await this.submitValidateInput()) {
      this.signUp();
    }
  };

  signUp = async () => {
    const validateInputResource =
      japaneseList.pages.auth.SignUpPage.validateInput;
    this.enableSubmit();

    const {
      password,
      givenName,
      familyName,
      gender,
      phone_number,
      birthdateFormat,
      furiganaGiven,
      furiganaFamily,
      countryCallingCode,
    } = this.inputs;

    const {
      prefectureCode,
      postalCode,
      addressCity,
      addressStreetNumber,
      addressBuilding,
    } = this.state.address;

    const uniqueId = store.get("unique_id");

    const patientInfo = {
      password,
      familyName: familyName,
      givenName: givenName,
      familyNameKana: furiganaFamily,
      givenNameKana: furiganaGiven,
      gender,
      birthdate: birthdateFormat,
      uniqueId,
      phoneNumber: phone_number,
      countryCallingCode,
      prefectureCode: prefectureCode,
      postalCode: postalCode,
      addressCity: convertToFullWidthCharacters(addressCity),
      addressBuilding: convertToFullWidthCharacters(addressBuilding),
      addressStreetNumber: convertToFullWidthCharacters(addressStreetNumber),
    };

    const response = await (
      await fetch(`${process.env.REACT_APP_PATIENT_AWS_API_GATEWAY}/register`, {
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Client-Version": process.env.REACT_APP_CLIENT_VERSION,
        },
        body: JSON.stringify(patientInfo),
      })
    ).json();

    if (response.result === "ok") {
      this.props.authcb("CONFIRMATION", {
        username: response.username,
        phone_number: phone_number,
      });
    } else if (response.errorMessage) {
      let msg = response.errorMessage;
      if (
        response.errorMessage.indexOf(
          ERRORS.INVALID_PASSWORD.LEGNTHDEFAULT.MESSAGE
        ) > -1 ||
        response.errorMessage.indexOf(
          ERRORS.INVALID_PASSWORD.POLICY.LENGTH.MESSAGE
        ) > -1
      ) {
        msg = ERRORS.INVALID_PASSWORD.POLICY.LENGTH.MESSAGEJP;
      } else if (
        response.errorMessage.indexOf(
          ERRORS.INVALID_PASSWORD.POLICY.LOWERCASE.MESSAGE
        ) > -1
      ) {
        msg = ERRORS.INVALID_PASSWORD.POLICY.LOWERCASE.MESSAGEJP;
      } else if (
        response.errorMessage.indexOf(
          ERRORS.INVALID_PASSWORD.POLICY.UPPERCASE.MESSAGE
        ) > -1
      ) {
        msg = ERRORS.INVALID_PASSWORD.POLICY.UPPERCASE.MESSAGEJP;
      } else if (
        response.errorMessage.indexOf(ERRORS.INVALID_PHONE_NUMBER.MESSAGEJP) >
        -1
      ) {
        msg = ERRORS.INVALID_PHONE_NUMBER.MESSAGEJP;
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FAMILYNAME.FULLWIDTH) > -1
      ) {
        this.setState({
          hasFamilyNameError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j014);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FAMILYNAME.YAMATO) > -1
      ) {
        this.setState({
          hasFamilyNameError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j009);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.GIVENNAME.FULLWIDTH) > -1
      ) {
        this.setState({
          hasGivenNameError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j015);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.GIVENNAME.YAMATO) > -1
      ) {
        this.setState({
          hasGivenNameError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j010);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FURIGANAFAMILY.FULLWIDTH) >
        -1
      ) {
        this.setState({
          hasFuriganaFamilyError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j016);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FURIGANAFAMILY.KATAKANA) >
        -1
      ) {
        this.setState({
          hasFuriganaFamilyError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j018);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FURIGANAGIVEN.FULLWIDTH) >
        -1
      ) {
        this.setState({
          hasFuriganaGivenError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j017);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.FURIGANAGIVEN.KATAKANA) > -1
      ) {
        this.setState({
          hasFuriganaGivenError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j019);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.ADDRESSCITY.YAMATO) > -1
      ) {
        this.setState({
          addressCityError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j020);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.ADDRESSBUILDING.YAMATO) > -1
      ) {
        this.setState({
          addressBuildingError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j021);
      } else if (
        response.errorMessage.indexOf(
          ERRORS.SIGNUP.ADDRESSSTREETNUMBER.YAMATO
        ) > -1
      ) {
        this.setState({
          addressStreetNumberError: true,
          disabled: true,
        });
        this.snackBar(validateInputResource.j022);
      } else if (
        response.errorMessage.indexOf(ERRORS.SIGNUP.POSTALCODE.DIGITS) > -1
      ) {
        this.setState({
          disabled: true,
        });
        this.snackBar(validateInputResource.j023);
      }
      this.snackBar(msg);
    }
    this.enableSubmit();
  };
  // #endregion submit and validation

  // #region enable/disable submit button
  disableSubmit = () => {
    if (this._isMounted) {
      this.setState({
        disabled: true,
      });
    }
  };

  enableSubmit = () => {
    if (this._isMounted) {
      this.setState({
        disabled: false,
      });
    }
  };
  // #endregion enable/disable submit button

  render() {
    const renderResource = japaneseList.pages.auth.SignUpPage.render;
    const disabled =
      this.state.disabled ||
      this.state.hasFamilyNameError ||
      this.state.hasGivenNameError ||
      this.state.hasFuriganaFamilyError ||
      this.state.hasFuriganaGivenError ||
      this.state.addressBuildingError ||
      this.state.addressCityError ||
      this.state.addressStreetNumberError;
    const isFromEnterprise =
      this.props.location.state &&
      this.props.location.state.from === "enterprise";
    return (
      <React.Fragment>
        {/* Headings */}
        <Heading1>{renderResource.div.PrimaryHeading.j001}</Heading1>
        {isFromEnterprise && (
          <EnterpriseStepperForm
            registerSteps={ENTERPRISE_SIGNUP_STEPS}
            currentStep={
              ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.SIGNUP]
            }
          />
        )}
        <PanelSection>
          <CustomFontPAccent>
            {renderResource.div.PrimaryHeadingInvertAlignCenter.j001}
          </CustomFontPAccent>
        </PanelSection>

        {/* Name area */}
        <InputNameForm
          onChange={this.handleInputChange}
          onKeyPress={(e) => this.checkInputLength(e, 10)}
          familyNameError={this.state.hasFamilyNameError}
          givenNameError={this.state.hasGivenNameError}
        />

        {/* Nick name area */}
        <InputKanaForm
          onChange={this.handleInputChange}
          onCompositionEnd={this.handleInputCompositionEnd}
          onKeyPress={(e) => this.checkInputLength(e, 10)}
          furiganaFamilyError={this.state.hasFuriganaFamilyError}
          furiganaGivenError={this.state.hasFuriganaGivenError}
        />

        {/* Sex area */}
        <RadioSex
          value={this.state.gender}
          onChange={this.handleChangeGender}
        />

        {/* Birthday area */}
        <SelectBirthdayArea
          value={this.state.birth_date}
          onDateChanged={this.handleBirthdateChange}
        />

        {/* Address area*/}
        <AddressArea
          address={this.state.address}
          addressCityError={this.state.addressCityError}
          addressStreetNumberError={this.state.addressStreetNumberError}
          addressBuildingError={this.state.addressBuildingError}
          handleInputChange={this.handleAddressInputChange}
          getAddressFromPostalCode={this.getAddressFromPostalCode}
          {...this.props}
        />

        {/* Telephone number area */}
        <InputTelForm onChange={this.handleInputChange} />

        <InputPasswordForm
          onChange={this.handleInputChange}
          onChangeConfirm={this.handlePasswordConfirmChange}
        />

        {/* Agree terms of service and Personal Information Protection Policy area */}
        <PanelWellCenter>
          <Policy>
            <Checkbox onChange={this.handleAgreementChange} />
            <LabelPolicy>
              <React.Fragment>
                <LinkTo to={`/terms-of-service`} target="_blank">
                  {renderResource.Fragment.PrimaryLinkTo.j001}
                </LinkTo>
                {renderResource.Fragment.j001}
                <LinkTo to={`/privacy-policy`} target="_blank">
                  {renderResource.Fragment.PrimaryLinkTo.j002}
                </LinkTo>
                、<br />
                <Row />
                <LinkTo to={`/application-privacy-policy`} target="_blank">
                  {renderResource.Fragment.PrimaryLinkTo.j003}
                </LinkTo>
                {renderResource.Fragment.j002}
              </React.Fragment>
            </LabelPolicy>
          </Policy>
          <ButtonFullWidth
            id="sign-up-confirm-button"
            onClick={this.handleButtonClick}
            disabled={disabled}
          >
            {renderResource.div.WellCenter.SubmitButton.j001}
          </ButtonFullWidth>
        </PanelWellCenter>
      </React.Fragment>
    );
  }
}

export default withRouter(SignUpPage);
