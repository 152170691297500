import styled from "styled-components";

export default styled.div`
  position: fixed;
  left: 50%;
  top: 64px;
  display: none;
  margin-left: 212px;
  width: 180px;
  height: 190px;
  padding-top: 15px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: ${p => p.theme.font.color.qr};
  background-color: ${p => p.theme.panel.background.color.qr};
  border-radius: 0 10px 10px 0;

  /* display only PC size */
  @media (min-width: 725px) {
    display: block;
  }
`;
