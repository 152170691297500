export const isSmsReceivablePhoneNumber = function (phoneNumber) {
  if (phoneNumber.match(/^0[789]0[0-9]{8}$/)) {
    return true;
  } else {
    return false;
  }
};

export const convertToDomestic = (internationalPhoneNumber) => {
  return (
    "0" + internationalPhoneNumber.substring(3, internationalPhoneNumber.length)
  );
};
