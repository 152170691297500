import React from "react";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import AddressDetail from "../organisms/AddressDetail";
import BasicTemplate from "../templates/BasicTemplate";
import Guard from "../../Utils/Guard";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Account } from "../../Utils/GraphQLQueries";
import { prefectureCodes } from "../../Utils/PrefectureCode";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";

class AddressDetailPage extends React.Component {
  state = {
    address: {
      postalCode: "",
      prefectureCode: "",
      prefectureName: "",
      addressCity: "",
      addressStreetNumber: "",
      addressBuilding: "",
    },
    isLoading: true,
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "AddressDetail"
    );
  };

  getAddress = async () => {
    const {
      data: { account },
    } = await GraphQLClient.query({ query: Account });
    if (Boolean(account) && account.address) {
      var prefectureName;
      if (account.address.prefectureCode !== "") {
        prefectureName = this.getNameByPrefectureCode(
          account.address.prefectureCode
        );
      }
      this.setState({
        address: {
          ...account.address,
          prefectureName: prefectureName,
        },
        isLoading: false,
      });
    }
  };

  getNameByPrefectureCode = (code) => {
    const prefectureCode = prefectureCodes.filter(
      (item) => item.code === code
    )[0];
    if (!prefectureCode) {
      this.snackBar(
        japaneseList.pages.AddressDetailPage.getNameByPrefectureCode.j001
      );
      return "";
    }
    return prefectureCode.jpName;
  };

  goToUpdatePage = () => {
    this.props.history.push("/my-page/address/update", this.state.address);
  };

  componentDidMount() {
    this.getAddress();
  }

  render() {
    const main = (
      <AddressDetail
        address={this.state.address}
        goToUpdatePage={this.goToUpdatePage}
        {...this.props}
      />
    );
    return (
      <BasicTemplate
        title={Titles.addressDetail}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(Guard(AddressDetailPage));
