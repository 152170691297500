import { Component } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { isNumber } from "lodash";

import Typography3 from "../atoms/fonts/Typography3";
import WarningMessage from "../molecules/WarningMessage";
import InfoMessageBlue from "../molecules/InfoMessageBlue";
import {
  APPOINTMENT_STATUS,
  PROGRAM_TYPE,
  DELIVERY_FEE_CALCULATE_TYPE,
  DELIVERY_METHOD,
  SETTLEMENT_ERROR,
} from "../../Utils/Constant";
import { japaneseList } from "../../Resources/japaneseList";

const BlueBorder = styled.div`
  border-top: 0.5px solid ${(p) => p.theme.color.primary};
  margin: 5px 0;
  padding: 2.5px 0;
`;
const Container = styled(BlueBorder)`
  border-bottom: 0.5px solid ${(p) => p.theme.color.secondary};
  margin: 10px 0;
  padding: 5px 0;
`;
const CostLine = styled.div`
  color: ${(p) => p.theme.color.font};
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;
const Total = styled.div`
  color: ${(p) => p.theme.color.font};
  font-weight: bold;
  text-align: right;
`;
const Message = styled.div`
  text-align: right;
  margin: 5px 0;
`;
const LineBreakWrapper = styled.span`
  word-break: keep-all;
  overflow-wrap: break-word;
`;

class CostDetail extends Component {
  isNotIncludeTaxAmountTotal(appointment, medicalTotalFee) {
    // Check system fee is not include tax
    const isNotIncludeTaxSystemFee =
      (appointment.program &&
        appointment.program.type === PROGRAM_TYPE.WELFARE_PROGRAM) ||
      Boolean(appointment.menu.supportPaymentByContractCompany);

    // Check exam fee is not include tax
    const isNotIncludeTaxExamFee =
      Boolean(medicalTotalFee) &&
      Boolean(appointment.menu.supportsInsuranceTreatment);

    // Check delivery fee is not include tax
    let isNotIncludeTaxDeliveryFee = false;
    if (Boolean(appointment.menu.supportPaymentByContractCompany)) {
      isNotIncludeTaxDeliveryFee = true;
    } else {
      if (appointment.payment.deliveryTotalFee) {
        isNotIncludeTaxDeliveryFee =
          appointment.program &&
          appointment.deliveryFeeCalculateType ===
            DELIVERY_FEE_CALCULATE_TYPE.EXCLUDE;
      } else {
        isNotIncludeTaxDeliveryFee =
          !Boolean(appointment.payment.deliveryStatus) ||
          appointment.payment.deliverySelectedOption ===
            DELIVERY_METHOD.NO_DELIVERY;
      }
    }

    return (
      isNotIncludeTaxSystemFee &&
      isNotIncludeTaxExamFee &&
      isNotIncludeTaxDeliveryFee
    );
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  createSettlementDate(paymentTime) {
    const date = new Date(paymentTime),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();
    return year + "年" + month + "月" + day + "日";
  }
  render() {
    const renderResource = japaneseList.molecules.CostDetail.render;
    const { appointment } = this.props;
    let usageFee,
      medicalTotalFee,
      totalFee,
      redNotify,
      paymentTime,
      examFeeText,
      totalFeeText;

    if (appointment.payment) {
      const taxNote = appointment.menu.supportsInsuranceTreatment
        ? renderResource.j003
        : renderResource.j002;

      // システム利用料
      usageFee = appointment.payment.usageFeeTaxIncluded || 0;

      // 医療機関からの請求額を計算する
      medicalTotalFee = appointment.payment.medicalExpense || 0;
      if (
        appointment.payment.errorCode === SETTLEMENT_ERROR.CREDIT_CARD &&
        appointment.status === APPOINTMENT_STATUS.EXAM_COMPLETED
      ) {
        medicalTotalFee = appointment.payment.errorMedicalExpense || 0;
      }

      const isCompletePayment =
        medicalTotalFee ||
        appointment.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED;
      examFeeText = isCompletePayment
        ? medicalTotalFee + taxNote
        : renderResource.j001;

      // 合計金額を計算する
      if (isCompletePayment) {
        const isWelfareProgram =
          appointment.program &&
          appointment.program.type === PROGRAM_TYPE.WELFARE_PROGRAM;
        // 読みやすさのために実装当時の意図を知らないまま条件を変数に入れており、変数名が適切かは不明
        const isNotIncludeDeliveryFee =
          Boolean(appointment.menu.supportPaymentByContractCompany) ||
          (appointment.program &&
            appointment.deliveryFeeCalculateType ===
              DELIVERY_FEE_CALCULATE_TYPE.EXCLUDE);

        totalFee =
          medicalTotalFee +
          (isWelfareProgram ? 0 : usageFee) +
          (isNotIncludeDeliveryFee
            ? 0
            : appointment.payment.deliveryTotalFee || 0);

        const taxNoteAmountTotal = this.isNotIncludeTaxAmountTotal(
          appointment,
          medicalTotalFee
        )
          ? renderResource.j003
          : renderResource.j002;

        totalFeeText = totalFee + taxNoteAmountTotal;
      } else {
        totalFeeText = renderResource.div.Total.j003;
      }

      redNotify =
        !appointment.payment.medicalExpense &&
        appointment.payment.medicalExpense !== 0;
      paymentTime = appointment.payment.paymentTime || "";
    }
    const notCreditCardError = !(
      appointment.status === APPOINTMENT_STATUS.EXAM_COMPLETED &&
      appointment.payment.errorCode === SETTLEMENT_ERROR.CREDIT_CARD
    );

    const isSelfFundedAppoimtment =
      appointment.payment && isNumber(appointment.payment.selfFundedFee) ? true : false;

    if (isSelfFundedAppoimtment) {
      examFeeText = appointment.payment.selfFundedFee + renderResource.div.Container.CostLine.Typography3.j002;
      totalFeeText = appointment.payment.selfFundedFee + renderResource.div.Container.CostLine.Typography3.j002;
    }

    return (
      <div>
        <Container>
          {Boolean(usageFee) && (
            <CostLine>
              <Typography3>
                {renderResource.div.Container.CostLine.Typography3.j001}
              </Typography3>
              {appointment.program &&
              appointment.program.type === PROGRAM_TYPE.WELFARE_PROGRAM ? (
                "-"
              ) : (
                <Typography3>
                  {this.formatNumber(usageFee)}
                  {Boolean(appointment.menu.supportPaymentByContractCompany)
                    ? renderResource.div.Container.CostLine.Typography3.j002a
                    : renderResource.div.Container.CostLine.Typography3.j002}
                </Typography3>
              )}
            </CostLine>
          )}
          <CostLine>
            <Typography3>
              {isSelfFundedAppoimtment
                ? renderResource.div.Container.CostLine.Typography3.j009
                : renderResource.div.Container.CostLine.Typography3.j003}
            </Typography3>
            <Typography3>{this.formatNumber(examFeeText)}</Typography3>
          </CostLine>
          {!isSelfFundedAppoimtment && (
            <>
              {!Boolean(appointment.menu.supportPaymentByContractCompany) &&
              (appointment.payment.deliveryTotalFee ||
                appointment.payment.deliverySelectedOption ===
                  DELIVERY_METHOD.OTHER) ? (
                <CostLine>
                  <Typography3>
                    {" "}
                    {renderResource.div.Container.CostLine.Typography3.j004}
                  </Typography3>
                  {appointment.program &&
                  appointment.deliveryFeeCalculateType ===
                    DELIVERY_FEE_CALCULATE_TYPE.EXCLUDE ? (
                    "-"
                  ) : (
                    <Typography3>
                      {this.formatNumber(appointment.payment.deliveryTotalFee)}
                      {renderResource.div.Container.CostLine.Typography3.j005}
                    </Typography3>
                  )}
                </CostLine>
              ) : (
                ""
              )}
              {Boolean(appointment.menu.supportPaymentByContractCompany) ? (
                Boolean(appointment.payment.deliveryStatus) ||
                appointment.payment.deliverySelectedOption !==
                  DELIVERY_METHOD.NO_DELIVERY ? (
                  <CostLine>
                    <Typography3>
                      {" "}
                      {renderResource.div.Container.CostLine.Typography3.j004a}
                    </Typography3>
                    <Typography3>
                      0{renderResource.div.Container.CostLine.Typography3.j002a}
                    </Typography3>
                  </CostLine>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </>
          )}
        </Container>
        <Total>
          {renderResource.div.Total.j001} {this.formatNumber(totalFeeText)}
        </Total>
        <Message>
          {redNotify && notCreditCardError ? (
            <WarningMessage>
              {appointment.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED ? (
                renderResource.div.Message.WarningMessage.j001
              ) : (
                <LineBreakWrapper>
                  {parse(
                    DOMPurify.sanitize(
                      renderResource.div.Message.WarningMessage.j002
                    )
                  )}
                </LineBreakWrapper>
              )}
            </WarningMessage>
          ) : (
            <div>
              <InfoMessageBlue>
                {renderResource.div.Message.InfoMessageBlue.j001}
              </InfoMessageBlue>
              <BlueBorder />
              <CostLine>
                <Typography3>
                  {renderResource.div.Container.CostLine.Typography3.j008}
                </Typography3>
                <Typography3>
                  {this.createSettlementDate(paymentTime)}
                </Typography3>
              </CostLine>
            </div>
          )}
        </Message>
      </div>
    );
  }
}
export default CostDetail;
