import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import InfoMessage from "../../molecules/InfoMessage";

const renderResource = japaneseList.organisms.auth.InputPasswordForm;

const WarningMessage = styled(InfoMessage)`
  align-items: center;
  padding: 10px;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
`;

const FontPBold = styled.span`
  font-weight: ${(p) => p.theme.font.weight.bold};
  font-size: ${(p) => p.theme.font.size.sss};
`;

export default ({ children, ...props }) => (
  <WarningMessage>
    <FontP size="sss">
      {renderResource.note.text.j001}
      {renderResource.note.text.j002}
      {renderResource.note.text.j003}
    </FontP>
    <FontP size="sss">{renderResource.note.text.j004}</FontP>
    <FontP size="sss">{renderResource.note.text.j005}</FontP>
    <FontP size="sss">{renderResource.note.text.j006}</FontP>
  </WarningMessage>
);
