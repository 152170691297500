import React from "react";
import styled from "styled-components";
import WarningMessageTopPage from "../../molecules/WarningMessageTopPage";
import { japaneseList } from "../../../Resources/japaneseList";
import { APPOINTMENT_STATUS } from "../../../Utils/Constant";

const UnapprovedWarningTopPage = styled(WarningMessageTopPage)`
  min-height: 88px;
  background-color: #fff6f6;
  text-align: center;
  color: #f26666;
  border-radius: 0px;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
`;

const RejectedWarningTopPage = styled(WarningMessageTopPage)`
  min-height: 88px;
  background-color: #f7f7f7;
  text-align: center;
  color: #999;
  border-radius: 0px;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
`;

const warningTopPageResource = japaneseList.molecules.warningMessageTopPage;

export const AppointmentDetailHeader = ({ appointment }) => {
  const { status } = appointment;

  if (status === APPOINTMENT_STATUS.REJECTED) {
    return (
      <RejectedWarningTopPage>
        <p>{warningTopPageResource.rejectedMessage}</p>
      </RejectedWarningTopPage>
    );
  } else if (status === APPOINTMENT_STATUS.UNAPPROVED) {
    return (
      <UnapprovedWarningTopPage>
        <p>
          {warningTopPageResource.unapprovedMessage.j001} <br />
          {warningTopPageResource.unapprovedMessage.j002}
        </p>
      </UnapprovedWarningTopPage>
    );
  }
  return null;
};
