import styled from "styled-components";
import { PriorityHigh } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { japaneseList } from "../../../Resources/japaneseList";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";

const Container = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 45px;
  background-color: #fcebe7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const styles = () => ({
  iconSVG: {
    border: "1px dashed #000000",
    fill: "#e63c14",
  },
  errorTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    "font-weight": "600",
    "font-size": "18px",
    "line-height": "32px",
    padding: "24px 12px",
  },
  errorContent: {
    padding: "0 12px 40px 12px",
    "font-size": "14px",
    "line-height": "26px",
  },
  backBtn: {
    margin: 0,
    width: "100%",
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
});

const IvrErrorMessage = japaneseList.organisms.IvrError;

const IvrError = (props) => {
  const history = useHistory();
  const { classes, errorType } = props;

  const renderErrorType = (error) => {
    const isExpiredError = error === "E124" || error === "E125";
    const isPatientNotFound = error === "E123";
    const isExceedLimitation = error === "E126";

    if (isExpiredError) {
      return {
        title: IvrErrorMessage.render.expiredTitle,
        content: IvrErrorMessage.render.expiredContent,
      };
    } else if (isPatientNotFound) {
      return {
        title: IvrErrorMessage.render.patientNotFoundTitle,
        content: IvrErrorMessage.render.patientNotFoundContent,
      };
    } else if (isExceedLimitation) {
      return {
        title: IvrErrorMessage.render.limitationTitle,
        content: IvrErrorMessage.render.limitationContent,
      };
    } else {
      return {
        title: IvrErrorMessage.render.commonTitle,
        content: IvrErrorMessage.render.commonContent,
      };
    }
  };

  const handleBackBtn = () => history.goBack();
  return (
    <Container>
      <IconWrapper>
        <PriorityHigh className={classes.iconSVG} />
      </IconWrapper>
      <Typography className={classes.errorTitle}>
        {renderErrorType(errorType).title}
      </Typography>
      <Typography className={classes.errorContent}>
        {renderErrorType(errorType).content}
      </Typography>
      <ButtonOutlined className={classes.backBtn} onClick={handleBackBtn}>
        {IvrErrorMessage.render.backBtn}
      </ButtonOutlined>
    </Container>
  );
};

export default withStyles(styles)(IvrError);
