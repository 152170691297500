import { Component } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import Heading from "../atoms/headings/Heading";
import { japaneseList } from "../../Resources/japaneseList";
import { withStyles } from "@material-ui/core/styles";

const Container = styled.div`
  border: 1.5px solid ${(p) => p.theme.color.primary};
  padding: 20px;
  border-radius: 10px;
  margin: 15px;
`;
const Header = styled(Heading)`
  color: ${(p) => p.theme.color.primary};
  background-color: transparent;
  text-align: center;
  padding: 0 0 20px 0;
`;

const ContainerFee = styled.div`
  border-top: 0.5px solid ${(p) => p.theme.color.primary};
  border-bottom: 0.5px solid ${(p) => p.theme.color.secondary};
  padding: 5px 0;
  margin: 0 0 10px 0;
`;
const CostLine = styled.div`
  color: ${(p) => p.theme.color.font};
  display: flex;
  justify-content: space-between;
  margin: 11px 0;
`;
const Total = styled.div`
  color: ${(p) => p.theme.color.font};
  font-weight: bold;
  text-align: right;
`;
const Message = styled.div`
  text-align: right;
  margin: 5px 0;
`;
const LineBreakWrapper = styled.div`
  word-break: keep-all;
  overflow-wrap: break-word;
`;
const Typography3 = styled.div`
  font-size: 14px;
`;

const styles = (theme) => ({
  header: {
    "font-size": "16px",
    "font-weight": "bold",
  },
  warning: {
    "font-size": "12px",
    color: "#F26666",
    padding: 0,
    margin: 0,
  },
  paymentNote: {
    "font-size": "10px",
  },
  paymentTotal: {
    "font-size": "15px",
  },
});

class PaymentDetailConfirmPage extends Component {
  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  render() {
    const { classes } = this.props;
    const renderResource =
      japaneseList.molecules.PaymentDetailConfirmPage.render;
    return (
      <div>
        <Container>
          <Header className={classes.header}>
            {renderResource.div.Container.Header.j001}
          </Header>
          <ContainerFee>
            <CostLine>
              <Typography3>
                {
                  renderResource.div.Container.ContainerFee.CostLine.Typography3
                    .j003
                }
              </Typography3>
              <Typography3>
                {
                  renderResource.div.Container.ContainerFee.CostLine.Typography3
                    .j004
                }
              </Typography3>
            </CostLine>
          </ContainerFee>
          <Total>
            <span className={classes.paymentTotal}>
              {renderResource.div.Container.Total.j001}
              {renderResource.div.Container.Total.j002}
            </span>
          </Total>
          <Message>
            <LineBreakWrapper className={classes.warning}>
              {parse(
                DOMPurify.sanitize(
                  renderResource.div.Container.Message.WarningMessage.j001
                )
              )}
            </LineBreakWrapper>
          </Message>
        </Container>
      </div>
    );
  }
}
export default withStyles(styles)(PaymentDetailConfirmPage);
