import React, { useContext } from "react";

const stub = () => {
  throw new Error(
    "You forgot to wrap your component in <CognitoAuthProvider>."
  );
};

const authContext = {
  isAuthenticated: false,
  user: undefined,
  isLoading: false,
  signIn: stub,
  signOut: stub,
};

export const AuthContext = React.createContext(authContext);
export const useAuthContext = () => useContext(AuthContext);
