export const EXAM = {
  FREE_EXAM: "freeExam",
  REPEATE_EXAM: "repeatExam",
  LOCAL_EXAM: "localExam",
  IDI_EXAM: "industrialDoctorInterview",
};

export const APPOINTMENT_STATUS = {
  BEFORE_EXAM: "beforeExam",
  EXAM_COMPLETED: "examCompleted",
  PAYMENT_COMPLETED: "paymentCompleted",
  UNAPPROVED: "unapproved",
  REJECTED: "rejected",
  CANCELED_DOCTOR: "canceledDoctor",
  CANCELED_PATIENT_PREVIOUS_DAY: "canceledPatientPreviousDay",
  CANCELED_PATIENT_FROM_DAY: "canceledPatientFromToday",
  CANCELEDBEFOREAPPROVING: "canceledBeforeApproving",
  TENTATIVE_RESERVATION: "tentativeReservation",
};

export const FACILITY_TYPE = {
  HOSPITAL: "hospital",
  PHARMACY: "pharmacy",
};

export const HUB_EVENT_SOURCE = {
  RESERVE_TIME_UPDATE: "ReservationTimeUpdated",
};

export const MEDICAL_METHOD = {
  ONLINE: "online",
  OFFLINE: "offline",
};

export const APPOINTMENT_SIDEEFFECT = {
  NOTHING: "nothing",
  REGISTER_TENTATIVE_RESERVATION: "registerTentativeReservation",
  UPDATE_SLOT: "updateSlot",
  UPDATE_AND_LOCK_SLOT: "updateAndLockSlot",
};

export const FEATURES = [
  {
    type: "memberInformation",
    pagePath: "/my-page/profile/update",
    subPath: [],
  },
  {
    type: "password",
    pagePath: "/my-page/password/change",
    subPath: [],
  },
  {
    type: "creditCardInformation",
    pagePath: "/my-page/payment/card-info",
    subPath: ["/my-page/payment/card-info/update/0"],
  },
  {
    type: "address",
    pagePath: "/my-page/address/detail",
    subPath: [],
  },
  {
    type: "mailAddress",
    pagePath: "/my-page/email/register",
    subPath: [],
  },
  {
    type: "insuranceCardInformation",
    pagePath: "/my-page/insurance-card",
    subPath: [],
  },
];

export const PROGRAM_TYPE = {
  WELFARE_PROGRAM: "welfareProgram",
  INDUCSTRIAL_PHYSICAL_INTERVIEW: "industrialPhysicalInterview",
};

export const DELIVERY_FEE_CALCULATE_TYPE = {
  EXCLUDE: 1, //1. delivery fee is paid by contract company. So, the system doesn't need to include it when payment & showing
  INCLUDE: 2, //2. include & showing delivery fee as normal
};

export const DELIVERY_METHOD = {
  NO_DELIVERY: -1,
  YAMATO: 1,
  POST: 2,
  OTHER: 3,
};

export const PROGRAM_ID = {
  INDUCSTRIAL_PHYSICAL_INTERVIEW: 1,
  WELFARE_PROGRAM: 2,
};

export const SETTLEMENT_ERROR = {
  CREDIT_CARD: "E15",
};

export const NOTIFICATION_BY = {
  SMS: "sms",
  MAIL: "mail",
};

export const CONTENT_TYPE = {
  CONSULTATION_CONTENT: "consultationContent",
  NONE: "none",
};

export const INTERVIEW_TYPE = {
  FREE_TEXT: "freeText",
  RADIO: "radio",
  RADIO_WITH_FREE_TEXT: "radioWithFreeText",
  CHECKBOX: "checkbox",
  CHECKBOX_WITH_FREE_TEXT: "checkboxWithFreeText",
};

export const INTERVIEW_SHEET_ANSWER_STATUS = {
  NONE: "none",
  UNANSWERED: "unanswered",
  ANSWERED: "answered",
};

export const NOTIFICATION_TYPE = {
  PAYMENT_COMPLETED: "paymentCompleted",
  PAYMENT_ERROR: "paymentError",
  NEW_ATTCAHMENT: "newAttachment",
  CREATED_BY_DOCTOR: "createdByDoctor",
  ENTRY_BILLING_ENTERPRISE: "entryBillingEnterprise",
};

export const PAYMENT_METHOD = {
  BILLING_PATIENT: "billingPatient",
  BILLING_ENTERPRISE: "billingEnterprise",
  ORGANIZATION_WELFARE_PROGRAM: "organizationWelfareProgram",
};

export const FORM_TYPE = {
  NO_FILE: "noFile",
  REVIEW_FILE: "ReviewFile",
  THUMBNAIL_LIST: "ThumbnailList",
  DETAIL_FILE: "DetailFile",
  UPLOAD_FILE_FORM: "UploadFileForm",
};

export const PATIENT_ATTACHMENT = {
  MAX_FILE_SIZE: 10485760, //Mb to B
  VALID_FILE_EXTENSION: ["image/png", "image/jpg", "image/jpeg"],
  ERROR: {
    WRONG_TYPE: 0,
    WRONG_SIZE: 1,
    WRONG_TYPE_AND_SIZE: 2,
    FILE_NAME_EMPTY: 3,
    FILE_NAME_MAX_CHAR: 4,
    FILE_NAME_INCLUDE_UNUSUAL: 5,
  },
  MAX_CHARACTERS_FILENAME: 30,
  MAX_DAY_CAN_UPLOAD: 14,
  MAX_FILE_CAN_UPLOADED: 5,
};

export const FILE_ACTION = {
  UPDATE: "update",
  DELETE: "delete",
  ADD_NEW: "addNew",
};

export const OPERATION_TYPE = {
  DOCTOR_LOW_NETWORK: "doctorLowNetwork",
  PATIENT_LOW_NETWORK: "patientLowNetwork",
};

export const MEDIA_DEVICE_ERROR = {
  NOT_FOUND_ERROR: "NotFoundError",
  NOT_ALLOWED_ERROR: "NotAllowedError",
  NOT_READABLE_ERROR: "NotReadableError",
};

export const ENTERPRISEAUTHORIZATION_MAINEFFECT = {
  DRY_RUN: "dryRun",
  EXECUTE: "execute",
};

export const MEDICINE_DELIVERY_METHOD = {
  NONE: "none",
  DELIVERY: "delivery",
  GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME: "goGetWithReceivePrescriptionAtHome",
};

export const MEDICAL_MENU = {
  EXAMINATION: "examination",
  CONSULTATION: "consultation",
  MEDICATION_INSTRUCTION: "medicationInstruction",
  EXAMINATION_EWP: "examinationEWP",
  CONSULTATION_EWP: "consultationEWP",
  MEDICATION_INSTRUCTION_EWP: "medicationInstructionEWP",
};

export const RESERVE_STEP = {
  INPUT: "input",
  INTERVIEW: "interview",
  MEDICINE_DELIVERY: "medicineDelivery",
  UPLOAD_FILE: "uploadFile",
  CONFIRM: "confirm",
  COMPLETE: "complete",
};

export const ENTERPRISE_REGISTER_STEP = {
  SIGNUP: "enterpriseSignUp",
  SIGNIN: "enterpriseSignIn",
  AUTHORIZATION: "enterpriseAuthorization",
  CONFIRM: "enterpriseConfirm",
  COMPLETED: "enterpriseCompleted",
};

export const ENTERPRISE_SIGNUP_STEPS = {
  [ENTERPRISE_REGISTER_STEP.SIGNUP]: {
    previousUrl: "/enterprise/authenticate",
    followingUrl: "/auth/signup",
    stepNo: 0,
  },
  [ENTERPRISE_REGISTER_STEP.SIGNIN]: {
    previousUrl: "/enterprise/authenticate",
    followingUrl: "/login",
    stepNo: 1,
  },
  [ENTERPRISE_REGISTER_STEP.AUTHORIZATION]: {
    previousUrl: "/login",
    followingUrl: "/enterprise/authorize",
    stepNo: 2,
  },
  [ENTERPRISE_REGISTER_STEP.CONFIRM]: {
    previousUrl: "/enterprise/authorize",
    followingUrl: "/enterprise/confirm",
    stepNo: 3,
  },
  [ENTERPRISE_REGISTER_STEP.COMPLETED]: {
    previousUrl: "/enterprise/confirm",
    followingUrl: "/enterprise/complete",
    stepNo: 4,
  },
};

export const ENTERPRISE_SIGNIN_STEPS = {
  [ENTERPRISE_REGISTER_STEP.SIGNIN]: {
    previousUrl: "/enterprise/authenticate",
    followingUrl: "/login",
    stepNo: 0,
  },
  [ENTERPRISE_REGISTER_STEP.AUTHORIZATION]: {
    previousUrl: "/login",
    followingUrl: "/enterprise/authorize",
    stepNo: 1,
  },
  [ENTERPRISE_REGISTER_STEP.CONFIRM]: {
    previousUrl: "/enterprise/authorize",
    followingUrl: "/enterprise/confirm",
    stepNo: 2,
  },
  [ENTERPRISE_REGISTER_STEP.COMPLETED]: {
    previousUrl: "/enterprise/confirm",
    followingUrl: "/enterprise/complete",
    stepNo: 3,
  },
};

export const PATIENT_MEDICINE_DELIVERY = {
  ERROR: {
    E01: "E01",
    E02: "E02",
  },
};

export const ACCOUNTSTATUS = {
  ENABLED: "enabled",
};

export const DISPLAYSITES = {
  NONE: "none",
};

export const IVR = {
  FLOWTYPE: {
    MEMBER_REGISTRATION: "MEMBER_REGISTRATION",
    RESET_PASSWORD: "RESET_PASSWORD",
  },
  ERROR: {
    E116: "E116",
    E117: "E117",
    E118: "E118",
    E119: "E119",
  },
};
// 同意登録ステータス
export const CONSENT_STATUS = {
  UNREGISTERED: "unregistered",
  REGISTERING: "registering",
  REGISTERED: "registered",
};

export const SUBMIT_MODE = "submit";
export const RETURN_MODE = "return";

export const PAGE_FROM = {
  TOP: "top",
  LOGIN: "login",
  SIGNUP: "signup",
  TERMS_OF_SERVICE: "terms_of_service",
  CONSUMER_POLICY: "consumer_policy",
  PRIVACY_POLICY: "privacy_policy",
  SEARCH_HOSPITAL: "search_hospital",
  SEARCH_PHARMACY: "search_pharmacy",
  SEARCH_LIST: "search_list",
  HOSPITAL: "hospital",
  RESERVE: "reserve",
  ENTERPRISE: "enterprise",
};
