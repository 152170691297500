import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-common";
import { AuthProvider } from "./contexts/AuthProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { PollingProvider } from "./contexts/PollingContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import Theme from "./Utils/Theme";
import ThemeLnln from "./Utils/ThemeLnln";
import { isLnln } from "./Utils/checkLnln";
import client from "./Utils/GraphQLClient";
import "./index.css";
import "./Utils/AmplifySetup";

const Theme_ = isLnln() ? ThemeLnln : Theme;

ReactDOM.render(
  <MuiThemeProvider theme={Theme_}>
    <CssBaseline />
    <ApolloProvider client={client}>
      <AuthProvider>
        <PollingProvider>
          <App />
        </PollingProvider>
      </AuthProvider>
    </ApolloProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

unregister();
