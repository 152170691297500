import React, { Component } from "react";
import styled from "styled-components";
import { br2nl } from "../../../Utils/Utility";
import FontPAccentCenter from "../../atoms/fonts/FontPAccentCenter";
import FontPSS from "../../atoms/fonts/FontPSS";

const Container = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant4};
  padding: 15px 10px 0px;
  margin: 20px 0px 0 0px;
`;

const CustomFontPAccentCenter = styled(FontPAccentCenter)`
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
`;

const Detail = styled(FontPSS)`
  font-size: 12px;
  :first-line {
    font-weight: bold;
  }
  line-height: 20px;
`;

class FileNote extends Component {
  render() {
    const { title, noteList } = this.props;
    return (
      <Container>
        <CustomFontPAccentCenter>{title}</CustomFontPAccentCenter>
        <React.Fragment>
          {noteList &&
            noteList.map((policy, i) => {
              return (
                <React.Fragment key={i}>
                  <Detail>{br2nl(policy.trim(), true)}</Detail> <br />
                </React.Fragment>
              );
            })}
        </React.Fragment>
      </Container>
    );
  }
}
export default FileNote;
