import React, { Component } from "react";
import styled from "styled-components";
import { FACILITY_TYPE } from "../../Utils/Constant";
import FontP from "../atoms/fonts/FontP";
import { japaneseList } from "../../Resources/japaneseList";

const Panel = styled.div`
  margin-bottom: 15px;
`;

class MedicalDetailAppointmentDetail extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.MedicalDetailAppointmentDetail.render;
    const { appointment } = this.props;
    return (
      <div>
        <Panel>
          <FontP color="accent" weight="bold">
            {appointment.facilityType === FACILITY_TYPE.PHARMACY
              ? renderResource.Container.Title.j001
              : renderResource.Container.Title.j002}
          </FontP>
          <FontP>
            {appointment.hospitalName + " "}
            <span>&nbsp;&nbsp;&nbsp;</span>
            {appointment.doctorName}
          </FontP>
        </Panel>
      </div>
    );
  }
}
export default MedicalDetailAppointmentDetail;
