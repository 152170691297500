import React from "react";
import ProfileUpdateForm from "../../organisms/profile/ProfileUpdateForm";
import BasicTemplate from "../../templates/BasicTemplate";
import Guard from "../../../Utils/Guard";
import { withRouter } from "react-router-dom";
import Titles from "../../../Resources/Titles";

class ProfileUpdatePage extends React.Component {
  render() {
    const main = <ProfileUpdateForm {...this.props} />;
    return <BasicTemplate title={Titles.profileupdate} main={main} />;
  }
}

export default withRouter(Guard(ProfileUpdatePage));
