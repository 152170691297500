import React, { Component } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import Heading1 from "../atoms/headings/Heading1";
import Heading3 from "../atoms/headings/Heading3";
import AreaFont from "../molecules/area/AreaFont";
import LinkAnoUnder from "../atoms/links/LinkAnoUnder";
import { japaneseList } from "../../Resources/japaneseList";
import JapanesePrivacyPolicy from "../../Resources/JapanesePrivacyPolicy";
const renderResource = japaneseList.organisms.PrivacyPolicy.mail;

const Container = styled.div`
  margin-bottom: 30px;
`;

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.setState({
          user: user,
        });
      })
      .catch((err) => {});
  }

  render() {
    const user = this.state.user;
    const newLineCode = "%0D%0A";
    const name =
      user !== null ? renderResource.j001 + user.username + newLineCode : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      `mailto:${JapanesePrivacyPolicy.article7.mail}?subject=` +
      renderResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      renderResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      renderResource.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode;

    return (
      <Container>
        <div>
          <Heading1>{JapanesePrivacyPolicy.heading}</Heading1>
          <Heading3>{JapanesePrivacyPolicy.article1.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article1.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article2.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article2.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article3.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article3.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article4.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article4.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article5.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article5.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article6.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article6.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article7.heading}</Heading3>
          <AreaFont>{JapanesePrivacyPolicy.article7.text}</AreaFont>
          <Heading3>{JapanesePrivacyPolicy.article8.heading}</Heading3>
          <AreaFont>
            {JapanesePrivacyPolicy.article8.text}
            <LinkAnoUnder href={msg}>
              {JapanesePrivacyPolicy.article8.mail}
            </LinkAnoUnder>
          </AreaFont>

          <AreaFont></AreaFont>
        </div>
      </Container>
    );
  }
}

export default PrivacyPolicy;
