const isIndustrialPhysicianByAccount = function(account) {
  if (account && account.organizationId) return true;
  return false;
};

const isIndustrialPhysicianByMenu = function(menu) {
  if (menu.medicalMenu === "industrialPhysician") return true;
  return false;
};

export { isIndustrialPhysicianByAccount, isIndustrialPhysicianByMenu };
