import React, { Component } from "react";
import BasicTemplate from "../templates/BasicTemplate";
import styled from "styled-components";
import Heading1 from "../atoms/headings/Heading1";
import { withRouter } from "react-router-dom";
import FullWidthButton from "../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";

const Container = styled.div`
  padding: 0 15px;
`;

const Content = styled.div`
  color: ${p => p.theme.color.font};
  size: 0.9rem;
  line-height: 1.5em;
  padding: 40px 0;
  text-align: center;
`;

class EmailCompletePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const renderResource = japaneseList.pages.EmailCompletePage.render;
    const main = (
      <div>
        <Heading1>
          {renderResource.div.PrimaryHeading.j001}
        </Heading1>
        <Container>
          <Content>{renderResource.div.Container.Content.j001}</Content>
          <FullWidthButton onClick={() => this.props.history.push(`/`)}>
            TOPへ
          </FullWidthButton>
        </Container>
      </div>
    );
    return <BasicTemplate title={Titles.emailComplate} main={main} />;
  }
}

export default withRouter(EmailCompletePage);
