import styled from "styled-components";

interface Props {
  error?: boolean;
  theme?: any;
}
export default styled.input.attrs({ type: "text" })<Props>`
  height: 35px;
  color: ${(p: Props) => p.theme.color.font};
  border: 1px solid ${(p: Props) => p.theme.color.fontVariant};
  padding: 6px 5px;
  font-size: 16px;
  background-color: ${(p: Props) => (p.error ? "#FFF1F2" : "#f7f7f7")};
  border: solid 1px #c3c3c3;
  border-radius: 0;
  -webkit-appearance: none;
  :focus {
    background-color: ${(p: Props) => (p.error ? "#FFF1F2" : "#fff")};
    outline: 0;
  }
`;
