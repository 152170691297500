import React, { Component } from "react";
import ReservationConfirmForm from "../organisms/reservation-form/ReservationConfirmForm";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  Account,
  Hospital,
  ReleaseTentativeReservation,
} from "../../Utils/GraphQLQueries";
import BasicTemplate from "../templates/BasicTemplate";
import Titles from "../../Resources/Titles";
import * as Sentry from "@sentry/browser";

class ReservationConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital: null,
      menu: null,
      account: {},
    };

    this.reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    this.reserveInterviewSheetAnswer =
      this.props.location.state &&
      this.props.location.state.reserveInterviewSheetAnswer;
    this.fileList =
      this.props.location.state && this.props.location.state.fileList;
    const { hospitalId, menuId } = this.props.match.params;
    if (
      !this.reserveInput ||
      this.reserveInput.hospitalId !== hospitalId ||
      this.reserveInput.menuId !== menuId
    ) {
      return this.redirectPage();
    }

    this.loadUserInfo();
    this.loadHospitalInfo();
  }

  componentDidMount = async () => {
    await this.loadUserInfo();
    await this.loadHospitalInfo();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  };

  componentWillUnmount = async () => {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  };

  redirectPage = () => {
    const { hospitalId } = this.props.match.params;
    this.props.history.push(`/hospital/${hospitalId}`);
    return {
      render() {
        return null;
      },
    };
  };

  loadUserInfo = async () => {
    const {
      data: { account },
    } = await GraphQLClient.query({ query: Account });
    this.setState({
      account,
    });
  };

  loadHospitalInfo = async () => {
    try {
      let res = await GraphQLClient.query({
        query: Hospital,
        variables: { hospitalId: this.reserveInput.hospitalId },
      });
      let hospital = res.data.hospital;
      const menu = hospital.menus.find(
        (item) => item.menuId === Number(this.reserveInput.menuId)
      );
      this.setState({
        hospital: hospital,
        menu,
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  handleBeforeUnload = async (e) => {
    // It is not always executed due to browser dependence.
    // If it is not executed, the tentative reservation will be deleted after the specified time has passed.
    await GraphQLClient.mutate({
      mutation: ReleaseTentativeReservation,
    });
  };

  render() {
    const main = (
      <ReservationConfirmForm
        {...this.props}
        hospital={this.state.hospital}
        account={this.state.account}
        menu={this.state.menu}
      />
    );
    return <BasicTemplate title={Titles.reserveConfirm} main={main} />;
  }
}
export default ReservationConfirmPage;
