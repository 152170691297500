import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { INTERVIEW_TYPE } from "../../../Utils/Constant";

const Page = styled.div`
  padding: 0px 15px 21px;
`;

const NoAnswer = styled.div`
  font-size: 14px;
  color: #393939;
  font-weight: 300;
  line-height: 20px;
`;

const Question = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${(p) => p.theme.heading.h1.bg};
`;

const Answer = styled.div`
  width: 100%;
  font-size: 14px;
  color: #393939;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const renderResource =
  japaneseList.organisms.interviewSheet.InterviewSheetAnswerDetail.render;

export const InterviewSheetAnswerDetail = ({ interviewSheetAnswer }) => {
  const interviewChoicesToText = (interviewAnswer) => {
    if (interviewAnswer.interviewChoiceNos.length !== 0) {
      const interviewChoices = interviewAnswer.interviewChoiceNos.map(
        (item) => {
          return interviewAnswer.interviewChoices.find((it) => {
            return item === it.no;
          }).text;
        }
      );
      if (!interviewAnswer.freeText || !interviewAnswer.freeText.trim()) {
        return interviewChoices.join("、");
      }
      return interviewChoices.join("、") + "/";
    } else {
      return null;
    }
  };

  const makeInterviewAnswer = (interviewAnswer) => {
    if (
      interviewAnswer.interviewChoiceNos.length !== 0 ||
      interviewAnswer.freeText
    ) {
      if (interviewAnswer.interviewType !== INTERVIEW_TYPE.FREE_TEXT) {
        return (
          <Answer>
            {interviewChoicesToText(interviewAnswer)}
            {interviewAnswer.freeText && (
              <React.Fragment>
                {interviewAnswer.interviewChoiceNos.length !== 0 && <br />}
                {interviewAnswer.freeText}
              </React.Fragment>
            )}
          </Answer>
        );
      } else {
        return <Answer>{interviewAnswer.freeText}</Answer>;
      }
    } else {
      return <NoAnswer>{renderResource.Content.j001}</NoAnswer>;
    }
  };

  return (
    <Page>
      {interviewSheetAnswer &&
        interviewSheetAnswer.interviewAnswers.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Question>{item.interviewText}</Question>
              {makeInterviewAnswer(item)}
            </React.Fragment>
          );
        })}
    </Page>
  );
};
