import React from "react";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";

const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  color: ${p => p.theme.color.fontVariant};
  height: 35px;
  vertical-align: top;

  border: 1px solid ${p => p.theme.color.fontVariant};
`;

const Select = styled.select`
  width: calc(100% - 32px);
  box-sizing: content-box;
  padding: 0 32px 0 4px;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  outline: none;

  border: 0;
  margin: 0;
  height: 100%;
  line-height: 1;
`;

const Arrow = styled(ArrowDropDown)`
  position: absolute;
  color: ${p => p.theme.color.secondary};
  top: calc(50% - 12px);
  right: 0;
  pointer-events: none;
`;

const Root = props => {
  return (
    <Wrapper>
      <Select {...props} />
      <Arrow />
    </Wrapper>
  );
};

export default Root;
