import React, { Component } from "react";
import BasicTemplate from "../templates/BasicTemplate";
import { withRouter } from "react-router-dom";
import Home from "../organisms/Home";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListAppointmentsWithConsentByStatus } from "../../Utils/GraphQLQueries";
import { Auth } from "aws-amplify";
import { ListHospitalsProgram, Account } from "../../Utils/GraphQLQueries";
import { isLnln } from "../../Utils/checkLnln";
import ApiWrapper from "../../Utils/ApiWrapper";
import Titles from "../../Resources/Titles";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appointments: [],
      hospitals: [],
      isLoading: true,
      login: false,
      account: {},
    };
  }

  getAppointmentList = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsWithConsentByStatus,
      variables: { status: ["beforeExam", "unapproved", "rejected"] },
    });
    if (errors) return;
    this.setState({
      appointments: data.appointmentsWithConsent.items,
    });
  };

  async getHospitalList() {
    const from = isLnln() ? "lnln" : "carada";
    if (this.state.login) {
      const { data } = await GraphQLClient.query({
        query: ListHospitalsProgram,
        variables: { from },
      });
      this.setState({
        hospitals: data && data.hospitalsProgram,
      });
    } else {
      let apiName = "dev-patient";
      let path = `/listhospital`;
      try {
        let response = await ApiWrapper.Get(apiName, path, {
          queryStringParameters: {
            from: from,
          },
        });
        this.setState({
          hospitals: response.hospitals,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  async componentDidMount() {
    let user = null;
    try {
      user = await Auth.currentUserInfo();
      user && this.setState({ login: true });
    } catch (error) {}
    if (user) {
      const { data } = await GraphQLClient.query({ query: Account });
      this.setState({ account: data.account });
      await this.getAppointmentList();
      if (this.state.account.organizationId) {
        await this.getHospitalList();
      }
    }

    this.setState({
      isLoading: false,
    });
  }

  render() {
    const _title = this.state.login ? Titles.home : Titles.singIn;
    return (
      <BasicTemplate
        isTop={true}
        title={_title}
        main={
          <Home
            appointments={this.state.appointments}
            hospitals={this.state.hospitals}
            login={this.state.login}
          />
        }
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(HomePage);
