import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { PriorityHigh } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import ButtonOutlined from "../atoms/buttons/ButtonOutlined";
import { japaneseList } from "../../Resources/japaneseList";

const Container = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 45px;
  background-color: #fcebe7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const styles = () => ({
  iconSVG: {
    border: "1px dashed #000000",
    fill: "#e63c14",
  },
  errorTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    "font-weight": "600",
    "font-size": "18px",
    "line-height": "32px",
    padding: "24px 12px",
  },
  errorContent: {
    padding: "0 12px 40px 12px",
    "font-size": "14px",
    "line-height": "26px",
  },
  backBtn: {
    margin: 0,
    width: "100%",
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
});

function SystemError(props) {
  const { classes } = props;
  const history = useHistory();
  const CancellationErrorMessage =
    japaneseList.organisms.account.CancellationError;

  const handleBackBtn = () => history.goBack();

  return (
    <Container>
      <IconWrapper>
        <PriorityHigh className={classes.iconSVG} />
      </IconWrapper>
      <Typography className={classes.errorTitle}>
        {CancellationErrorMessage.systemError.commonTitle}
      </Typography>
      <Typography className={classes.errorContent}>
        {CancellationErrorMessage.systemError.commonContent}
      </Typography>
      <ButtonOutlined className={classes.backBtn} onClick={handleBackBtn}>
        {CancellationErrorMessage.systemError.backBtn}
      </ButtonOutlined>
    </Container>
  );
}

export default withStyles(styles)(SystemError);
