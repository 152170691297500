import React from "react";
import dateFns from "date-fns";

import FileUploadDetailItemDeleteByDoctor from "./FileUploadDetailItemDeleteByDoctor";
import FileUploadDetailItemDeleteByPeriod from "./FileUploadDetailItemDeleteByPeriod";

const _ = require("lodash");

const FileUploadDetailFileList = (props) => {
  const { doctorFiles } = props;
  const filesOrderBytUpdateTime = _.orderBy(
    doctorFiles,
    ["updatedTime"],
    ["desc"]
  );
  const fileList = filesOrderBytUpdateTime.map(function (item, index) {
    let status = item.status;
    let periodTime = item.expiredTime;
    let currentTime = new Date().toISOString();
    if (periodTime < currentTime && item.status !== "deleted") {
      status = "expired";
    }
    switch (status) {
      case "deleted":
        return <FileUploadDetailItemDeleteByDoctor key={index} />;
      case "expired":
        let exTimeFormat = dateFns.format(
          new Date(item.expiredTime),
          "YYYY/MM/DD HH:mm"
        );
        let upTimeFormat = dateFns.format(
          new Date(item.updatedTime),
          "YYYY/MM/DD HH:mm"
        );
        let showTime = `${upTimeFormat} ～ ${exTimeFormat}`;
        return (
          <FileUploadDetailItemDeleteByPeriod
            fileName={item.fileName}
            key={index}
            period={showTime}
          />
        );
      default:
        return "";
    }
  });
  return <React.Fragment>{fileList}</React.Fragment>;
};

export default FileUploadDetailFileList;
