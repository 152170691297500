import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AssignmentOutlined from "@material-ui/icons/AssignmentOutlined";
import _ from "lodash";

import { device } from "../../device";
import { FACILITY_TYPE } from "../../../Utils/Constant";
import Heading2UnderlineIcon from "../../atoms/headings/Heading2UnderlineIcon";

import FontP from "../../atoms/fonts/FontP";
import Button from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";
import MedicalCost from "./MedicalCost";
import MenuTitle from "./MenuTitle";
import EnterpriseMenuTitle from "./EnterpriseMenuTitle";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";
import { isLnln } from "../../../Utils/checkLnln";
import ImgCheckedPath from "../../../images/carada/check.svg";
import ImgCheckedPathLnln from "../../../images/lnln/check.svg";

import MenuAccordion from "../../molecules/MenuAccordion";

const imgChecked = isLnln() ? ImgCheckedPathLnln : ImgCheckedPath;
const IconResource = japaneseList.insurance;

const ContainerWrapper = styled.div`
  padding: 15px 8px;
  background-color: #f7f7f7;
  margin-bottom: -20px;
  @media screen and ${device.tb} {
    padding: 20px 15px;
  }
`;
const MenusWithEnterpriseWrapper = styled.div`
  margin-bottom: -20px;
`;

const EnterpriseContainerWrapper = styled.div`
  padding: 15px 8px;
  background-color: #fff8ed;
  @media screen and ${device.tb} {
    padding: 20px 15px;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 13px 8px;
  margin-bottom: 15px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  @media screen and ${device.tb} {
    padding: 15px;
  }
`;

const EnterpriseContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;
const EnterpriseLine = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${(p) => p.theme.color.primary};
  border-radius: 0px;
`;
const EnterpriseMenuTitleWrapper = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant4};
  padding: 8px;
  @media screen and ${device.tb} {
    padding-left: 15px;
  }
`;
const EnterpriseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  margin-bottom: 10px;
`;

const EnterpriseBodyWrapper = styled.div`
  padding: 0px 8px 13px 8px;
  @media screen and ${device.tb} {
    padding: 0px 15px 15px 15px;
  }
`;
// padding: 13px 8px;
const GridWapper = styled.div`
  padding-top: 15px;
  margin-top: 15px;
  border-top: solid 1px ${(p) => p.theme.panel.border.color.default};
`;
const ButtonWapper = styled.div`
  text-align: right;
`;
const FontPWapper = styled.div`
  padding-top: 10px;
`;

const CustomButton = styled(Button)`
  @media screen and ${device.tb} {
    padding: 10px 20px;
  }
`;
const CustomIconInsurance = styled(IconInsurance)`
  position: absolute;
  top: 15px;
  right: 15px;
`;
const CustomIconNotInsurance = styled(IconNotInsurance)`
  position: absolute;
  top: 15px;
  right: 15px;
`;
const IconMedicalMethod = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  padding: 1px 8px;
  text-align: center;
  font-size: 0.7rem;
  color: ${(p) => p.theme.font.color.black};
  border-radius: 5px;
  background-color: whitesmoke;
`;

const DialogContentCustom = styled(DialogContent)``;

const DialogTitleText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.color.primary};
  line-height: 23px;
`;

const DialogContentText = styled.span`
  font-size: 14px;
  color: #393939;
  line-height: 22px;
`;

const ContentItem = styled.div``;

const styles = (theme) => ({
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    padding: "30px 21px 15px 21px",
    "font-size": "17px",
  },
  dialogAction: {
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "15px 21px 30px 21px",
    margin: 0,
  },
  dialogContent: {
    display: "grid",
    "grid-template-columns": "21px 1fr",
    "grid-row-gap": "19px",
    "grid-column-gap": "8.5px",
    padding: "15px 21px 15px 21px",
    "text-align": "justify",
    "font-size": "12px",
    "max-width": "303px",
  },
  dialogButton: {
    margin: 0,
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
  img: {
    width: "21px",
    height: "14px",
    marginTop: "2px",
  },
});

class MedicalCareMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openApprovalWarning: false,
    };
  }

  submitClick = (menu) => {
    if (menu.extarnal_system_url) {
      const paramExternalBrowser = menu.extarnal_system_url.includes("?")
        ? "&lnln_ob=1"
        : "?lnln_ob=1";
      window.location.href = menu.extarnal_system_url + paramExternalBrowser;
      return;
    }

    const { hospital } = this.props;
    const doctor = hospital.doctors.find((d) => d.doctorId === menu.doctorId);
    menu.doctorName = doctor && doctor.doctorName;
    const reserveData = { menu, hospital };
    const pathname = `/reserve/input/${hospital.hospitalId}/${menu.menuId}`;

    this.props.history.push({
      pathname,
      state: { reserveData },
    });
  };

  openApprovalWarning = () => {
    this.setState({ openApprovalWarning: true });
  };

  closeApprovalWarning = (e) => {
    this.setState({ openApprovalWarning: false });
  };

  handleSelectMenu = (menu) => (e) => {
    if (Boolean(menu.approvalRequired)) {
      this.openApprovalWarning();
      this.setState({
        selectedMenu: menu,
      });
    } else {
      this.submitClick(menu);
    }
  };

  renderMenus = ({ menus, hospital, renderResource }) => {
    return (
      <ContainerWrapper>
        {menus
          .sort((a, b) => a.order - b.order)
          .map((menu, i) => {
            return (
              <Container key={i}>
                <IconMedicalMethod>
                  {menu.medicalMethod === "online"
                    ? renderResource.div.Container.OnlineOrOffline
                        .TextMedicalMethod.j001
                    : renderResource.div.Container.OnlineOrOffline
                        .TextMedicalMethod.j002}
                </IconMedicalMethod>
                <MenuTitle menu={menu} hospital={hospital} />
                {Boolean(menu.supportsInsuranceTreatment) ? (
                  <CustomIconInsurance>{IconResource.ok}</CustomIconInsurance>
                ) : (
                  <CustomIconNotInsurance>
                    {IconResource.no}
                  </CustomIconNotInsurance>
                )}
                <FontPWapper>
                  <FontP size="ss">
                    {menu.createAppointmentByPatient
                      ? menu.helpDialogContent.allowPatientMakeAppointment
                      : menu.helpDialogContent.denyPatientMakeAppointment}

                    {menu.helpDialogContent.allowPatientMakeAppointment ||
                    menu.helpDialogContent.denyPatientMakeAppointment ? (
                      <>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {menu.description}
                  </FontP>
                </FontPWapper>
                <GridWapper>
                  <Grid container>
                    <Grid item xs={8}>
                      <MedicalCost menu={menu} />
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonWapper>
                        <CustomButton
                          id="reserve-button"
                          padding="s"
                          onClick={this.handleSelectMenu(menu)}
                        >
                          {
                            renderResource.div.Container.ContainerButton
                              .FullWidthButton.j001
                          }
                        </CustomButton>
                      </ButtonWapper>
                    </Grid>
                  </Grid>
                </GridWapper>
              </Container>
            );
          })}
      </ContainerWrapper>
    );
  };

  renderEnterpriseMenus = ({ enterpriseMenus, hospital, renderResource }) => {
    return enterpriseMenus
      .sort((a, b) => a.order - b.order)
      .map((menu, i) => {
        return (
          <EnterpriseContainer key={i}>
            <EnterpriseLine />
            <EnterpriseMenuTitleWrapper>
              <EnterpriseIconWrapper>
                {Boolean(menu.supportsInsuranceTreatment) ? (
                  <IconInsurance>{IconResource.ok}</IconInsurance>
                ) : (
                  <IconNotInsurance>{IconResource.no}</IconNotInsurance>
                )}
              </EnterpriseIconWrapper>
              <EnterpriseMenuTitle menu={menu} hospital={hospital} />
            </EnterpriseMenuTitleWrapper>
            <EnterpriseBodyWrapper>
              <FontPWapper>
                <FontP size="ss">
                  {menu.createAppointmentByPatient
                    ? menu.helpDialogContent.allowPatientMakeAppointment
                    : menu.helpDialogContent.denyPatientMakeAppointment}

                  {menu.helpDialogContent.allowPatientMakeAppointment ||
                  menu.helpDialogContent.denyPatientMakeAppointment ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {menu.description}
                </FontP>
              </FontPWapper>
              <GridWapper>
                <Grid container>
                  <Grid item xs={8}>
                    <MedicalCost menu={menu} />
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonWapper>
                      <CustomButton
                        id="reserve-button"
                        padding="s"
                        onClick={this.handleSelectMenu(menu)}
                      >
                        {
                          renderResource.div.Container.ContainerButton
                            .FullWidthButton.j001
                        }
                      </CustomButton>
                    </ButtonWapper>
                  </Grid>
                </Grid>
              </GridWapper>
            </EnterpriseBodyWrapper>
          </EnterpriseContainer>
        );
      });
  };

  // 健康経営メニューと一般診療メニューを分割して表示
  renderMenusWithEnterprise = ({ menus, hospital, renderResource }) => {
    const enterpriseMenus = menus.filter((menu) => this.isEnterpriseMenu(menu));
    const normalMenus = menus.filter((menu) => !this.isEnterpriseMenu(menu));
    return (
      <MenusWithEnterpriseWrapper>
        <EnterpriseContainerWrapper>
          {this.renderEnterpriseMenus({
            enterpriseMenus,
            hospital,
            renderResource,
          })}
        </EnterpriseContainerWrapper>
        {normalMenus.length > 0 ? (
          <MenuAccordion>
            {this.renderMenus({ menus: normalMenus, hospital, renderResource })}
          </MenuAccordion>
        ) : null}
      </MenusWithEnterpriseWrapper>
    );
  };

  containEnterpriseMenu = (menus) => {
    for (const menu of menus) {
      if (this.isEnterpriseMenu(menu)) return true;
    }
    return false;
  };

  isEnterpriseMenu = (menu) => {
    return menu.patientCategory === "enterprise";
  };

  render() {
    const { classes } = this.props;
    const renderResource = japaneseList.organisms.MedicalCareMenu.render;
    const ApprovalWarningDialogResource =
      japaneseList.organisms.MedicalCareMenu.ApprovalWarningDialog;
    const { hospital } = this.props;
    console.log("hospital", hospital);
    const menus = _.cloneDeep(hospital.menus);
    return (
      <React.Fragment>
        <Heading2UnderlineIcon icon={<AssignmentOutlined />}>
          {hospital.facilityType === FACILITY_TYPE.PHARMACY
            ? renderResource.div.HomeHeadingAlignLeft.j001
            : renderResource.div.HomeHeadingAlignLeft.j002}
        </Heading2UnderlineIcon>
        {isLnln() && this.containEnterpriseMenu(menus)
          ? this.renderMenusWithEnterprise({
              menus,
              hospital,
              renderResource,
            })
          : this.renderMenus({ menus, hospital, renderResource })}
        <Dialog id="attention-dialog" open={this.state.openApprovalWarning}>
          <DialogTitle className={classes.dialogTitle}>
            <DialogTitleText>
              {ApprovalWarningDialogResource.DialogTitle.Title.j001}
            </DialogTitleText>
          </DialogTitle>
          <DialogContentCustom className={classes.dialogContent}>
            <ContentItem>
              <img src={imgChecked} alt={imgChecked} className={classes.img} />
            </ContentItem>
            <ContentItem>
              <DialogContentText>
                {ApprovalWarningDialogResource.DialogContent.j001}
              </DialogContentText>
            </ContentItem>
            <ContentItem>
              <img src={imgChecked} alt={imgChecked} className={classes.img} />
            </ContentItem>
            <ContentItem>
              <DialogContentText>
                {ApprovalWarningDialogResource.DialogContent.j002}
              </DialogContentText>
            </ContentItem>
            <ContentItem>
              <img src={imgChecked} alt={imgChecked} className={classes.img} />
            </ContentItem>
            <ContentItem>
              <DialogContentText>
                {ApprovalWarningDialogResource.DialogContent.j003}
              </DialogContentText>
            </ContentItem>
          </DialogContentCustom>

          <DialogActions className={classes.dialogAction}>
            {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
            <Button
              id="attention-dialog-ok-button"
              className={classes.dialogButton}
              onClick={(e) => this.submitClick(this.state.selectedMenu)}
            >
              {ApprovalWarningDialogResource.DialogActions.OkButton.j001}
            </Button>
            {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
            <ButtonOutlined
              id="attention-dialog-close-button"
              className={classes.dialogButton}
              onClick={this.closeApprovalWarning}
            >
              {ApprovalWarningDialogResource.DialogActions.CloseButton.j001}
            </ButtonOutlined>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(MedicalCareMenu));
