import React, { Component } from "react";
import { Redirect } from "react-router";

import ConfirmationForm from "../../organisms/auth/ConfirmationForm";
import InquirySignupSmsFrom from "../../organisms/auth/InquirySignupSmsFrom";
import TagManager from "react-gtm-module";

class ConfirmationPage extends Component {
  componentDidMount() {
    const pathname = "/auth/verify";
    const pageDataLayer = {
      dataLayer: {
        event: "pageView",
        page: pathname,
      },
    };
    TagManager.dataLayer(pageDataLayer);
    window.scrollTo(0, 0);
  }

  render() {
    if (!this.props.user) {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <ConfirmationForm authcb={this.props.authcb} user={this.props.user} />
        <InquirySignupSmsFrom username={this.props.user.username}/>
      </React.Fragment>
    );
  }
}
export default ConfirmationPage;
