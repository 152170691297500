import React from "react";
import styled from "styled-components";
import FontP from "../atoms/fonts/FontP";
import { prefectureCodes } from "../../Utils/PrefectureCode";
import { formatPostalCode } from "../../Utils/Utility";

interface Props {
  name: string;
  address: Adress;
}

interface Adress {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  tel?: string;
}

const Title = styled.div`
  padding: 14px 14px 5px 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #393939;
  font-family: Hiragino Kaku Gothic ProN;
`;

const Content = styled.div`
  color: #393939;
  padding: 0px 14px 14px 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  font-family: Hiragino Kaku Gothic ProN;
`;

const TitleFontP = styled(FontP)`
  font-size: 16px;
`;

const ContentFontP = styled(FontP)`
  font-size: 14px;
`;

export const AddressDetail = (props: Props) => {
  const { name, address } = props;

  const prefectureName = (prefectureCode: string) => {
    const prefectureData = prefectureCodes.find(
      (p) => p.code === prefectureCode
    );
    return prefectureData && prefectureData.jpName;
  };

  return (
    <React.Fragment>
      <Title>{name && <TitleFontP>{name}</TitleFontP>}</Title>
      <Content>
        {address && address.postalCode && (
          <ContentFontP>{formatPostalCode(address.postalCode)}</ContentFontP>
        )}
        {address && (
          <ContentFontP>
            {address.prefectureCode && prefectureName(address.prefectureCode)}
            {`${address.addressCity || ""}${address.addressStreetNumber || ""}`}
          </ContentFontP>
        )}
        {address && address.addressBuilding && (
          <ContentFontP>{address.addressBuilding}</ContentFontP>
        )}
        {address && address.tel && <ContentFontP>{address.tel}</ContentFontP>}
      </Content>
    </React.Fragment>
  );
};
