const JapaneseTermsOfServiceForEnterprise = {
  heading: "ルナルナ オフィスご利用に関する同意事項",
  text: {
    title: "「ルナルナ オフィス」のご利用にあたって",
    signAndDate: {
      j001: "株式会社LIFEM",
      j002: "株式会社カラダメディカ",
      j003: "最終改定日：2022年8月1日",
    },
    introduction:
      "お客様が、株式会社LIFEM（以下、「LIFEM」といいます。）が提供する「ルナルナ オフィス」（以下、「本プログラム」といいます。）をご利用いただくにあたっては、株式会社カラダメディカ（以下、「カラダメディカ」といいます。）が別途定める「ルナルナ オンライン診療　利用規約」（以下、「オンライン診療利用規約」という。）に加え、下記の規約（以下、「本規約」といいます。）に同意いただきます。",
    ol: {
      li1: {
        heading: "支払い方法の変更",
        j001: "診療費用等及び配送料等について、ご所属の法人から、ご所属の法人が代わって支払う旨の案内がある場合、従業員様がそれらの費用をお支払いいただく必要はございません。その場合、オンライン診療利用規約中のお支払いに関する条項は、本プログラムの利用に伴う診療に関しては、適用されないものとします。",
      },
      li2: {
        heading: "情報の管理",
        j001: "オンライン診療利用規約第９条に規定されている「認証番号」「パスワード」には、ご所属の法人からお渡しいただいております、「招待コード」を含むものとします。この招待コードは、パスワードと同様に厳格に管理ください。",
      },
      li3: {
        heading: "禁止行為",
        j001: "本プログラムの利用にあたり、オンライン診療利用規約第15条に定める事項のほか、下記各号の行為を行ってはならないものとします。",
        j002: "（１）招待コードを厳格に管理せず、他者に提示、譲渡、流出等させること",
        j003: "（２）不正に入手した招待コードを利用するなどして、本プログラムの利用対象でないにも関わらず、本プログラムを利用すること",
        j004: "（３）ご所属の法人を退職された場合など、ご所属の法人が定める本プログラムの利用対象でなくなった後にも関わらず、本プログラムを利用すること",
        j005: "（４）本プログラムに付随して実施されるセミナーを録画・録音、複製、上映、公衆送信その他乙又はセミナーを実施する医師その他の者の著作権、肖像権その他の権利を侵害する行為",
        j006: "（５）自ら又は第三者のために不正な利益を得ようとする行為",
        j007: "（６）その他本プログラムの提供を困難にするものであるとLIFEMもしくはカラダメディカが判断する一切の行為",
      },
      li4: {
        heading: "利用停止",
        j001: "オンライン診療利用規約第13条に定める各場合のほか、下記各号に一にでも該当する場合、LIFEMおよびカラダメディカは、本プログラムの利用の停止、予約の変更又はキャンセル、アカウントの停止・削除等の措置をお客様に何ら事前の通知することなくできるものとします。なお、当社はお客様さまに対し、それらの措置を講じる理由について説明する義務を負いません。",
        j002: "（１）利用者が禁止行為を行った場合",
        j003: "（２）LIFEMとご所属の法人の間の本プログラムの提供に関する契約が効力を失った場合",
        j004: "（３）医師の急病や退職等、医療機関に起因する事象により本プログラムの提供が一時的に困難となった場合",
        j005: "（４）その他当社が必要と認める場合",
      },
      li5: {
        heading: "個人情報の共同利用",
        j001: "LIFEM及びカラダメディカは、以下の通り、お預かりした個人情報を共同して利用いたします。",
        j002: "（１）共同して利用する個人情報の項目",
        j003: "氏名、招待コードに紐づく所属法人名、提携医療機関名、予約並びに実施された診療メニュー名及び診療実績",
        j004: "（２）共同して利用する者の範囲",
        j005: "①",
        j006: "法人名：株式会社LIFEM",
        j007: "住所：東京都新宿区西新宿3-20-2",
        j008: "代表者：代表取締役 菅原 誠太郎",
        j009: "②",
        j010: "法人名：株式会社カラダメディカ",
        j011: "住所：東京都新宿区西新宿3-20-2",
        j012: "代表者：代表取締役 菅原 誠太郎",
        j013: "（３）共同して利用する者の利用目的",
        j014: "・所属法人への請求・実績報告を行うため",
        j015: "・提携医療機関への診療費の支払に関する業務を行うため",
        j016: "（４）個人データの管理について責任を有する者の名称等",
        j017: "（２）に同じ",
        j018: "（５）取得方法",
        j019: "・「ルナルナ オンライン診療」の会員登録及び招待コードの入力",
        j020: "・提携医療機関が診療後にデータを入力",
      },
      li6: {
        heading: "本規約の変更",
        j001: "LIFEM及びカラダメディカは、変更された本規約の施行時期を定めた上で、「ルナルナ オンライン診療」上で掲示する等の方法でお客様に通知する方法で、本規約を変更することができるものとします。本規約の変更内容が公開された後、最初にお客様が「ルナルナ オンライン診療」を利用した時点でお客様は当該変更内容に同意したものとみなします。",
      },
      li7: {
        heading: "本規約の適用",
        j001: " お客様が、本規約に同意することにより、お客様、LIFEM及びカラダメディカの三者間契約が成立し、お客様が本規約に違反することは、オンライン診療利用規約の違反を構成するものとします。",
      },
    },
  },
  date: {
    revision: "2022-08-01",
  },
};
export default JapaneseTermsOfServiceForEnterprise;
