import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { Auth, Hub } from "aws-amplify";
import Guard from "../../../Utils/Guard";
import { ERRORS } from "../../../Utils/Errors";

import Heading1 from "../../atoms/headings/Heading1";
import Heading3 from "../../atoms/headings/Heading3";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import StyledInputText from "../../atoms/forms/InputText";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";

import PasswordCaution from "../../organisms/auth/PasswordCaution";

import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";

const InputText = styled(StyledInputText)`
  width: 100%;
  margin-bottom: 15px;
`;
const Margin = styled.div`
  margin-bottom: 10px;
`;

const SectionSub = ({ title, titlesub, children }) => (
  <div>
    <Heading1>{title}</Heading1>
    <Heading3>{titlesub}</Heading3>
    <PanelFormArea column={true}>{children}</PanelFormArea>
  </div>
);

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.disable = false;
    this.inputs = {};
    this.state = {
      disable: false,
    };
  }

  change = () => {
    Sentry.captureMessage("password-change", Sentry.Severity.Log);
    const changeResource = japaneseList.pages.auth.ChangePasswordPage.change;
    this.disable = true;
    if (
      !this.inputs.newPassword ||
      !this.inputs.oldPassword ||
      !this.inputs.confirmPassword
    ) {
      Sentry.captureMessage(
        "password-change-error-itemNoValue",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: changeResource.j001,
            level: "error",
          },
        },
        "ChangePassword"
      );
      this.disable = false;
      return;
    } else if (this.inputs.newPassword !== this.inputs.confirmPassword) {
      Sentry.captureMessage(
        "password-change-error-notSameAsConfirm",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: changeResource.j002,
            level: "error",
          },
        },
        "ChangePassword"
      );
      this.disable = false;
      return;
    }
    Auth.changePassword(
      this.props.user,
      this.inputs.oldPassword,
      this.inputs.newPassword
    )
      .then((result) => {
        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: {
              message: result === "SUCCESS" ? changeResource.j003 : result,
              level: "info",
            },
          },
          "ChangePassword"
        );
        this.disable = false;
        this.props.history.push("/my-page");
      })
      .catch((err) => {
        Sentry.captureMessage(
          "password-change-error-" + err.code,
          Sentry.Severity.Log
        );
        var message = "";

        if (
          err.code === "NotAuthorizedException" ||
          err.code === "InvalidParameterException"
        ) {
          if (
            err.message.indexOf(ERRORS.INVALID_PASSWORD.LEGNTHDEFAULT.MESSAGE) >
            -1
          ) {
            message = ERRORS.INVALID_PASSWORD.MESSAGEJP;
          } else {
            message = changeResource.j004;
          }
        } else if (err.code === ERRORS.INVALID_PASSWORD.POLICY.CODE) {
          message = ERRORS.INVALID_PASSWORD.MESSAGEJP;
        } else if (err.code === "LimitExceededException") {
          message = changeResource.j005;
        } else {
          message = err.message;
        }

        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: {
              message: message,
              level: "error",
            },
          },
          "ChangePassword"
        );
      })
      .catch((err) => console.log(err));
  };

  handleClick = () => {
    this.change();
  };
  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value } = evt.target;
    this.inputs[name] = value;
  };

  render() {
    const renderResource = japaneseList.pages.auth.ChangePasswordPage.render;
    const main = (
      <React.Fragment>
        <SectionSub
          title={renderResource.SectionSub.j001}
          titlesub={renderResource.SectionSub.j002}
        >
          <fontP>{renderResource.SectionSub.InputText.j001}</fontP>
          <InputText
            type="password"
            name="oldPassword"
            placeholder={renderResource.SectionSub.InputText.placeholder}
            onChange={this.handleInputChange}
            value={this.inputs.oldPassword}
          />
          <Margin>
            <PasswordCaution />
          </Margin>

          <fontP>{renderResource.SectionSub.InputText.j002}</fontP>
          <InputText
            placeholder={renderResource.SectionSub.InputText.placeholder}
            name="newPassword"
            value={this.inputs.newPassword}
            onChange={this.handleInputChange}
            type="password"
          />

          <fontP>{renderResource.SectionSub.InputText.j003}</fontP>
          <InputText
            placeholder={renderResource.SectionSub.InputText.placeholder}
            type="password"
            onChange={this.handleInputChange}
            id="password-input"
            name="confirmPassword"
            value={this.inputs.confirmPassword}
          />
        </SectionSub>
        <PanelFormArea>
          <ButtonFullWidth onClick={this.handleClick}>
            {renderResource.SectionSub.Button.j001}
          </ButtonFullWidth>
        </PanelFormArea>
      </React.Fragment>
    );
    return <BasicTemplate title={Titles.changepassword} main={main} />;
  }
}

export default withRouter(Guard(ChangePasswordPage));
