import React, { Component } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import { isLnln } from "../../../Utils/checkLnln";

import GraphQLClient from "../../../Utils/GraphQLClient";
import { GetMenuMaster } from "../../../Utils/GraphQLQueries";

import Heading1 from "../../atoms/headings/Heading1";
import FontP from "../../atoms/fonts/FontP";
import Panel from "../../atoms/panels/Panel";

import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import TopButton from "../../molecules/TopButton";

import { japaneseList } from "../../../Resources/japaneseList";

import Img01Path from "../../../images/carada/icon/icon-yen.svg";
import Img02Path from "../../../images/carada/icon/icon-hatena.svg";
import Img03Path from "../../../images/carada/icon/icon-otoiawase.svg";
import Img04Path from "../../../images/carada/icon/icon-pill.svg";
import Img01PathLnln from "../../../images/lnln/icon/icon-yen.svg";
import Img02PathLnln from "../../../images/lnln/icon/icon-hatena.svg";
import Img03PathLnln from "../../../images/lnln/icon/icon-otoiawase.svg";
import Img04PathLnln from "../../../images/lnln/icon/icon-pill.svg";
import { MEDICAL_MENU, MEDICAL_METHOD } from "../../../Utils/Constant";

const Resource = japaneseList.organisms.onlineChatFinishHospital;
const Img01 = isLnln() ? Img01PathLnln : Img01Path;
const Img02 = isLnln() ? Img02PathLnln : Img02Path;
const Img03 = isLnln() ? Img03PathLnln : Img03Path;
const Img04 = isLnln() ? Img04PathLnln : Img04Path;

const CustomPanel = styled(Panel)`
  margin-bottom: 15px;
`;

const Img = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;

const TitleFontP = styled(FontP)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const TextFontP = styled(FontP)`
  padding-bottom: 20px;
  border-bottom: solid 1px #dddddd;
`;

const TextLastFontP = styled(FontP)`
  padding-bottom: 20px;
`;

const MailFontP = styled(FontP)`
  margin-top: 5px;
`;

const ContactUs = styled.div`
  padding-bottom: 40px;
  text-align: center;
`;

const Appointment = styled.div`
  padding-bottom: 20px;
  text-align: center;
  border-bottom: solid 1px #dddddd;
`;

class OnlineChatFinishHospital extends Component {
  state = {
    menuMaster: null,
  };

  static defaultProps = {
    handleClickContact: function () {},
  };

  async componentDidMount() {
    const params = {
      medicalMenu: this.props.medicalMenu,
      medicalMethod: this.props.medicalMethod,
    };

    const { data } = await GraphQLClient.query({
      query: GetMenuMaster,
      variables: params,
    });
    this.setState({
      menuMaster: data.menuMaster,
    });
  }

  render() {
    const { handleClickContact, handleClickAppointmentDetail } = this.props;
    const menuMaster = this.state.menuMaster;

    return (
      menuMaster && (
        <React.Fragment>
          <Heading1>{Resource.h1}</Heading1>
          <CustomPanel align="center" padding="l" background="therd">
            <FontP weight="bold">
              {menuMaster.endVideoChatContent[0].finishPageTitle}
            </FontP>
          </CustomPanel>
          <Panel padding="v0hs">
            {menuMaster.endVideoChatContent[0].medicineDelivery &&
              menuMaster.medicalMenu === MEDICAL_MENU.EXAMINATION &&
              menuMaster.medicalMethod === MEDICAL_METHOD.ONLINE && (
                <Panel padding="bm">
                  <TitleFontP size="l" weight="bold" color="therd">
                    <Img src={Img04} />
                    {
                      menuMaster.endVideoChatContent[0].medicineDelivery[0]
                        .title
                    }
                  </TitleFontP>
                  <div>
                    {parse(
                      DOMPurify.sanitize(
                        menuMaster.endVideoChatContent[0].medicineDelivery[0]
                          .body
                      )
                    )}
                  </div>
                  <Appointment>
                    <ButtonFullWidth
                      id="video-complete-reserve-detail-button"
                      onClick={handleClickAppointmentDetail}
                    >
                      {Resource.Appointment.button}
                    </ButtonFullWidth>
                    <MailFontP size="sss" color="gray">
                      {Resource.Appointment.text}
                    </MailFontP>
                  </Appointment>
                </Panel>
              )}
            {menuMaster.endVideoChatContent[0].confirmPrice && (
              <Panel padding="bm">
                <TitleFontP size="l" weight="bold" color="therd">
                  <Img src={Img01} />
                  {menuMaster.endVideoChatContent[0].confirmPrice[0].title}
                </TitleFontP>
                <TextFontP>
                  {menuMaster.endVideoChatContent[0].confirmPrice[0].body}
                </TextFontP>
              </Panel>
            )}
            {menuMaster.endVideoChatContent[0].inThisCase && (
              <Panel padding="bm">
                <TitleFontP size="l" weight="bold" color="therd">
                  <Img src={Img02} />
                  {menuMaster.endVideoChatContent[0].inThisCase[0].title}
                </TitleFontP>
                <TextFontP>
                  {menuMaster.endVideoChatContent[0].inThisCase[0].body}
                </TextFontP>
              </Panel>
            )}
            <Panel padding="bm">
              <TitleFontP size="l" weight="bold" color="therd">
                <Img src={Img03} />
                {menuMaster.endVideoChatContent[0].contactUs[0].title}
              </TitleFontP>
              <TextLastFontP>
                {menuMaster.endVideoChatContent[0].contactUs[0].body}
              </TextLastFontP>
            </Panel>
            <ContactUs>
              <ButtonFullWidth
                id="video-complete-inquiry-button"
                onClick={handleClickContact}
              >
                {Resource.contactUs.button}
              </ButtonFullWidth>
              <MailFontP size="sss" color="gray">
                {Resource.contactUs.text}
              </MailFontP>
            </ContactUs>
          </Panel>
          <TopButton>TOP</TopButton>
        </React.Fragment>
      )
    );
  }
}

export default OnlineChatFinishHospital;
