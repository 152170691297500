import React, { Component } from "react";
import { getDaysInMonth, getYear, getMonth, getDate } from "date-fns";
import styled from "styled-components";

import Select from "../../atoms/forms/Select";

import { japaneseList } from "../../../Resources/japaneseList";

const Root = styled.div`
  & > * {
    margin-right: 10px;
  }
`;

export default class SelectBirthday extends Component {
  state = {
    year: "",
    month: "",
    date: "",
  };

  constructor(props) {
    super(props);
    const birthdate = props.value;
    const year = new Date().getFullYear();
    const years = this.createArray(year - 120, year);
    this.years = years;

    if (birthdate) {
      this.state = {
        year: getYear(birthdate),
        month: getMonth(birthdate) + 1,
        date: getDate(birthdate),
      };
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const { value } = nextProps;
      this.updateDate(value);
    }
  }

  updateDate = (dateTime) => {
    this.setState({
      year: getYear(dateTime),
      month: getMonth(dateTime) + 1,
      date: getDate(dateTime),
    });
  };

  createArray(from, to) {
    const arr = [];
    for (let index = from; index <= to; index++) {
      arr.push(index);
    }
    return arr;
  }

  handleChange = (name, event) => {
    this.setState({ [name]: event.target.value }, this.handleDateChanged);
  };

  handleDateChanged = () => {
    if (!this.checkDate()) return false;
    if (this.props.onDateChanged) {
      this.props.onDateChanged(this.getDate());
    }
  };

  checkDate = () => {
    let { date: value, month: birthMonth, year: birthYear } = this.state;
    if (birthYear === "") return false;
    if (birthMonth === "") return false;
    if (value === "") return false;
    return true;
  };

  getDate() {
    let { date: value, month: birthMonth, year: birthYear } = this.state;
    return new Date(Number(birthYear), Number(birthMonth) - 1, Number(value));
  }

  render() {
    const renderResource = japaneseList.molecules.BirthdateSelectForm.render;
    const { date, month, year } = this.state;
    return (
      <Root>
        <Select
          value={year}
          onChange={(e) => this.handleChange("year", e)}
          name="year"
        >
          <option value="">----</option>
          {this.years.map((year) => (
            <option value={year} key={year}>
              {year + renderResource.Root.Select.option.j001}
            </option>
          ))}
        </Select>
        <Select
          value={month}
          onChange={(e) => this.handleChange("month", e)}
          name="month"
        >
          <option value="">--</option>
          {this.createArray(1, 12).map((month) => (
            <option value={month} key={month}>
              {month + renderResource.Root.Select.option.j002}
            </option>
          ))}
        </Select>
        <Select
          value={date}
          onChange={(e) => this.handleChange("date", e)}
          name="date"
        >
          <option value="">--</option>
          {year &&
            month &&
            this.createArray(
              1,
              getDaysInMonth(new Date(Number(year), Number(month) - 1))
            ).map((day) => (
              <option value={day} key={day}>
                {day + renderResource.Root.Select.option.j003}
              </option>
            ))}
        </Select>
      </Root>
    );
  }
}
