import qs from "querystring";
import Cookies from "js-cookie";
const { from } = qs.parse(window.location.search.substr(1));

export function isLnln() {
  //  return true;
  if (from === "lnln") return true;
  if (document.domain.match(/lnln/)) return true;
  return false;
}

export function setCookieLnlnApp() {
  // called from initializeLnlnProcess@LnlnProcess.js
  Cookies.set("from-lnln-app", "true");
}

export function isLnlnApp() {
  if (Cookies.get("from-lnln-app") === "true") return true;
  return false;
}
