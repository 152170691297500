import styled from "styled-components";
import FontBase from "./FontBase";

const FontPAccent = styled(FontBase)`
  color: ${p => p.theme.font.color.accent};
  font-weight: bold;
  text-align: center;
`;

export default FontPAccent;
