import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";

const InputFormHeaderNoIcon = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant3};
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;

const BwCarefulBody = styled.div`
  display: inline-block;
  line-height: 21px;
  height: 21px;
  font-size: 14px;
  margin: 20px 14px;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 15px;
`;

export const EnterpriseCompleteContent = withRouter((props) => {
  const renderResource = japaneseList.pages.EnterpriseCompletePage.render;
  const goToHospitalSearchPage = () => {
    props.history.push("/search?type=hospital");
  };
  return (
    <React.Fragment>
      <InputFormHeaderNoIcon>
        {renderResource.Content.j001}
      </InputFormHeaderNoIcon>
      <BwCarefulBody>{renderResource.Content.j002}</BwCarefulBody>
      <ButtonContainer>
        <ButtonFullWidth
          id="hospitals-search-link"
          onClick={goToHospitalSearchPage}
        >
          {renderResource.Button.j001}
        </ButtonFullWidth>
      </ButtonContainer>
    </React.Fragment>
  );
});
