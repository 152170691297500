import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import { device } from "../../device";
import { ChattingPromptInformationArea } from "./ChattingPromptInformationArea";
import InfoMessage from "../../molecules/InfoMessage";
import Typography4 from "../../atoms/fonts/Typography4";
import LoadingBall from "../../atoms/LoadingBall";

import { japaneseList } from "../../../Resources/japaneseList";

const styles = (theme) => ({
  icon: {
    width: "32px",
    height: "32px",
    marginRight: "20px",
  },
});

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`;

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #000;
  @media screen and (orientation: landscape) and ${device.mobile} {
    width: 410px;
    height: 220px;
    margin: auto;
    margin-top: 15px;
  }
`;
const LoadingBallWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -131px;
  margin-left: -125px;
`;
const Text = styled.p`
  width: 250px;
  height: 48px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: block;
`;
const SmallText = styled.p`
  width: 250px;
  height: 48px;
  text-align: center;
  color: #999999;
  font-size: 12px;
  display: block;
`;

const LocalMedia = styled.div`
  width: 100px;
  height: 75px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  & video {
    width: 100%;
  }
  @media screen and (orientation: landscape) and ${device.mobile} {
    width: 68px;
    height: 51px;
  }
  @media screen and ${device.tb} {
    width: 200px;
    height: 150px;
  }
`;
const RemoteMedia = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  @media screen and ${device.tb} {
    flex-direction: column;
  }
  & video {
    display: block;
    flex: 1;
    object-fit: cover;
    @media screen and ${device.tb} {
      width: 100%;
    }
  }
`;
const WarningMessage = styled(InfoMessage)`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
  border-radius: 5px;
  @media screen and (orientation: landscape) and ${device.mobile} {
    margin-top: 10px;
  }
`;

const Typography = styled(Typography4)`
  flex: 1;
  line-height: 1.8em;
  font-weight: bold;
`;
class OnlineChat extends Component {
  // 表示 rendering
  //-------------------------------------------------
  render() {
    const renderResource = japaneseList.organisms.onlineChat.OnlineChat;
    const { classes, isDisplayLocalLowNetwork, isDisplayLocalLowAudio } =
      this.props;
    return (
      <Container>
        <VideoWrapper>
          <LoadingBallWrapper>
            <Text>
              <div>{renderResource.WaitText.j001}</div>
              <div>{renderResource.WaitText.j002}</div>
            </Text>
            <LoadingBall />
            <SmallText>
              <div>{renderResource.WaitText.j003}</div>
              <div>{renderResource.WaitText.j004}</div>
            </SmallText>
          </LoadingBallWrapper>
          <LocalMedia id="local-media" />
          <RemoteMedia id="remote-media" />
          <ChattingPromptInformationArea
            isDisplayLocalLowNetwork={isDisplayLocalLowNetwork}
            isDisplayLocalLowAudio={isDisplayLocalLowAudio}
          />
        </VideoWrapper>
        <WarningMessage>
          <ErrorOutline color={"primary"} className={classes.icon} />
          <Typography>{renderResource.WarningMessage.j001}</Typography>
        </WarningMessage>
      </Container>
    );
  }
}

export default withStyles(styles)(OnlineChat);
