import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import Typography1 from "../../atoms/fonts/Typography1";
import { japaneseList } from "../../../Resources/japaneseList";

const Row = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};

  :nth-child(even) {
    padding-top: ${(p) => p.theme.space.medium};
  }

  :nth-child(odd) {
    padding-top: ${(p) => p.theme.space.short};
  }
`;

const Typography = styled(Typography1)`
  font-weight: normal;
`;

class PaymentCardInfo extends Component {
  render() {
    const renderResource =
      japaneseList.organisms.payment.PaymentCardInfo.render;
    const { card } = this.props;

    return (
      <React.Fragment>
        <Row>
          <Typography>{renderResource.Fragment.Row.Typography.j001}</Typography>
        </Row>
        <Row>
          <Typography>{card.cardNo}</Typography>
        </Row>
        <Row>
          <Typography>{renderResource.Fragment.Row.Typography.j002}</Typography>
        </Row>
        <Row>
          <Typography>{card.expire}</Typography>
        </Row>
        <Row>
          <ButtonFullWidth
            onClick={() => {
              this.props.history.push(
                "/my-page/payment/card-info/update/" + card.cardSeq
              );
            }}
          >
            {renderResource.Fragment.FullWidthButton.j001}
          </ButtonFullWidth>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(PaymentCardInfo);
