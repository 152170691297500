import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import { sha256 } from "./Utility";
import { japaneseList } from "../Resources/japaneseList";
import { isLnln } from "./checkLnln";
import TagManager from "react-gtm-module";

const containerId = isLnln()
  ? process.env.REACT_APP_GTM_CONTAINER_ID_LNLN
  : process.env.REACT_APP_GTM_CONTAINER_ID;

TagManager.initialize({
  gtmId: containerId,
});
class GoogleTagManager extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
    this.logCustomDimensions();
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
      this.logCustomDimensions();
    }
  }

  logPageChange = (pathname, search = "") => {
    const page = pathname + search;
    const { location } = window;
    const pageDataLayer = {
      dataLayer: {
        event: "pageView",
        page: page,
        location: `${location.origin}${page}`,
      },
    };
    TagManager.dataLayer(pageDataLayer);
  };

  logCustomDimensions = async () => {
    const logCustomDimensionsResource =
      japaneseList.Utils.GoogleTagManager.logCustomDimensions;
    const user = await Auth.currentUserPoolUser().catch((err) => {
      return null;
    });
    let loggedIn = !!user;

    const dimensionArgs = {
      dataLayer: {
        event: "customDimension",
        dimension1: loggedIn
          ? logCustomDimensionsResource.j001
          : logCustomDimensionsResource.j002,
        dimension2: loggedIn
          ? logCustomDimensionsResource.j003
          : logCustomDimensionsResource.j004,
        dimension3: logCustomDimensionsResource.j005,
        dimension4: logCustomDimensionsResource.j006,
      },
    };
    TagManager.dataLayer(dimensionArgs);

    if (loggedIn) {
      const uid = await sha256(user.username);
      const dimensionLoggedInArgs = {
        dataLayer: {
          event: "customDimension",
          dimension8: uid,
          uid: uid,
        },
      };
      TagManager.dataLayer(dimensionLoggedInArgs);
    }
  };

  render() {
    return null;
  }
}

const RouteTracker = () => <Route component={GoogleTagManager} />;

export default {
  GoogleTagManager,
  RouteTracker,
};
