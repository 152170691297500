import React from "react";
import styled, { keyframes } from "styled-components";

const scale = keyframes`
  0%,
  80%{
    opacity:1
  }
  45%{
    opacity:.7
  }
`;

const Container = styled.div`
  width: 54px;
  height: 18px;
  margin: 60px auto;
  div {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    background-color: ${p => p.theme.color.primaryVariant};
  }
  div:nth-child(1) {
    animation: ${scale} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(2) {
    animation: ${scale} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(3) {
    animation: ${scale} 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
`;

const LoadingBall = classNmae => (
  <Container className={classNmae}>
    <div />
    <div />
    <div />
  </Container>
);

export default LoadingBall;
