import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";

const styles = () => ({
  badge: {
    top: -5,
    right: -5,
    width: 8,
    height: 8,
    backgroundColor: "#ff4d4d"
  },
  icon: {
    width: '90%',
    height: '90%',
    margin: 'auto'
  }
});


const Menu = styled.div`
  position: fixed;
  bottom: 14px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 52px;
  height: 52px;
  margin-left: -12px;
  padding: 6px 0;
  margin-right: -10px;
  box-sizing: border-box;
  text-align: center;
  border: none;
  border-radius: 50%;
  background-color: ${p =>
    p.open ? p.theme.color.secondary : p.theme.color.primary};
  color: ${p => p.theme.color.fontInvert};
`;

class LnlnMenuIcon extends Component {
  state = {
    open: false
  };

  onClick = () => {
    this.state.open ? this.props.closeMenu() : this.props.openMenu();
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { classes, showBadge, open } = this.props;
    return (
      <Menu open={open}>
        {open ? (
          <CloseIcon className={classes.icon} />
        ) : showBadge ? (
          <Badge
            variant="dot"
            invisible={!showBadge}
            classes={{ badge: classes.badge }}
          >
            <MenuIcon className={classes.icon} />
          </Badge>
        ) : (
          <MenuIcon className={classes.icon} />
        )}
      </Menu>
    );
  }
}

export default withStyles(styles)(LnlnMenuIcon);
