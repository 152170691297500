import React, { Component } from "react";
import HeadingLeftDecoration from "../../atoms/headings/HeadingLeftDecoration";
import FontP from "../../atoms/fonts/FontP";

import { japaneseList } from "../../../Resources/japaneseList";

class MenuTitle extends Component {
  render() {
    const renderResource = japaneseList.molecules.MenuTitle.render;
    const { menu, hospital } = this.props;
    const doctor = hospital.doctors.find(
      doctor => doctor.doctorId === menu.doctorId
    );
    return (
      <React.Fragment>
        <HeadingLeftDecoration>{menu.displayName}</HeadingLeftDecoration>
        <FontP size="s" weight="bold">
          {doctor && doctor.doctorName
            ? doctor.doctorName + renderResource.div.Container.Title.Text.j001
            : ""}
        </FontP>
      </React.Fragment>
    );
  }
}

export default MenuTitle;
