import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;
  padding-bottom: 16px;
  padding-left: 16px;
  color: ${(p) => p.theme.color.secondary};
  line-height: 20px;
  font-size: 16px;
  border-bottom: 0.5px solid #cccccc;
`;

const CardContent = styled.div`
  color: #393939;
`;

export default ({ children }) => (
  <Card>
    <CardContent>{children}</CardContent>
    <ChevronRightIcon />
  </Card>
);
