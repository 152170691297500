import styled from "styled-components";

export default styled.div`
  background-color: ${p => p.theme.icon.course.bg};
  color: ${p => p.theme.icon.course.color};
  border-radius: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: center;
  padding: 4px 9px;
  display: inline;
  margin-right: 6px;
`;
