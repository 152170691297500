import React, {
  createContext,
  useState,
  useContext,
  ComponentType,
  useEffect,
  useRef,
} from "react";

interface IPollingContext {
  addPollingId: (key: string, id: intervalId) => void;
  stopAllPolling: () => void;
  stopPollingManager: () => void;
}
interface IPollingIds {
  [key: string]: intervalId;
}
type intervalId = number | NodeJS.Timer;

const pollingContext: IPollingContext = {
  addPollingId: () => {},
  stopAllPolling: () => {},
  stopPollingManager: () => {},
};

export const PollingContext = createContext(pollingContext);
export const usePollingContext = () => useContext(PollingContext);

export const PollingProvider: React.FC = ({ children }) => {
  const [pollingIds, setPollingIds] = useState<IPollingIds>({});
  // setInterval内でstateを参照するためにuseRefを使う
  const pollingIdsRef = useRef(pollingIds);

  // pollingIdsが更新されたらrefも更新する
  useEffect(() => {
    pollingIdsRef.current = pollingIds;
  }, [pollingIds]);

  const addPollingId = (key: string, id: intervalId) => {
    setPollingIds((prev) => {
      return { ...prev, [key]: id };
    });
  };

  // 以下の理由からidをremoveする処理は不要
  // 1. addPollingId時に古いIdが更新される
  // 2. clearInterval(id) に渡すidは存在しなくてもエラーにはならない

  const stopAllPolling = () => {
    // setIntervalから呼ばれる可能性があるため、refを参照する
    Object.values(pollingIdsRef.current).forEach((id) => {
      clearInterval(id);
    });
  };

  // 障害対応のため、このような実装になっている
  // 結合度の観点からContextではstopPolling(key)のように、指定されたkeyに対してclearIntervalを実行するだけの関数だけを持つべき
  const stopPollingManager = () => {
    clearInterval(pollingIdsRef.current["PollingManager"]);
  };

  const contextValue: IPollingContext = {
    addPollingId,
    stopAllPolling,
    stopPollingManager,
  };

  return (
    <PollingContext.Provider value={contextValue}>
      {children}
    </PollingContext.Provider>
  );
};
export const PollingConsumer = PollingContext.Consumer;

export const withPolling = <Props extends IPollingContext>(
  Component: ComponentType<Props>
) => {
  return (props: Props) => (
    <PollingConsumer>
      {(pollingContext) => (
        <Component {...props} pollingContext={pollingContext} />
      )}
    </PollingConsumer>
  );
};
