import React, { Component, Fragment } from "react";
import Heading1 from "../atoms/headings/Heading1";
import ButtonOutlined from "../atoms/buttons/ButtonOutlined";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";

const InquiryIntro = styled.div`
  padding: 20px 14px;
  font-weight: 300;
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
`;

const InquiryNotify = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant4};
  margin: 0 14px 30px;
`;

const InquiryNotifyTitle = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: ${(p) => p.theme.color.primary};
`;

const InquiryNotifyContent = styled.div`
  padding: 0 14px 20px;
  font-weight: 300;
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
`;

const InquiryInformation = styled.div`
  padding: 0 14px 10px;
`;

const InquiryInformationTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  font-size: 16px;
  color: ${(p) => p.theme.color.font};
`;

const InquiryInformationContent = styled.div`
  font-weight: 300;
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  padding-top: 4px;
  padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
  padding: 0 30px;
  display: grid;
`;

const EmailButton = styled.a`
  height: 50px;
  padding: 1px 5px;
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  background-color: ${(p) => p.theme.button.background.color.default};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.color.fontInvert};
  text-decoration: none;
`;

const BackButton = styled(ButtonOutlined)`
  height: 50px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`;

const NoteButton = styled.div`
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${(p) => p.theme.color.primary};
  text-align: center;
  padding-bottom: 10px;
`;

class InquiryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isEnvironment:
        window.location.hash && window.location.hash === "#environment",
    };
  }

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.setState({
          user: user,
        });
      })
      .catch((err) => {});
  }

  generateMessage = () => {
    const generateMessageResource =
      japaneseList.organisms.InquiryList.generateMessage;
    const user = this.state.user;
    let name = "";
    const newLineCode = "%0D%0A";
    name =
      user !== null
        ? generateMessageResource.j001 + user.username + newLineCode
        : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:inquiry@caradamedica.co.jp?subject=" +
      generateMessageResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      generateMessageResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      generateMessageResource.j004 +
      newLineCode +
      generateMessageResource.j005 +
      newLineCode +
      "-------------------------" +
      newLineCode +
      generateMessageResource.j006 +
      newLineCode +
      newLineCode +
      generateMessageResource.j007 +
      newLineCode +
      newLineCode +
      generateMessageResource.j008 +
      newLineCode +
      newLineCode +
      generateMessageResource.j009 +
      newLineCode +
      newLineCode +
      generateMessageResource.j010 +
      newLineCode +
      newLineCode;

    return msg;
  };

  render() {
    const renderResource = japaneseList.organisms.InquiryList.render;
    const msg = this.generateMessage();

    return (
      <Fragment>
        <div>
          <Heading1>{renderResource.Fragment.div.PrimaryHeading.j001}</Heading1>
          <InquiryIntro>
            {renderResource.Fragment.div.InquiryIntro.j001}
          </InquiryIntro>
          <InquiryNotify>
            <InquiryNotifyTitle>
              {renderResource.Fragment.div.InquiryNotify.j001}
            </InquiryNotifyTitle>
            <InquiryNotifyContent>
              {renderResource.Fragment.div.InquiryNotify.j002}
            </InquiryNotifyContent>
          </InquiryNotify>
          <InquiryInformation>
            <InquiryInformationTitle>
              {
                renderResource.Fragment.div.InquiryInformation.ReceptionTime
                  .j001
              }
            </InquiryInformationTitle>
            <InquiryInformationContent>
              {
                renderResource.Fragment.div.InquiryInformation.ReceptionTime
                  .j002
              }
            </InquiryInformationContent>
            <InquiryInformationTitle>
              {renderResource.Fragment.div.InquiryInformation.ResponseTime.j001}
            </InquiryInformationTitle>
            <InquiryInformationContent>
              {renderResource.Fragment.div.InquiryInformation.ResponseTime.j002}
              <br />
              {renderResource.Fragment.div.InquiryInformation.ResponseTime.j003}
              <br />
              {renderResource.Fragment.div.InquiryInformation.ResponseTime.j004}
            </InquiryInformationContent>
            <InquiryInformationTitle>
              {
                renderResource.Fragment.div.InquiryInformation
                  .AcquisitionInformation.j001
              }
            </InquiryInformationTitle>
            <InquiryInformationContent>
              {
                renderResource.Fragment.div.InquiryInformation
                  .AcquisitionInformation.j002
              }
            </InquiryInformationContent>
            <InquiryInformationTitle>
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .title
              }
            </InquiryInformationTitle>
            <InquiryInformationContent>
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j001
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j002
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j003
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j004
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j005
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j006
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j007
              }
              <br />
              {
                renderResource.Fragment.div.InquiryInformation.NotesOnInquiries
                  .content.j008
              }
            </InquiryInformationContent>
          </InquiryInformation>
        </div>

        <ButtonContainer>
          <EmailButton href={msg} id="inquiry-email-button">
            {renderResource.Fragment.div.InquiryButton.j001}
          </EmailButton>
          <NoteButton>
            {renderResource.Fragment.div.InquiryButton.j002}
          </NoteButton>
          <BackButton
            onClick={() => window.history.back()}
            id="inquiry-back-button"
          >
            {renderResource.Fragment.div.InquiryButton.j003}
          </BackButton>
        </ButtonContainer>
      </Fragment>
    );
  }
}

export default InquiryList;
