import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Heading2 from "../../atoms/headings/Heading2";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";

const ButtonContainer = styled.div`
  padding: 20px 30px;
`;

const Button = styled(ButtonFullWidth)`
  height: 50px;
`;

export const EnterpriseAuthenticationContent = withRouter((props) => {
  const handleSignUpButtonClick = () => {
    props.history.push({
      pathname: "/auth/signup",
      state: {
        from: "enterprise",
      },
    });
  };

  const handleLoginButtonClick = () => {
    props.history.push({
      pathname: "/login",
      state: {
        from: "enterprise",
      },
    });
  };

  const renderResource =
    japaneseList.organisms.enterprise.EnterpriseAuthenticationContent;

  return (
    <React.Fragment>
      <Heading2>{renderResource.render.j001}</Heading2>
      <ButtonContainer>
        <Button id="sign-up-page-link" onClick={handleSignUpButtonClick}>
          {renderResource.render.Buttons.j001}
        </Button>
      </ButtonContainer>
      <Heading2>{renderResource.render.j002}</Heading2>
      <ButtonContainer>
        <Button id="sign-in-page-link" onClick={handleLoginButtonClick}>
          {renderResource.render.Buttons.j002}
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
});
