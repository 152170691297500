const serviceLogLib = {
  eventType: {
    login: 1,
    logout: 2,
    startVideoChat: 3,
    endVideoChat: 4,
    senEmail: 5,
    sedSMS: 6,
    executionSettlement: 7,
    executionDelivery: 8,
    createAppointment: 9,
    cancelAppointment: 10,
    updateProfile: 11,
  },
  executorType: {
    patient: 1,
    doctor: 2,
    system: 3,
  },
  resourceType: {
    none: "none",
    appointment: "appointment",
    slot: "slot",
    slotRepetition: "slotRepetition",
  },
};

module.exports.serviceLogLib = serviceLogLib;
