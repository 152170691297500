import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import MyPage from "../organisms/MyPage";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListAppointmentsByStatus, Account } from "../../Utils/GraphQLQueries";
import { Hub } from "aws-amplify";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";
import Guard from "../../Utils/Guard";
import { withRouter } from "react-router-dom";

class MyPageNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      user: null,
    };
  }
  getAppoitmentList = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsByStatus,
      variables: {
        status: [
          "examCompleted",
          "paymentCompleted",
          "canceledDoctor",
          "canceledPatientPreviousDay",
          "canceledPatientFromToday",
          "canceledBeforeApproving",
        ],
      },
    });
    if (errors) return;
    this.setState({
      appointments: data.appointments.items,
    });
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "MyPage"
    );
  };

  async getUser() {
    const { data, error } = await GraphQLClient.query({ query: Account });
    if (error || data === null) return;
    this.setState({
      user: data.account,
    });
  }

  async componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.status === "succeed"
    ) {
      this.snackBar(japaneseList.pages.MyPageNew.componentDidMount.j001);
    }
    this.getAppoitmentList();
    this.getUser();
  }

  render() {
    return (
      <AuthenticatedTemplate
        title={Titles.mypage}
        main={
          <MyPage
            appointments={this.state.appointments}
            user={this.state.user}
          />
        }
      />
    );
  }
}

export default withRouter(Guard(MyPageNew));
