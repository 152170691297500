import React from "react";
import Card from "../../molecules/Card";
import LinkToNoUnder from "../../atoms/links/LinkToNoUnder";
import Typography3 from "../../atoms/fonts/Typography3";
import styled from "styled-components";
import SiteContext from "../../../SiteContext";

const Content = styled(Typography3)`
  display: flex;
  align-items: center;
`;

export default ({ children, ...props }) => {
  return (
    <SiteContext.Consumer>
      {(value) => {
        return (
          <LinkToNoUnder
            as={props.toExternal ? "a" : undefined}
            to={props.to}
            href={
              value.ui === "lnln"
                ? `${props.toExternal}&lnln_ob=1`
                : props.toExternal
            }
            id={props.id}
          >
            <Card>
              <Content>{children}</Content>
            </Card>
          </LinkToNoUnder>
        );
      }}
    </SiteContext.Consumer>
  );
};
