import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash";

import { japaneseList } from "../../../Resources/japaneseList";
import Heading1 from "../../atoms/headings/Heading1";
import IrregularAppointment from "../../molecules/account/IrregularAppointment";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";

const WrapContent = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};
`;

const SendMailActionWrapper = styled.div`
  padding: 20px 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const styles = () => ({
  iconSVG: {
    border: "1px dashed #000000",
    fill: "#e63c14",
  },
  errorTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    "font-weight": "600",
    "font-size": "18px",
    "line-height": "32px",
    padding: "24px 12px",
  },
  errorContent: {
    padding: "0 12px 40px 12px",
    "font-size": "14px",
    "line-height": "26px",
  },
  backBtn: {
    margin: 0,
    width: "100%",
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
  typoTitle: {
    padding: "15px 0",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  typoContent: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "21px",
    "white-space": "pre-line",
  },
  sendMailBtn: {
    height: 50,
  },
  helperText: {
    fontWeight: 300,
    fontSize: 12,
    lineHeight: "20px",
  },
});

const CancellationErrorMessage =
  japaneseList.organisms.account.CancellationError;

const CancellationError = (props) => {
  const { classes, errorType } = props;
  const { state } = useLocation();
  const [user, setUser] = useState();

  useEffect(() => {
    Auth.currentUserPoolUser().then((data) => setUser(data));
  }, []);

  const generateMessage = () => {
    const newLineCode = "%0D%0A";
    const name =
      user !== null
        ? CancellationErrorMessage.contact.j001 + user.username + newLineCode
        : "";
    const userAgent = window.navigator.userAgent;
    return (
      "mailto:inquiry@caradamedica.co.jp?subject=" +
      CancellationErrorMessage.contact.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      CancellationErrorMessage.contact.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      CancellationErrorMessage.contact.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode
    );
  };

  const handleSendMail = (e) => {
    e.preventDefault();
    window.open(generateMessage());
  };

  const renderErrorType = (error) => {
    let body = null;

    switch (true) {
      case error === "E127":
        body = (
          <>
            <Typography className={classes.typoTitle}>
              {CancellationErrorMessage.irregularError.Title.j001}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j001}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j002}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j003}
            </Typography>
          </>
        );
        return renderIrregularAppointment(body);
      case error === "E128":
        body = (
          <>
            <Typography className={classes.typoTitle}>
              {CancellationErrorMessage.irregularError.Title.j002}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j004}
            </Typography>
          </>
        );
        return renderIrregularAppointment(body);
      case error === "E129":
        body = (
          <>
            <Typography className={classes.typoTitle}>
              {CancellationErrorMessage.irregularError.Title.j003}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j005}
            </Typography>
          </>
        );
        return renderIrregularAppointment(body);
      case error === "E130":
        body = (
          <>
            <Typography className={classes.typoTitle}>
              {CancellationErrorMessage.irregularError.Title.j004}
            </Typography>
            <Typography className={classes.typoContent}>
              {CancellationErrorMessage.irregularError.Content.j006}
            </Typography>
            <SendMailActionWrapper>
              <FullWidthButton
                onClick={handleSendMail}
                className={classes.sendMailBtn}
                id="cancel-member-irregular-inquiry-button"
              >
                {CancellationErrorMessage.irregularError.sendMailBtn}
              </FullWidthButton>
              <Typography className={classes.helperText} color="primary">
                {CancellationErrorMessage.irregularError.helperText}
              </Typography>
            </SendMailActionWrapper>
          </>
        );
        return renderIrregularAppointment(body);
      default:
        break;
    }
  };

  const renderIrregularAppointment = (content) => {
    const appointments = state?.appointments;
    return (
      <React.Fragment>
        <Heading1>{CancellationErrorMessage.Header.j001}</Heading1>
        <WrapContent>{content}</WrapContent>
        {!isEmpty(appointments) &&
          (errorType === "E128" ? (
            <IrregularAppointment appointment={appointments[0]} />
          ) : (
            appointments.map((appointment, index) => {
              return appointments.length > 1 ? (
                <IrregularAppointment
                  appointment={appointment}
                  numberOrder={index}
                  hospitalURL={
                    errorType === "E129" && `hospital/${appointment.hospitalId}`
                  }
                />
              ) : (
                <IrregularAppointment
                  appointment={appointment}
                  hospitalURL={
                    errorType === "E129" && `hospital/${appointment.hospitalId}`
                  }
                />
              );
            })
          ))}
      </React.Fragment>
    );
  };

  return <React.Fragment>{renderErrorType(errorType)}</React.Fragment>;
};

export default withStyles(styles)(CancellationError);
