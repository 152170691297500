import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import Typography2 from "../../atoms/fonts/Typography2";
import Heading1 from "../../atoms/headings/Heading1";
import PaymentCardInfo from "../../organisms/payment/PaymentCardInfo";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import Guard from "../../../Utils/Guard";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import ApiWrapper from "../../../Utils/ApiWrapper";

const Row = styled.div`
  padding: ${(p) => p.theme.space.normal};
  text-align: center;

  :last-child {
    padding-top: 0;
  }
`;

class CardInfoPage extends Component {
  state = {
    cardList: [],
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.loadCardRegisterStatus();
  }

  loadCardRegisterStatus = async () => {
    const opt = {
      headers: {
        Authorization: this.props.user.signInUserSession.idToken.jwtToken,
      },
    };
    try {
      await ApiWrapper.Post("dev-payment", "/members/create", opt);
      const res = await ApiWrapper.Post("dev-payment", "/cards/list", opt);
      this.setState({
        cardList: res.cardList,
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const renderResource = japaneseList.pages.payment.CardInfoPage.render;
    const { cardList } = this.state;

    const notRegistered = (
      <React.Fragment>
        <Row>
          <Typography2>
            {renderResource.notRegistered.Fragment.Row.Typography2.j001}
          </Typography2>
        </Row>
        <Row>
          <FullWidthButton
            onClick={() => {
              this.props.history.push("/my-page/payment/card-info/update/");
            }}
          >
            {renderResource.notRegistered.Fragment.Row.FullWidthButton.j001}
          </FullWidthButton>
        </Row>
      </React.Fragment>
    );
    const main = (
      <React.Fragment>
        <Heading1>{renderResource.main.Fragment.PrimaryHeading.j001}</Heading1>
        {!cardList || !cardList.length
          ? notRegistered
          : cardList.map((card, index) => (
              <PaymentCardInfo card={card} key={index} />
            ))}
      </React.Fragment>
    );
    return (
      <AuthenticatedTemplate
        title={Titles.cardInfo}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(Guard(CardInfoPage));
