import React, { Component } from "react";
import { Auth } from "aws-amplify";
import OnlineChatFinishHospital from "../organisms/onlineChatFinish/OnlineChatFinishHospital";
import BasicTemplate from "../templates/BasicTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";
import * as Sentry from "@sentry/browser";
import Guard from "../../Utils/Guard";
import { withRouter } from "react-router-dom";

class OnlineChatFinishPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      medicalMenu: null,
      medicalMethod: null,
      menuMaster: null,
    };

    if (!this.props.location.state) {
      return this.redirectPage();
    }
  }

  redirectPage = () => {
    this.props.history.push(`/`);
    return {
      render() {
        return null;
      },
    };
  };

  handleClickContact = () => {
    const contactResource = japaneseList.pages.OnlineChatFinishPage.contact;
    const user = this.state.user;
    let name = "";
    const newLineCode = "%0D%0A";
    name =
      user !== null ? contactResource.j001 + user.username + newLineCode : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:inquiry@caradamedica.co.jp?subject=" +
      contactResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      contactResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      contactResource.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode;
    window.location.href = msg;
  };

  handleClickAppointmentDetail = () => {
    const { appointment } = this.props.location.state;
    this.props.history.push({
      pathname: `/appointment/${appointment.createTime}`,
    });
  };

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.setState({
          user: user,
        });
        Sentry.captureMessage(
          "video-chat-finish-page-transfer",
          Sentry.Severity.Log
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }

  render() {
    const Main = (
      <OnlineChatFinishHospital
        handleClickContact={this.handleClickContact}
        handleClickAppointmentDetail={this.handleClickAppointmentDetail}
        medicalMenu={this.props.location.state.medicalMenu}
        medicalMethod={this.props.location.state.medicalMethod}
      />
    );
    return <BasicTemplate title={Titles.onlineChatFinish} main={Main} />;
  }
}

export default withRouter(Guard(OnlineChatFinishPage));
