import styled from "styled-components";

interface IconArrowProps {
  theme: object;
  size?: string;
  color?: string;
}

export const IconArrow = styled.div<IconArrowProps>`
  &::after {
    content: "";
    width: ${(p) => p.size || "6px"};
    height: ${(p) => p.size || "6px"};
    border-right: 1px solid
      ${(p) =>
        p.color && p.theme.font.color[p.color]
          ? p.theme.font.color[p.color]
          : p.theme.font.color.link};
    border-top: 1px solid
      ${(p) =>
        p.color && p.theme.font.color[p.color]
          ? p.theme.font.color[p.color]
          : p.theme.font.color.link};
    display: inline-block;
    transform: rotate(45deg);
  }
`;
