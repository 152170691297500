import React, { useEffect } from "react";
import styled from "styled-components";

const ImageStyle = styled.img`
  border: 1px solid ${(p) => p.theme.thumbnailItem.borderColor};
  border-radius: 12px;
  width: 105px;
  height: 105px;
  background-color: ${(p) => p.theme.thumbnailItem.backgroundColor};
  object-fit: contain;
`;

const ThumbnailItem = React.memo((props) => {
  const { src, isViewOnly, canOpenNewTab, alt } = props;
  const objectURL = URL.createObjectURL(src);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  }, [objectURL]);

  return (
    <ImageStyle
      src={objectURL}
      style={
        !isViewOnly || canOpenNewTab
          ? { cursor: "pointer" }
          : { cursor: "default" }
      }
      alt={alt}
    />
  );
});

export default ThumbnailItem;
