import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Titles from "../../../Resources/Titles";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import Heading1 from "../../atoms/headings/Heading1";
import { EnterpriseTopContent } from "../../organisms/enterprise/EnterpriseTopContent";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import { Account } from "../../../Utils/GraphQLQueries";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { useAuthContext } from "../../../contexts/AuthContext";
import { withRouter } from "react-router-dom";

const Explanation = styled(FontP)`
  padding: 20px 14px;
  font-size: 14px;
`;

export const EnterpriseTopPage = withRouter((props) => {
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useAuthContext();
  const renderResource = japaneseList.pages.EnterpriseTopPage;

  useEffect(() => {
    if (authContext.isLoading) return;
    if (!authContext.isAuthenticated) {
      props.history.push("/enterprise/authenticate");
      return;
    }
    loadPatientData();
  }, [authContext.isLoading]);

  const loadPatientData = async () => {
    const { data } = await GraphQLClient.query({ query: Account });
    if (!(data.account.enterprise && data.account.enterprise.enterpriseId)) {
      props.history.push({
        pathname: "/enterprise/authorize",
        state: props.location.state,
      });
      return;
    }
    setPatient(data.account);
    setIsLoading(false);
  };

  const main = (
    <React.Fragment>
      <Heading1>{Titles.enterpriseTop}</Heading1>
      <Explanation>{renderResource.render.j001}</Explanation>
      <EnterpriseTopContent patient={patient} />
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate
      title={Titles.enterpriseTop}
      main={main}
      isLoading={isLoading}
      isNoIndex={true}
    />
  );
});
