import { Component } from "react";
import styled from "styled-components";
import Image from "../../atoms/others/Image";

const ImageWrap = styled.div`
  width: 100%;
  max-height: 250px;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class HospitalImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  onError = (_event) => {
    this.setState({
      show: false,
    });
  };

  render() {
    if (!this.state.show) return null;

    const { hospital } = this.props;
    const imageUrl = `/images/clinic/${hospital.hospitalId}.png`;

    return (
      <ImageWrap>
        <Image src={imageUrl} onError={this.onError} />
      </ImageWrap>
    );
  }
}

export default HospitalImage;
