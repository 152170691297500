import React from "react";
import { Redirect } from "react-router";
import BasicTemplate from "../../templates/BasicTemplate";
import Heading1 from "../../atoms/headings/Heading1";
import { EnterpriseConfirmForm } from "../../organisms/enterprise/EnterpriseConfirmForm";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNIN_STEPS,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";

const Heading1Custom = styled(Heading1)`
  height: "40px";
  font-size: "17px";
  font-weight: "bold";
`;

export const EnterpriseConfirmPage = (props) => {
  const renderResource = japaneseList.pages.EnterpriseConfirmPage.render;
  const isFlowSignUp =
    props.location.state && props.location.state.flow === "signup";
  const currentStep = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.CONFIRM]
    : ENTERPRISE_SIGNIN_STEPS[ENTERPRISE_REGISTER_STEP.CONFIRM];

  const registerSteps = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS
    : ENTERPRISE_SIGNIN_STEPS;

  if (
    !(
      props.location.state &&
      props.location.state.enterprise &&
      props.location.state.enterpriseAuthorizationCodes &&
      props.location.state.employeeNumber
    )
  ) {
    return <Redirect to="/enterprise/authorize" />;
  }

  const main = (
    <React.Fragment>
      <Heading1Custom>{renderResource.Title}</Heading1Custom>
      <EnterpriseStepperForm
        registerSteps={registerSteps}
        currentStep={currentStep}
      />
      <EnterpriseConfirmForm
        enterprise={props.location.state.enterprise}
        enterpriseAuthorizationCodes={
          props.location.state.enterpriseAuthorizationCodes
        }
        employeeNumber={props.location.state.employeeNumber}
        props={props}
        isAgreed={props.location.state.isAgreed}
      />
    </React.Fragment>
  );

  return (
    <BasicTemplate
      title={Titles.enterpriseConfirm}
      main={main}
      isNoIndex={true}
    />
  );
};
