import React, { Component } from "react";
import styled from "styled-components";

import FontP from "../atoms/fonts/FontP";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { japaneseList } from "../../Resources/japaneseList";

const styles = () => ({
  areaWrapper: {
    borderTop: "thin solid #ccc",
    backgroundColor: "#F7F7F7",
    marginBottom: "20px",
  },
  icon: {
    margin: 0,
    color: "#ccc",
  },
});

const MenuPanel = withStyles({
  root: {
    border: "none",
    borderBottom: "thin solid #ccc",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: "none",
  },
})(ExpansionPanel);

const MenuPanelSummary = withStyles({
  root: {
    minHeight: "auto",
    padding: "10px 8px 10px 15px",
    color: "#ccc",
    "&$expanded": {
      minHeight: "auto",
    },
    display: "flex",
    alignItems: "baseline",
  },
  content: {
    margin: "auto",
    padding: 0,
    "&$expanded": {
      margin: "auto",
    },
  },
  expandIcon: {
    width: "auto",
  },
  expanded: {},
})(ExpansionPanelSummary);

const AreaNameWrapper = styled.div`
  margin: 0px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const MenuPanelDetails = withStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0",
    paddingTop: "5px",
    backgroundColor: "#F7F7F7",
  },
}))(ExpansionPanelDetails);

class MenuAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { classes } = this.props;
    const handleChange = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    return (
      <React.Fragment>
        <MenuPanel
          square={true}
          expanded={this.state.isOpen}
          onChange={handleChange}
        >
          <MenuPanelSummary
            aria-controls="panel2d-content"
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <AreaNameWrapper>
              <FontP size="m" color="black">
                {
                  japaneseList.organisms.MedicalCareMenu.render.MenuAccordion
                    .j001
                }
              </FontP>
              <FontP size="ss" color="black">
                {
                  japaneseList.organisms.MedicalCareMenu.render.MenuAccordion
                    .j002
                }
              </FontP>
            </AreaNameWrapper>
          </MenuPanelSummary>
          <div className={classes.areaWrapper}>
            <MenuPanelDetails>{this.props.children}</MenuPanelDetails>
          </div>
        </MenuPanel>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MenuAccordion);
