import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import IvrError from "../../organisms/auth/IvrError";
import BasicTemplate from "../../templates/BasicTemplate";

function IvrErrorPage() {
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  return <BasicTemplate main={<IvrError errorType={query.get("error")} />} />;
}

export default IvrErrorPage;
