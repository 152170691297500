import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HospitalSearchByAdress from "../organisms/hospital/HospitalSearchByAdress";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import Titles from "../../Resources/Titles";
import qs from "querystring";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListHospitalCountsByAddress } from "../../Utils/GraphQLQueries";
import { isLnln } from "../../Utils/checkLnln";

class HospitalSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalCounts: [],
    };
  }

  getHospitalCountList = async () => {
    const { type } = qs.parse(window.location.search.substr(1));
    const { data, errors } = await GraphQLClient.query({
      query: ListHospitalCountsByAddress,
      variables: {
        facilityType: type,
        from: isLnln() ? "lnln" : "carada",
      },
    });
    if (errors || !data.hospitalCounts) return;
    this.setState({ hospitalCounts: data.hospitalCounts.items });
  };

  async componentDidMount() {
    await this.getHospitalCountList();
  }

  render() {
    const { type } = qs.parse(window.location.search.substr(1));

    const searchResultPageUrl = (prefecture) =>
      `/search/list?type=${type}&prefecture=${prefecture}`;

    return (
      <AuthenticatedTemplate
        title={Titles.searchPrefecture}
        main={
          <HospitalSearchByAdress
            searchResultPageUrl={searchResultPageUrl}
            hospitalCounts={this.state.hospitalCounts}
          />
        }
      />
    );
  }
}

export default withRouter(HospitalSearchPage);
