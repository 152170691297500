const JapanesePrivacyPolicy = {
  heading: "個人情報の取り扱いについて",
  article1: {
    heading: "(1)利用目的",
    text:
      "「ルナルナ/CARADA オンライン診療（以下当サービス）」では、以下に示す利用目的の範囲内において、皆さまよりお預かりする個人情報、およびその他の情報を利用します。\n\n1．当サービスの会員登録・認証・管理のため\nお預かりする情報：氏名、性別、生年月日、電話番号、パスワード\n2．予約情報等を通知するため\nお預かりする情報：電話番号、メールアドレス\n3. お客様・登録医療機関からのお問合せ、ご相談および苦情への対応ならびに紛争の解決のために必要な業務を行うため\nお預かりする情報：氏名、性別、生年月日、電話番号、メールアドレス、住所、登録医療機関が設定した問診情報、予約情報、当サービスの操作ログ\n",
  },
  article2: {
    heading: "(2)個人情報の第三者提供に関する旨",
    text:
      "皆さまからお預かりする個人情報およびその他の情報については､登録医療機関による本サービスの適正な利用を担保するために､第三者へ提供致します｡\n\n1）提供する目的：当サービス登録医療機関による診療・服薬指導等をお客様へ適切に提供するため\n2）提供する個人情報の項目：氏名、性別、生年月日、電話番号、メールアドレス、住所、お薬手帳や検査結果等の診療・服薬指導に関わる登録医療機関が求める情報、保険証画像等の登録医療機関が求める本人確認情報、登録医療機関が設定した問診情報\n3）提供する手段または方法：当社が提供する登録医療機関向けのサイト上で公開\n4）提供を受ける者：当サービス登録医療機関のうち、お客様が予約をした医療機関\n\n上記の場合の他は、皆さまからお預かりする個人情報およびその他の情報については､法令に基づく場合や人の生命､身体又は財産の保護のために必要がある場合等を除き､第三者提供は致しません｡",
  },
  article3: {
    heading: "(3)個人情報の委託に関する旨",
    text:
      "皆さまからお預かりする個人情報およびその他の情報の全部、または一部を外部（海外を含む）に委託する場合がございます。",
  },
  article4: {
    heading: "(4)匿名加工情報の利用",
    text:
      "特定の個人を識別することができないように個人情報を加工して得られる情報であって、加工の元になった個人情報を復元できないようにした情報（以下「匿名加工情報」といいます）を作成し、当該情報に含まれる情報の項目を公表の上、利用することがあります。また、弊社は、第三者に匿名加工情報を提供するときは、提供する情報の項目および提供方法について公表するとともに、第三者に匿名加工情報として明示します。",
  },
  article5: {
    heading: "(5)開示請求に応じる旨",
    text:
      "弊社が保有する個人情報およびその他の情報に関する利用目的の通知、開示、訂正等、利用停止等および第三者提供停止に関するお問い合わせにつきましては、下記ご相談窓口までご連絡いただくことにより合理的な範囲で速やかに対応します。",
  },
  article6: {
    heading: "(6)任意の旨",
    text:
      "弊社が皆さまの個人情報およびその他の情報の提供をお願いした場合、個人情報の提供は任意です。\nまた、ご入力いただく項目の中で必須項目に漏れがある場合は､当社の適切なサービスの利用および医療機関の診療が受けられない場合がございます｡",
  },
  article7: {
    heading: "■個人情報取得元および個人情報保護管理者",
    text:
      "【個人情報の取得元】\n株式会社カラダメディカ\n【個人情報保護管理者】\n菅原 誠太郎",
  },
  article8: {
    heading: "■個人情報のご相談窓口",
    text: "E-mail:",
    mail: "privacy@caradamedica.co.jp",
  },
};

export default JapanesePrivacyPolicy;
