import React from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import { japaneseList } from "../../../Resources/japaneseList";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
const networkErrorResource =
  japaneseList.organisms.payment.RecaptchaErrorList.onNetworkError;

interface IInvisibleRecaptcha {
  children: React.ReactNode;
  recaptchaTokenCallback: (recapchaToken: string) => void;
  showError: (message: string) => void;
}

const RecaptchaWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const InvisibleRecaptcha: React.FC<IInvisibleRecaptcha> = (props) => {
  const { children, recaptchaTokenCallback, showError } = props;
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const onChildrenClick = async () => {
    const token = (await recaptchaRef?.current?.executeAsync()) ?? "";
    recaptchaTokenCallback(token);
    recaptchaRef?.current?.reset();
  };

  const onNetworkError = () => {
    const message = networkErrorResource.j001;
    showError(message);
  };

  const clickableChildren = () => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onClick: onChildrenClick,
        });
      }
    });
  };

  return (
    <>
      <RecaptchaWrapper>
        <ReCAPTCHA
          size="invisible"
          sitekey={RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          badge="bottomright"
          onErrored={onNetworkError}
        />
      </RecaptchaWrapper>
      {clickableChildren()}
    </>
  );
};
export default InvisibleRecaptcha;
