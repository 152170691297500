import React, { useCallback } from "react";
import styled from "styled-components";
import Button from "../../atoms/buttons/Button";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import FileNote from "../../molecules/patient-upload-file/FileNote";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.organisms.reservationForm;

const useStyles = {
  my20: {
    margin: "20px 0 20px 0",
  },
};

const Container = styled.div`
  padding: 20px 15px 0px 15px;
  line-height: 1.3;
  margin-bottom: -10px;
`;

const FormDevided = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 22px;
`;

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};
  color: ${(p) => p.theme.font.color.default};
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
`;

export const ReservationUploadFileForm = (props) => {
  const noteList = [
    renderResource.ReservationUploadFileNote.j002,
    renderResource.ReservationUploadFileNote.j003,
  ];
  const { handleSkipUploadFile, handleCancelUploadFile, handleOpenPopup } =
    props;

  const handleClickUpload = useCallback(() => {
    handleOpenPopup();
  });

  return (
    <React.Fragment>
      <FormDevided>
        <FormGroup>
          {renderResource.ReservationUploadFileForm.j001}​​
          <br />
          <br />
          {renderResource.ReservationUploadFileForm.j002}
        </FormGroup>
      </FormDevided>
      <Container>
        <FullWidthButtonCustom
          id="choices-image-share-button"
          onClick={handleClickUpload}
        >
          {renderResource.ReservationUploadFileForm.j003}
        </FullWidthButtonCustom>
        <ButtonContainer>
          <FullWidthButtonCustom
            id="choices-not-image-share-confirm-button"
            onClick={handleSkipUploadFile}
          >
            {renderResource.ReservationUploadFileForm.j004}
          </FullWidthButtonCustom>
        </ButtonContainer>
      </Container>
      <Container>
        <FileNote
          title={renderResource.ReservationUploadFileNote.j001}
          noteList={noteList}
        />
      </Container>
      <Container style={useStyles.my20}>
        <ButtonContainer>
          <FullWidthOutlinedButton
            id="choices-image-share-back-button"
            onClick={handleCancelUploadFile}
          >
            {renderResource.ReservationUploadFileForm.j005}
          </FullWidthOutlinedButton>
        </ButtonContainer>
      </Container>
    </React.Fragment>
  );
};
