import uaParser from "ua-parser-js";

enum DeviceTypes {
  Mobile = "mobile",
  Tablet = "tablet",
}

enum OsTypes {
  IOS = "iOS",
  Android = "Android",
  Windows = "Windows",
  MAC_OS = "Mac OS",
}

enum BrowserTypes {
  Safari = "Safari",
  MobileSafari = "Mobile Safari",
  Chrome = "Chrome",
  Edge = "Edge",
}

const UA = new uaParser();
const browser = UA.getBrowser();
const device = UA.getDevice();
const os = UA.getOS();

const getNavigatorInstance = () => {
  if (typeof window !== "undefined") {
    if (window.navigator || navigator) {
      return window.navigator || navigator;
    }
  }

  return false;
};

// From iOS 13, iPhone/iPad user agent changed.
const isIOS13Check = (type: "iPhone" | "iPad") => {
  const nav = getNavigatorInstance();
  return (
    nav &&
    nav.platform &&
    (nav.platform.indexOf(type) !== -1 ||
      (nav.platform === "MacIntel" &&
        nav.maxTouchPoints > 1 &&
        !window.MSStream))
  );
};

const isIpad13 = isIOS13Check("iPad");
const isIphone13 = isIOS13Check("iPhone");

// Detecting device/OS/browser result
// Device
export const isMobile = device.type === DeviceTypes.Mobile;
export const isTablet = device.type === DeviceTypes.Tablet || isIpad13;
// OS
export const isIOS = os.name === OsTypes.IOS || isIpad13 || isIphone13;
export const isMacOS = os.name === OsTypes.MAC_OS;
export const isAndroid = os.name === OsTypes.Android;
export const isWindow = os.name === OsTypes.Windows;
// Browser
export const isSafari =
  browser.name === BrowserTypes.Safari ||
  browser.name === BrowserTypes.MobileSafari;
export const isChrome = browser.name === BrowserTypes.Chrome;
export const isMSEdge = browser.name === BrowserTypes.Edge;
