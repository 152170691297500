import React from "react";
import styled from "styled-components";
import IconRequired from "../../atoms/icons/IconRequired";
import IconNote from "../../atoms/icons/IconNote";
import FontPBold from "../../atoms/fonts/FontPBold";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.molecules.InputFormHeader.export.InputFormHeader;

const HeadingForm = styled.h3`
  display: flex;
  align-items: center;
  height: 35px;
  margin:10px 0;
  padding: 0 15px;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: ${p => p.theme.color.primary};
  background-color: ${p => p.theme.color.secondaryVariant};
`;

export default ({ children, ...props }) => {
  const Required=props.hideRequired?false:true;
  const note=props.note? props.note:false;
  return (
    <HeadingForm {...props}>
      <FontPBold>{children}</FontPBold>
      {Required &&
        <IconRequired>
          {renderResource.WarningLabel.j001}
        </IconRequired>
      }
      {note && <IconNote>※</IconNote>}
    </HeadingForm>
  );
};
