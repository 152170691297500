import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Heading2 from "../atoms/headings/Heading2";
import { japaneseList } from "../../Resources/japaneseList";
import ButtonPadding from "../molecules/buttons/ButtonPadding";
import { TopTabTitles } from "./TopTabTitles";
import iconAppointment from "../../images/icon/icon-appointment.svg";
import iconAppointmentDisabled from "../../images/icon/icon-appointment-disabled.svg";
import iconPrefecture from "../../images/icon/icon-prefecture.svg";
import { APPOINTMENT_STATUS, FACILITY_TYPE } from "../../Utils/Constant";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListAppointmentsByStatus } from "../../Utils/GraphQLQueries";

const FacilityType = {
  hospital: 0,
  pharmacy: 1,
};

const ButtonPaddingCustom = styled(ButtonPadding)`
  padding: 15px 0 0 0;
  > button {
    padding: 13px 20px 13px 20px;
  }
`;

const ButtonArea = styled.div`
  padding: 5px 30px 20px 30px;
`;

const Image = styled.img`
  margin: 0 10px -4px 0;
  width: 20px;
  height: 20px;
`;

function TabContainer(props) {
  return <div>{props.children}</div>;
}

class SearchHospital extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      value: 0,
      hospitalDisabled: true,
      pharmacyDisabled: true,
    };
  }

  goToPrefectureSearchPage = () => {
    if (this.state.value === FacilityType.hospital) {
      this.props.history.push(`/search?type=hospital`);
    } else if (this.state.value === FacilityType.pharmacy) {
      this.props.history.push(`/search?type=pharmacy`);
    }
  };

  goToHistoriesSearchPage = () => {
    if (this.state.value === FacilityType.hospital) {
      this.props.history.push(`/search/histories?type=hospital`);
    } else if (this.state.value === FacilityType.pharmacy) {
      this.props.history.push(`/search/histories?type=pharmacy`);
    }
  };

  loadAppointmentHistory = async (facilityType) => {
    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsByStatus,
      variables: {
        status: [
          APPOINTMENT_STATUS.EXAM_COMPLETED,
          APPOINTMENT_STATUS.PAYMENT_COMPLETED,
          APPOINTMENT_STATUS.CANCELED_DOCTOR,
          APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
          APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_DAY,
          APPOINTMENT_STATUS.CANCELEDBEFOREAPPROVING,
        ],
        numberOfItems: 1,
        facilityType: [facilityType],
      },
    });
    if (errors) {
      console.error("loadAppointmentHistory errors:", JSON.stringify(errors));
    }
    if (
      data.appointments.items.length > 0 &&
      facilityType === FACILITY_TYPE.HOSPITAL &&
      this._isMounted
    ) {
      this.setState({
        hospitalDisabled: false,
      });
    }
    if (
      data.appointments.items.length > 0 &&
      facilityType === FACILITY_TYPE.PHARMACY &&
      this._isMounted
    ) {
      this.setState({
        pharmacyDisabled: false,
      });
    }
  };

  componentDidMount = async () => {
    this._isMounted = true;
    if (this.props.login) {
      await Promise.all([
        this.loadAppointmentHistory(FACILITY_TYPE.HOSPITAL),
        this.loadAppointmentHistory(FACILITY_TYPE.PHARMACY),
      ]);
    } else {
      this.setState({
        hospitalDisabled: true,
        pharmacyDisabled: true,
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const renderResource = japaneseList.organisms.SearchHospital.render;
    const handleChange = (newValue) => {
      if (this._isMounted) {
        this.setState({
          value: newValue,
        });
      }
    };
    const disabled =
      this.state.value === FacilityType.hospital
        ? this.state.hospitalDisabled
        : this.state.pharmacyDisabled;
    const icon = disabled ? iconAppointmentDisabled : iconAppointment;

    return (
      <div>
        <Heading2>{renderResource.div.HomeHeadingAlignLeft.j001}</Heading2>
        <Paper>
          <TopTabTitles value={this.state.value} handleChange={handleChange} />
        </Paper>
        <TabContainer>
          <ButtonArea>
            <ButtonPaddingCustom
              id="hospitals-by-appointment-history-search-button"
              onClick={this.goToHistoriesSearchPage}
              disabled={disabled}
            >
              <Image src={icon} />
              {renderResource.div.SearchContents.j002}
            </ButtonPaddingCustom>
            <ButtonPaddingCustom
              id="hospitals-by-prefecture-search-button"
              onClick={this.goToPrefectureSearchPage}
            >
              <Image src={iconPrefecture} />
              {renderResource.div.SearchContents.j001}
            </ButtonPaddingCustom>
          </ButtonArea>
        </TabContainer>
      </div>
    );
  }
}

export default withRouter(SearchHospital);
