import React, { Component } from "react";
import styled from "styled-components";
import Heading1 from "../atoms/headings/Heading1";
import PanelFormArea from "../atoms/panels/PanelFormArea";
import InputText from "../atoms/forms/InputText";
import Select from "../atoms/forms/Select";
import Button from "../atoms/buttons/Button";
import ButtonFullWidth from "../atoms/buttons/ButtonFullWidth";
import WarningMessage from "../molecules/WarningMessage";
import CheckboxLabel from "../molecules/checkboxLabels/CheckboxLabel";
import { prefectureCodes } from "../../Utils/PrefectureCode";
import { japaneseList } from "../../Resources/japaneseList";

const Row = styled(PanelFormArea)`
  padding-bottom: 0;

  :last-child {
    padding-bottom: ${(p) => p.theme.space.normal};
  }
`;

const PostalCodeRow = styled(Row)`
  position: relative;

  > button {
    position: absolute;
    right: 18px;
    height: 30px;
    bottom: 2px;
    padding: 0 15px;
  }
`;

const FullWidth = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

const Agreement = styled.p`
  padding: ${(p) => p.theme.space.normal};
  background-color: ${(p) => p.theme.color.primaryVariant4};
  margin: 0;
  line-height: 1.5;
`;

class AddressForm extends Component {
  render() {
    const renderResource = japaneseList.organisms.AddressForm.render;
    const {
      isUpdated,
      isAgreed,
      addressCityError,
      addressBuildingError,
      addressStreetNumberError,
      address: {
        postalCode,
        prefectureCode,
        addressCity,
        addressStreetNumber,
        addressBuilding,
      },
      handleInputChange,
      getAddressFromPostalCode,
      agreementChange,
      update,
      disabled,
    } = this.props;

    return (
      <div>
        <Heading1>
          {isUpdated
            ? renderResource.div.PrimaryHeading.j001
            : renderResource.div.PrimaryHeading.j002}
        </Heading1>
        <React.Fragment>
          <PostalCodeRow>
            <InputText
              style={{ width: "100%" }}
              placeholder={
                renderResource.div.Fragment.PostalCodeRow.InputText.j001
              }
              name="postalCode"
              value={postalCode}
              pattern="\d*"
              onChange={(e) => handleInputChange(e, 7)}
            />
            <Button onClick={getAddressFromPostalCode}>
              {renderResource.div.Fragment.PostalCodeRow.Button.j001}
            </Button>
          </PostalCodeRow>
          <Row>
            <FullWidth>
              <Select
                name="prefectureCode"
                value={prefectureCode}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">
                  {renderResource.div.Fragment.Row.FullWidth.Select.j001}
                </option>
                {prefectureCodes.map(function (code) {
                  return (
                    <option key={code.code} value={code.code}>
                      {code.jpName}
                    </option>
                  );
                })}
              </Select>
            </FullWidth>
          </Row>
          <Row>
            <InputText
              style={{ width: "100%" }}
              placeholder={renderResource.div.Fragment.Row.InputText.j001}
              name="addressCity"
              value={addressCity}
              onChange={(e) => handleInputChange(e, 70)}
              error={addressCityError}
            />
          </Row>
          <Row>
            <InputText
              style={{ width: "100%" }}
              placeholder={renderResource.div.Fragment.Row.InputText.j002}
              name="addressStreetNumber"
              value={addressStreetNumber}
              onChange={(e) => handleInputChange(e, 15)}
              error={addressStreetNumberError}
            />
          </Row>
          <Row>
            <InputText
              style={{ width: "100%" }}
              placeholder={renderResource.div.Fragment.Row.InputText.j003}
              name="addressBuilding"
              value={addressBuilding}
              onChange={(e) => handleInputChange(e, 30)}
              error={addressBuildingError}
            />
          </Row>
          {!isUpdated && (
            <React.Fragment>
              <Row>
                <Agreement>
                  {renderResource.div.Fragment.Fragment.Row.Agreement.j001}
                  {renderResource.div.Fragment.Fragment.Row.Agreement.j002}
                </Agreement>
              </Row>
              <Row>
                <CheckboxLabel
                  checked={isAgreed}
                  onChange={agreementChange}
                  label={
                    renderResource.div.Fragment.Fragment.Row.CheckboxLabel.j001
                  }
                />
              </Row>
            </React.Fragment>
          )}

          <Row>
          {isUpdated ? 
            <ButtonFullWidth id="update-address-button" disabled={disabled} onClick={update}>
              {renderResource.div.Fragment.Row.FullWidthButton.j001}
            </ButtonFullWidth>
            :
            <ButtonFullWidth id="register-address-button" disabled={disabled} onClick={update}>
              {renderResource.div.Fragment.Row.FullWidthButton.j002}
            </ButtonFullWidth>
          }
          </Row>
          <Row>
            <WarningMessage>
              {renderResource.div.Fragment.Row.WarningMessage.j001}
            </WarningMessage>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

export default AddressForm;
