import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import InsuranceCardDetail from "../organisms/mypage/InsuranceCardDetail";
import { withRouter } from "react-router-dom";
import Guard from "../../Utils/Guard";
import Titles from "../../Resources/Titles";
import { InsuranceCard } from "../../Utils/InsuranceCard";

class InsuranceCardPage extends Component {
  state = {
    isLoading: false,
  };

  handleSelectInsuranceCard = (e) => {
    // TODO file select
    this.setState({ isLoading: true });
    try {
      let fileSelected = e.target.files.item(0);
      let validateInsuranceCard = InsuranceCard.validation(fileSelected);
      if (validateInsuranceCard.length === 0) {
        InsuranceCard.compression(fileSelected).then((res) => {
          return this.props.history.push("/my-page/insurance-card/confirm", {
            file: res,
            lastModified: res.lastModified,
            numberOfload: 0,
          });
        });
      } else {
        return this.props.history.push("/my-page/insurance-card/error", {
          InsuranceCardErr: validateInsuranceCard,
        });
      }
    } catch (error) {
      return this.props.history.push("/my-page/insurance-card/error", {
        InsuranceCardErr: ["localFileModified"],
      });
    }
  };

  render() {
    const main = (
      <InsuranceCardDetail
        handleSelectInsuranceCard={this.handleSelectInsuranceCard}
        {...this.props}
      />
    );
    return (
      <AuthenticatedTemplate
        title={Titles.insuranceCard}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(Guard(InsuranceCardPage));
