import React from "react";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import AddressForm from "../organisms/AddressForm";
import BasicTemplate from "../templates/BasicTemplate";
import Guard from "../../Utils/Guard";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Account, RegisterAddress } from "../../Utils/GraphQLQueries";
import {
  convertToFullWidthCharacters,
  isValidAddress,
} from "../../Utils/Utility";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";

class AddressPage extends React.Component {
  state = {
    address: {
      postalCode: "",
      prefectureCode: "",
      addressCity: "",
      addressStreetNumber: "",
      addressBuilding: "",
    },
    isUpdated: true,
    isAgreed: true,
    isLoading: true,
    isAddressCityError: false,
    isAddressStreetNumberError: false,
    isAddressBuildingError: false,
    disabled: true,
  };

  constructor(props) {
    super(props);

    this.reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    this.reserveData =
      this.props.location.state && this.props.location.state.reserveData;
  }

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "RegisterAddress"
    );
  };

  getAddressByPostalCode = async (postalCode) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_POSTCODE_AWS_API_GATEWAY}?postcode=${postalCode}&apiKey=${process.env.REACT_APP_ZIP_CODE_API_KEY}`
      )
    ).json();
  };

  getAddressFromPostalCode = async () => {
    const validationInputNameArray = [
      "addressCity",
      "addressStreetNumber",
      "addressBuilding",
    ];
    const { postalCode } = this.state.address;
    if (!postalCode) {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
      return;
    }
    const result = await this.getAddressByPostalCode(postalCode);

    if (result.data.length > 0) {
      const state = `JP-${result.data[0].prefCode}`;
      const town = result.data[0].city;
      const street = result.data[0].town;
      this.setState({
        address: {
          ...this.state.address,
          prefectureCode: state,
          addressCity: town,
          addressStreetNumber: street,
        },
      });
      validationInputNameArray.forEach((name) => {
        this.validateYamato(name);
      });
      this.validate();
    } else {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
    }
  };

  getAddress = async () => {
    let address = this.props.history.location.state;

    if (!address || !address.postalCode) {
      const {
        data: { account },
      } = await GraphQLClient.query({ query: Account });
      if (Boolean(account) && account.address) {
        address = account.address;
      }
    }
    const alreadyHasAddress = !!address.postalCode;
    this.setState({
      address: { ...address },
      isUpdated: alreadyHasAddress,
      isAgreed: alreadyHasAddress,
      isLoading: false,
    });
  };

  handleInputChange = (evt, maxLength) => {
    const { name, value } = evt.target;
    if (maxLength && maxLength < value.length) {
      return;
    }

    if (name === "postalCode" && !value.match(/^\d*$/)) {
      return;
    }

    this.setState(
      {
        address: {
          ...this.state.address,
          [name]: value,
        },
      },
      () => {
        this.validateYamato(name);
        this.validate();
      }
    );
  };

  agreementChange = (event) => {
    this.setState(
      {
        isAgreed: event.target.checked,
      },
      () => {
        this.validate();
      }
    );
  };

  validateYamato = (name) => {
    const { addressCity, addressStreetNumber, addressBuilding } =
      this.state.address;
    switch (name) {
      case "addressCity":
        if (addressCity && !isValidAddress(addressCity)) {
          this.setState({
            isAddressCityError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j003);
          return;
        } else {
          this.setState({
            isAddressCityError: false,
            disabled: false,
          });
        }
        break;
      case "addressStreetNumber":
        if (addressStreetNumber && !isValidAddress(addressStreetNumber)) {
          this.setState({
            isAddressStreetNumberError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j004);
          return;
        } else {
          this.setState({
            isAddressStreetNumberError: false,
            disabled: false,
          });
        }
        break;
      case "addressBuilding":
        if (addressBuilding && !isValidAddress(addressBuilding)) {
          this.setState({
            isAddressBuildingError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j005);
          return;
        } else {
          this.setState({
            isAddressBuildingError: false,
            disabled: false,
          });
        }
        break;
      default:
        break;
    }
  };

  validate = () => {
    const {
      postalCode,
      addressCity,
      prefectureCode,
      addressStreetNumber,
      addressBuilding,
    } = this.state.address;

    if (
      !postalCode ||
      !addressCity ||
      !prefectureCode ||
      !this.state.isAgreed ||
      postalCode.length > 7 ||
      addressCity.length > 70 ||
      addressStreetNumber.length > 15 ||
      addressBuilding.length > 30
    ) {
      this.setState({ disabled: true });
      return false;
    }
    this.setState({ disabled: false });
    return true;
  };

  validatePostalCodeAndAddress = (data) => {
    const { prefectureCode, addressCity } = this.state.address;
    return data.some((item) => {
      return (
        prefectureCode === `JP-${item.prefCode}` &&
        addressCity.indexOf(item.city) === 0
      );
    });
  };

  update = async () => {
    const { postalCode, addressStreetNumber } = this.state.address;
    if (!this.validate()) {
      return;
    }
    if (!this.state.isUpdated && !this.state.isAgreed) {
      this.snackBar(japaneseList.pages.AddressPage.update.j001);
      return false;
    }

    const result = await this.getAddressByPostalCode(postalCode);
    if (result.data.length <= 0) {
      this.snackBar(
        japaneseList.pages.AddressPage.getAddressFromPostalCode.j001
      );
      return false;
    }

    if (!this.validatePostalCodeAndAddress(result.data)) {
      this.snackBar(
        japaneseList.pages.AddressPage.validatePostalCodeAndAddress.j001
      );
      return false;
    }

    if (!addressStreetNumber) {
      this.snackBar(japaneseList.pages.AddressPage.validate.j002);
      return false;
    }

    const body = {
      ...this.state.address,
      addressCity: convertToFullWidthCharacters(this.state.address.addressCity),
      addressStreetNumber: convertToFullWidthCharacters(
        this.state.address.addressStreetNumber
      ),
      addressBuilding: convertToFullWidthCharacters(
        this.state.address.addressBuilding
      ),
    };

    const { data, errors } = await GraphQLClient.mutate({
      mutation: RegisterAddress,
      variables: body,
    });
    if (data && data.registerAddress) {
      const locationState = this.props.location.state;
      if (locationState && locationState.from === "reserve")
        this.props.history.push({
          pathname: `/reserve/input/${this.reserveInput.hospitalId}/${this.reserveInput.menuId}`,
          state: {
            reserveData: this.reserveData,
            reserveInput: this.reserveInput,
          },
        });
      else this.props.history.push("/my-page", { status: "succeed" });
    }
    if (errors) {
      switch (errors[0].errorType) {
        case "E110":
          // AddressCity cannot be use.
          this.setState({
            isAddressCityError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j003);
          break;
        case "E111":
          // AddressStreetNumber cannot be use.
          this.setState({
            isAddressStreetNumberError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j004);
          break;
        case "E112":
          // AddressBuilding cannot be use.
          this.setState({
            isAddressBuildingError: true,
            disabled: true,
          });
          this.snackBar(japaneseList.pages.AddressPage.validate.j005);
          break;
        default:
          break;
      }
    }
  };

  componentDidMount() {
    this.getAddress();
  }

  render() {
    const disabled =
      this.state.disabled ||
      this.state.isAddressCityError ||
      this.state.isAddressBuildingError ||
      this.state.isAddressStreetNumberError;
    const main = (
      <AddressForm
        address={this.state.address}
        isUpdated={this.state.isUpdated}
        isAgreed={this.state.isAgreed}
        disabled={disabled}
        addressCityError={this.state.isAddressCityError}
        addressStreetNumberError={this.state.isAddressStreetNumberError}
        addressBuildingError={this.state.isAddressBuildingError}
        handleInputChange={this.handleInputChange}
        getAddressFromPostalCode={this.getAddressFromPostalCode}
        agreementChange={this.agreementChange}
        update={this.update}
        {...this.props}
      />
    );
    return (
      <BasicTemplate
        title={Titles.address}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(Guard(AddressPage));
