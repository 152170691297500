import React from "react";
import styled from "styled-components";
import Heading2Underline from "./Heading2Underline";

const Heading2UnderlineIcon = styled(Heading2Underline)`
  display:flex;
  align-items:center;
 > svg{
   margin-right:5px;
 }
`;

export default ({ children, ...props }) => <Heading2UnderlineIcon>{props.icon}{children}</Heading2UnderlineIcon>;
