import React from "react";
import styled from "styled-components";

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.checkbox.bg};
  border: 1px solid ${(p) => p.theme.checkbox.border};
  display: flex;
  justify-content: center;
  border-radius: 4px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    width: 40%;
    height: 80%;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Input = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ ${Checkmark} {
    background-color: ${(p) => p.theme.checkbox.checked};
    border: 1px solid ${(p) => p.theme.checkbox.checkedborder};
  }

  :checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const Bg = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.checkbox.border};
`;

const Wrapper = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;

  > ${Input}:checked ~ ${Bg} {
    border: 1px solid ${(p) => p.theme.color.primary};
  }
`;

const Checkbox = (props) => (
  <Wrapper className={props.className}>
    <Input
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      checked={props.checked}
      id={props.id}
    />
    <Bg />
    <Checkmark />
  </Wrapper>
);

export default Checkbox;
