import React from "react";
import styled from "styled-components";
import IconRequired from "../../atoms/icons/IconRequired";
import { japaneseList } from "../../../Resources/japaneseList";
import { INTERVIEW_TYPE } from "../../../Utils/Constant";
import Checkbox from "../../atoms/forms/Checkbox";
import Radio from "../../atoms/forms/Radio";

const Question = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #393939;
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 9px 15px 10px;
  background-color: #f1f3f3;
`;

const QuestionTotal = styled.div`
  padding: 7px 15px 8px 0px;
  text-align: right;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #393939;
  border-bottom: 1px solid ${(p) => p.theme.heading.h1.bg};
`;

const AnswerError = styled.div`
  padding-top: 8px;
  font-size: 16px;
  color: #ff4d4d;
  line-height: 20px;
  font-weight: 600;
`;

const InterviewTextRemarks = styled.div`
  font-size: 16px;
  color: #393939;
  line-height: 20px;
  font-weight: 300;
  padding: 15px 15px 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const AnswerArea = styled.div`
  padding: 15px 15px;
`;

const Interval = styled.div`
  padding-bottom: 15px;
`;

const FreeTextRemarks = styled(InterviewTextRemarks)`
  padding: 15px 0px;
`;

const AdditionalTextarea = styled.textarea`
  height: 98px;
  width: 100%;
  resize: none;
  border: 1px solid #b2b2b2;
  font-size: 16px;
  color: #393939;
  word-wrap: break-word;
  background-color: #ffffff;
  :empty {
    background-color: #f7f7f7;
  }
  :focus {
    outline: none;
    background-color: #ffffff;
  }
`;

const Text = styled.div`
  position: relative;
  vertical-align: middle;
  font-size: 16px;
  color: #393939;
  font-weight: 300;
  right: 35px;
`;

const Option = styled.label`
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #cccccc;
  margin-bottom: -1px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  height: ${(p) => p.theme.space.medium};
  line-height: 1;
`;

const InterviewCheckbox = styled(Checkbox)`
  input[type="checkbox"] + span {
    border: 1px solid ${(p) => p.theme.heading.h1.bg};
  }
`;

const Title = styled.div`
  vertical-align: middle;
  font-size: 16px;
  padding-left: 8px;
  color: #393939;
  font-weight: 300;
`;

const renderResource =
  japaneseList.organisms.interviewSheet.InterviewSheetAnswerForm;
const questionTotal = renderResource.Title.j001;
const questionNo = renderResource.Title.j002;

export const InterviewSheetAnswerForm = (props) => {
  const {
    interviewSheetAnswer,
    isValidationError,
    onInterViewAnswerChange,
  } = props;

  const handleRadioChange = (event) => {
    const interviewAnswer =
      interviewSheetAnswer.interviewAnswers[event.target.name];
    const no = parseInt(event.target.value, 10);
    interviewAnswer.interviewChoiceNos = [];
    interviewAnswer.interviewChoiceNos.push(no);
    onInterViewAnswerChange(interviewSheetAnswer);
  };

  const handleCheckboxChange = (event) => {
    const interviewAnswer =
      interviewSheetAnswer.interviewAnswers[event.target.name];
    const no = parseInt(event.target.value, 10);
    interviewAnswer.interviewChoiceNos = interviewAnswer.interviewChoiceNos.filter(
      (interviewChoiceNo) => {
        return interviewChoiceNo !== no;
      }
    );
    if (event.target.checked) {
      interviewAnswer.interviewChoiceNos.push(no);
      interviewAnswer.interviewChoiceNos = [
        ...interviewAnswer.interviewChoiceNos,
      ].sort((a, b) => {
        return a - b;
      });
    }
    onInterViewAnswerChange(interviewSheetAnswer);
  };

  const handleTextareaChange = (event) => {
    const interviewAnswer =
      interviewSheetAnswer.interviewAnswers[event.target.name];
    interviewAnswer.freeText = event.target.value;
    onInterViewAnswerChange(interviewSheetAnswer);
  };

  const isRequiredAnswer = ({
    requiredAnswer,
    interviewType,
    interviewChoiceNos,
    freeText,
  }) => {
    const isRequiredSelectChoise =
      requiredAnswer &&
      interviewType !== INTERVIEW_TYPE.FREE_TEXT &&
      !(interviewChoiceNos && interviewChoiceNos.length);
    const isRequiredInputText =
      requiredAnswer &&
      interviewType === INTERVIEW_TYPE.FREE_TEXT &&
      !(freeText && freeText.trim());
    if (isRequiredSelectChoise || isRequiredInputText) {
      return true;
    } else {
      return false;
    }
  };

  const isRequiredReduceCharacters = ({ freeText }) => {
    if (freeText && freeText.length > 100) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <QuestionTotal>
        {questionTotal.replace(
          /\{0\}/g,
          interviewSheetAnswer.interviewAnswers.length
        )}
      </QuestionTotal>
      {interviewSheetAnswer.interviewAnswers.map((question, index) => {
        return (
          <React.Fragment key={index}>
            <Question>
              {questionNo
                .replace(/\{0\}/g, question.idNotation)
                .replace(/\{1\}/g, question.interviewText)}
              {question.requiredAnswer && (
                <IconRequired>{renderResource.Content.j001}</IconRequired>
              )}
              {isValidationError && isRequiredAnswer(question) && (
                <AnswerError>{renderResource.Error.j001}</AnswerError>
              )}
              {isValidationError && isRequiredReduceCharacters(question) && (
                <AnswerError>{renderResource.Error.j002}</AnswerError>
              )}
            </Question>
            {(isRequiredAnswer(question) ||
              isRequiredReduceCharacters(question)) && (
              <div className="validationErrorElement" />
            )}
            {question.interviewTextRemarks && (
              <InterviewTextRemarks>
                {question.interviewTextRemarks}
              </InterviewTextRemarks>
            )}
            <AnswerArea>
              {(question.interviewType === INTERVIEW_TYPE.RADIO ||
                question.interviewType ===
                  INTERVIEW_TYPE.RADIO_WITH_FREE_TEXT) &&
                question.interviewChoices.map((value, i) => {
                  return (
                    <Option key={i}>
                      <Radio
                        customOnChange={handleRadioChange}
                        name={index}
                        value={value.no}
                        checked={question.interviewChoiceNos.includes(value.no)}
                      />
                      <Text>{value.text}</Text>
                    </Option>
                  );
                })}
              {(question.interviewType === INTERVIEW_TYPE.CHECKBOX ||
                question.interviewType ===
                  INTERVIEW_TYPE.CHECKBOX_WITH_FREE_TEXT) && (
                <React.Fragment>
                  {question.interviewChoices.map((value, i) => {
                    return (
                      <Option key={i}>
                        <CheckboxContainer>
                          <InterviewCheckbox
                            onChange={handleCheckboxChange}
                            name={index}
                            value={value.no}
                            checked={question.interviewChoiceNos.includes(
                              value.no
                            )}
                          />
                        </CheckboxContainer>
                        <Title>{value.text}</Title>
                      </Option>
                    );
                  })}
                </React.Fragment>
              )}
              {(question.interviewType ===
                INTERVIEW_TYPE.RADIO_WITH_FREE_TEXT ||
                question.interviewType ===
                  INTERVIEW_TYPE.CHECKBOX_WITH_FREE_TEXT) && <Interval />}
              {question.freeTextRemarks && (
                <FreeTextRemarks>{question.freeTextRemarks}</FreeTextRemarks>
              )}
              {(question.interviewType ===
                INTERVIEW_TYPE.RADIO_WITH_FREE_TEXT ||
                question.interviewType ===
                  INTERVIEW_TYPE.CHECKBOX_WITH_FREE_TEXT ||
                question.interviewType === INTERVIEW_TYPE.FREE_TEXT) && (
                <AdditionalTextarea
                  name={index}
                  value={question.freeText || ""}
                  placeholder={question.freeTextPlaceholder}
                  maxLength={"100"}
                  onChange={handleTextareaChange}
                />
              )}
            </AnswerArea>
            <Interval />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
