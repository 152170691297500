import React from "react";
import styled from "styled-components";
import PdfThumbnailItem from "../../atoms/thumbnail/PdfThumbnailItem";
import ThumbnailItem from "../../atoms/thumbnail/ThumbnailItem";
import ThumbnailItemText from "../../atoms/thumbnail/ThumbnailItemText";
import ThumbnailPlus from "../../atoms/thumbnail/ThumbnailPlus";
import ThumbnailPlusText from "../../atoms/thumbnail/ThumbnailPlusText";
import { GenerateGetPresignedUrl } from "../../../Utils/GraphQLQueries";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.molecules.PatientUploadFile.thumbnailList;

const ThumbnailItemContainer = styled.div`
  width: 105px;
`;

const ThumbnailPlusContainer = styled.div`
  width: 105px;
`;

const ThumbnailListContainer = styled.div`
  width: 335px;
  margin: auto;
  padding-top: 30px;
`;

const ThumbnailListStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 105px);
  justify-content: space-between;
  div:nth-child(n + 4) {
    padding-top: 20px;
  }
`;

const IconStyle = styled.div`
  margin: auto;
  border: 1px solid ${(p) => p.theme.color.primary};
  border-radius: 12px;
  width: 105px;
  height: 105px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const MAX_ITEM = 5;

const STATUS_OK = 200;

const ThumbnailList = (props) => {
  const {
    thumbnailList,
    handleClickPlus,
    handleChooseFile,
    isViewOnly,
    isApptCancelOrRejectOrExpired,
    canOpenNewTab,
    isShowPlusButon,
  } = props;

  const generateGetFileUrl = async (file) => {
    let windowReference = window.open();
    if (file.filePath) {
      const response = await GraphQLClient.mutate({
        mutation: GenerateGetPresignedUrl,
        variables: { filePath: file.filePath },
      });
      const { getSignedRequest, errors } =
        response.data.generateGetPresignedUrl;

      if (file.expiredTime < new Date().toISOString()) {
        windowReference.location = "/notfound";
        return;
      }

      let myRequest = new Request(getSignedRequest);
      fetch(myRequest).then(function (res) {
        if (res.status !== STATUS_OK) {
          windowReference.location = "/notfound";
          return;
        }
      });

      if (!errors) {
        windowReference.location = getSignedRequest;
      }
    }
  };

  const selectFile = (file) => {
    if (!isViewOnly && !canOpenNewTab) {
      handleChooseFile(file);
    }
    if (!isViewOnly && canOpenNewTab) {
      generateGetFileUrl(file);
    }
  };

  return (
    <ThumbnailListContainer>
      <ThumbnailListStyle>
        {thumbnailList.map((file) => {
          return (
            <ThumbnailItemContainer key={file.fileId}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  selectFile(file);
                }}
              >
                {file.isOriginPdf ? (
                  <PdfThumbnailItem
                    src={
                      file.thumbnailFile
                        ? file.thumbnailFile
                        : file.originalFile
                    }
                  />
                ) : (
                  <ThumbnailItem
                    src={
                      file.thumbnailFile
                        ? file.thumbnailFile
                        : file.originalFile
                    }
                    isViewOnly={isViewOnly}
                    canOpenNewTab={canOpenNewTab}
                    alt={file.fileName ? file.fileName : file.originalFile.name}
                  />
                )}
              </a>
              <ThumbnailItemText
                text={file.fileName ? file.fileName : file.originalFile.name}
              />
            </ThumbnailItemContainer>
          );
        })}
        {isApptCancelOrRejectOrExpired
          ? ""
          : thumbnailList.length < MAX_ITEM &&
            isShowPlusButon && (
              <ThumbnailPlusContainer
                id="add-image-share-button"
                onClick={handleClickPlus}
              >
                <IconStyle>
                  <ThumbnailPlus />
                  <ThumbnailPlusText text={renderResource.j001} />
                </IconStyle>
              </ThumbnailPlusContainer>
            )}
      </ThumbnailListStyle>
    </ThumbnailListContainer>
  );
};

// Set default props
ThumbnailList.defaultProps = {
  isApptCancelOrRejectOrExpired: false,
  isViewOnly: true,
  isShowPlusButon: true,
  canOpenNewTab: false,
  thumbnailList: [],
};

export default ThumbnailList;
