import React, { useCallback } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import Button from "../../atoms/buttons/Button";
import ThumbnailList from "../../molecules/patient-upload-file/ThumbnailList";
import { FILE_ACTION } from "../../../Utils/Constant";

const renderResource =
  japaneseList.organisms.reservationForm.ReservationUploadFileThumbnailList;

const styles = {
  container: {
    padding: "27px 27px 0",
  },
};

const Container = styled.div`
  padding: 20px 15px 0px 15px;
  line-height: 1.3;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
`;

export const ReservationUploadFileThumbnailList = (props) => {
  const {
    thumbnailList,
    handleChooseFile,
    fileNameAction,
    handleOpenPopup,
    handleCancelUploadFile,
    handleConfirmClick,
    actionFile,
    isApptCancelOrRejectOrExpired,
    isFromAppointmentDetailPage,
  } = props;

  const handleClickPlus = useCallback((e) => {
    handleOpenPopup();
  });

  const renderActionFile = (action) => {
    switch (action) {
      case FILE_ACTION.ADD_NEW:
        return (
          <FontP size="s" align="center">
            {fileNameAction}
            <br />
            {renderResource.j001}
          </FontP>
        );
      case FILE_ACTION.UPDATE:
        return (
          <FontP size="s" align="center">
            {fileNameAction}
            <br />
            {renderResource.j004}
          </FontP>
        );
      case FILE_ACTION.DELETE:
        return (
          <FontP size="s" align="center">
            {fileNameAction}
            <br />
            {renderResource.j005}
          </FontP>
        );
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      {!isFromAppointmentDetailPage && (
        <div style={styles.container}>{renderActionFile(actionFile)}</div>
      )}
      <ThumbnailList
        thumbnailList={thumbnailList.sort((a, b) =>
          a.uploadedTime > b.uploadedTime ? 1 : -1
        )}
        handleClickPlus={handleClickPlus}
        handleChooseFile={handleChooseFile}
        isViewOnly={false}
        isApptCancelOrRejectOrExpired={isApptCancelOrRejectOrExpired}
      />
      {thumbnailList &&
        thumbnailList.length > 0 &&
        !isFromAppointmentDetailPage && (
          <Container>
            <ButtonContainer>
              <FullWidthButtonCustom
                id="image-share-confirm-button"
                onClick={handleConfirmClick}
              >
                {renderResource.j002}
              </FullWidthButtonCustom>
            </ButtonContainer>
            <ButtonContainer>
              <FullWidthOutlinedButton
                id="image-share--back-button"
                onClick={handleCancelUploadFile}
              >
                {renderResource.j003}
              </FullWidthOutlinedButton>
            </ButtonContainer>
          </Container>
        )}
    </React.Fragment>
  );
};
