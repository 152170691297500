//--------------------------------------------------
//
// Responsive template
// レスポンシブ対応テンプレート
// レスポンシブ
//
//-------------------------------------------------

// import
//-------------------------------------------------
import React from "react";
import styled from "styled-components";

import { device } from "../device";
import { isLnln } from "../../Utils/checkLnln";
import { japaneseList } from "../../Resources/japaneseList";

import Favicon from "../atoms/others/Favicon";
import TitleTag from "../atoms/others/TitleTag";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";

// const
//-------------------------------------------------
const Root = styled.div`
  position: relative;
  min-height: calc(100vh - 120px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media screen and ${device.pc} {
    width: 1000px;
    margin: 0 auto;
  }
`;
const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

// export
//-------------------------------------------------
export default ({ main, title = "", isLoading = false }) => {
  const service_name = japaneseList.service.name;
  const _title = title ? title + " | " + service_name : service_name;
  const servicePath = isLnln() ? "lnln" : "carada";
  return (
    <React.Fragment>
      <Favicon servicePath={servicePath} />
      <TitleTag title={_title} />
      <Header />
      <Root>
        <Main>{main}</Main>
        <LoadingScreen isLoading={isLoading} />
      </Root>
      <Footer />
    </React.Fragment>
  );
};
