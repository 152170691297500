import styled from "styled-components";

const ButtonOutlined = styled.button`

  color: ${p => p.theme.color.primary};
  background-color: ${p => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${p => p.theme.color.primary};
  font-size: 0.7rem;
  padding: 1px 5px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  cursor:pointer;
`;

export default ButtonOutlined;
