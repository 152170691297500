import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { ErrorOutline } from "@material-ui/icons";
import styled from "styled-components";
import { format, isSameDay, parse } from "date-fns";
import ja from "date-fns/locale/ja";

import { japaneseList } from "../../Resources/japaneseList";
const renderResource = japaneseList.organisms.Announcement;

const styles = (theme) => ({
  icon: {
    display: "block",
    width: "16px",
    height: "16px",
    marginRight: "10px",
  },
});

const Info = styled.div`
  position: relative;
  margin: 34px 15px 25px 15px;
  padding: 10px;
  border: solid 2px #f26666;
  background-color: #fff;
  font-size: ${(p) => p.theme.font.size.ss};
`;

const Icon = styled.div`
  margin-right: 5px;
  display: flex;
`;

const Title = styled.div`
  position: absolute;
  bottom: 100%;
  left: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-weight: bold;
  color: #fff;
  background-color: #f26666;
`;

const Text = styled.div`
  margin-bottom: 20px;
`;

const Date = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Note = styled.div`
  color: #999999;
`;

class Announcement extends Component {
  makeMaintenanceDate() {
    let from = parse(this.props.announcementInfo.from);
    let to = parse(this.props.announcementInfo.to);

    if (isSameDay(from, to)) {
      from = format(from, "M月D日（dd）H:mm", { locale: ja });
      to = format(to, "H:mm", { locale: ja });
    } else {
      from = format(from, "M月D日（dd）H:mm", { locale: ja });
      to = format(to, "M月D日（dd）H:mm", { locale: ja });
    }

    return { from, to };
  }

  render() {
    const { from, to } = this.makeMaintenanceDate();

    return (
      <Info>
        <Title>
          <Icon>
            <ErrorOutline />
          </Icon>
          {renderResource.title}
        </Title>
        <Text>{renderResource.text}</Text>
        <Date>
          <div>{renderResource.dateTitle}</div>
          <div>
            {from} ～ {to}
          </div>
        </Date>
        <Note>{renderResource.note}</Note>
      </Info>
    );
  }
}

export default withStyles(styles)(Announcement);
