import styled from "styled-components";

const IconMenuTag = styled.div`
  display: inline-block;
  line-height: 15px;

  margin: 2px;
  margin-left: 3px;
  padding: 1px 4px;

  text-align: center;
  font-size: 0.7rem;
  font-weight: normal;
  color: #666666;

  background-color: #f1f3f3;
  border-radius: 4px;
`;

export default IconMenuTag;
