import styled from "styled-components";
import ButtonFullWidth from "./ButtonFullWidth";

const ButtonDialog = styled(ButtonFullWidth)`
  background-color: transparent;
  padding: ${p => p.theme.space.normal};
  color: ${p => p.theme.color.font};
  border-left: thin solid ${p => p.theme.color.secondary};
  border-radius: 0;
  font-weight: bold;
  color: ${p => p.theme.color.primary};

  &:first-child {
    border-left: none;
    font-weight: normal;
    color: ${p => p.theme.color.font};
  }
`;
export default ButtonDialog;
