import React, { useState } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { InterviewSheetAnswerForm } from "../../organisms/interviewSheet/InterviewSheetAnswerForm";
import { INTERVIEW_TYPE } from "../../../Utils/Constant";

const ConfirmButton = styled.div`
  margin: 0px 15px 15px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  background-color: ${(p) => p.theme.heading.h1.bg};
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
`;

const RevertButton = styled.div`
  margin: 0px 15px 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => p.theme.heading.h1.bg};
  background-color: #fff;
  border-radius: 5px;
  border: 1.5px solid ${(p) => p.theme.heading.h1.bg};
  cursor: pointer;
`;

const renderResource =
  japaneseList.organisms.reservationForm.InterviewSheetForm;

export const InterviewSheetForm = (props) => {
  const [isValidationError, setIsValidationError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    interviewSheetAnswer,
    handleRevertClick,
    handleConfirmClick,
    handleInterviewAnwerChange,
  } = props;

  const handlerInterviewConfirm = () => {
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);
    const checkError = interviewSheetAnswer.interviewAnswers.some((item) => {
      return isRequiredAnswer(item) || isRequiredReduceCharacters(item);
    });
    if (checkError) {
      const anchorElement = document.getElementsByClassName(
        "validationErrorElement"
      );
      if (anchorElement && anchorElement.length > 0) {
        anchorElement[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
      setIsValidationError(true);
      setIsSubmitting(false);
      return;
    }
    handleConfirmClick();
  };

  const isRequiredAnswer = ({
    requiredAnswer,
    interviewType,
    interviewChoiceNos,
    freeText,
  }) => {
    const isRequiredSelectChoices =
      requiredAnswer &&
      interviewType !== INTERVIEW_TYPE.FREE_TEXT &&
      !(interviewChoiceNos && interviewChoiceNos.length);
    const isRequiredInputText =
      requiredAnswer &&
      interviewType === INTERVIEW_TYPE.FREE_TEXT &&
      !(freeText && freeText.trim());
    if (isRequiredSelectChoices || isRequiredInputText) {
      return true;
    } else {
      return false;
    }
  };

  const isRequiredReduceCharacters = ({ freeText }) => {
    if (freeText && freeText.length > 100) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {interviewSheetAnswer && (
        <InterviewSheetAnswerForm
          interviewSheetAnswer={interviewSheetAnswer}
          isValidationError={isValidationError}
          onInterViewAnswerChange={handleInterviewAnwerChange}
        />
      )}
      <ConfirmButton
        id="reservation-Interview-confirm-button"
        onClick={handlerInterviewConfirm}
        disabled={isSubmitting}
      >
        {renderResource.FormGroup.ConfirmButton.j001}
      </ConfirmButton>
      <RevertButton
        id="reservation-Interview-back-button"
        onClick={handleRevertClick}
      >
        {renderResource.FormGroup.RevertButton.j001}
      </RevertButton>
    </React.Fragment>
  );
};
