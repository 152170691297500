import React, { useState, useCallback } from "react";

import styled from "styled-components";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import iconWarning from "../../../images/icon/icon-warning.svg";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { ResendCodeByPhoneCall } from "../../../Utils/GraphQLQueries";
import { japaneseList } from "../../../Resources/japaneseList";
import { IVR } from "../../../Utils/Constant";
import { Phone } from "@material-ui/icons";
import { IVRConfirmPopup } from "../../molecules/popup/IVRConfirmPopup";
import { useHistory } from "react-router-dom";

const InquirySignupSmsFromResource =
  japaneseList.organisms.InquirySignupSmsFrom;

const VoiceNotificationFromResource = japaneseList.organisms.VoiceNotification;

const contactCenterPhoneNumber =
  process.env.REACT_APP_TELEMEDICINE_CONTACT_CENTER_PHONE_NUMBER;

const PhoneCustomIcon = styled(Phone)`
  vertical-align: middle;
  margin: 0px 2px 0px 2px;
  margin-right: 7px;
`;

const ImgWarning = styled.img`
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
`;

const VoiceNotificationArea = styled.div`
  padding: 20px 14px;
  background: #f7f7f7;
  margin: 0 15px 20px 15px;
`;

const VoiceNotificationText = styled.div``;

const VoiceNotiHeading = styled.div``;

const VoiceNotiTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
`;

const VoiceNotiBody = styled.div`
  display: inline-block;
  font-size: 14px;
`;

const VoiceNotiBodyText = styled.p`
  line-height: 21px;
  padding-bottom: 5px;
`;

const ResendCodeButton = styled.div`
  text-align: center;
`;

const ResendCodeFullWidthButton = styled(FullWidthButton)`
  height: 50px;
`;

const ResendCodeText = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const TipMessage = styled.div`
  margin: 15px 0 20px;
`;

const TipTitle = styled.div`
  margin: 23px 0 0 15px;
  font-size: 14px;
`;

const TipSpot = styled.div`
  width: 16px;
  display: inline-block;
  vertical-align: top;
  margin-left: 22px;
`;

const TipBody = styled.div`
  width: calc(100% - 38px);
  display: inline-block;
  line-height: 25px;
  padding-right: 22px;
  font-size: 14px;
`;

const renderGaIdResendCodeByFlowType = (flowType) => {
  switch (flowType) {
    case IVR.FLOWTYPE.MEMBER_REGISTRATION:
      return "verification-code-resend-by-phone-call-button";
    case IVR.FLOWTYPE.RESET_PASSWORD:
      return "reset-password-resend-by-phone-call-button";
    default:
      return "";
  }
};

export default (props) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const history = useHistory();

  const handleOpenPopup = () => {
    setIsOpenPopup(true);
  };

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);
  });

  const handleIVRCall = async () => {
    try {
      const response = await GraphQLClient.mutate({
        mutation: ResendCodeByPhoneCall,
        variables: {
          phoneNumber: props.phoneNumber,
          flowType: props.flowType,
        },
      });

      // Handle error
      if (!response.data) {
        //redirect to error page
        const errorType = response.errors[0].errorType;
        history.push(`/ivrError?error=${errorType}`);
      }
      setIsOpenPopup(false);
    } catch (error) {
      //redirect to error page
      const errorType = "E01";
      history.push(`/ivrError?error=${errorType}`);
    }
  };

  return (
    <>
      <TipTitle>
        {InquirySignupSmsFromResource.render.Fragment.tip.j001}
      </TipTitle>
      <TipMessage>
        <TipSpot>
          {InquirySignupSmsFromResource.render.Fragment.symbols.j001}
        </TipSpot>
        <TipBody>
          {InquirySignupSmsFromResource.render.Fragment.tip.j003}
        </TipBody>
        <TipSpot>
          {InquirySignupSmsFromResource.render.Fragment.symbols.j001}
        </TipSpot>
        <TipBody>
          {InquirySignupSmsFromResource.render.Fragment.tip.j004}
        </TipBody>
        <TipSpot>
          {InquirySignupSmsFromResource.render.Fragment.symbols.j001}
        </TipSpot>
        <TipBody>
          {InquirySignupSmsFromResource.render.Fragment.tip.j005}
        </TipBody>
      </TipMessage>
      <VoiceNotificationArea>
        <VoiceNotificationText>
          <VoiceNotiHeading>
            <ImgWarning src={iconWarning} />
            <VoiceNotiTitle>
              {VoiceNotificationFromResource.render.j001}
            </VoiceNotiTitle>
          </VoiceNotiHeading>
          <VoiceNotiBody>
            <VoiceNotiBodyText>
              {VoiceNotificationFromResource.render.j002.replace(
                /{{contact_center_phone_number}}/g,
                contactCenterPhoneNumber
              )}
            </VoiceNotiBodyText>
            <VoiceNotiBodyText>
              {VoiceNotificationFromResource.render.j003}
            </VoiceNotiBodyText>
            <VoiceNotiBodyText>
              {VoiceNotificationFromResource.render.j004}
            </VoiceNotiBodyText>
          </VoiceNotiBody>
        </VoiceNotificationText>
        <ResendCodeButton>
          <ResendCodeFullWidthButton
            onClick={handleOpenPopup}
            id={renderGaIdResendCodeByFlowType(props.flowType)}
          >
            <PhoneCustomIcon />
            <ResendCodeText>
              {VoiceNotificationFromResource.render.Buttons.ResendCode.j001}
            </ResendCodeText>
          </ResendCodeFullWidthButton>
        </ResendCodeButton>
      </VoiceNotificationArea>
      <IVRConfirmPopup
        isOpen={isOpenPopup}
        handleIVRCall={handleIVRCall}
        handleClosePopup={handleClosePopup}
        flowType={props.flowType}
      />
    </>
  );
};
