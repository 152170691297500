import React, { Component } from "react";
import format from "date-fns/format";

import Select from "../../atoms/forms/Select";

class SelectYear extends Component {
  constructor(props) {
    super(props);

    this.init({
      currentYear: parseInt(format(new Date(), "YYYY"), 10),
      maxYears: 20
    });

    this.setState({
      value: this.props.value
    });
  }

  init({currentYear, maxYears}) {
    const values = [];
    for(let index = 0; index <= maxYears; index++){
      values.push((currentYear + index).toString());
    }
    this.values = values;
  }

  render() {
    const values = this.values;
    return(
      <Select
        {...this.props}
      >
        {values.map(value =>(
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </Select>
    )
  }
}
export default SelectYear;
