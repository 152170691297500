import React, { Component } from "react";
import styled from "styled-components";
import Heading2 from "../atoms/headings/Heading2";
import Hospital from "../molecules/Hospital";
import LinkToNoUnder from "../atoms/links/LinkToNoUnder";
import { FACILITY_TYPE } from "../../Utils/Constant";
import { japaneseList } from "../../Resources/japaneseList";

const ListCard = styled.div``;
const CustomLinkToNoUnder = styled(LinkToNoUnder)`
  display: block;
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
  :last-child {
    border-bottom: none;
  }
`;

class ListHospital extends Component {
  static defaultProps = {
    hospitals: [],
    organizationId: null,
  };
  render() {
    const renderResource = japaneseList.organisms.ListHospital.render;
    const { hospitals, organizationId } = this.props;
    const hospitalList =
      hospitals && hospitals.length > 0
        ? hospitals
            .filter((h) => h.facilityType === FACILITY_TYPE.HOSPITAL)
            .map((hospital, i) => {
              let url = "/hospital/" + hospital.hospitalId;
              return (
                <CustomLinkToNoUnder to={url} key={hospital.hospitalId}>
                  <Hospital hospital={hospital} />
                </CustomLinkToNoUnder>
              );
            })
        : "";

    const pharmacyList =
      hospitals && hospitals.length > 0
        ? hospitals
            .filter((h) => h.facilityType === FACILITY_TYPE.PHARMACY)
            .map((hospital, i) => {
              let url = "/hospital/" + hospital.hospitalId;
              return (
                <CustomLinkToNoUnder to={url} key={hospital.hospitalId}>
                  <Hospital hospital={hospital} />
                </CustomLinkToNoUnder>
              );
            })
        : "";

    return (
      <div>
        {organizationId ? (
          <Heading2>{renderResource.div.HomeHeadingAlignLeft.j001}</Heading2>
        ) : (
          ""
        )}
        <ListCard>{hospitalList}</ListCard>

        {!organizationId && pharmacyList.length>0 &&(
          <React.Fragment>
            <ListCard>{pharmacyList}</ListCard>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ListHospital;
