import React from "react";
import styled from "styled-components";

const Selection = styled.select`
  border: 1px solid ${(p) => p.theme.color.secondary};
  width: 100%;
  border-radius: 8px;
  padding: 0 8px;
  height: 40px;
  line-height: 40px;
  outline: none;
  margin-top: 5px;
  font-size: 16px;
`;

class SelectTimeReservation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: null,
      isDropDown: false,
    };
  }

  //   Life Cycle
  componentDidMount() {
    const { selectedReserve } = this.props;
    if (selectedReserve && selectedReserve.from) {
      this.setState(
        {
          selection: selectedReserve,
        },
        this.updateSelected(selectedReserve)
      );
    } else if (this.props.options) {
      const selected = this.props.options[0];
      this.setState(
        {
          selection: selected,
        },
        this.updateSelected(selected)
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      const selected = nextProps.options[0];
      this.setState(
        {
          selection: selected,
        },
        this.updateSelected(selected)
      );
    }
  }
  // End Life Cycle

  onChange = (e) => {
    if (this.props.onChange) {
      const matched = this.props.options.find((x) => {
        return x.from === e.target.value;
      });

      this.updateSelected(matched);
    }
  };

  updateSelected = (selected) => {
    this.setState(
      (prevState) => {
        return {
          selection: selected,
        };
      },
      () => {
        if (this.state.selection) {
          this.props.onChange(this.state.selection);
        }
      }
    );
  };

  render() {
    const { selectedReserve } = this.props;
    let selection = null;
    if (Object.keys(selectedReserve).length === 0) {
      selection = (
        <Selection disabled="disabled">
          <option>---</option>;
        </Selection>
      );
    } else {
      selection = (
        <Selection
          onChange={(e) => this.onChange(e)}
          className={this.props.className}
          value={selectedReserve.from}
        >
          {this.props.options ? (
            this.props.options.map((item) => (
              <option key={item.from} value={item.from}>
                {item.label}
              </option>
            ))
          ) : (
            <option>---</option>
          )}
        </Selection>
      );
    }

    return selection;
  }
}

export default SelectTimeReservation;
