import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.organisms.onlineChat.OnlineChat;

const WarningMessageContainer = styled.div`
  width: 100%;
  margin: 6px;
  padding: 14px 17px;
  background-color: #393939;
  border: 1px solid rgba(255, 255, 255, 0.8);
  display: flex;
  & svg {
    font-size: 18px;
    color: white;
  }
  & p {
    font-size: 14px;
    color: white;
    margin-left: 8px;
    margin-top: -1px;
  }
`;

const Container = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: calc(100% - 32px);
`;

const TextWarningWrapper = styled.div``;

interface Props {
  isDisplayLocalLowNetwork: boolean;
  isDisplayLocalLowAudio: boolean;
}

export const ChattingPromptInformationArea = (props: Props) => {
  const { isDisplayLocalLowNetwork, isDisplayLocalLowAudio } = props;
  return (
    <Container>
      {isDisplayLocalLowNetwork && (
        <WarningMessageContainer>
          <ErrorIcon />
          <TextWarningWrapper>
            <Typography>{Resource.NetworkQualityWarning.j001}</Typography>
            <Typography>{Resource.NetworkQualityWarning.j002}</Typography>
          </TextWarningWrapper>
        </WarningMessageContainer>
      )}
      {isDisplayLocalLowAudio && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.AudioLowWarning.j001}</Typography>
        </WarningMessageContainer>
      )}
    </Container>
  );
};
