import React, { Component } from "react";
import styled from "styled-components";
import iconBlockPath from "../../images/unsupported/error_outline-24px.svg";
import { japaneseList } from "../../Resources/japaneseList";
import { device } from "../device";
import TitleTag from "../atoms/others/TitleTag";
import Favicon from "../atoms/others/Favicon";
import Footer from "../organisms/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";
import ImgLogoPath from "../../images/carada/logo.svg";
import ImgLogoPathLnln from "../../images/lnln/logo.svg";
import { isLnln } from "../../Utils/checkLnln";

const imgLogoPath = isLnln() ? ImgLogoPathLnln : ImgLogoPath;

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  @media screen and ${device.tb} {
    width: 424px;
    border-right: thin solid #c7c7c7;
    border-left: thin solid #c7c7c7;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.05),
      0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;
const Main = styled.div`
  flex-grow: 1;
`;
const Logo = styled.div`
  width: 181px;
  height: 16px;
  margin: 16px auto 0px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
`;
const Line = styled.div`
  margin-top: 17px;
  height: 1px;
  background: #f1f3f3 0% 0% no-repeat padding-box;
  overflow: hidden;
  opacity: 1;
`;
const Head = styled.div`
  margin-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
  background: #fff1f2 0% 0% no-repeat padding-box;
  opacity: 1;
`;

const Header1 = styled.div`
  margin-top: 15px;
  left: 15px;
  height: 38px;
  opacity: 1;
`;

const Info = styled.div`
  width: 22px;
  height: 22px;
  margin-top: 15px;
  margin-left: 15px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  float: left;
`;

const Heading1 = styled.div`
  height: 20px;
  text-align: left;
  background: #fff1f2 0% 0% no-repeat padding-box;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ef5e5e;
  opacity: 1;
  margin-top: 16px;
  margin-left: 4px;
  float: left;
  vertical-align: middle;
  font-weight: bolder;
`;

const Warning = styled.div`
  padding: 11px 15px 15px;
  height: auto;
  text-align: left;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ef5e5e;
  opacity: 1;
`;

const Heading2 = styled.div`
  margin-top: 30px;
  margin-left: 15px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bolder;
  text-align: left;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;
  height: 20px;
  :before {
    content: " ";
    background: ${(p) => p.theme.color.primary} 0% 0% no-repeat padding-box;
    width: 6px;
    height: 17px;
    margin-top: -2px;
    margin-right: 6px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    opacity: 1;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  margin-left: 15px;
  box-sizing: border-box;
  margin-right: 15px;
  height: auto;
  text-align: left;
  letter-spacing: 0px;
  font-size: 0.9rem;
  color: #393939;
  opacity: 1;
`;

const Typography1 = styled.div`
  font-weight: bolder;
`;
const Typography2 = styled.div`
  padding-left: 13px;
  font-weight: bolder;
`;

const Typography3 = styled.div`
  padding-left: 13px;
  padding-bottom: 20px;
`;

const Complement = styled.div`
  margin-top: 12px;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
`;
class UnsupportedDevicePage extends Component {
  render() {
    const unsupportedServiceBlockPage =
      japaneseList.pages.UnsupportedServiceBlockPage;
    const servicePath = isLnln() ? "lnln" : "carada";
    const main = (
      <div>
        <div>
          <Line />
          <Head>
            <Header1>
              <Info>
                <img src={iconBlockPath} alt={""} />
              </Info>
              <Heading1>
                {unsupportedServiceBlockPage.render.ServiceBlockContent.j001}
              </Heading1>
            </Header1>
            <Warning>
              {unsupportedServiceBlockPage.render.ServiceBlockContent.j002}
            </Warning>
          </Head>
        </div>
        <Heading2>
          {unsupportedServiceBlockPage.render.ServiceBlockContent.j003}
        </Heading2>
        <Content>
          <Typography1>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j004}
          </Typography1>
          <Typography2>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j005}
          </Typography2>
          <Typography3>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j006}
            <br />
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j007}
          </Typography3>
          <Typography2>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j008}
          </Typography2>
          <Typography3>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j009}
            <br />
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j010}
          </Typography3>
          <Typography1>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j011}
          </Typography1>
          <Typography2>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j012}
          </Typography2>
          <Typography3>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j013}
            <br />
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j014}
          </Typography3>
          <Typography1>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j015}
          </Typography1>
          <Typography2>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j016}
          </Typography2>
          <Typography3>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j017}
            <br />
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j018}
          </Typography3>
          <Typography2>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j019}
          </Typography2>
          <Typography3>
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j020}
            <br />
            {unsupportedServiceBlockPage.render.ServiceBlockContent.j021}
          </Typography3>
        </Content>
        <Complement>
          {unsupportedServiceBlockPage.render.ServiceBlockContent.j022}
          <br />
          {unsupportedServiceBlockPage.render.ServiceBlockContent.j023}
        </Complement>
      </div>
    );

    return (
      <React.Fragment>
        <Favicon servicePath={servicePath} />
        <TitleTag title={japaneseList.service.name} />
        <Root>
          <Logo>
            <img src={imgLogoPath} alt={""} />
          </Logo>
          <Main>{main}</Main>
          <Footer />
          <LoadingScreen isLoading={false} />
        </Root>
      </React.Fragment>
    );
  }
}

export default UnsupportedDevicePage;
