import styled from "styled-components";

const HeadingEnterprise = styled.div`
  margin-bottom: 4px;
  line-height: 1.5;
  font-weight: bold;
  font-size: ${(p) => p.theme.font.size.l};
  color: ${(p) => p.theme.heading.enterprise.color};
`;

export default HeadingEnterprise;
