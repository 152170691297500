import styled from "styled-components";

const Panel = styled.div`
  display: block;
  padding: ${p =>
    p.padding && p.theme.panel.padding[p.padding]
      ? p.theme.panel.padding[p.padding]
      : p.theme.panel.padding.s};
  ${p =>
    p.align && p.theme.panel.align[p.align]
      ? "text-align:" + p.theme.panel.align[p.align] + ";"
      : ""}
  ${p =>
    p.background && p.theme.panel.background.color[p.background]
      ? "background-color:" + p.theme.panel.background.color[p.background] + ";"
      : ""}
`;

export default Panel;
