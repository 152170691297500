import React, { Component } from "react";
import styled from "styled-components";
import { withPolling } from "../../contexts/PollingContext";
import StandbyPageMessageCard from "../molecules/StandbyPageMessageCard";
import { formatPeriod } from "../../Utils/Utility";
import { StandbyAppointment } from "../../Utils/GraphQLQueries";
import GraphQLClient from "../../Utils/GraphQLClient";
import { japaneseList } from "../../Resources/japaneseList";
const STANDBY_INTERVAL = 10000;
const OnlineChatStandbyMessage = styled.div`
  background-color: ${(p) => p.theme.color.primary};
  cursor: pointer;
  padding-bottom: 20px;
`;

const HospitalName = styled.div`
  padding-top: 20px;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AppointmentTime = styled.div`
  padding-top: 6px;
  font-size: 14px;
  color: #ffffff;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  opacity: 1;
`;

const Explain = styled.div`
  padding-top: 6px;
  padding-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  opacity: 1;
`;

class OnlineStandbyMessage extends Component {
  onlineChatUrl = "/telemedicine/chat";
  onlineChatStandByUrl = "/telemedicine/standby";
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isShowOnlineChatStandbyMessage: false,
      intervalId: 0,
      standbyAppointmentInfo: null,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      this.showOnlineChatStandbyMessage();
      const intervalId = setInterval(
        this.showOnlineChatStandbyMessage,
        STANDBY_INTERVAL
      );
      // 時間経過でポーリング処理停止のためにIDを渡す
      const { addPollingId } = this.props.pollingContext;
      addPollingId("OnlineStandbyMessage", intervalId);
      this._isMounted &&
        this.setState({
          intervalId: intervalId,
        });
    } catch (err) {
      console.error(err);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.restInterval();
  }

  isOnlineChatOrOnLineChatStandByPage = () => {
    return (
      window.location.pathname.startsWith(this.onlineChatUrl) ||
      window.location.pathname.startsWith(this.onlineChatStandByUrl)
    );
  };

  showOnlineChatStandbyMessage = async () => {
    if (this.isOnlineChatOrOnLineChatStandByPage()) {
      return;
    }
    const { data } = await GraphQLClient.query({
      query: StandbyAppointment,
    });
    if (!this._isMounted) return;
    if (data.standbyAppointment && data.standbyAppointment.isStandby) {
      this.setState({
        isShowOnlineChatStandbyMessage: true,
        standbyAppointmentInfo: data.standbyAppointment.appointment,
      });
    } else {
      this.setState({
        isShowOnlineChatStandbyMessage: false,
      });
    }
  };

  restInterval() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const { onTapStandby } = this.props;
    const standbyResource = japaneseList.organisms.Header.render.Standby;
    return (
      <React.Fragment>
        {this.state.isShowOnlineChatStandbyMessage && (
          <OnlineChatStandbyMessage onClick={onTapStandby}>
            <HospitalName>
              {this.state.standbyAppointmentInfo.hospitalName}
            </HospitalName>
            <AppointmentTime>
              {formatPeriod(
                this.state.standbyAppointmentInfo.from,
                this.state.standbyAppointmentInfo.to
              )}
              {standbyResource.j001}
            </AppointmentTime>
            <Explain>{standbyResource.j002}</Explain>
            <StandbyPageMessageCard>
              {standbyResource.j003}
            </StandbyPageMessageCard>
          </OnlineChatStandbyMessage>
        )}
      </React.Fragment>
    );
  }
}
export default withPolling(OnlineStandbyMessage);
