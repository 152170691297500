import React, { useCallback, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Heading1 from "../atoms/headings/Heading1";
import { UploadFilePopup } from "../molecules/popup/UploadFilePopup";
import { StepperForm } from "../organisms/reservation-form/StepperForm";
import { ReservationUploadFileReview } from "../organisms/reservation-form/ReservationUploadFileReview";
import { ReservationUploadFileDetail } from "../organisms/reservation-form/ReservationUploadFileDetail";
import { ReservationUploadFileThumbnailList } from "../organisms/reservation-form/ReservationUploadFileThumbnailList";
import { ReservationUploadFileForm } from "../organisms/reservation-form/ReservationUploadFileForm";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import { compressFile, readFile } from "../../Utils/PatientAttachment";
import {
  FORM_TYPE,
  PATIENT_ATTACHMENT,
  FILE_ACTION,
  RESERVE_STEP,
} from "../../Utils/Constant";
import Guard from "../../Utils/Guard";

const renderResource = japaneseList.pages.ReservationUploadFilePage.render;
const { MAX_FILE_SIZE, VALID_FILE_EXTENSION } = PATIENT_ATTACHMENT;

export const ReservationUploadFilePage = Guard((props) => {
  const { hospitalId, menuId } = props.match.params;
  const reserveData = props.location.state && props.location.state.reserveData;
  if (!reserveData) {
    props.history.push(`/hospital/${hospitalId}`);
    return null;
  }
  const reserveInterviewSheetAnswer =
    props.location.state && props.location.state.reserveInterviewSheetAnswer;
  const reserveInput =
    props.location.state && props.location.state.reserveInput;
  const medicineDelivery =
    props.location.state && props.location.state.medicineDelivery;
  const stateFileList = props.location.state && props.location.state.fileList;
  const reserveSteps =
    props.location.state && props.location.state.reserveSteps;
  const currentStep =
    reserveSteps &&
    reserveSteps.find((step) => step.reserveStep === RESERVE_STEP.UPLOAD_FILE);

  // States declaration
  const [formType, setFormType] = useState(FORM_TYPE.UPLOAD_FILE_FORM);
  const [file, setFile] = useState({});
  const [fileList, setFileList] = useState([]);
  const [fileNameAction, setFileNameAction] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [actionFile, setActionFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check existing select file again after error
    if (props.location.state && props.location.state.reUploadedFile) {
      setFileList(stateFileList || []);
      handleSelectImage(props.location.state.reUploadedFile);
      props.history.replace(props.location.pathname, {
        ...props.location.state,
        reUploadedFile: undefined,
      });
    } else if (stateFileList && stateFileList.length > 0) {
      setFileList(stateFileList);
      setFormType(FORM_TYPE.THUMBNAIL_LIST);
      props.history.replace(props.location.pathname, {
        ...props.location.state,
      });
    }
  }, []);

  const handleOpenPopup = useCallback(() => {
    setIsOpenPopup(true);
  });

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);
  });

  // popup select file
  const handleSelectImage = useCallback(async (selectImage) => {
    const invalidFileType = !VALID_FILE_EXTENSION.includes(selectImage.type);
    const invalidFileSize = selectImage.size > MAX_FILE_SIZE;
    if (invalidFileType || invalidFileSize) {
      props.history.replace(props.location.pathname, {
        ...props.location.state,
        fileList,
      });
      props.history.push(`/reserve/upload-file/error`, {
        ...props.location.state,
        from: props.location.pathname,
        fileList,
        errorFile: selectImage,
      });
    } else {
      const fileName = selectImage.name
        ? selectImage.name.split(".").slice(0, -1).join("")
        : "";
      const extensionFile = selectImage.name
        ? selectImage.name.split(".").slice(-1)[0]
        : "";
      const fileType = selectImage.type;
      const buffer = await readFile(selectImage);
      selectImage = new Blob([buffer], { type: fileType });
      const selectFile = {
        fileName,
        extensionFile,
        fileType,
        originalFile: selectImage,
      };
      setFile(selectFile);
      setFormType(FORM_TYPE.REVIEW_FILE);
    }
  });

  const handleChooseFile = useCallback((selectFile) => {
    setFile(selectFile);
    setActionFile("");
    setFormType(FORM_TYPE.DETAIL_FILE);
  });

  const handleBackUploadForm = useCallback(() => {
    setActionFile("");
    fileList.length > 0
      ? setFormType(FORM_TYPE.THUMBNAIL_LIST)
      : setFormType(FORM_TYPE.UPLOAD_FILE_FORM);
  });

  const handleDeleteFileLocal = useCallback((id) => {
    const index = fileList.findIndex((n) => n.fileId === id);
    const fileDelete = fileList[index];
    setFileNameAction(`${fileDelete.fileName}.${fileDelete.extensionFile}`);
    const files = [...fileList.slice(0, index), ...fileList.slice(index + 1)];
    setFileList(files);
    setActionFile(FILE_ACTION.DELETE);
    saveFileToLocal(files);
    files.length > 0
      ? setFormType(FORM_TYPE.THUMBNAIL_LIST)
      : setFormType(FORM_TYPE.UPLOAD_FILE_FORM);
  });

  const handleAddNewFileToThumbnailList = useCallback(async (selectFile) => {
    setIsLoading(true);
    const id = uuidv4();
    const compressNewFile = await compressFile(selectFile);
    const newFile = {
      fileId: id,
      fileName: selectFile.fileName,
      extensionFile: selectFile.extensionFile,
      fileType: selectFile.fileType,
      uploadedTime: Date.now(),
      updatedTime: Date.now(),
      originalFile: compressNewFile,
    };
    const getListFile = [...fileList, newFile];
    setFileNameAction(`${newFile.fileName}.${newFile.extensionFile}`);
    setFileList(getListFile);
    setIsLoading(false);
    setActionFile(FILE_ACTION.ADD_NEW);
    saveFileToLocal(getListFile);
    setFormType(FORM_TYPE.THUMBNAIL_LIST);
  });

  const handleUpdateFileThumbnailList = useCallback((id, fileName) => {
    const index = fileList.findIndex((n) => n.fileId === id);
    let fileUpdate = {
      ...fileList[index],
      fileName,
      updatedTime: Date.now(),
    };
    const files = [
      ...fileList.slice(0, index),
      fileUpdate,
      ...fileList.slice(index + 1),
    ];
    setFileNameAction(`${fileUpdate.fileName}.${fileUpdate.extensionFile}`);
    setActionFile(FILE_ACTION.UPDATE);
    saveFileToLocal(files);
    setFormType(FORM_TYPE.THUMBNAIL_LIST);
    setFileList(files);
  });

  const handleBackButtonDetailFile = useCallback(() => {
    setFormType(FORM_TYPE.THUMBNAIL_LIST);
  });

  const handleSkipUploadFile = useCallback(() => {
    props.history.push({
      pathname: currentStep && currentStep.followingUrl,
      state: {
        reserveData,
        reserveInput,
        reserveInterviewSheetAnswer,
        medicineDelivery,
        reserveSteps,
      },
    });
  });

  const handleCancelUploadFile = useCallback(() => {
    props.history.push({
      pathname: currentStep && currentStep.previousUrl,
      state: {
        reserveData,
        reserveInput,
        reserveInterviewSheetAnswer,
        medicineDelivery,
        fileList,
        reserveSteps,
      },
    });
  });

  const handleConfirmClick = () => {
    props.history.push({
      pathname: currentStep && currentStep.followingUrl,
      state: {
        reserveData,
        reserveInput,
        reserveInterviewSheetAnswer,
        medicineDelivery,
        fileList,
        reserveSteps,
      },
    });
  };

  const saveFileToLocal = (setNewFileList) => {
    props.history.replace({
      pathname: `/reserve/upload-file/${hospitalId}/${menuId}`,
      state: {
        reserveData,
        reserveInput,
        reserveInterviewSheetAnswer,
        medicineDelivery,
        fileList: setNewFileList,
        reserveSteps,
      },
    });
  };

  const renderForm = () => {
    switch (formType) {
      case FORM_TYPE.UPLOAD_FILE_FORM:
        return (
          <ReservationUploadFileForm
            handleSkipUploadFile={handleSkipUploadFile}
            handleCancelUploadFile={handleCancelUploadFile}
            handleOpenPopup={handleOpenPopup}
          />
        );
      case FORM_TYPE.REVIEW_FILE:
        return (
          <ReservationUploadFileReview
            file={file}
            handleSelectImage={handleSelectImage}
            handleAddNewFileToThumbnailList={handleAddNewFileToThumbnailList}
            handleBackUploadForm={handleBackUploadForm}
          />
        );
      case FORM_TYPE.THUMBNAIL_LIST:
        return (
          <ReservationUploadFileThumbnailList
            thumbnailList={fileList}
            handleChooseFile={handleChooseFile}
            fileNameAction={fileNameAction}
            handleOpenPopup={handleOpenPopup}
            handleCancelUploadFile={handleCancelUploadFile}
            handleConfirmClick={handleConfirmClick}
            actionFile={actionFile}
          />
        );
      case FORM_TYPE.DETAIL_FILE:
        return (
          <ReservationUploadFileDetail
            file={file}
            handleUpdateFileThumbnailList={handleUpdateFileThumbnailList}
            handleDeleteFileLocal={handleDeleteFileLocal}
            handleBackButtonDetailFile={handleBackButtonDetailFile}
          />
        );
      default:
        break;
    }
  };

  const main = (
    <React.Fragment>
      <Heading1>{renderResource.Title.j001}</Heading1>
      <StepperForm reserveSteps={reserveSteps} currentStep={currentStep} />
      {renderForm()}
      <UploadFilePopup
        isOpen={isOpenPopup}
        handleSelectImage={handleSelectImage}
        handleClosePopup={handleClosePopup}
      />
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate
      title={renderResource.Title.j001}
      main={main}
      isLoading={isLoading}
    />
  );
});
