import React from "react";
import styled from "styled-components";
import CheckboxLabel from "../../molecules/checkboxLabels/CheckboxLabel";
import { japaneseList } from "../../../Resources/japaneseList";

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short};
`;

const Content = styled.div`
  padding: 0;
  line-height: 1.5;
  font-size: 0.8rem;
  color: ${(p) => p.theme.color.font};
`;

const CheckboxContainer = styled.div`
  padding: 0px 0 4px 0;
`;

const CheckboxLabelWithPadding = styled(CheckboxLabel)`
  padding: 20px 0 0;
`;

const CheckboxSMSLabel = styled.span`
  font-size: 0.9rem;
  color: ${(p) => p.theme.color.font};
  vertical-align: middle;
`;

/**
 * @param {object} props
 * @param {Function} props.handleAgreeSendSMS
 * @param {Function} props.handleAgreeSendMail
 * @param {boolean} props.sendSms
 * @param {boolean} props.sendMail
 */

export const NotificationSelectingCheckbox = (props) => {
  const RenderSelectFormResource =
    japaneseList.organisms.reservationForm.ReservationForm.RenderSelectForm;

  return (
    <FormGroup>
      <Content>
        {RenderSelectFormResource.Fragment.Fragment.FormGroup.Content.j001}
      </Content>
      <CheckboxContainer>
        <CheckboxLabelWithPadding
          id="accept-sms-notification-checkbox"
          style={{ marginBottom: "5px" }}
          label={
            <CheckboxSMSLabel>
              {
                RenderSelectFormResource.Fragment.Fragment.FormGroup
                  .CheckboxContainer.CheckboxLabel.CheckboxSMSLabel.j002
              }
            </CheckboxSMSLabel>
          }
          checked={props.sendSms}
          onChange={props.handleAgreeSendSMS}
        />
        <CheckboxLabelWithPadding
          id="accept-email--notification-checkbox"
          style={{ marginBottom: "5px" }}
          label={
            <CheckboxSMSLabel>
              {
                RenderSelectFormResource.Fragment.Fragment.FormGroup
                  .CheckboxContainer.CheckboxLabel.CheckboxSMSLabel.j001
              }
            </CheckboxSMSLabel>
          }
          checked={props.sendMail}
          onChange={props.handleAgreeSendMail}
        />
      </CheckboxContainer>
    </FormGroup>
  );
};
