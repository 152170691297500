import React, { useState } from "react";
import styled from "styled-components";
import { MedicineDeliveryForm } from "../reservation-form/MedicineDeliveryForm";
import { japaneseList } from "../../../Resources/japaneseList";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import Button from "../../atoms/buttons/Button";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { UpdateMedicineDelivery } from "../../../Utils/GraphQLQueries";
import HeadingForm from "../../molecules/headings/HeadingForm";
import {
  MEDICINE_DELIVERY_METHOD,
  PATIENT_MEDICINE_DELIVERY,
} from "../../../Utils/Constant";

const Resource =
  japaneseList.organisms.AppointmentDetail.MedicineDeliveryEdit.render;
const ERROR = PATIENT_MEDICINE_DELIVERY.ERROR;

interface Props {
  appointment: Appointment;
  patientInfo: PatientInfo;
  reloadAppointment: (isUpdateSuccess: boolean) => void;
  showAppointmentDetail: () => void;
  setIsLoading: (isLoading: boolean) => void;
  handleFailedUpdateMedicineDelivery: (errorType: string) => void;
}

interface Appointment {
  hospitalId: string;
  createTime: string;
  medicineDelivery: MedicineDelivery;
}

interface MedicineDelivery {
  deliveryMethod: string;
  deliveryHospitalId: string;
  pharmacy: Hospital;
}

interface PatientInfo {
  familyName: string;
  givenName: string;
  address: Address;
}

interface Address {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface Hospital {
  hospitalId: string;
  name?: string;
  postalCode?: string;
  prefectureCode?: string;
  addressCity?: string;
  addressStreetNumber?: string;
  addressBuilding?: string;
  tel?: string;
}

const InputFormHeader = styled(HeadingForm)`
  margin: 10px 0px 10px 0px;
  background-color: #f1f3f3;
  font-family: Hiragino Kaku Gothic ProN;
  font-weight: 600 !important;
  color: #333333 !important;
`;

const FootButton = styled.div`
  padding: 0 30px;
  margin: 30px 0 0 0;
`;

const ButtonUpdate = styled(ButtonFullWidth)`
  margin-bottom: 10px;
`;

const ButtonBack = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
`;

export const MedicineDeliveryEdit = (props: Props) => {
  const {
    appointment,
    patientInfo,
    reloadAppointment,
    showAppointmentDetail,
    setIsLoading,
    handleFailedUpdateMedicineDelivery,
  } = props;

  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    appointment.medicineDelivery && appointment.medicineDelivery.deliveryMethod
  );
  const [pharmacy, setPharmacy] = useState<Hospital>({
    hospitalId:
      appointment.medicineDelivery &&
      appointment.medicineDelivery.deliveryHospitalId,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleUpdate = async () => {
    if (isSubmitting) {
      return false;
    }
    setIsLoading(true);
    setIsSubmitting(true);
    const medicineDelivery =
      deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY
        ? {
            deliveryMethod: deliveryMethod,
            deliveryHospitalId: pharmacy.hospitalId,
          }
        : {
            deliveryMethod: deliveryMethod,
          };
    // @ts-ignore ToDo: Since errors cannot be defined, it is forcibly defined using "@ts-ignore".
    const { data, errors } = await GraphQLClient.mutate<{
      updateMedicineDelivery: boolean;
    }>({
      mutation: UpdateMedicineDelivery,
      variables: {
        createTime: appointment.createTime,
        medicineDelivery: medicineDelivery,
      },
    });
    if (errors && errors[0].errorType === ERROR.E02) {
      setIsSubmitting(false);
      handleFailedUpdateMedicineDelivery(errors[0].errorType);
    }
    if (data && data.updateMedicineDelivery) {
      reloadAppointment(data.updateMedicineDelivery);
    }
  };

  const handleSelectDeliveryMethod = (selectedDeliveryMethod: string) => {
    setDeliveryMethod(selectedDeliveryMethod);
    setPharmacy({ hospitalId: "" });
  };

  const handleSelectPharmacy = (selectedPharmacy: Hospital) => {
    setPharmacy(selectedPharmacy);
  };

  const isCompleted = (): boolean => {
    if (
      deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME &&
      appointment.medicineDelivery.deliveryMethod !== deliveryMethod
    ) {
      return true;
    }
    if (
      deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY &&
      pharmacy &&
      pharmacy.hospitalId &&
      (appointment.medicineDelivery.deliveryMethod !== deliveryMethod ||
        appointment.medicineDelivery.deliveryHospitalId !== pharmacy.hospitalId)
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <InputFormHeader>{Resource.Title.j001}</InputFormHeader>
      <MedicineDeliveryForm
        hospitalId={appointment.hospitalId}
        deliveryMethod={deliveryMethod}
        deliveryHospitalId={pharmacy && pharmacy.hospitalId}
        patientInfo={patientInfo}
        handleSelectDeliveryMethod={handleSelectDeliveryMethod}
        handleSelectPharmacy={handleSelectPharmacy}
        setIsLoading={setIsLoading}
      />
      <FootButton>
        <ButtonUpdate
          id="change-receive-confirm-button"
          onClick={() => handleUpdate()}
          disabled={isSubmitting || !isCompleted()}
        >
          {Resource.Button.j001}
        </ButtonUpdate>
        <ButtonBack
          id="change-receive-back-button"
          onClick={() => showAppointmentDetail()}
        >
          {Resource.Button.j002}
        </ButtonBack>
      </FootButton>
    </React.Fragment>
  );
};
