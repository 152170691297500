import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Heading1 from "../atoms/headings/Heading1";
import BasicTemplate from "../templates/BasicTemplate";
import HeadingForm from "../molecules/headings/HeadingForm";
import { StepperForm } from "../organisms/reservation-form/StepperForm";
import { MedicineDeliveryForm } from "../organisms/reservation-form/MedicineDeliveryForm";
import { japaneseList } from "../../Resources/japaneseList";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Account } from "../../Utils/GraphQLQueries";
import styled from "styled-components";
import ButtonFullWidth from "../atoms/buttons/ButtonFullWidth";
import Button from "../atoms/buttons/Button";
import { MEDICINE_DELIVERY_METHOD, RESERVE_STEP } from "../../Utils/Constant";

const Resource = japaneseList.pages.MedicineDeliveryUpdatePage.render;

interface Props extends RouteComponentProps<{ hospitalId: string }> {}

interface State {
  reserveData: ReserveData;
  medicineDelivery: MedicineDelivery;
  reserveSteps: Step[];
}

interface ReserveData {}

interface MedicineDelivery {
  deliveryMethod: string;
  pharmacy: Hospital;
  patientInfo: PatientInfo;
}

interface Hospital {
  hospitalId: string;
  name?: string;
  postalCode?: string;
  prefectureCode?: string;
  addressCity?: string;
  addressStreetNumber?: string;
  addressBuilding?: string;
  tel?: string;
}

interface PatientInfo {
  familyName: string;
  givenName: string;
  address: Address;
}

interface Address {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface Step {
  reserveStep: string;
  previousUrl: string;
  followingUrl: string;
}

const ButtonFullWidthSuccess = styled(ButtonFullWidth)`
  margin-bottom: 10px;
`;

const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  margin-bottom: 20px;
`;

const FootButton = styled.div`
  margin: 30px 16px 0 16px;
`;

const InputFormHeader = styled(HeadingForm)`
  background-color: ${(p) => p.theme.color.secondaryVariant};
  margin: 0px 0px 20px 0px;
  font-family: Hiragino Kaku Gothic ProN;
  font-weight: 600 !important;
  color: #333333 !important;
`;

export const ReservationMedicineDeliveryPage = (props: Props) => {
  const hospitalId = props.match.params.hospitalId;
  const { state } = useLocation<State>();
  if (!state || !state.reserveData) {
    props.history.push(`/hospital/${hospitalId}`);
    return null;
  }
  const reserveSteps = state.reserveSteps;
  const currentStep =
    reserveSteps &&
    reserveSteps.find(
      (step: Step) => step.reserveStep === RESERVE_STEP.MEDICINE_DELIVERY
    );
  const [medicineDelivery] = useState<MedicineDelivery>(state.medicineDelivery);
  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    medicineDelivery && medicineDelivery.deliveryMethod
  );
  const [pharmacy, setPharmacy] = useState<Hospital>(
    medicineDelivery && medicineDelivery.pharmacy
  );
  const [patientInfo, setPatientInfo] = useState<PatientInfo>(
    medicineDelivery && medicineDelivery.patientInfo
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    loadAccount();
  }, []);

  const handleGoToUploadFile = () => {
    props.history.push({
      pathname: currentStep && currentStep.followingUrl,
      state: {
        ...state,
        medicineDelivery: {
          deliveryMethod: deliveryMethod,
          pharmacy:
            deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY && pharmacy,
          patientInfo: patientInfo,
        },
      },
    });
  };

  const handleBack = () => {
    props.history.push({
      pathname: currentStep && currentStep.previousUrl,
      state: {
        ...state,
        medicineDelivery: {
          deliveryMethod: deliveryMethod,
          pharmacy:
            deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY && pharmacy,
          patientInfo: patientInfo,
        },
      },
    });
  };

  const loadAccount = async () => {
    const { data, errors } = await GraphQLClient.query({ query: Account });
    if (errors) {
      console.error("loadAccount errors:", JSON.stringify(errors));
    }
    setPatientInfo(data.account);
  };

  const handleSelectDeliveryMethod = (selectedDeliveryMethod: string) => {
    setDeliveryMethod(selectedDeliveryMethod);
    setPharmacy({ hospitalId: "" });
  };

  const handleSelectPharmacy = (selectedPharmacy: Hospital) => {
    setPharmacy(selectedPharmacy);
  };

  const isCompleted = (): boolean => {
    if (
      deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY &&
      pharmacy &&
      pharmacy.hospitalId
    )
      return true;
    if (
      deliveryMethod ===
      MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
    )
      return true;
    return false;
  };

  const main = (
    <React.Fragment>
      <Heading1>{Resource.Content.j009}</Heading1>
      <StepperForm reserveSteps={reserveSteps} currentStep={currentStep} />
      <InputFormHeader>{Resource.Content.j009}</InputFormHeader>
      <MedicineDeliveryForm
        hospitalId={hospitalId}
        deliveryMethod={deliveryMethod}
        deliveryHospitalId={pharmacy && pharmacy.hospitalId}
        patientInfo={patientInfo}
        handleSelectDeliveryMethod={handleSelectDeliveryMethod}
        handleSelectPharmacy={handleSelectPharmacy}
        setIsLoading={setIsLoading}
      />
      <FootButton>
        <ButtonFullWidthSuccess
          id="medicine-receiving-confirm-button"
          onClick={handleGoToUploadFile}
          disabled={!isCompleted()}
        >
          {Resource.Content.j010}
        </ButtonFullWidthSuccess>
        <FullWidthOutlinedButton
          id="medicine-receiving-back-button"
          onClick={handleBack}
        >
          {Resource.Content.j011}
        </FullWidthOutlinedButton>
      </FootButton>
    </React.Fragment>
  );

  return (
    <BasicTemplate
      title={Resource.Title.j001}
      main={main}
      isLoading={isLoading}
    />
  );
};
