import { Hub } from "aws-amplify";
import React, { useContext } from "react";
import styled from "styled-components";

import { japaneseList } from "../../../Resources/japaneseList";
import {
  reopenDefaultBrowser,
  buildExternalUrlToCreate,
  buildExternalUrlToUpdate,
  createConsentStatus,
  updateConsentStatusToProgress,
} from "../../../Utils/ConsentRegistration";
import { APPOINTMENT_STATUS, CONSENT_STATUS } from "../../../Utils/Constant";
import SiteContext from "../../../SiteContext";

import HeadingForm from "../../molecules/headings/HeadingForm";
import { ConsentRegistrationFormToCancel } from "./ConsentRegistrationFormToCancel";
import { ConsentRegistrationFormToCreate } from "./ConsentRegistrationFormToCreate";
import { ConsentRegistrationFormToUpdate } from "./ConsentRegistrationFormToUpdate";

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentDetailContent.render
    .ConsentRegistration;

interface Props {
  appointment: Appointment;
  onlineQualificationConsentStatus?: OnlineQualificationConsentStatus;
}
interface Appointment {
  createTime: number;
  hospitalId: string;
  status: string;
  to: string;
}
interface OnlineQualificationConsentStatus {
  consentStatus: string;
  isSupportMyNumberInsuranceCard: string;
  onlineQualificationSystemCode: string;
  canCancelConsent: string;
  hasFutureAppointment: string;
}

const ConsentFormHeader = styled(HeadingForm)`
  margin: 10px 0px 10px 0px;
  padding: 10px 15px 10px 15px;
  height: auto;
  background-color: #f1f3f3;
  font-family: Hiragino Kaku Gothic ProN;
  color: #333333;
`;
const ConsentFormHeaderMain = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;
const ConsentFormHeaderSub = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 300;
`;
const ConsentFormContent = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConsentRegistrationForm: React.FC<Props> = (props) => {
  const { appointment, onlineQualificationConsentStatus } = props;
  const { ui } = useContext(SiteContext);
  if (!onlineQualificationConsentStatus) return <></>;

  const { consentStatus, canCancelConsent, onlineQualificationSystemCode } =
    onlineQualificationConsentStatus;
  const currentUrl = window.location.href;

  const isEnableAppointment = (status: string) => {
    const ENABLE_APPOINTMENT_STATUSES = [
      APPOINTMENT_STATUS.UNAPPROVED,
      APPOINTMENT_STATUS.BEFORE_EXAM,
      APPOINTMENT_STATUS.EXAM_COMPLETED,
      APPOINTMENT_STATUS.PAYMENT_COMPLETED,
    ];
    return ENABLE_APPOINTMENT_STATUSES.includes(status);
  };

  const handleCreateClicked = async () => {
    {
      const isLnln = ui === "lnln";
      if (isLnln) return reopenDefaultBrowser();
    }

    // iOS13以降はユーザー操作の直後でないと新規タブを開けない
    // そのため、先に新規タブを開いておきAPIリクエストが失敗した場合は新規タブを閉じる
    const externalUrl = buildExternalUrlToCreate(
      onlineQualificationSystemCode,
      appointment.to,
      currentUrl
    );
    const newTab = window.open(externalUrl, "_blank");
    const result = await createConsentStatus(appointment);
    if (!result) {
      if (newTab) newTab.close();
      snackBar(renderResource.Error.j001);
      return;
    }
    window.location.reload();
  };

  const handleUpdateClicked = async () => {
    {
      const isLnln = ui === "lnln";
      if (isLnln) return reopenDefaultBrowser();
    }
    const externalUrl = buildExternalUrlToUpdate(
      onlineQualificationSystemCode,
      appointment.to,
      currentUrl
    );
    const newTab = window.open(externalUrl, "_blank");
    const result = await updateConsentStatusToProgress(appointment);
    if (!result) {
      if (newTab) newTab.close();
      snackBar(renderResource.Error.j001);
      return;
    }
    window.location.reload();
  };

  const snackBar = (message: string) => {
    Hub.dispatch(
      "msg",
      { event: "openAutoToast", data: { message: message, level: "error" } },
      "ConsentRegistration"
    );
  };

  const renderConsentForm = (formComponent: React.ReactNode) => (
    <>
      <ConsentFormHeader hideRequired={true}>
        <ConsentFormHeaderMain>
          {renderResource.title.main}
        </ConsentFormHeaderMain>
        <ConsentFormHeaderSub>{renderResource.title.sub}</ConsentFormHeaderSub>
      </ConsentFormHeader>
      <ConsentFormContent>{formComponent}</ConsentFormContent>
    </>
  );

  const renderForm = () => {
    if (
      isEnableAppointment(appointment.status) &&
      consentStatus === CONSENT_STATUS.UNREGISTERED
    )
      return renderConsentForm(
        <ConsentRegistrationFormToCreate
          handleCreateClicked={handleCreateClicked}
        />
      );

    if (
      isEnableAppointment(appointment.status) &&
      (consentStatus === CONSENT_STATUS.REGISTERING ||
        consentStatus === CONSENT_STATUS.REGISTERED)
    )
      return renderConsentForm(
        <ConsentRegistrationFormToUpdate
          handleCreateClicked={handleCreateClicked}
          handleUpdateClicked={handleUpdateClicked}
        />
      );

    if (
      !isEnableAppointment(appointment.status) &&
      (consentStatus === CONSENT_STATUS.REGISTERED ||
        consentStatus === CONSENT_STATUS.REGISTERING) &&
      canCancelConsent
    )
      return renderConsentForm(
        <ConsentRegistrationFormToCancel
          handleCancelClicked={handleUpdateClicked}
        />
      );

    return <></>;
  };
  return renderForm();
};
