import React from "react";
import styled from "styled-components";
import IconRequired from "../../atoms/icons/IconRequired";
import IconNote from "../../atoms/icons/IconNote";
import FontPBold from "../../atoms/fonts/FontPBold";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource =
  japaneseList.molecules.InputFormHeader.export.InputFormHeader;

const HeadingForm = styled.h3`
  display: flex;
  align-items: center;
  height: 35px;
  margin: 20px 0 0 0;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: ${(p) => p.theme.color.primary};
`;

export default ({ children, ...props }) => {
  const Required = props.hideRequired ? false : true;
  const note = props.note ? props.note : false;
  return (
    <HeadingForm {...props}>
      <FontPBold>{children}</FontPBold>
      {Required && (
        <IconRequired>{renderResource.WarningLabel.j001}</IconRequired>
      )}
      {note && <IconNote>{renderResource.ImportantSymbols.j001}</IconNote>}
    </HeadingForm>
  );
};
