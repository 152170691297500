import React from "react";
import InputFormTitle from "../../molecules/calendar/InputFormTitle";
import { japaneseList } from "../../../Resources/japaneseList";
import { NotificationSelectingCheckbox } from "./NotificationSelectingCheckbox";
import { NotificationDescription } from "./NotificationDescription";
import { NOTIFICATION_BY } from "../../../Utils/Constant";

/**
 * @param {Object} props
 * @param {Function} props.handleAgreeSendSMS
 * @param {Function} props.handleAgreeSendMail
 * @param {boolean} props.phoneNumberRegistered
 * @param {boolean} props.mailRegistered
 * @param {boolean} props.sendSms
 * @param {boolean} props.sendMail
 */

export const ReservationNotificationSettings = (props) => {
  const RenderSelectFormResource =
    japaneseList.organisms.reservationForm.ReservationForm.RenderSelectForm;

  return (
    <React.Fragment>
      <InputFormTitle
        titleNumber={
          props.isConsultationContent && props.requiredCompanyName
            ? 5
            : props.isConsultationContent || props.requiredCompanyName
            ? 4
            : 3
        }
      >
        {RenderSelectFormResource.Fragment.Fragment.InputFormHeaderNoIcon.j001}
      </InputFormTitle>

      {props.mailRegistered && props.phoneNumberRegistered ? (
        <NotificationSelectingCheckbox
          handleAgreeSendSMS={props.handleAgreeSendSMS}
          handleAgreeSendMail={props.handleAgreeSendMail}
          sendSms={props.sendSms}
          sendMail={props.sendMail}
        />
      ) : props.phoneNumberRegistered && !props.mailRegistered ? (
        // TODO: change props to a constant
        <NotificationDescription notificationBy={NOTIFICATION_BY.SMS} />
      ) : (
        <NotificationDescription notificationBy={NOTIFICATION_BY.MAIL} />
      )}
    </React.Fragment>
  );
};
