import React from "react";
import styled from "styled-components";
import Header from "../atoms/fonts/Header";

const InputFormHeader = styled.div`
  background-color: ${p => p.theme.color.primaryVariant2};
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 15px;
`;

export default ({ children, ...props }) => {
  return (
    <InputFormHeader {...props}>
      <Header>{children}</Header>
    </InputFormHeader>
  );
};
