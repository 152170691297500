import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import Panel from "../../atoms/panels/Panel";
import FontPError from "../../atoms/fonts/FontPError";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import InputFile from "../../atoms/forms/InputFile";
import { japaneseList } from "../../../Resources/japaneseList";
import { Redirect } from "react-router-dom";
const _ = require("lodash");
const Resources = japaneseList.organisms.mypage.InsuranceCardErr;

const CustomInputFile = styled(InputFile)`
  display: none;
`;

class InsuranceCardErr extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    errors: Resources.Errors
  };

  handleClickButton = e => {
    // default event stop
    e.preventDefault();
    // Open file select
    return document.getElementById("inputInsuranceCard").click();
  };

  render() {
    if (!_.has(this.props.location.state, "InsuranceCardErr")) {
      return <Redirect from="/my-page" to="/my-page/insurance-card" />;
    }

    const { errors, handleSelectInsuranceCard } = this.props;
    // error handle
    const error = errors
      .filter(item => {
        // [todo] check error type
        return _.includes(
          this.props.location.state.InsuranceCardErr,
          item.code
        );
      })
      .map((item, key) => {
        return (
          <FontPError size="ss" key={key}>
            {item.text}
          </FontPError>
        );
      });

    return (
      <React.Fragment>
        <Heading1>{Resources.title}</Heading1>
        {error.length > 0 && <Panel padding="l">{error}</Panel>}
        <Panel padding="v0hm">
          <CustomInputFile
            id={"inputInsuranceCard"}
            onChange={e => handleSelectInsuranceCard(e)}
          />
          <ButtonFullWidth onClick={e => this.handleClickButton(e)}>
            {Resources.button}
          </ButtonFullWidth>
        </Panel>
      </React.Fragment>
    );
  }
}

export default withRouter(InsuranceCardErr);
