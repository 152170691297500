import React, { Component } from "react";
import styled from "styled-components";

const Checkmark = styled.span`
  display: inline-block;
  vertical-align: middle;
  height: ${p => p.theme.space.medium};
  width: ${p => p.theme.space.medium};
  border: 1px solid ${p => p.theme.radio.border};
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: calc(50% + 1px);
    height: calc(50% + 1px);
    border-radius: 50%;
    margin: auto;
    background: ${p => p.theme.radio.checked};
  }
`;

const InputRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked ~ ${Checkmark} {
    background-color: white;
  }

  :checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const Container = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 35px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  height: ${p => p.theme.space.medium};
  line-height: 1;
`;

const Text = styled.span`
  vertical-align: middle;
`;

class Radio extends Component {
  render() {
    return (
      <Container>
        <InputRadio
          {...this.props.attrs}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.props.customOnChange}
          name={this.props.name}
        />
        <Checkmark />
        <Text>{this.props.children}</Text>
      </Container>
    );
  }
}

export default Radio;
