import { API } from "aws-amplify";
import Reload from "./Reload";

const CLIENT_VERSION = process.env.REACT_APP_CLIENT_VERSION;
const RELOAD_INTERVAL_TIME = 6000;

async function Get(apiName, path, myInit) {
  let opt = {
    headers: {
      "client-version": CLIENT_VERSION,
    },
  };
  if (myInit) {
    Object.assign(opt, myInit);
    if (myInit.headers) {
      opt.headers["client-version"] = CLIENT_VERSION;
    }
  }
  try {
    const result = await API.get(apiName, path, opt);
    localStorage.removeItem("forceReloadCount");
    return result;
  } catch (error) {
    console.log("REST API Get Error", path, error);

    if (error.response && error.response.data) {
      const statusCode = error.response.data.statusCode;
      if (statusCode === 403 || statusCode === 503) {
        if (window.location.pathname.startsWith("/not-access")) return;

        let reloadTimestamp = localStorage.getItem("reloadTimestamp");
        if (
          reloadTimestamp &&
          Date.now() - reloadTimestamp <= RELOAD_INTERVAL_TIME
        )
          return;

        localStorage.setItem("reloadTimestamp", Date.now());
        Reload();
      }
    }
  }
}

async function Post(apiName, path, myInit) {
  let opt = {
    headers: {
      "client-version": CLIENT_VERSION,
    },
  };
  if (myInit) {
    Object.assign(opt, myInit);
    if (myInit.headers) {
      opt.headers["client-version"] = CLIENT_VERSION;
    }
  }

  try {
    const result = await API.post(apiName, path, opt);
    localStorage.removeItem("forceReloadCount");
    return result;
  } catch (error) {
    console.log("REST API POST Error", path, error);

    if (error.response && error.response.data) {
      const statusCode = error.response.data.statusCode;
      if (statusCode === 403 || statusCode === 503) {
        if (window.location.pathname.startsWith("/not-access")) return;

        let reloadTimestamp = localStorage.getItem("reloadTimestamp");
        if (
          reloadTimestamp &&
          Date.now() - reloadTimestamp <= RELOAD_INTERVAL_TIME
        )
          return;

        localStorage.setItem("reloadTimestamp", Date.now());
        Reload();
      }
    }
  }
}

export default {
  Get,
  Post,
};
