
const prefectureAreas = [
  {
    jpName: '関東',
    enName: 'Hokuriku,koshinetsu',
    prefectures: [
      {
        code: 'JP-08',
        jpName: '茨城県',
        enName: 'Ibaraki'
      },
      {
        code: 'JP-09',
        jpName: '栃木県',
        enName: 'Tochigi'
      },
      {
        code: 'JP-10',
        jpName: '群馬県',
        enName: 'Gunma'
      },
      {
        code: 'JP-11',
        jpName: '埼玉県',
        enName: 'Saitama'
      },
      {
        code: 'JP-12',
        jpName: '千葉県',
        enName: 'Chiba'
      },
      {
        code: 'JP-13',
        jpName: '東京都',
        enName: 'Tokyo'
      },
      {
        code: 'JP-14',
        jpName: '神奈川県',
        enName: 'Kanagawa'
      }
    ]
  },
  {
    jpName: '北海道・東北',
    enName: 'Hokkaido,Tohoku',
    prefectures: [
      {
        code: 'JP-01',
        jpName: '北海道',
        enName: 'Hokkaido'
      },
      {
        code: 'JP-02',
        jpName: '青森県',
        enName: 'Aomori'
      },
      {
        code: 'JP-03',
        jpName: '岩手県',
        enName: 'Iwate'
      },
      {
        code: 'JP-04',
        jpName: '宮城県',
        enName: 'Miyagi'
      },
      {
        code: 'JP-05',
        jpName: '秋田県',
        enName: 'Akita'
      },
      {
        code: 'JP-06',
        jpName: '山形県',
        enName: 'Yamagata'
      },
      {
        code: 'JP-07',
        jpName: '福島県',
        enName: 'Fukushima'
      }
    ]
  },
  {
    jpName: '北陸・甲信越',
    enName: 'Hokuriku,koshinetsu',
    prefectures: [
      {
        code: 'JP-15',
        jpName: '新潟県',
        enName: 'Niigata'
      },
      {
        code: 'JP-16',
        jpName: '富山県',
        enName: 'Toyama'
      },
      {
        code: 'JP-17',
        jpName: '石川県',
        enName: 'Ishikawa'
      },
      {
        code: 'JP-18',
        jpName: '福井県',
        enName: 'Fukui'
      },
      {
        code: 'JP-19',
        jpName: '山梨県',
        enName: 'Yamanashi'
      },
      {
        code: 'JP-20',
        jpName: '長野県',
        enName: 'Nagano'
      }
    ]
  },
  {
    jpName: '東海',
    enName: 'Tokai',
    prefectures: [
      {
        code: 'JP-21',
        jpName: '岐阜県',
        enName: 'Gifu'
      },
      {
        code: 'JP-22',
        jpName: '静岡県',
        enName: 'Shizuoka'
      },
      {
        code: 'JP-23',
        jpName: '愛知県',
        enName: 'Aichi'
      },
      {
        code: 'JP-24',
        jpName: '三重県',
        enName: 'Mie'
      }
    ]
  },
  {
    jpName: '近畿',
    enName: 'Kinki',
    prefectures: [
      {
        code: 'JP-25',
        jpName: '滋賀県',
        enName: 'Shiga'
      },
      {
        code: 'JP-26',
        jpName: '京都府',
        enName: 'Kyoto'
      },
      {
        code: 'JP-27',
        jpName: '大阪府',
        enName: 'Osaka'
      },
      {
        code: 'JP-28',
        jpName: '兵庫県',
        enName: 'Hyogo'
      },
      {
        code: 'JP-29',
        jpName: '奈良県',
        enName: 'Nara'
      },
      {
        code: 'JP-30',
        jpName: '和歌山県',
        enName: 'Wakayama'
      }
    ]
  },
  {
    jpName: '中国・四国',
    enName: 'Chugoku,shikoku',
    prefectures: [
      {
        code: 'JP-31',
        jpName: '鳥取県',
        enName: 'Tottori'
      },
      {
        code: 'JP-32',
        jpName: '島根県',
        enName: 'Shimane'
      },
      {
        code: 'JP-33',
        jpName: '岡山県',
        enName: 'Okayama'
      },
      {
        code: 'JP-34',
        jpName: '広島県',
        enName: 'Hiroshima'
      },
      {
        code: 'JP-35',
        jpName: '山口県',
        enName: 'Yamaguchi'
      },
      {
        code: 'JP-36',
        jpName: '徳島県',
        enName: 'Tokushima'
      },
      {
        code: 'JP-37',
        jpName: '香川県',
        enName: 'Kagawa'
      },
      {
        code: 'JP-38',
        jpName: '愛媛県',
        enName: 'Ehime'
      },
      {
        code: 'JP-39',
        jpName: '高知県',
        enName: 'Kochi'
      }
    ]
  },
  {
    jpName: '九州・沖縄',
    enName: 'Kyushu,Okinawa',
    prefectures: [
      {
        code: 'JP-40',
        jpName: '福岡県',
        enName: 'Fukuoka'
      },
      {
        code: 'JP-41',
        jpName: '佐賀県',
        enName: 'Saga'
      },
      {
        code: 'JP-42',
        jpName: '長崎県',
        enName: 'Nagasaki'
      },
      {
        code: 'JP-43',
        jpName: '熊本県',
        enName: 'Kumamoto'
      },
      {
        code: 'JP-44',
        jpName: '大分県',
        enName: 'Oita'
      },
      {
        code: 'JP-45',
        jpName: '宮崎県',
        enName: 'Miyazaki'
      },
      {
        code: 'JP-46',
        jpName: '鹿児島県',
        enName: 'Kagoshima'
      },
      {
        code: 'JP-47',
        jpName: '沖縄県',
        enName: 'Okinawa'
      }
    ]
  }
];

const prefectureCodes = prefectureAreas.reduce(
  (codes, area) => codes.concat(area.prefectures),
  []
).sort(
  (p1, p2) => p1.code > p2.code ? 1 : -1
);

exports.prefectureAreas = prefectureAreas;
exports.prefectureCodes = prefectureCodes;