import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { Hub } from "aws-amplify";
import Video from "twilio-video";

import GraphQLClient from "../../Utils/GraphQLClient";
import { GetAppointment } from "../../Utils/GraphQLQueries";
import TwilioHandler from "../../Utils/TwilioHandler";
import { APPOINTMENT_STATUS, OPERATION_TYPE } from "../../Utils/Constant";
import Guard from "../../Utils/Guard";
import { GetTwilioToken } from "../../Utils/GraphQLQueries";
import { DisconnectVideoChat } from "../../Utils/GraphQLQueries";
import { RegisterServiceLog } from "../../Utils/GraphQLQueries";
import { AddLowNetworkHistoryLog } from "../../Utils/GraphQLQueries";

import OnlineChat from "../organisms/online-chat/OnlineChat";
import OnlineChatDialog from "../organisms/online-chat/OnlineChatDialog";
import ResponsiveTemplate from "../templates/ResponsiveTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";
import { serviceLogLib } from "../../Resources/serviceLogLib";
import * as Sentry from "@sentry/browser";

const SURVEY_CHAT_URL = "/telemedicine/finish";

const LOW_NETWORK_LEVELS = [0, 1, 2];

const NOT_ZERO_NETWORK_LEVELS = [1, 2, 3, 4, 5];

const MAX_DECIBELS_OF_LOW_AUDIO = 5;
const INTERVAL_COUNTS_CHECK_LOW_AUDIO = 2;

class OnlineChatPage extends Component {
  state = {
    openPrompt: true,
    openCaution: true,
    medicalMenu: null,
    medicalMethod: null,
    isDisplayLocalLowNetwork: false,
    isDisplayLocalLowAudio: false,
    localNetWorkLevel: 0,
    hasAddedLocalLowNetworkLog: false,
    hasAddedRemoteLowNetworkLog: false,
    appointment: {},
    localVolumeLowCount: 0,
  };
  twilioHandler = null;
  constructor(props) {
    super(props);
    this.twilioHandler = new TwilioHandler(this);
  }

  async DisconnectVideoChat() {
    await GraphQLClient.mutate({
      mutation: DisconnectVideoChat,
    });
  }

  leaveRoom = async () => {
    this.twilioHandler.leaveRoomIfJoined();
    Hub.dispatch(
      "msg",
      {
        event: "open",
        data: {
          message: japaneseList.pages.OnlineChatPage.leaveRoom.j001,
          level: "info",
        },
      },
      "OnlineChat"
    );

    this.setState({
      openPrompt: false,
      hasAddedLocalLowNetworkLog: false,
      hasAddedRemoteLowNetworkLog: false,
    });
    await this.DisconnectVideoChat();
    this.props.history.push({
      pathname: SURVEY_CHAT_URL,
      state: {
        appointment: this.state.appointment,
        medicalMenu: this.state.medicalMenu,
        medicalMethod: this.state.medicalMethod,
      },
    });
  };

  leaveRoomWhenNoDoctor = async (isChangePage) => {
    this.twilioHandler.leaveRoomIfJoined();
    Sentry.captureMessage(
      "video-chat-error-flow-5-doctorDidNotConnect",
      Sentry.Severity.Log
    );
    await this.DisconnectVideoChat();
    !isChangePage && this.props.history.push("/");
  };

  openPrompt = () => {
    return (
      <NavigationPrompt when={true}>
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            return (
              <OnlineChatDialog
                onCancel={onCancel}
                onConfirm={() => {
                  this.handlePopup();
                  onConfirm();
                }}
              />
            );
          }
        }}
      </NavigationPrompt>
    );
  };

  handlePopup = () => {
    this.twilioHandler.removeLocal();
  };

  onCancel = () => {
    this.setState({
      openCaution: false,
    });
  };

  async componentWillUnmount() {
    const patientWaitTimeoutID = this.twilioHandler.patientWaitTimeoutID;
    patientWaitTimeoutID && clearTimeout(patientWaitTimeoutID);
    if (this.twilioHandler.activeRoom) {
      if (this.twilioHandler.activeRoom.participants.size <= 0) {
        this.leaveRoomWhenNoDoctor(true);
      } else {
        this.twilioHandler.leaveRoomIfJoined();
      }
    }
  }

  async componentDidMount() {
    Sentry.captureMessage("video-chat-page-transfer", Sentry.Severity.Log);

    const {
      data: { twilio },
    } = await GraphQLClient.query({
      query: GetTwilioToken,
    });

    if (!twilio) {
      this.props.history.push("/");
      Sentry.captureMessage("video-chat-error-flow-1", Sentry.Severity.Log);

      return;
    }

    const onlineChatToken = twilio.token;
    const createTime = twilio.appointmentKey;

    if (!onlineChatToken) {
      this.setState(
        {
          openPrompt: false,
        },
        () => this.props.history.push("/")
      );
      Sentry.captureMessage("video-chat-error-flow-2", Sentry.Severity.Log);

      return;
    }

    const {
      data: { appointment },
    } = await GraphQLClient.query({
      query: GetAppointment,
      variables: { createTime },
    });

    this.setState({
      medicalMenu: appointment.menu.medicalMenu,
      medicalMethod: appointment.menu.medicalMethod,
      appointment,
    });

    if (
      ![
        APPOINTMENT_STATUS.BEFORE_EXAM,
        APPOINTMENT_STATUS.EXAM_COMPLETED,
      ].includes(appointment.status)
    ) {
      this.props.history.push("/");
      Sentry.captureMessage("video-chat-error-flow-3", Sentry.Severity.Log);

      return;
    }
    this.twilioHandler.appointment = appointment;
    const connectOptions = {
      preferredVideoCodecs: ["H264"],
      networkQuality: {
        // 取得できるネットワーク品質の情報量となります、設定できる値は下記です。
        // none       0: 全く情報を取得できない。
        // minimal    1：参加者のネットワーク品質のレベル（NetworkQualityLevel）が取得できる。
        // moderate   2：ネットワーク品質のレベル（NetworkQualityLevel）とステータス（NetworkQualityStats）が取得できる。
        // detailed   3：2の設定よりもさらに詳細なネットワーク品質の情報が取得できる。
        // localの設定範囲は1～3です、remoteの設定範囲は0～3です。
        local: 3,
        remote: 3,
      },
    };
    const params = {
      eventType: serviceLogLib.eventType.startVideoChat,
      executorType: serviceLogLib.executorType.patient,
      isSucceeded: true,
      resourceType: serviceLogLib.resourceType.none,
    };
    Video.connect(onlineChatToken, connectOptions)
      .then(this.twilioHandler.roomJoined)
      .then(() => {
        GraphQLClient.mutate({
          mutation: RegisterServiceLog,
          variables: params,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);

        const params = {
          eventType: serviceLogLib.eventType.startVideoChat,
          executorType: serviceLogLib.executorType.patient,
          isSucceeded: false,
          contents: [
            {
              name: "Error",
              value: error.name + "/" + error.message,
            },
            {
              name: "Raw Data",
              value: error,
            },
          ],
          resourceType: serviceLogLib.resourceType.none,
        };
        GraphQLClient.mutate({
          mutation: RegisterServiceLog,
          variables: params,
        });
        this.props.history.push("/");
      });
  }

  addRemoteLowNetworkHistoryLog = async () => {
    const { data, errors } = await GraphQLClient.mutate({
      mutation: AddLowNetworkHistoryLog,
      variables: {
        appointmentId: `${this.twilioHandler.appointment.patientId}/${this.twilioHandler.appointment.createTime}`,
        operationType: OPERATION_TYPE.DOCTOR_LOW_NETWORK,
      },
    });

    if ((data && data.addLowNetworkHistoryLog) || errors) {
      this.setState({ ...this.state, hasAddedRemoteLowNetworkLog: true });
    }
  };

  addLocalLowNetworkHistoryLog = async () => {
    const { data, errors } = await GraphQLClient.mutate({
      mutation: AddLowNetworkHistoryLog,
      variables: {
        appointmentId: `${this.twilioHandler.appointment.patientId}/${this.twilioHandler.appointment.createTime}`,
        operationType: OPERATION_TYPE.PATIENT_LOW_NETWORK,
      },
    });

    if ((data && data.addLowNetworkHistoryLog) || errors) {
      this.setState({ ...this.state, hasAddedLocalLowNetworkLog: true });
    }
  };

  handleLocalNetworkLevelChanged = (level) => {
    if (
      LOW_NETWORK_LEVELS.includes(level) &&
      !this.state.hasAddedLocalLowNetworkLog
    ) {
      this.addLocalLowNetworkHistoryLog();
    }

    this.setState({
      ...this.state,
      isDisplayLocalLowNetwork: LOW_NETWORK_LEVELS.includes(level),
      localNetWorkLevel: level,
    });
  };

  handleRemoteNetworkLevelChanged = (level) => {
    if (
      LOW_NETWORK_LEVELS.includes(level) &&
      NOT_ZERO_NETWORK_LEVELS.includes(this.state.localNetWorkLevel) &&
      !this.state.hasAddedRemoteLowNetworkLog
    ) {
      this.addRemoteLowNetworkHistoryLog();
    }
  };

  handleLocalAudioVolumeLow = (decibel) => {
    if (decibel < MAX_DECIBELS_OF_LOW_AUDIO) {
      this.setState({
        localVolumeLowCount: this.state.localVolumeLowCount + 1,
      });
      if (this.state.localVolumeLowCount >= INTERVAL_COUNTS_CHECK_LOW_AUDIO) {
        this.setState({
          isDisplayLocalLowAudio: true,
        });
      } else {
        this.setState({
          isDisplayLocalLowAudio: false,
        });
      }
    } else {
      this.setState({
        isDisplayLocalLowAudio: false,
        localVolumeLowCount: 0,
      });
    }
  };

  render() {
    const main = (
      <React.Fragment>
        <OnlineChat
          isDisplayLocalLowNetwork={this.state.isDisplayLocalLowNetwork}
          isDisplayLocalLowAudio={this.state.isDisplayLocalLowAudio}
        />
      </React.Fragment>
    );

    return <ResponsiveTemplate title={Titles.onlineChat} main={main} />;
  }
}

export default withRouter(Guard(OnlineChatPage));
