import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { InterviewSheetAnswerDetail } from "../interviewSheet/InterviewSheetAnswerDetail";

const renderResource =
  japaneseList.organisms.AppointmentDetail.InterviewSheetAnswerContent.render;

const Content = styled.div`
  margin-top: 21px;
  padding: 0px 15px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #393939;
  box-sizing: content-box;
  background-color: #f1f3f3;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  display: inline-block;
`;

const Button = styled.div`
  margin: 0 0 0 auto;
  width: 84px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: ${(p) => p.theme.heading.h1.bg};
  background-color: #fff;
  cursor: pointer;
  border: 1.5px solid ${(p) => p.theme.heading.h1.bg};
  border-radius: 5px;
`;

export const InterviewSheetAnswerContent = (props) => {
  const {
    interviewSheetAnswer,
    isShowInterviewSheetAnswerEditButton,
    handleEditButtonClick,
  } = props;

  return (
    <React.Fragment>
      <Content>
        <Title>{renderResource.Title.j001}</Title>
        {isShowInterviewSheetAnswerEditButton && (
          <Button onClick={handleEditButtonClick}>
            {renderResource.Button.j001}
          </Button>
        )}
      </Content>
      <InterviewSheetAnswerDetail interviewSheetAnswer={interviewSheetAnswer} />
    </React.Fragment>
  );
};
