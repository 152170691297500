import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import dateFns from "date-fns";
import axios from "axios";
import Image from "../../atoms/others/Image";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import Button from "../../atoms/buttons/Button";
import { ConfirmPopup } from "../../molecules/popup/ConfirmPopup";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import { japaneseList } from "../../../Resources/japaneseList";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { AttachmentPresignedUrlForGetObject } from "../../../Utils/GraphQLQueries";
import { FORM_TYPE, PATIENT_ATTACHMENT } from "../../../Utils/Constant";
import { toastError } from "../../../Utils/Utility";
import { checkTextInput } from "../../../Utils/PatientAttachment";

const renderResource =
  japaneseList.organisms.reservationForm.ReservationUploadFileDetail;

const Container = styled.div`
  padding: 30px 15px 0px 15px;
  line-height: 1.3;
  margin-bottom: 10px;
`;

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short};
  background-color: #f1f3f3;
`;

const Margin = styled.div`
  padding-top: 10px;
`;

const ImageBox = styled.div`
  text-align: center;
`;

const CustomImage = styled(Image)`
  object-fit: contain;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const DeleteButton = styled(Button)`
  width: 100%;
  background-color: #ff4d4d;
  border-radius: 5px;
  height: 50px;
`;

const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
`;

const InputTextStyle = styled.input.attrs({ type: "text" })`
  width: 100%;
  height: 44px;
  color: ${(p) => p.theme.color.font};
  border: 1px solid ${(p) => p.theme.color.fontVariant};
  border-radius: 5px;
  padding: 6px 5px;
  font-size: 16px;
  background-color: #fff;
  border: solid 1px #c3c3c3;
  border-radius: 0;
  -webkit-appearance: none;
  :focus {
    background-color: #fff;
    outline: 0;
  }
`;

const FileDateTime = styled.div`
  padding-left: 10px;
  padding-top: 5px;
`;

export const ReservationUploadFileDetail = (props) => {
  const {
    file,
    handleUpdateFileThumbnailList,
    handleDeleteFileLocal,
    handleBackButtonDetailFile,
    handleDeleteFile,
    appointmentFrom,
    isFromAppointmentDetailPage,
    setFormType,
    currentFiles,
  } = props;
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [fileDetail, setFileDetail] = useState(null);
  useEffect(() => {
    setFileName(fileSelectedName);
    if (isFromAppointmentDetailPage && !file.originalFile) {
      const getAttachments = async () => {
        try {
          const preSignUrl = await GraphQLClient.query({
            query: AttachmentPresignedUrlForGetObject,
            variables: {
              filePath: file.filePath,
              fileName: file.fileName,
            },
          });
          const fileBuffer = await axios.get(
            preSignUrl.data.attachmentPresignedUrlForGetObject.url,
            {
              responseType: "arraybuffer",
            }
          );
          const blob = new Blob([fileBuffer.data]);
          // file.originalFile = blob;
          setFileDetail(blob);
        } catch (error) {
          // Error if this file had already been deleted,
          toastError(renderResource.j011);
          setFormType(
            currentFiles && currentFiles.length > 0
              ? FORM_TYPE.THUMBNAIL_LIST
              : FORM_TYPE.NO_FILE
          );
        } finally {
          setIsLoading(false);
        }
      };
      getAttachments();
    }
  }, []);
  const reservationDay = new Date(appointmentFrom);
  const reservationDayNextMonth = dateFns.addMonths(reservationDay, 1);
  const lastDayofMonth = dateFns.endOfMonth(reservationDayNextMonth);
  const expiredDay = dateFns.format(lastDayofMonth, "YYYY/MM/DD");

  const fileSelectedName = file.fileName
    ? file.fileName
    : file.originalFile.name
    ? file.originalFile.name.split(".").slice(0, -1).join("")
    : "";

  const handleClickUpdateFileName = useCallback((e) => {
    // default event stop
    e.preventDefault();
    const fileNameInput = checkTextInput(fileName.trim());
    switch (fileNameInput) {
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_EMPTY:
        toastError(renderResource.j008);
        return;
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_MAX_CHAR:
        toastError(renderResource.j009);
        return;
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_INCLUDE_UNUSUAL:
        toastError(renderResource.j010);
        return;
      default:
        break;
    }
    handleUpdateFileThumbnailList(file.fileId, fileNameInput);
  });

  const handleInputFileName = useCallback((e) => {
    // default event stop
    e.preventDefault();
    const { value } = e.target;
    setFileName(value);
  });

  const handleDeleFile = useCallback(() => {
    isFromAppointmentDetailPage
      ? handleDeleteFile(file.fileId)
      : handleDeleteFileLocal(file.fileId);
    setIsOpenConfirmPopup(false);
  });

  const handleConfirmDeleFile = useCallback((e) => {
    e.preventDefault();
    setIsOpenConfirmPopup(true);
  });

  const handleClosePopup = useCallback(() => {
    setIsOpenConfirmPopup(false);
  });

  return (
    <React.Fragment>
      {isFromAppointmentDetailPage && !file.originalFile && (
        <LoadingScreen isLoading={isLoading} />
      )}
      <FormGroup style={isFromAppointmentDetailPage && { marginTop: "21px" }}>
        <Margin>
          <InputTextStyle
            onChange={handleInputFileName}
            name="fileName"
            value={fileName}
          />
        </Margin>
      </FormGroup>
      {(file.originalFile || fileDetail) && (
        <ImageMemo file={file.originalFile ? file.originalFile : fileDetail} />
      )}
      {isFromAppointmentDetailPage && (
        <FileDateTime>
          {renderResource.j004}: {expiredDay} {renderResource.j005}
        </FileDateTime>
      )}
      <Container>
        <FullWidthButtonCustom
          id="reserved-update-image-name-confirm-button"
          onClick={handleClickUpdateFileName}
        >
          {renderResource.j001}
        </FullWidthButtonCustom>
        <ButtonContainer>
          <DeleteButton
            id="reserved-delete-image-confirm-button"
            onClick={handleConfirmDeleFile}
          >
            {renderResource.j002}
          </DeleteButton>
        </ButtonContainer>
        {!isFromAppointmentDetailPage && (
          <ButtonContainer>
            <FullWidthOutlinedButton
              id="reserved-update-image-back-button"
              onClick={handleBackButtonDetailFile}
            >
              {renderResource.j003}
            </FullWidthOutlinedButton>
          </ButtonContainer>
        )}
      </Container>
      <ConfirmPopup
        isOpen={isOpenConfirmPopup}
        handleClosePopup={handleClosePopup}
        handleDeleFile={handleDeleFile}
      />
    </React.Fragment>
  );
};

const ImageMemo = React.memo(({ file }) => {
  const objectURL = URL.createObjectURL(file);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  }, [objectURL]);

  return (
    <ImageBox>
      <CustomImage src={objectURL} />
    </ImageBox>
  );
});
