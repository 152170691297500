import React from "react";
import styled from "styled-components";
import { prefectureCodes } from "../../Utils/PrefectureCode";

interface Props {
  pharmacy: Hospital;
}

interface Hospital {
  hospitalId: string;
  name: string;
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  tel: string;
}

const Container = styled.div`
  position: relative;
  vertical-align: middle;
  font-size: 16px;
  color: #393939;
  font-weight: 300;
  right: 35px;
`;

const Title = styled.div`
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  color: #393939;
`;

const Content = styled.div`
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  color: #393939;
`;

export const PharmacySelectItem = (props: Props) => {
  const { pharmacy } = props;

  const prefectureName = (prefectureCode: string) => {
    const prefectureData = prefectureCodes.find(
      (p) => p.code === prefectureCode
    );
    return prefectureData && prefectureData.jpName;
  };

  return (
    <Container>
      {pharmacy && (
        <React.Fragment>
          <Title>{pharmacy.name || ""}</Title>
          <Content>
            {prefectureName(pharmacy.prefectureCode) || ""}
            {`${pharmacy.addressCity || ""}${
              pharmacy.addressStreetNumber || ""
            }`}
          </Content>
          <Content>{pharmacy.addressBuilding || ""}</Content>
        </React.Fragment>
      )}
    </Container>
  );
};
