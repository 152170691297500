import React from "react";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import LinkStyle from "../../atoms/links/LinkStyle";
import SiteContext from "../../../SiteContext";

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${p => p.theme.color.fontInvert};
  background-color: ${p => p.theme.color.secondary};
  padding: ${p => p.theme.space.normal} 8px ${p => p.theme.space.normal}
    ${p => p.theme.space.normal}
`;

const CustomLinkStyle=styled(LinkStyle)`
  height:24px;
`;

export default ({ children, ...props }) => (
  <Root>
    {children}
    <SiteContext.Consumer>
      {value => value.ui !== "lnln" && <CustomLinkStyle onClick={props.onClose}><Close /></CustomLinkStyle>}
    </SiteContext.Consumer>
  </Root>
);
