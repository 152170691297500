import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import ImgClearPath from "../../images/clear-24px.svg";
import ImgErrorPath from "../../images/error_outline-24px.svg";
import { HashLink } from "react-router-hash-link";
import { device } from "../device";
import { withRouter } from "react-router-dom";
const imgClear = ImgClearPath;
const imgError = ImgErrorPath;
const renderResource = japaneseList.Components.UnsupportedAlertMessage;
const unsupportedDeviceAlert = document.getElementById(
  "unsupportedDeviceAlert"
);

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  @media screen and ${device.tb} {
    width: 424px;
    border-right: thin solid #c7c7c7;
    border-left: thin solid #c7c7c7;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.05),
      0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;

const UnsupportedAlertBox = styled.div`
  position: fixed;
  background: #393939 0% 0% no-repeat padding-box;
  margin: 0;
  color: white;
  text-align: left;
  bottom: 0;
  z-index: 10;
  max-height: 250px;
  width: inherit;
  opacity: 0.8;
`;

const UnsupportedAlertTitle = styled.div`
  line-height: 1;
  font-weight: bold;
  text-align: left;
  padding-top: 16px;
  padding-left: 15px;
  padding-bottom: 0;
  font-size: 15px;
  display: block;
  color: #ffffff;
`;

const UnsupportedAlertContent = styled.div`
  display: block;
  font-size: 13px;
  padding: 11px 15px 15px;
  color: #ffffff;
`;

const CloseSign = styled.div`
  margin-top: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  float: right;
  cursor: pointer;
`;

const ErrorSign = styled.span`
  display: inline;
  vertical-align: text-top;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-right: 4px;
`;

const LinkToEnv = styled(HashLink)`
  padding: 0 15px 15px 0px;
  float: right;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`;

class UnsupportedDeviceAlert extends Component {
  constructor(props) {
    super(props);
    this.container = document.createElement("div");
    this.state = {
      isOpen: true,
      message: renderResource.j002,
    };
  }

  componentDidMount() {
    this.unregisterHistoryCallback = this.props.history.listen(() => {
      this.setState({ isOpen: true });
    });
    unsupportedDeviceAlert.appendChild(this.container);
  }

  componentWillUnmount() {
    unsupportedDeviceAlert.removeChild(this.container);
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const Title = renderResource.j001;
    const { isOpen, message } = this.state;
    const { blockStartMessage } = this.props;
    return ReactDOM.createPortal(
      isOpen && (
        <Root>
          {isOpen ? (
            <UnsupportedAlertBox>
              <CloseSign onClick={this.close}>
                <img src={imgClear} alt={''} />
              </CloseSign>
              <UnsupportedAlertTitle>
                <ErrorSign>
                  <img src={imgError} alt={''} />
                </ErrorSign>
                {Title}
              </UnsupportedAlertTitle>
              <UnsupportedAlertContent>
                {blockStartMessage ? blockStartMessage : message}
              </UnsupportedAlertContent>
              <LinkToEnv to={"/inquiry#environment"}>
                {renderResource.j003}
              </LinkToEnv>
            </UnsupportedAlertBox>
          ) : (
            ""
          )}
        </Root>
      ),
      this.container
    );
  }
}

export default withRouter(UnsupportedDeviceAlert);
