import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";

const EnterpriseContainer = styled.div`
  margin: 20px 14px;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const NameTitle = styled.div`
  height: 30px;
  background: #f1f3f3;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  text-align: center;
`;

const EnterpriseNameTitle = styled(NameTitle)`
  border-radius: 5px 5px 0px 0px;
`;

const EnterpriseName = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: left;
`;

const PlanNames = styled.div`
  padding: 10px 10px 5px;
`;

const PlanName = styled.div`
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
`;

const Content = styled(FontP)`
  font-size: 14px;
`;

export const EnterpriseContents = ({ enterprise }) => {
  const renderResource = japaneseList.organisms.MyPage.render;
  return (
    <EnterpriseContainer>
      <EnterpriseNameTitle>
        {renderResource.div.Enterprise.j001}
      </EnterpriseNameTitle>
      <EnterpriseName>{enterprise.enterpriseName}</EnterpriseName>
      <NameTitle>{renderResource.div.Enterprise.j002}</NameTitle>
      <PlanNames>
        {enterprise.contractPlans.length ? (
          enterprise.contractPlans.map((item, index) => {
            return <PlanName key={index}>{item.contractPlanName}</PlanName>;
          })
        ) : (
          <Content>{renderResource.div.Enterprise.j003}</Content>
        )}
      </PlanNames>
    </EnterpriseContainer>
  );
};
