import React from "react";
import styled from "styled-components";
import Checkbox from "../../atoms/forms/Checkbox";

const CheckboxElement = styled(Checkbox)`
  margin-right: 5px;
  vertical-align: middle;
`;

const Title = styled.div`
  vertical-align: middle;
  font-size: 11px;
`;

const LabelFor = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = (props) => (
  <LabelFor className={props.className} htmlFor={props.id}>
    <CheckboxElement
      onChange={props.onChange}
      checked={props.checked}
      id={props.id}
    />
    <Title>{props.label}</Title>
  </LabelFor>
);

export default CheckboxLabel;
