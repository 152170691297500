import styled from "styled-components";

const FontBase = styled.p`
  padding:0;
  margin:0;
  color: ${p => p.theme.font.color.default};
  font-size: ${p => p.theme.font.size.default};
  line-height:1.5;
  white-space: pre-wrap;
`;

export default FontBase;
