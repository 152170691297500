import React from "react";
import Radio from "../atoms/forms/Radio";

export default class RadioGroup extends React.Component {
  render() {
    let children = React.Children.map(this.props.children, child => {
      if (child.type === Radio) {
        return React.cloneElement(child, {
          name: this.props.name ? this.props.name : "radio",
          checked: this.props.value === child.props.value ? true : false,
          customOnChange: this.props.onChange
            ? this.props.onChange.bind(this)
            : () => {}
        });
      } else {
        return child;
      }
    });

    return <div className="radio-group">{children}</div>;
  }
}
