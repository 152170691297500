import { Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import { GetMediaDeviceInfo } from "./MediaDevice";
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

const audioContextBufferSize = 16384;
const numberOfInputChannels = 1;
const numberOfOutputChannels = 1;
const MINUTES_INTERVAL_OUTPUT_SENTRY_LOG_DURING_VIDEO_CHAT = 4;
const SECONDS_WAIT_DOCTOR_BEFORE_VIDEO_CHAT = 20;

class TwilioHandler {
  activeRoom;
  previewTracks;
  identity;
  roomName;
  component;
  patientWaitTimeoutID;
  appointment;
  audioContext;

  constructor(component) {
    this.attachParticipantTracks = this.attachParticipantTracks.bind(this);
    this.detachParticipantTracks = this.detachParticipantTracks.bind(this);
    this.roomJoined = this.roomJoined.bind(this);
    this.component = component;
    try {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    } catch (err) {
      console.error(err);
    }
  }

  // Attach the Tracks to the DOM.
  attachTracks(tracks, container) {
    tracks.forEach((publication) => {
      if (!publication.track) {
        return;
      }
      try {
        container.appendChild(publication.track.attach());
        // dispatch video call process, and output to sentry log.
        this.dispatchProcessForTrack(publication.track);
      } catch (err) {
        //TODO: not defined how to handle this error
        console.error(err);
      }
    });
  }

  // Attach the Participant's Tracks to the DOM.
  attachParticipantTracks(participant, container) {
    let tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
  }

  // Detach the Tracks from the DOM.
  detachTracks(tracks) {
    tracks.forEach((publication) => {
      try {
        publication.track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      } catch (err) {
        //TODO: not defined how to handle this error
      }
    });
  }

  stopTracks(tracks) {
    tracks.forEach((track) => {
      track.stop();
    });
  }

  // Detach the Participant's Tracks from the DOM.
  detachParticipantTracks(participant) {
    let tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  }

  leaveRoomIfJoined() {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
  }

  removeLocal() {
    if (this.activeRoom) {
      this.stopTracks(
        Array.from(this.activeRoom.localParticipant.tracks.values())
      );
    }
  }

  // Successfully connected!
  roomJoined(room) {
    const intervalID = setInterval(() => {
      Sentry.captureMessage("video-chat-interval", Sentry.Severity.Log);
    }, MINUTES_INTERVAL_OUTPUT_SENTRY_LOG_DURING_VIDEO_CHAT * 60 * 1000);

    Sentry.addBreadcrumb({
      message: "video-chat-room-join",
      level: Sentry.Severity.Log,
    });

    // Media Device Info Output To Sentry Log.
    GetMediaDeviceInfo()
      .then((devicesInfo) => {
        Sentry.addBreadcrumb({
          message: `video-chat-mediaDevices\n${devicesInfo}`,
          level: Sentry.Severity.Log,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    let self = this;
    self.activeRoom = room;

    if (room.participants.size <= 0) {
      self.patientWaitTimeoutID = setTimeout(() => {
        self.patientWaitTimeoutID && clearTimeout(self.patientWaitTimeoutID);
        if (room.participants.size <= 0) {
          self.component.leaveRoomWhenNoDoctor(false);
        }
      }, SECONDS_WAIT_DOCTOR_BEFORE_VIDEO_CHAT * 1000);
    }

    // Attach LocalParticipant's Tracks, if not already attached.
    let previewContainer = document.getElementById("local-media");
    if (!previewContainer.querySelector("video")) {
      self.attachParticipantTracks(room.localParticipant, previewContainer);
    }

    // Attach the Tracks of the Room's Participants.
    room.participants.forEach((participant) => {
      let previewContainer = document.getElementById("remote-media");
      self.attachParticipantTracks(participant, previewContainer);
      participant.on("networkQualityLevelChanged", (networkQualityLevel) => {
        this.component.handleRemoteNetworkLevelChanged(networkQualityLevel);
      });
    });

    // When a Participant joins the Room, log the event.
    room.on("participantConnected", (participant) => {
      self.patientWaitTimeoutID && clearTimeout(self.patientWaitTimeoutID);
      const remoteParticipant = room.participants.get(participant.sid);
      this.component.handleRemoteNetworkLevelChanged(
        remoteParticipant.networkQualityLevel
      );
      remoteParticipant.on(
        "networkQualityLevelChanged",
        (networkQualityLevel) => {
          this.component.handleRemoteNetworkLevelChanged(networkQualityLevel);
        }
      );
      Sentry.addBreadcrumb({
        message: "video-chat-room-participantConnected",
        level: Sentry.Severity.Log,
      });
    });

    // When a Participant adds a Track, attach it to the DOM.
    room.on("trackSubscribed", (track, participant) => {
      let previewContainer = document.getElementById("remote-media");
      const tracks = [{ track: track }];
      self.attachTracks(tracks, previewContainer);

      Sentry.addBreadcrumb({
        message: "video-chat-trackSubscribed",
        level: Sentry.Severity.Log,
      });
    });

    // When a Participant removes a Track, detach it from the DOM.
    room.on("trackUnsubscribed", (track, participant) => {
      const tracks = [{ track: track }];
      self.detachTracks(tracks);

      Sentry.addBreadcrumb({
        message: "video-chat-trackUnsubscribed",
        level: Sentry.Severity.Log,
      });
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on("participantDisconnected", (participant) => {
      clearInterval(intervalID);
      self.detachParticipantTracks(participant);
      self.component.leaveRoom();
      Hub.dispatch("auth", { event: "showDuringChattingMessage" });

      Sentry.captureMessage(
        "video-chat-participantDisconnected",
        Sentry.Severity.Log
      );
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on("disconnected", () => {
      clearInterval(intervalID);
      // reset mic input volume acquired.
      self.resetMicInputLog();

      if (self.previewTracks) {
        self.previewTracks.forEach((track) => {
          track.stop();
        });
      }
      self.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(self.detachParticipantTracks);
      self.activeRoom = null;

      Sentry.captureMessage(
        "video-chat-room-disconnected",
        Sentry.Severity.Log
      );
    });

    this.component.handleLocalNetworkLevelChanged(
      room.localParticipant.networkQualityLevel
    );

    // When Network Quality Level changed, Output To Sentry Log.
    room.localParticipant.on(
      "networkQualityLevelChanged",
      (networkQualityLevel) => {
        this.component.handleLocalNetworkLevelChanged(networkQualityLevel);
        Sentry.addBreadcrumb({
          message: `video-chat-networkQuality-level${room.localParticipant.networkQualityLevel}`,
          level: Sentry.Severity.Log,
        });
      }
    );
  }

  // dispatch video call process, and output to sentry log.
  dispatchProcessForTrack = (track) => {
    if (track.kind === "audio" && track instanceof LocalAudioTrack) {
      // local mic input volume acquired, output to sentry log. and check mic input volume low to show warning.
      this.onAcquiredMicInputVolume(
        track.mediaStreamTrack,
        this.audioContext,
        (decibel) => {
          this.loggingMicInput("video-chat-localMicInput-", decibel);
          this.component.handleLocalAudioVolumeLow(decibel);
        }
      );
      this.loggingInputMediaLabel(
        track.mediaStreamTrack.label,
        "video-chat-inputMediaLabel-audio"
      );
    } else if (track.kind === "audio") {
      const remoteAudio = document
        .getElementById("remote-media")
        .querySelector("audio");
      if (remoteAudio) {
        // listener remote audio events, output to sentry log.
        this.addEventListeners(remoteAudio, "remote", "Audio");
        // when video start, output remote audio tag volume to sentry log.
        this.loggingVolumeLevel(
          remoteAudio,
          "video-chat-remoteAudioVolume-level"
        );
      }
      // remote mic input volume acquired, output to sentry log.
      this.onAcquiredMicInputVolume(
        track.mediaStreamTrack,
        this.audioContext,
        (decibel) => {
          this.loggingMicInput("video-chat-remoteMicInput-", decibel);
        }
      );
    } else if (track.kind === "video" && track instanceof LocalVideoTrack) {
      const localVideo = document
        .getElementById("local-media")
        .querySelector("video");
      if (localVideo) {
        // listener local video events, output to sentry log.
        this.addEventListeners(localVideo, "local", "Video");
        this.addVideoEventListeners(localVideo, "local");
      }
      this.loggingInputMediaLabel(
        track.mediaStreamTrack.label,
        "video-chat-inputMediaLabel-video"
      );
    } else if (track.kind === "video") {
      const remoteVideo = document
        .getElementById("remote-media")
        .querySelector("video");
      if (remoteVideo) {
        // listener remote video events, output to sentry log.
        this.addEventListeners(remoteVideo, "remote", "Video");
        this.addVideoEventListeners(remoteVideo, "remote");
        // when video start, output remote video tag volume to sentry log.
        this.loggingVolumeLevel(
          remoteVideo,
          "video-chat-remoteVideoVolume-level"
        );
      }
    }
  };

  // output mic input volume to sentry log.
  loggingMicInput = (message, decibel) => {
    Sentry.addBreadcrumb({
      message: `${message}${decibel}dB`,
      level: Sentry.Severity.Log,
    });
  };

  // acquired mic input volume.
  onAcquiredMicInputVolume = (audioTrack, audioContext, callback) => {
    if (!audioTrack || !audioContext) {
      return;
    }
    const mediaStreamSource = audioContext.createMediaStreamSource(
      new MediaStream([audioTrack])
    );
    const scriptProcessor = audioContext.createScriptProcessor(
      audioContextBufferSize,
      numberOfInputChannels,
      numberOfOutputChannels
    );
    mediaStreamSource.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    const decibels = [];
    scriptProcessor.onaudioprocess = (audioEvent) => {
      decibels.push(
        Math.max.apply(null, audioEvent.inputBuffer.getChannelData(0))
      );
      // every 10 seconds, about 30 mic input volumes acquired.
      // execute callback the max mic input volume.
      if (decibels.length >= 30) {
        const decibel =
          Math.ceil(Math.max.apply(null, decibels) * Math.pow(10, 4)) /
          Math.pow(10, 2);
        decibels.splice(0);
        callback(decibel);
      }
    };
  };

  // reset mic input volume acquired.
  resetMicInputLog = () => {
    try {
      if (this.audioContext) {
        this.audioContext.close();
      }
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    } catch (err) {
      console.error(err);
    }
  };

  // listener audio and video events.
  addEventListeners = (element, location, media) => {
    // 再生コンテンツ終了／再生一時停止／再生開始／データ不足後再生再開始／
    // データ読込エラー／データ読込一時停止／データ不足／音量変更
    const eventTypes = [
      "ended",
      "pause",
      "play",
      "playing",
      "stalled",
      "suspend",
      "waiting",
      "volumechange",
    ];
    eventTypes.forEach((eventType) => {
      element.addEventListener(
        eventType,
        (event) => {
          this.loggingMediaEvent(event, `video-chat-${location}${media}Event-`);
          if (location === "remote" && event.type === "volumechange") {
            this.loggingVolumeLevel(
              event.target,
              `video-chat-${location}${media}Volume-level`
            );
          }
        },
        false
      );
    });
  };

  // listener video only events.
  addVideoEventListeners = (element, location) => {
    // リサイズイベントを間引きするための判定関数
    const sizeOf = {};
    const isResizedHTMLVideoElement = ({
      clientWidth,
      clientHeight,
      videoWidth,
      videoHeight,
    } = {}) => {
      if (
        sizeOf.clientWidth === clientWidth &&
        sizeOf.clientHeight === clientHeight &&
        sizeOf.videoWidth === videoWidth &&
        sizeOf.videoHeight === videoHeight
      ) {
        return false;
      }
      sizeOf.clientWidth = clientWidth;
      sizeOf.clientHeight = clientHeight;
      sizeOf.videoWidth = videoWidth;
      sizeOf.videoHeight = videoHeight;
      return true;
    };
    // リサイズ
    element.addEventListener(
      "resize",
      (event) => {
        if (!isResizedHTMLVideoElement(event.target)) {
          return;
        }
        this.loggingDisplaySizeAndResolution(
          event.target,
          `video-chat-${location}VideoDisplaySize-`,
          `video-chat-${location}VideoDefinitionSize-`
        );
      },
      false
    );
  };

  // output media label to sentry log.
  loggingInputMediaLabel = (label, message) => {
    Sentry.addBreadcrumb({
      message: `${message}\nlabel:${label}`,
      level: Sentry.Severity.Log,
    });
  };

  // output media event to sentry log.
  loggingMediaEvent = (event, message) => {
    Sentry.addBreadcrumb({
      message: `${message}${event.type}`,
      level: Sentry.Severity.Log,
    });
  };

  // output volume level to sentry log.
  loggingVolumeLevel = (element, message) => {
    const volumeLevel = element.muted ? 0 : element.volume * Math.pow(10, 2);
    Sentry.addBreadcrumb({
      message: `${message}${volumeLevel}`,
      level: Sentry.Severity.Log,
    });
  };

  // output display size and resolution to sentry log.
  loggingDisplaySizeAndResolution = (
    element,
    displaySizeMessage,
    definitionSizeMessage
  ) => {
    Sentry.addBreadcrumb({
      message: `${displaySizeMessage}${element.clientWidth}x${element.clientHeight}`,
      level: Sentry.Severity.Log,
    });
    Sentry.addBreadcrumb({
      message: `${definitionSizeMessage}${element.videoWidth}x${element.videoHeight}`,
      level: Sentry.Severity.Log,
    });
  };
}

export default TwilioHandler;
