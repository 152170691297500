import { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { RegisterServiceLog } from "../../../Utils/GraphQLQueries";
import { serviceLogLib } from "../../../Resources/serviceLogLib";
class SignOutPage extends Component {
  async componentDidMount() {
    const params = {
      eventType: serviceLogLib.eventType.logout,
      executorType: serviceLogLib.executorType.patient,
      isSucceeded: true,
      resourceType: serviceLogLib.resourceType.none,
    };
    GraphQLClient.mutate({
      mutation: RegisterServiceLog,
      variables: params,
    });
    await GraphQLClient.clearStore();
    await Auth.signOut();
    this.props.history.push("/");
  }

  render() {
    return null;
  }
}

export default withRouter(SignOutPage);
