import React from "react";
import styled from "styled-components";
import InputFormHeaderNoIcon from "../../molecules/InputFormHeaderNoIcon";
import { AddressDetail } from "../../molecules/AddressDetail";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { japaneseList } from "../../../Resources/japaneseList";
import { MEDICINE_DELIVERY_METHOD } from "../../../Utils/Constant";
import FontP from "../../atoms/fonts/FontP";
const DISPLAYSITES = {
  NONE: "none",
};
const ACCOUNTSTATUS = {
  ENABLED: "enabled",
};

const renderResource = japaneseList.molecules.MedicineDeliveryDetail.render.div;

interface Props {
  appointment: Appointment;
  patientInfo: PatientInfo;
  handleGoToLinK: () => void;
  showEditMedicineDelivery: () => void;
}

interface Appointment {
  medicineDelivery: MedicineDelivery;
}

interface MedicineDelivery {
  deliveryMethod: string;
  name: string;
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  tel: string;
  isEditable: boolean;
  accountStatus: string;
  displaySites: string;
}

interface PatientInfo {
  familyName: string;
  givenName: string;
  address: Address;
}

interface Address {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

const CustomInputFormHeader = styled(InputFormHeaderNoIcon)`
  margin: 10px 0px 20px 0px;
  background-color: #f1f3f3;
  font-family: Hiragino Kaku Gothic ProN;
  font-weight: 600 !important;
  color: #333333 !important;
`;

const DescripInfo = styled.div`
  border: 1px solid #cccccc;
  background: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  position: relative;
`;

const BoldText = styled(FontP)`
  color: #393939;
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
`;

const BoldTextWarningTop = styled(BoldText)`
  margin: 20px 0;
`;

const DescripText = styled(FontP)`
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  color: #393939;
`;

const DescripTextHospitalFirst = styled(DescripText)`
  margin-top: 10px;
`;

const UpdateButton = styled.div`
  width: 72px;
  height: 21px;
  border: 1px solid ${(p) => p.theme.button.background.color.default};
  font-size: 12px;
  color: ${(p) => p.theme.button.background.color.default};
  border-radius: 2px;
  line-height: 21px;
  text-align: center;
  font-weight: bolder;
  float: right;
  margin-top: -48px;
  background: #ffffff;
  margin-right: 10px;
  cursor: pointer;
`;

const AddressDescrip = styled.div`
  padding: 0px 15px;
`;

const ChevronRightIconBox = styled(ChevronRightIcon)`
  color: #cccccc;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const DeliveryMethodWarningText = styled(FontP)`
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  color: #000000;
  font-family: Hiragino Kaku Gothic ProN;
`;

const DeliveryMethodWarningText2 = styled(DeliveryMethodWarningText)`
  font-weight: 600;
  border: 1px solid red;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  color: #ff4d4d;
  margin-bottom: 5px;
  font-size: 12px;
`;

const DescripTextColorBottom = styled.div`
  padding: 0px 15px;
  margin-top: 20px;
  word-break: break-word;
  white-space: pre-wrap;
  color: #999999;
  font-size: 12px;
  font-family: Hiragino Kaku Gothic ProN;
`;

const PharmacyWarning = styled(FontP)`
  font-size: 12px;
  color: #ff4d4d;
  margin: 14px 0px -9px 14px;
`;

const WarningText = styled.span`
  color: #ff4d4d;
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const MedicineDeliveryDetail = (props: Props) => {
  const { appointment, patientInfo, handleGoToLinK, showEditMedicineDelivery } =
    props;

  return (
    <React.Fragment>
      <CustomInputFormHeader>
        {renderResource.Container.Header.j001}
      </CustomInputFormHeader>
      {appointment.medicineDelivery.isEditable && (
        <UpdateButton
          id="change-receive-button"
          onClick={showEditMedicineDelivery}
        >
          {renderResource.Container.ButtonContainer.j001}
        </UpdateButton>
      )}
      {appointment.medicineDelivery.deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.NONE && (
        <AddressDescrip>
          <BoldText>{renderResource.Message.j007}</BoldText>
        </AddressDescrip>
      )}
      {appointment.medicineDelivery.deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME && (
        <AddressDescrip>
          <React.Fragment>
            <BoldText>{renderResource.Message.j001}</BoldText>
            <DescripTextHospitalFirst>
              {renderResource.Message.j003}
            </DescripTextHospitalFirst>
            <DescripText>{renderResource.Message.j005}</DescripText>
          </React.Fragment>
          <DescripInfo id="delivery-address-link" onClick={handleGoToLinK}>
            <AddressDetail
              name={
                patientInfo &&
                `${patientInfo.familyName} ${patientInfo.givenName}`
              }
              address={patientInfo && patientInfo.address}
            />
            <ChevronRightIconBox />
          </DescripInfo>
        </AddressDescrip>
      )}
      {appointment.medicineDelivery.deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.DELIVERY && (
        <AddressDescrip>
          <React.Fragment>
            <BoldTextWarningTop>
              {renderResource.Message.j002}
            </BoldTextWarningTop>
            <DeliveryMethodWarningText2>
              {renderResource.Message.j004}
            </DeliveryMethodWarningText2>
            <DeliveryMethodWarningText>
              {renderResource.Message.j006}
              <WarningText>{renderResource.Message.j010}</WarningText>
              {renderResource.Message.j011}
            </DeliveryMethodWarningText>
          </React.Fragment>
          <DescripInfo id="delivery-address-link" onClick={handleGoToLinK}>
            {!(
              appointment.medicineDelivery.accountStatus ===
                ACCOUNTSTATUS.ENABLED &&
              appointment.medicineDelivery.displaySites !== DISPLAYSITES.NONE
            ) && (
              <PharmacyWarning>{renderResource.Message.j009}</PharmacyWarning>
            )}
            <AddressDetail
              name={appointment.medicineDelivery.name}
              address={{
                postalCode: appointment.medicineDelivery.postalCode,
                prefectureCode: appointment.medicineDelivery.prefectureCode,
                addressCity: appointment.medicineDelivery.addressCity,
                addressStreetNumber:
                  appointment.medicineDelivery.addressStreetNumber,
                addressBuilding: appointment.medicineDelivery.addressBuilding,
                tel: appointment.medicineDelivery.tel,
              }}
            />
            {appointment.medicineDelivery.accountStatus ===
              ACCOUNTSTATUS.ENABLED &&
              appointment.medicineDelivery.displaySites !==
                DISPLAYSITES.NONE && <ChevronRightIconBox />}
          </DescripInfo>
        </AddressDescrip>
      )}
      {(appointment.medicineDelivery.deliveryMethod ===
        MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME ||
        appointment.medicineDelivery.deliveryMethod ===
          MEDICINE_DELIVERY_METHOD.DELIVERY) && (
        <DescripTextColorBottom>
          {renderResource.Message.j008}
        </DescripTextColorBottom>
      )}
    </React.Fragment>
  );
};
