import React, { useCallback, useState } from "react";
import FullWidthButton from "../atoms/buttons/ButtonFullWidth";
import Button from "../atoms/buttons/Button";
import Heading2 from "../atoms/headings/Heading2";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import { FileError } from "../molecules/patient-upload-file/FileError";
import { UploadFilePopup } from "../molecules/popup/UploadFilePopup";

const renderResource = japaneseList.pages.ReservationUploadFileErrorPage.render;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
  margin: 14px;
  width: calc(100% - 28px);
  font-weight: bold;
`;

const FullWidthOutlinedButton = styled(Button)`
  margin: 1px 14px;
  width: calc(100% - 28px);
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
  font-weight: bold;
`;

const Heading2Custom = styled(Heading2)`
  height: 48px;
  background-color: transparent;
  border-bottom: 1.5px solid ${(p) => p.theme.color.primary};
  display: flex;
  align-items: center;
  color: #393939
  padding-left: 18px;
`;

const ErrorContainer = styled.div`
  margin: 20px 0;
`;

export const PatientAttachmentError = (props) => {
  const { errorType, handleSelectImage, handleGoBack } = props;
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleOpenPopup = useCallback(() => {
    setIsOpenPopup(true);
  });

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);
  });

  return (
    <React.Fragment>
      <Heading2Custom>{renderResource.Titles.j002}</Heading2Custom>
      <ErrorContainer>
        <FileError errorType={errorType}></FileError>
        <FullWidthButtonCustom onClick={handleOpenPopup}>
          {renderResource.Buttons.j001}
        </FullWidthButtonCustom>
        <FullWidthOutlinedButton onClick={handleGoBack}>
          {renderResource.Buttons.j002}
        </FullWidthOutlinedButton>
        <UploadFilePopup
          isOpen={isOpenPopup}
          handleSelectImage={handleSelectImage}
          handleClosePopup={handleClosePopup}
        />
      </ErrorContainer>
    </React.Fragment>
  );
};
