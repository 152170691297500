import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: ${p => p.theme.color.font};
  font-size: ${p => p.theme.font.size.ss};
`;

export default ({ children, ...props }) => (
  <Container className={props.className}>{children}</Container>
);
