import styled from "styled-components";
import FontBase from "./FontBase";

const FontPCenter = styled(FontBase)`
  text-align: center;
  ${(p) =>
    p.size && p.theme.font.size[p.size]
      ? "font-size:" + p.theme.font.size[p.size] + ";"
      : ""}
`;

export default FontPCenter;
