import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Titles from "../../../Resources/Titles";
import FontP from "../../atoms/fonts/FontP";
import Heading1 from "../../atoms/headings/Heading1";
import { EnterpriseAuthenticationContent } from "../../organisms/enterprise/EnterpriseAuthenticationContent";
import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import { useAuthContext } from "../../../contexts/AuthContext";

const Explanation = styled(FontP)`
  padding: 20px 14px;
  font-size: 14px;
`;

export const EnterpriseAuthenticationPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useAuthContext();
  const renderResource = japaneseList.pages.EnterpriseAuthenticationPage;

  useEffect(() => {
    if (authContext.isLoading) return;
    if (authContext.isAuthenticated) {
      props.history.push("/enterprise/top");
      return;
    }
    setIsLoading(false);
  }, [authContext.isLoading]);

  const main = (
    <React.Fragment>
      <Heading1>{Titles.enterpriseAuthentication}</Heading1>
      <Explanation>{renderResource.render.j001}</Explanation>
      <EnterpriseAuthenticationContent />
    </React.Fragment>
  );

  return (
    <BasicTemplate
      title={Titles.enterpriseAuthentication}
      main={main}
      isLoading={isLoading}
      isNoIndex={true}
    />
  );
};
