import React from "react";
import styled from "styled-components";

import Heading3 from "../../atoms/headings/Heading3";
import Panel from "../../atoms/panels/Panel";
import ThumbnailList from "../../molecules/patient-upload-file/ThumbnailList";
import FileNote from "../../molecules/patient-upload-file/FileNote";
import FileUploadDetailFileList from "./FileUploadDetailFileList";

import { japaneseList } from "../../../Resources/japaneseList";
const Resource = japaneseList.molecules.FileUploadDetail;
const noteList = [Resource.note.j002];

const ContainerFileNote = styled.div`
  padding: 0px 15px;
`;

const FileUploadDetail = (props) => {
  const { avaiableFiles, doctorFiles, isShowPlusButon } = props;
  return (
    <React.Fragment>
      <Heading3>{Resource.title}</Heading3>
      {avaiableFiles && avaiableFiles.length > 0 && (
        <ThumbnailList
          thumbnailList={avaiableFiles}
          isViewOnly={false}
          canOpenNewTab={true}
          isShowPlusButon={isShowPlusButon}
        />
      )}
      <ContainerFileNote>
          <FileNote title={Resource.note.j001} noteList={noteList} />
      </ContainerFileNote>
      <Panel>
        <FileUploadDetailFileList doctorFiles={doctorFiles} />
      </Panel>
    </React.Fragment>
  );
};

export default FileUploadDetail;
