import React, { Component } from "react";
import styled from "styled-components";
import {isLnln} from "../../Utils/checkLnln";
import Heading2 from "../atoms/headings/Heading2";
import Image from "../atoms/others/Image";
import { japaneseList } from "../../Resources/japaneseList";

import Img3Path from "../../images/carada/03.svg";
import Img3PathLnln from "../../images/lnln/03.svg";
import Img3_2Path from "../../images/carada/03_2.svg";
import Img3_2PathLnln from "../../images/lnln/03_2.svg";
const img3Path = isLnln()? Img3PathLnln: Img3Path;
const img3_2Path = isLnln()? Img3_2PathLnln: Img3_2Path;

const ImageContainer = styled.div`
  margin: 15px 15px 0;
  > img {
    display: block;
  }
`;

class UsingFlow extends Component {
  // Propsのデフォルト値
  static defaultProps = {
    login: false,
  };

  render() {
    const renderResource = japaneseList.organisms.UsingFlow.render;
    const imgPath = (this.props.login)? img3_2Path: img3Path;
    return (
      <div>
        <Heading2>
          {renderResource.div.HomeHeadingAlignCenter.j001}
        </Heading2>
        <ImageContainer>
          <Image src={imgPath} />
        </ImageContainer>
      </div>
    );
  }
}

export default UsingFlow;
