import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Hub } from "aws-amplify";
import Heading1 from "../../atoms/headings/Heading1";
import PaymentCardUpdate from "../../organisms/payment/PaymentCardUpdate";
import BasicTemplate from "../../templates/BasicTemplate";
import Guard from "../../../Utils/Guard";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { Account } from "../../../Utils/GraphQLQueries";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import ApiWrapper from "../../../Utils/ApiWrapper";

const Container = styled.div`
  padding: ${(p) => p.theme.space.normal};
`;

class RegisterPage extends Component {
  state = {
    isCompleted: false,
    postalCode: null,
  };

  constructor(props) {
    super(props);

    this.reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    this.reserveData =
      this.props.location.state && this.props.location.state.reserveData;
  }

  async componentDidMount() {
    try {
      const opt = {
        headers: {
          Authorization: this.props.user.signInUserSession.idToken.jwtToken,
        },
      };
      await ApiWrapper.Post("dev-payment", "/members/create", opt);
    } catch (e) {
      const message = e instanceof Error ? e.message : e.toString();
      Hub.dispatch(
        "msg",
        { event: "open", data: { message: message, level: "error" } },
        "PaymentCard"
      );
    }
  }

  onRegisterCard = async () => {
    const {
      data: { account },
    } = await GraphQLClient.query({ query: Account });
    if (account) {
      this.setState(
        {
          postalCode: account.address.postalCode,
        },
        () => this.direct()
      );
    }
  };

  onRegisterCardError = (message) => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: message, level: "error" } },
      "PaymentCard"
    );
  };

  direct = () => {
    const locationState = this.props.location.state;
    if (
      !this.state.postalCode &&
      this.reserveData &&
      this.reserveData.menu.requiredAddressRegister
    ) {
      this.props.history.push({
        pathname: `/my-page/address/update`,
        state: {
          from: "reserve",
          reserveData: this.reserveData,
          reserveInput: this.reserveInput,
        },
      });
    } else {
      if (locationState && locationState.from === "reserve")
        this.props.history.push({
          pathname: `/reserve/input/${this.reserveInput.hospitalId}/${this.reserveInput.menuId}`,
          state: {
            reserveData: this.reserveData,
            reserveInput: this.reserveInput,
          },
        });
      else this.props.history.push(`/my-page/payment/card-info`);
    }
  };

  render() {
    const renderResource = japaneseList.pages.payment.RegisterPage.render;
    const main = (
      <React.Fragment>
        <Heading1>{renderResource.Fragment.PrimaryHeading.j001}</Heading1>
        <Container>
          <PaymentCardUpdate
            completeRegisterCard={this.onRegisterCard}
            showError={this.onRegisterCardError}
          />
        </Container>
      </React.Fragment>
    );
    return <BasicTemplate title={Titles.cardRegister} main={main} />;
  }
}

export default withRouter(Guard(RegisterPage));
