import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Radio from "../../atoms/forms/Radio";
import CaradaMedicineDelivery from "../../../images/carada/medicineDelivery.svg";
import LnlnMedicineDelivery from "../../../images/lnln/medicineDelivery.svg";
import { isLnln } from "../../../Utils/checkLnln";
import { japaneseList } from "../../../Resources/japaneseList";
import { MEDICINE_DELIVERY_METHOD } from "../../../Utils/Constant";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { RecommandedPharmacies } from "../../../Utils/GraphQLQueries";
import { PharmacySelectItem } from "../../molecules/PharmacySelectItem";
import { AddressDetail } from "../../molecules/AddressDetail";
import FontP from "../../atoms/fonts/FontP";

const Resource = japaneseList.pages.MedicineDeliveryUpdatePage.render;
const displayImg = isLnln() ? LnlnMedicineDelivery : CaradaMedicineDelivery;

interface Props {
  hospitalId: string;
  deliveryHospitalId: string;
  deliveryMethod: string;
  patientInfo: PatientInfo;
  handleSelectDeliveryMethod: (selectedDeliveryMethod: string) => void;
  handleSelectPharmacy: (selectedPharmacy: Hospital) => void;
  setIsLoading: (isLoading: boolean) => void;
}

interface PatientInfo {
  familyName: string;
  givenName: string;
  address: Address;
}

interface Address {
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface Hospital {
  hospitalId: string;
  name: string;
  postalCode: string;
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  tel: string;
}

const Option = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  margin-top: 20px;
  height: 48px;
`;

const Patient = styled.div`
  border: 1px solid #cccccc;
  background: #ffffff;
  margin-top: 10px;
`;

const Text = styled.div`
  position: absolute;
  vertical-align: middle;
  font-size: 16px;
  color: #393939;
  font-weight: 300;
  left: 45px;
  right: 14px;
`;

const RadioMarginText = styled(Text)`
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 16px;
`;

const DescripText = styled(FontP)`
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  color: #393939;
`;

const BorderDescripText = styled(DescripText)`
  font-weight: 600;
  font-size: 14px;
`;

const DescripTextFirst = styled(DescripText)`
  margin-top: 8px;
  padding-left: 30px;
`;

const DescripBody = styled.div`
  margin: 20px 0 0 0;
  background-color: #f4f4f4;
  padding: 22.5px 14.5px;
`;

const BoldText = styled(FontP)`
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  color: #393939;
`;

const PictureArea = styled.div`
  margin: 20px 0 0 0;
  padding: 10px 10px 0 10px;
  border-radius: 10px;
  border: 1px solid #393939;
  height: 255px;
`;

const Img = styled.img`
  width: 100%;
  height: 170px;
`;

const UpdateContent = styled.div`
  padding: 0 14px;
`;

const DescripTextColor = styled(DescripText)`
  margin-top: 10px;
  color: #999999;
  font-size: 12px;
`;

const DescripTextTitle = styled(DescripText)`
  color: #393939;
  font-size: 14px;
  font-weight: 600;
`;

const WarningDescripText = styled(DescripText)`
  margin-top: 20px;
  margin-bottom: 10px;
  color: #ff4d4d;
  font-size: 12px;
`;

const WarningDescripNoPharmacies = styled(DescripText)`
  margin-top: 20px;
  color: #ff4d4d;
  font-weight: 700;
`;

const DescripTextColorBottom = styled(DescripTextColor)`
  margin-top: 20px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const DescripTextMarginTop = styled(DescripText)`
  color: #393939;
  margin: 8px 0 0 0;
  padding-left: 30px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const WarningText = styled.span`
  color: #ff4d4d;
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
  word-break: break-word;
  white-space: pre-wrap;
`;

const NormalText = styled.span`
  color: #393939;
  font-size: 14px;
  font-family: Hiragino Kaku Gothic ProN;
`;

const Container = styled.div`
  padding: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  margin-bottom: -1px;
`;

const OptionMarginTop = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  margin-top: 0px;
`;

export const MedicineDeliveryForm = (props: Props) => {
  const {
    hospitalId,
    deliveryMethod,
    deliveryHospitalId,
    patientInfo,
    handleSelectDeliveryMethod,
    handleSelectPharmacy,
    setIsLoading,
  } = props;
  const [pharmacies, setPharmacies] = useState<Hospital[]>([]);

  useEffect(() => {
    loadRecommandedPharmacies(hospitalId);
  }, []);

  const loadRecommandedPharmacies = async (hospitalId: string) => {
    const siteType = isLnln() ? "lnln" : "carada";
    const { data, errors } = await GraphQLClient.query({
      query: RecommandedPharmacies,
      variables: {
        hospitalId: hospitalId,
        siteType: siteType,
      },
    });
    if (errors) {
      setIsLoading(false);
      console.error(
        "loadRecommandedPharmacies errors:",
        JSON.stringify(errors)
      );
    }
    setPharmacies(data && data.recommandedPharmacies);
    setIsLoading(false);
  };

  return (
    <UpdateContent>
      <BoldText>{Resource.Content.j017}</BoldText>
      <PictureArea>
        <DescripText>
          <NormalText>{Resource.Content.j001}</NormalText>
          <WarningText>{Resource.Content.j002}</WarningText>
        </DescripText>
        <Img src={displayImg} />
      </PictureArea>
      <React.Fragment>
        <Option key={1}>
          <Radio
            id="delivery-receive-radio-button"
            value={1}
            checked={deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY}
            customOnChange={() =>
              handleSelectDeliveryMethod(MEDICINE_DELIVERY_METHOD.DELIVERY)
            }
            name={Resource.Content.j005}
          />
          <RadioMarginText>{Resource.Content.j005}</RadioMarginText>
        </Option>
        <DescripTextFirst>
          {Resource.Content.j012}
          <WarningText>{Resource.Content.j018}</WarningText>
          {Resource.Content.j013}
        </DescripTextFirst>
        {deliveryMethod === MEDICINE_DELIVERY_METHOD.DELIVERY && (
          <DescripBody>
            <BorderDescripText>{Resource.Content.j003}</BorderDescripText>
            {!pharmacies && (
              <WarningDescripNoPharmacies>
                {Resource.Content.j019}
              </WarningDescripNoPharmacies>
            )}
            {pharmacies && (
              <React.Fragment>
                <WarningDescripText>{Resource.Content.j004}</WarningDescripText>
                {pharmacies.map((item: Hospital, index: number) => {
                  return (
                    <Container key={index}>
                      <OptionMarginTop>
                        <Radio
                          id="receive-pharmacy-radio-button"
                          value={item.hospitalId}
                          checked={item.hospitalId === deliveryHospitalId}
                          customOnChange={() => handleSelectPharmacy(item)}
                          name={item.name}
                        />
                        <PharmacySelectItem pharmacy={item} />
                      </OptionMarginTop>
                    </Container>
                  );
                })}
                <DescripTextColorBottom>
                  {Resource.Content.j016}
                </DescripTextColorBottom>
              </React.Fragment>
            )}
          </DescripBody>
        )}
        <Option key={2}>
          <Radio
            id="other-pharmacy-radio-button"
            value={2}
            checked={
              deliveryMethod ===
              MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
            }
            customOnChange={() =>
              handleSelectDeliveryMethod(
                MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
              )
            }
            name={Resource.Content.j014}
          />
          <RadioMarginText>{Resource.Content.j014}</RadioMarginText>
        </Option>
        <DescripTextMarginTop>{Resource.Content.j006}</DescripTextMarginTop>
        {deliveryMethod ===
          MEDICINE_DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME && (
          <DescripBody>
            <DescripTextTitle>{Resource.Content.j007}</DescripTextTitle>
            <DescripTextColor>{Resource.Content.j008}</DescripTextColor>
            {patientInfo && (
              <Patient>
                <AddressDetail
                  name={`${patientInfo.familyName} ${patientInfo.givenName}`}
                  address={patientInfo.address}
                />
              </Patient>
            )}
          </DescripBody>
        )}
        <DescripTextColorBottom>{Resource.Content.j015}</DescripTextColorBottom>
      </React.Fragment>
    </UpdateContent>
  );
};
