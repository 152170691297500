import { useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../atoms/buttons/Button";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";

const styles = () => ({
  dialogPaper: {
    "max-height": "100%",
    width: "300px",
  },
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    padding: "30px 20px 0 20px",
  },
  dialogAction: {
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "0 20px 30px 20px",
    margin: 0,
  },
  dialogContent: {
    maxWidth: "303px",
    padding: "15px 20px 15px 20px",
    "white-space": "pre-wrap",
  },
  dialogButton: {
    margin: 0,
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
});
const DialogTitleText = styled.span`
  font-size: 18px;
  color: ${(p) => p.theme.color.primary};
  font-weight: 600;
  line-height: 27px;
  text-align: center;
`;

const DialogContentText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #393939;
  line-height: 22px;
`;

const popUp = (props) => {
  const { classes, isOpen } = props;
  const reloadPopUpResource = japaneseList.organisms.ReloadPopUp;

  const handleConfirm = useCallback((e) => {
    // default event stop
    e.preventDefault();
    window.location.reload();
  });

  return (
    <>
      <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>{reloadPopUpResource.title}</DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {reloadPopUpResource.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            className={classes.dialogButton}
            onClick={handleConfirm}
            id="reload-button"
          >
            {reloadPopUpResource.reloadButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export const ReloadPopUp = withStyles(styles)(popUp);
