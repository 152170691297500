import React from "react";
import { japaneseList } from "../../../Resources/japaneseList";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ENTERPRISE_REGISTER_STEP } from "../../../Utils/Constant";

const styles = {
  label: {
    fontSize: "12px",
    marginTop: "10px !important",
  },
};

const renderResource =
  japaneseList.organisms.enterprise.EnterpriseStepperForm.RegisterSteps;

const QontoConnector = withStyles({
  line: {
    borderColor: "#cccccc",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const ShadowBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f7f7f7;
  box-shadow: 0px 15px 14px -15px #00000033 inset;
`;

interface StepsObject {
  [x: string]: StepElement;
}

interface StepElement {
  previousUrl: string;
  followingUrl: string;
  stepNo: number;
}

interface Props {
  registerSteps: StepsObject;
  currentStep: StepElement;
  classes: any;
}

const StepperFormCustom = (props: Props) => {
  const { registerSteps, currentStep, classes } = props;
  const steps = Object.keys(registerSteps).map((item) => {
    switch (item) {
      case ENTERPRISE_REGISTER_STEP.SIGNUP:
        return renderResource.j001;
      case ENTERPRISE_REGISTER_STEP.SIGNIN:
        return renderResource.j002;
      case ENTERPRISE_REGISTER_STEP.AUTHORIZATION:
        return renderResource.j003;
      case ENTERPRISE_REGISTER_STEP.CONFIRM:
        return renderResource.j004;
      case ENTERPRISE_REGISTER_STEP.COMPLETED:
        return renderResource.j005;
      default:
        return "";
    }
  });
  const activeStep = registerSteps && currentStep && currentStep.stepNo;

  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        nonLinear
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.label }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ShadowBar />
    </React.Fragment>
  );
};

export const EnterpriseStepperForm = withStyles(styles)(StepperFormCustom);
