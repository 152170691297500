import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { PATIENT_MEDICINE_DELIVERY } from "../../../Utils/Constant";

const renderResource =
  japaneseList.pages.ReservationMedicineDeliveryPage.render;
const ERROR = PATIENT_MEDICINE_DELIVERY.ERROR;

interface Props {
  errorType: string;
}

const Text = styled.p`
  font-size: 16px;
  margin: 10px 18px;
  line-height: 27px;
  color: #333333;
`;

export const MedicineDeliveryError = (props: Props) => {
  const { errorType } = props;
  return (
    <React.Fragment>
      {errorType === ERROR.E02 && <Text>{renderResource.Errors.j001}</Text>}
    </React.Fragment>
  );
};
