import React, { Component } from "react";
import styled from "styled-components";
import PrimaryHeading from "../atoms/headings/Heading1";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Heading3 from "../atoms/headings/Heading3";
import Card from "../molecules/Card";
import LinkToNoUnder from "../atoms/links/LinkToNoUnder";
import ListAppointmentMyPage from "./ListAppointmentMyPage";
import { japaneseList } from "../../Resources/japaneseList";
import { FEATURES } from "../../Utils/Constant";
import { EnterpriseContents } from "./mypage/EnterpriseContents";
import NumberFormat from "react-number-format";

const _ = require("lodash");
const PatientName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 15px 20px;
  & > span {
    font-size: 1.5rem;
    margin-left: 10px;
  }
`;

const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:277px;
  margin: 0px auto 30px auto;
  padding: 8px 14px;
  & > span {
    font-size: 14px;
    font-weight: 400
    line-height: 21px
    margin-left: 13px;
    color: #333333;
  }
  background: #f1f3f3;
  border-radius: 10px;
`;

const PhoneNumberText = styled.div`
  font-size: 12px;
  font-weight: 400
  line-height: 18px
  margin-left: 0px;
  color: #999999;
`;

const ListCard = styled.div`
  > a > div {
    border-bottom: thin solid ${(p) => p.theme.color.secondary};
  }
`;

const LoadMore = styled.p`
  text-align: right;
  padding-right: 25px;
  cursor: pointer;
`;

const renderResource = japaneseList.organisms.MyPage.render;

class MyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      limit: 5,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState({
      limit: this.state.limit + 5,
    });
  }

  getListMenuItem(user) {
    const myPageItems = [];
    const featureTitles = [
      {
        type: "memberInformation",
        japanese: "j001",
      },
      {
        type: "password",
        japanese: "j002",
      },
      {
        type: "creditCardInformation",
        japanese: "j003",
      },
      {
        type: "address",
        japanese: "j004",
      },
      {
        type: "mailAddress",
        japanese: "j005",
      },
      {
        type: "insuranceCardInformation",
        japanese: "j006",
      },
    ];
    if (Boolean(user.organization)) {
      user.organization.featureSettings
        .filter((item) => {
          return item.enable;
        })
        .forEach((item, index) => {
          let feature = _.find(FEATURES, { type: item.type });
          let featureTitle = _.find(featureTitles, { type: item.type });
          if (Boolean(feature)) {
            myPageItems.push(
              <LinkToNoUnder
                to={{
                  pathname: feature.pagePath,
                  state: { from: "mypage" },
                }}
              >
                <Card>
                  {
                    renderResource.div.ListCard.LinkContainer.Card[
                      featureTitle.japanese
                    ]
                  }
                </Card>
              </LinkToNoUnder>
            );
          }
        });
    } else if (!Boolean(user.organizationId)) {
      FEATURES.forEach((item, index) => {
        myPageItems.push(
          <LinkToNoUnder
            to={{
              pathname: item.pagePath,
              state: { from: "mypage" },
            }}
          >
            <Card>
              {
                renderResource.div.ListCard.LinkContainer.Card[
                  featureTitles[index].japanese
                ]
              }
            </Card>
          </LinkToNoUnder>
        );
      });
    }
    return myPageItems;
  }

  render() {
    const { appointments, user } = this.props;
    const enterprise = user && user.enterprise;
    const phoneNumber = user && user.phoneNumber;
    let userName =
      user && `${user.familyName || ""} ${user.givenName || ""}`.trim();
    let appointmentList = [...appointments];
    if (appointmentList) {
      appointmentList.sort(function (a, b) {
        let dateA = new Date(a.from),
          dateB = new Date(b.from);
        return dateB - dateA;
      });
    }
    const total = appointmentList.length;
    const loadMore =
      total <= this.state.limit ? (
        ""
      ) : (
        <LoadMore onClick={this.loadMore}>もっと見る</LoadMore>
      );
    const myPageItems = user && this.getListMenuItem(user);
    return (
      <div>
        <PrimaryHeading>
          {renderResource.div.PrimaryHeading.j001}
        </PrimaryHeading>
        <PatientName>
          <div>
            <AccountBoxIcon style={{ height: "24px", width: "24px" }} />
          </div>
          <span>{userName}</span>
        </PatientName>
        <PhoneNumber>
          <PhoneNumberText>
            {renderResource.div.PhoneNumber.j001}
          </PhoneNumberText>
          <NumberFormat
            value={phoneNumber}
            displayType={"text"}
            format="###-####-####"
          />
        </PhoneNumber>
        {enterprise && <EnterpriseContents enterprise={enterprise} />}
        {Boolean(myPageItems) && myPageItems.length !== 0 && (
          <Heading3>{renderResource.div.SecondaryHeading.j001}</Heading3>
        )}
        <ListCard>
          <React.Fragment>{myPageItems}</React.Fragment>
        </ListCard>
        <ListAppointmentMyPage
          appointments={appointmentList.slice(0, this.state.limit)}
        />
        {loadMore}
      </div>
    );
  }
}

export default MyPage;
