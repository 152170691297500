import React from "react";
import { Redirect } from "react-router";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import Heading1 from "../../atoms/headings/Heading1";
import { EnterpriseCompleteContent } from "../../organisms/enterprise/EnterpriseCompleteContent";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNIN_STEPS,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";

const Heading1Custom = styled(Heading1)`
  height: "40px";
  font-size: "17px";
  font-weight: "bold";
`;

export const EnterpriseCompletePage = (props) => {
  const renderResource = japaneseList.pages.EnterpriseCompletePage.render;
  const isFlowSignUp =
    props.location.state && props.location.state.flow === "signup";
  const currentStep = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.COMPLETED]
    : ENTERPRISE_SIGNIN_STEPS[ENTERPRISE_REGISTER_STEP.COMPLETED];

  const registerSteps = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS
    : ENTERPRISE_SIGNIN_STEPS;

  if (!props.location.state || !props.location.state.isFinish) {
    return <Redirect to="/enterprise/authorize" />;
  }

  const main = (
    <React.Fragment>
      <Heading1Custom>{renderResource.Title}</Heading1Custom>
      <EnterpriseStepperForm
        registerSteps={registerSteps}
        currentStep={currentStep}
      />
      <EnterpriseCompleteContent />
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate
      title={Titles.enterpriseCompleted}
      main={main}
      isNoIndex={true}
    />
  );
};
