import React from "react";
import styled from "styled-components";

const PlusText = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.color.primary};
  text-align: center;
  margin-top: -10px;
  font-weight: bold;
`;

const ThumbnailPlusText = (props) => {
  return <PlusText>{props.text}</PlusText>;
};

export default ThumbnailPlusText;
