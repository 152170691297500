import React, { Component } from "react";
import styled from "styled-components";
import FontP from "../atoms/fonts/FontP";
import { japaneseList } from "../../Resources/japaneseList";

const Panel = styled.div`
  margin-bottom: 15px;
`;

class MedicalMenuAppointmentDetail extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.MedicalMenuAppointmentDetail.render;
    const { appointment } = this.props;
    return (
      <Panel>
        <FontP color="accent" weight="bold">
          {renderResource.Container.Title.j001}
        </FontP>
        <FontP>{appointment && appointment.menu.displayName}</FontP>
      </Panel>
    );
  }
}
export default MedicalMenuAppointmentDetail;
