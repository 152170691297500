import React, { Component } from "react";

import Heading1 from "../../atoms/headings/Heading1";
import { japaneseList } from "../../../Resources/japaneseList";
const renderResource =
  japaneseList.organisms.reservationForm.ReservationForm.render;

class ReservationFormHeading extends Component {
  static defaultProps = {
    menu: null
  };

  render() {
    const { menu } = this.props;
    const { displayName } = menu;
    const doctorName = menu && menu.doctorName;
    let title = displayName;
    if (doctorName)
      title =
        displayName +
        renderResource.Container.Fragment.PrimaryHeading.j002 +
        doctorName +
        renderResource.Container.Fragment.PrimaryHeading.j003;
    return <Heading1>{title}</Heading1>;
  }
}

export default ReservationFormHeading;
