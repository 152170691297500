import { japaneseList } from "../../../Resources/japaneseList";

const RecaptchaErrorListResource =
  japaneseList.organisms.payment.RecaptchaErrorList;

const RecaptchaErrorList = {
  E116: RecaptchaErrorListResource.j001,
  E117: RecaptchaErrorListResource.j001,
  E118: RecaptchaErrorListResource.j001,
  E119: RecaptchaErrorListResource.j002,
  E120: RecaptchaErrorListResource.j001,
  E121: RecaptchaErrorListResource.j002,
};

export default RecaptchaErrorList;
