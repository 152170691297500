import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import FontP from "../../atoms/fonts/FontP";
import LinkA from "../../atoms/links/LinkA";
import PanelCaution from "../../atoms/panels/PanelCaution";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.SignInLnlnCaution;

const CustomPanelCaution = styled(PanelCaution)`
  margin-bottom: 20px;
`;
const TextBorder = styled.span`
  border-bottom: 1px solid rgb(253, 177, 182);
`;

class SignInLnlnCaution extends Component {
  handleClickLinkTo = () => {
    this.props.history.push({
      pathname: "/auth/signup",
      state: {
        flow:
          this.props.location.state &&
          this.props.location.state.from === "enterprise"
            ? "signup"
            : "",
        from: this.props.location.state && this.props.location.state.from,
      },
      action: null,
    });
  };

  render() {
    return (
      <CustomPanelCaution padding="s">
        <FontP size="s">
          {renderResource.text.j001}
          <LinkA color="note" onClick={this.handleClickLinkTo}>
            <TextBorder>{renderResource.text.j002}</TextBorder>
          </LinkA>
          {renderResource.text.j003}
        </FontP>
      </CustomPanelCaution>
    );
  }
}
export default withRouter(SignInLnlnCaution);
