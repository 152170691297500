import React from "react";
import styled from "styled-components";
import { Schedule, AttachFile, ChevronRight } from "@material-ui/icons";
import Typography4 from "../../atoms/fonts/Typography4";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import FontPCaution from "../../atoms/fonts/FontPCaution";
import { japaneseList } from "../../../Resources/japaneseList";
import { NOTIFICATION_TYPE } from "../../../Utils/Constant";

const Typography = styled(Typography4)`
  color: ${(p) => p.theme.color.secondary};
`;

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.color.secondary};
  cursor: pointer;
`;

// NOTE: background-colorの値はthemeから取得するべき
const FreeButton = styled.div`
  background-color: #ef5e5e;
  color: ${(p) => p.theme.color.fontInvert};
  height: 15px;
  width: 28px;
  padding: 0px 0px 0px 4px;
  font-size: 0.6rem;
  line-height: 15px;
  border-radius: 4px;
  display: inline-block;
  font-weight: normal;
  margin-left: 3px;
`;

// NOTE: colorの値はthemeから取得するべき
const CautionText = styled(FontPCaution)`
  font-size: 16px;
  color: #ef5e5e;
`;
// NOTE: colorの値はthemeから取得するべき
const CautionSmallText = styled(FontPCaution)`
  font-size: 12px;
  color: #ef5e5e;
`;

const WriteInterviewSheetFontP = styled(FontP)`
  color: ${(p) => p.theme.color.primary};
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.space.short} 8px ${(p) => p.theme.space.short}
    ${(p) => p.theme.space.normal};
  color: ${(p) => p.theme.color.secondary};
  line-height: 1.5;
`;

const Notification = styled.div`
  width: 90%;
  color: ${(p) => p.theme.color.font};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const AppoText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NewAttachFile = styled(AttachFile)`
  transform: scale(0.83);
`;

export const NotificationList = (props) => {
  const renderResource =
    japaneseList.organisms.NotificationList.render.Container.Card;
  const {
    notificationType,
    appoDatetime,
    hospitalName,
    menuDisplayName,
    notificationDatetime,
    isUnread,
    linkTo,
    isCanceled,
    isShowInterviewSheetAnswerMsg,
    isShowFileDeletedMsg,
    shouldCancelConsentRegistration,
  } = props;

  const getTitle = () => {
    switch (notificationType) {
      case NOTIFICATION_TYPE.PAYMENT_COMPLETED:
        return renderResource.j001;
      case NOTIFICATION_TYPE.ENTRY_BILLING_ENTERPRISE:
        return renderResource.j010;
      case NOTIFICATION_TYPE.PAYMENT_ERROR:
        return renderResource.j002;
      case NOTIFICATION_TYPE.NEW_ATTCAHMENT:
        return renderResource.j003;
      case NOTIFICATION_TYPE.CREATED_BY_DOCTOR:
        return renderResource.j004;
      default:
        return;
    }
  };

  const getAttentionText = () => {
    switch (notificationType) {
      case NOTIFICATION_TYPE.CREATED_BY_DOCTOR:
        return isCanceled ? (
          <CautionText>{renderResource.j007}</CautionText>
        ) : isShowInterviewSheetAnswerMsg ? (
          <WriteInterviewSheetFontP>
            {renderResource.j006}
          </WriteInterviewSheetFontP>
        ) : (
          <br />
        );
      case NOTIFICATION_TYPE.PAYMENT_ERROR:
        return <CautionText>{renderResource.j008}</CautionText>;
      case NOTIFICATION_TYPE.NEW_ATTCAHMENT:
        return isShowFileDeletedMsg ? (
          <CautionText>{renderResource.j009}</CautionText>
        ) : (
          <NewAttachFile />
        );
      default:
        return <br />;
    }
  };

  return (
    <Container onClick={linkTo}>
      <Card>
        <Notification>
          <FontPBold>{getTitle()}</FontPBold>
          <FontP>{appoDatetime}</FontP>
          <AppoText>
            {renderResource.j005
              .replace(/#hospitalName/g, hospitalName)
              .replace(/#menuDisplayName/g, menuDisplayName)}
          </AppoText>
          {getAttentionText()}
          {shouldCancelConsentRegistration && (
            <CautionSmallText>{renderResource.j011}</CautionSmallText>
          )}
          <Typography>
            <Schedule
              color="disabled"
              style={{
                fontSize: "15px",
                marginRight: "5px",
                "vertical-align": "middle",
              }}
            />
            {notificationDatetime}
            {isUnread && (
              <FreeButton>
                {renderResource.Typography.Schedule.FreeButton.j001}
              </FreeButton>
            )}
          </Typography>
        </Notification>
        <ChevronRight />
      </Card>
    </Container>
  );
};
