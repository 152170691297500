import React from "react";
import styled from "styled-components";
import InputText from "../../atoms/forms/InputText";

const InputLabel = styled.label`
  span {
    font-weight: bold;
  }
  input {
    width: 100%;
  }
`;

export default ({ children, ...props }) => {
  return (
    <InputLabel>
      <span>{children}</span>
      <InputText {...props} />
    </InputLabel>
  );
};
