import { PAGE_FROM } from "./Constant";

export function getRedirectStateAfterAuth(location, props) {
  const { search: pathSearch, pathname } = location;
  if (pathname === null) {
    return {};
  }

  const pageMap = {
    "/": PAGE_FROM.TOP, // TOPページ
    "/login": PAGE_FROM.LOGIN, // ログインページ
    "/auth/signup": PAGE_FROM.SIGNUP, // 会員登録ページ
    "/terms-of-service": PAGE_FROM.TERMS_OF_SERVICE, // 利用規約ページ
    "/consumer-policy": PAGE_FROM.CONSUMER_POLICY, // 特定商取引法ページ
    "/privacy-policy": PAGE_FROM.PRIVACY_POLICY, // 個人情報の取り扱いについてページ
    "/search":
      pathSearch == "?type=hospital"
        ? PAGE_FROM.SEARCH_HOSPITAL // 都道府県から探すページ（病院/クリニック）
        : PAGE_FROM.SEARCH_PHARMACY, // 都道府県から探すページ（薬局）
    "/search/list": PAGE_FROM.SEARCH_LIST, // 検索結果ページ（薬局）OR （病院/クリニック）
    "/hospital/": PAGE_FROM.HOSPITAL, // 医療機関詳細ページ
    "/reserve/input/": PAGE_FROM.RESERVE, // 予約（日時選択）ページ
    "/enterprise/authenticate": PAGE_FROM.ENTERPRISE, // ルナルナオフィス利用登録ページ
  };

  const from =
    pageMap[pathname] ||
    pageMap[pathname.startsWith("/hospital/") ? "/hospital/" : ""] ||
    pageMap[pathname.startsWith("/reserve/input/") ? "/reserve/input/" : ""] ||
    pageMap[
      pathname.startsWith("/enterprise/authenticate")
        ? "/enterprise/authenticate"
        : ""
    ] ||
    "";

  const search = pathname.startsWith("/search/list") ? pathSearch : "";
  const hospitalId = pathname.startsWith("/hospital/")
    ? props.match.params.hospitalId
    : "";

  const state = { from };
  if (search) {
    state.search = search;
  }
  if (hospitalId) {
    state.hospitalId = hospitalId;
  }
  return { state };
}
