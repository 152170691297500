import styled from "styled-components";

const PanelWell = styled.div`
  min-height: ${p => p.theme.space.medium};
  padding: ${p => p.theme.space.medium};
  margin-bottom: ${p => p.theme.space.medium};
  background-color: transparent;
`;

export default PanelWell;
