import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import Panel from "../../atoms/panels/Panel";
import FontP from "../../atoms/fonts/FontP";
import InputFile from "../../atoms/forms/InputFile";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import LinkA from "../../atoms/links/LinkA";
import Image from "../../atoms/others/Image";
import { japaneseList } from "../../../Resources/japaneseList";
import { Redirect } from "react-router-dom";
const Resources = japaneseList.organisms.mypage.InsuranceCardConfirm;
const _ = require("lodash");

const ImageBox = styled.div`
  padding: 0 20px;
  text-align: center;
`;

const CustomImage = styled(Image)`
  max-height: 200px;
  object-fit: contain;
`;
const CustomInputFile = styled(InputFile)`
  display: none;
`;

class InsuranceCardConfirm extends Component {
  static defaultProps = {
    file: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      alreadyConfirm: false,
    };
  }

  handleClickLink = (e) => {
    // default event stop
    e.preventDefault();
    // Open file select
    return document.getElementById("inputInsuranceCard").click();
  };

  componentDidMount() {
    let numberOfload = 0;
    if (
      _.has(this.props.location.state, "file") &&
      this.props.location.state.file.size > 0
    ) {
      this.props.history.replace("/my-page/insurance-card/confirm", {
        file: this.props.location.state.file,
        lastModified: this.props.location.state.file.lastModified,
        numberOfload: _.has(this.props.location.state, "numberOfload")
          ? this.props.location.state.numberOfload + 1
          : numberOfload + 1,
      });
    }
  }

  render() {
    const { handleUploadInsuranceCard, handleSelectInsuranceCard } = this.props;
    if (
      _.has(this.props.location.state, "file") &&
      (!_.has(this.props.location.state, "numberOfload") ||
        this.props.location.state.numberOfload <= 1)
    ) {
      const { file } = this.props.location.state;
      return (
        <React.Fragment>
          <Heading1>{Resources.title}</Heading1>
          <Panel>
            <Panel>
              <FontP size="s" align="center">
                {Resources.confirm}
              </FontP>
            </Panel>
            <ImageBox>
              <CustomImage src={URL.createObjectURL(file)} />
            </ImageBox>
            {this.state.alreadyConfirm ? (
              <React.Fragment>
                <Panel>
                  <FontP align="center">
                    <LinkA style={{ color: "gray" }} disabled>
                      {Resources.reSelect}
                    </LinkA>
                  </FontP>
                </Panel>
                <ButtonFullWidth disabled>{Resources.button}</ButtonFullWidth>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Panel>
                  <CustomInputFile
                    id={"inputInsuranceCard"}
                    onChange={(e) => handleSelectInsuranceCard(e)}
                  />
                  <FontP align="center">
                    <LinkA href="#" onClick={this.handleClickLink}>
                      {Resources.reSelect}
                    </LinkA>
                  </FontP>
                </Panel>
                <ButtonFullWidth
                  onClick={(e) => {
                    handleUploadInsuranceCard(this.props.location.state.file);
                  }}
                >
                  {Resources.button}
                </ButtonFullWidth>
              </React.Fragment>
            )}
          </Panel>
        </React.Fragment>
      );
    }
    return <Redirect from="/my-page" to="/my-page/insurance-card" />;
  }
}

export default withRouter(InsuranceCardConfirm);
