import imageCompression from "browser-image-compression";
import { PATIENT_ATTACHMENT } from "./Constant";

const MAX_SIZE_BY_B = 2097152;

export const compressFile = async (file) => {
  const originalFile = file.originalFile;

  // When insurance card file size > 2Mb = 2097152 Bytes (in binary), compress it.
  if (originalFile.size > MAX_SIZE_BY_B) {
    try {
      const imageCompressionOptions = {
        maxSizeMB: 2,
        maxIteration: 15,
        useWebWorker: false,
      };
      return await imageCompression(
        new File([originalFile], file.fileName, {
          lastModified: Date.now(),
          type: file.fileType,
        }),
        imageCompressionOptions
      );
    } catch (error) {
      console.log(`Compressing file failed! : ${file.fileName}`, error);
    }
  }

  return originalFile;
};

// Validate input file name
export const checkTextInput = (text) => {
  if (!text) {
    return PATIENT_ATTACHMENT.ERROR.FILE_NAME_EMPTY;
  }
  if (text && text.length > PATIENT_ATTACHMENT.MAX_CHARACTERS_FILENAME) {
    return PATIENT_ATTACHMENT.ERROR.FILE_NAME_MAX_CHAR;
  }

  const regexp = /[¥/:*?"<>|.]/g;
  const checkingResult = regexp.test(text);
  if (checkingResult) {
    return PATIENT_ATTACHMENT.ERROR.FILE_NAME_INCLUDE_UNUSUAL;
  }
  return text;
};

export const readFile = (fileInput) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (res) => {
      resolve(res.target.result);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsArrayBuffer(fileInput);
  });
};
