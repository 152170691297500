import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: ${p => p.theme.color.warning};
  background-color: ${p => p.theme.color.warningBackground};
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  line-height: 1.5;
`;

export default ({ children, ...props }) => (
  <Container className={props.className}>{children}</Container>
);
