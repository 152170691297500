import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = () => ({
  dialogContentText: {
    textAlign: "center",
    lineHeight: "1.2rem"
  }
});

export default withStyles(styles)(props => (
  <DialogContent>
    <DialogContentText
      id="alert-dialog-description"
      classes={{ root: props.classes.dialogContentText }}
    >
      {props.children}
    </DialogContentText>
  </DialogContent>
));
