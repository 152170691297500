import React from "react";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import Heading3 from "../../atoms/headings/Heading3";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { japaneseList } from "../../../Resources/japaneseList";

const InputFormHeaderNoIcon = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant2};
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0;
  margin-top: 5px;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
`;

const InputFormHeaderNoBackgroundColor = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 22px;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.heading.h1.bg};
`;

const StepperBody = styled.div`
  margin: -24px;
  padding: 0 15px 29px 20px;
`;

const Circle = styled.div`
  width: 60px;
  height: 60px;
  padding: 0;
  padding: 15px 16px 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.primary};
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  white-space: pre-wrap;
`;

const StepContentCustom = styled.div`
  border-left: 6px solid
    ${(p) => (p.connect ? "#ffffff" : p.theme.color.primaryVariant3)};
  margin-top: -60px;
  margin-left: 27px;
  padding-left: 38px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 97px;
`;

const NoStepContentCustom = styled.div`
  margin-top: -60px;
  margin-left: 27px;
  padding-left: 44px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 97px;
`;

const SubStep = styled.p`
  font-size: 12px;
  color: #393939;
  margin: 0;
  margin-top: -5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 21px;
`;

const BwCareful = styled.div`
  margin: 20px 15px 0;
  font-size: 14px;
`;

const StepDetail = styled.div`
  margin: 0;
  padding: 15px 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const BwCarefulSpot = styled.div`
  width: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;

const BwCarefulBody = styled.div`
  width: calc(100% - 15px);
  display: inline-block;
  line-height: 25px;
`;

export const InquirySignupSmsCompleted = () => {
  const InquirySignupSmsCompleted = japaneseList.InquirySignupSmsCompleted;
  const steps = InquirySignupSmsCompleted.Step;
  return (
    <React.Fragment>
      <Heading1>{InquirySignupSmsCompleted.Heading}</Heading1>
      <InputFormHeaderNoIcon>
        {InquirySignupSmsCompleted.InputFormHeaderNoIcon}
      </InputFormHeaderNoIcon>
      <InputFormHeaderNoBackgroundColor>
        {InquirySignupSmsCompleted.InputFormHeaderNoBackgroundColor}
      </InputFormHeaderNoBackgroundColor>
      <Stepper orientation="vertical" connector={null}>
        <StepperBody>
          {steps.map((step, index) => (
            <Step key={index} active={true}>
              <StepLabel icon={<Circle>{step.title}</Circle>}></StepLabel>
              {index === 0 ? (
                <StepContentCustom>
                  <StepDetail>
                    <SubStep>{step.subStep}</SubStep>
                  </StepDetail>
                </StepContentCustom>
              ) : (
                <NoStepContentCustom>
                  <StepDetail>
                    <SubStep>{step.subStep}</SubStep>
                  </StepDetail>
                </NoStepContentCustom>
              )}
            </Step>
          ))}
        </StepperBody>
      </Stepper>
      <Heading3>{InquirySignupSmsCompleted.BwCareful.title}</Heading3>
      <BwCareful>
        <BwCarefulSpot>
          {InquirySignupSmsCompleted.BwCareful.symbol}
        </BwCarefulSpot>
        <BwCarefulBody>
          {InquirySignupSmsCompleted.BwCareful.j001}
        </BwCarefulBody>
      </BwCareful>
      <BwCareful>
        <BwCarefulSpot>
          {InquirySignupSmsCompleted.BwCareful.symbol}
        </BwCarefulSpot>
        <BwCarefulBody>
          {InquirySignupSmsCompleted.BwCareful.j002}
        </BwCarefulBody>
      </BwCareful>
    </React.Fragment>
  );
};
