import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: ${p => p.theme.space.normal};
  color: ${p => p.theme.color.fontInvert};
  background-color: ${p => p.theme.color.warning};
`;

export default ({ children, ...props }) => (
  <Container className={props.className}>{children}</Container>
);
