import React from "react";
import styled from "styled-components";
import QRCodeTag from "../atoms/others/QRCodeTag";
import QRCode from "qrcode.react";
import { japaneseList } from "../../Resources/japaneseList";

const QRCodeImageContainer = styled.div`
  margin-top: 7px;
`;

const QRCodeImageBorder = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: ${p => p.theme.color.fontInvert};
  width: 100px;
  height: 100px;
`;

const QRCODE_CONTENT = window.location.origin;

export default () => (
  <QRCodeTag>
    <div>{japaneseList.organisms.QRCode.export.QRCodeTag.div.j001}</div>
    <div>{japaneseList.organisms.QRCode.export.QRCodeTag.div.j002}</div>
    <QRCodeImageContainer>
      <QRCodeImageBorder>
        <QRCode
          value={QRCODE_CONTENT}
          style={{ height: 80, width: 80 }}
        />
      </QRCodeImageBorder>
    </QRCodeImageContainer>
  </QRCodeTag>
);
