import styled from "styled-components";
import InfoIcon from "../../../images/icon/icon-info.svg";
interface Props {
  size?: string;
}

const Img = styled.img<Props>`
  height: ${(p) => p.size || "1rem"};
`;

export const IconInfo = (props: Props) => <Img src={InfoIcon} {...props} />;
