import React, { Component } from "react";
import styled from "styled-components";

import { br2nl } from "../../Utils/Utility";
import FontPCenter from "../atoms/fonts/FontPCenter";
import FontPSS from "../atoms/fonts/FontPSS";
import LinkTo from "../atoms/links/LinkTo";
import { IconArrow } from "../atoms/icons/IconArrow";

import { japaneseList } from "../../Resources/japaneseList";
import { MEDICAL_MENU, MEDICAL_METHOD } from "../../Utils/Constant";
import SiteContext from "../../SiteContext";

const Container = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant4};

  padding: 15px 18px;
  margin: 20px 15px 0 15px;
`;
const CustomFontPCenter = styled(FontPCenter)`
  color: ${(p) => p.theme.color.primary};
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const Detail = styled(FontPSS)`
  font-size: 12px;
  :first-line {
    font-weight: bold;
  }
  line-height: 20px;
`;

const LnlnOfficeDetail = styled(FontPSS)`
  font-size: 12px;
  line-height: 20px;
`;

const LinkWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`;
const LinkToDetailPage = styled(LinkTo)`
  font-size: 12px;
`;

const IconArrowInline = styled(IconArrow)`
  display: inline-block;
`;

const DetailFooter = styled(FontPSS)`
  font-size: 12px;
  line-height: 20px;
  padding-left: 1em;
  margin-top: 16px;
  text-indent: -1em;
`;

class CancelPolicy extends Component {
  isDisplayLinkToHospitalDetail = (menu) => {
    if (
      menu.medicalMenu === MEDICAL_MENU.EXAMINATION &&
      menu.medicalMethod == MEDICAL_METHOD.ONLINE
    )
      return true;
    if (
      menu.medicalMenu === MEDICAL_MENU.CONSULTATION &&
      menu.medicalMethod == MEDICAL_METHOD.ONLINE
    )
      return true;
    return false;
  };

  render() {
    const renderResource = japaneseList.molecules.CancelPolicy.render;
    const {
      menu,
      hospitalId,
      gtmId,
      isLnlnOfficeMenu,
      isShowFooter = false,
    } = this.props;
    const { ui } = this.context;
    const isLnLnApp = ui === "lnln";
    const hospitalDetailUrl = `/hospital/${hospitalId}${
      isLnLnApp ? "?lnln_ob=1" : ""
    }`;
    return (
      <div>
        <Container>
          <CustomFontPCenter>
            {menu && isLnlnOfficeMenu
              ? renderResource.div.Container.Header.j002
              : renderResource.div.Container.Header.j001}
          </CustomFontPCenter>
          <React.Fragment>
            {menu &&
              menu.cancelPolicyContent &&
              menu.cancelPolicyContent
                .trim()
                .split(/(<\s*\/?br\s*\/?>\s*){2,}/i)
                .map((policy, i) => {
                  return isLnlnOfficeMenu ? (
                    <LnlnOfficeDetail key={i}>
                      {br2nl(policy.trim(), true)}
                    </LnlnOfficeDetail>
                  ) : (
                    <Detail key={i}>{br2nl(policy.trim(), true)}</Detail>
                  );
                })}
            {menu && this.isDisplayLinkToHospitalDetail(menu) && (
              <LinkWrapper>
                <LinkToDetailPage
                  to={hospitalDetailUrl}
                  id={gtmId}
                  target="_blank"
                  color="default"
                >
                  {renderResource.div.Container.Link.j001}
                  <IconArrowInline color="default" />
                </LinkToDetailPage>
              </LinkWrapper>
            )}
            {isShowFooter && (
              <DetailFooter>
                {renderResource.div.Container.Footer.j001}
              </DetailFooter>
            )}
          </React.Fragment>
        </Container>
      </div>
    );
  }
}
CancelPolicy.contextType = SiteContext;
export default CancelPolicy;
