import styled from "styled-components";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";

const Container = styled.div`
  padding: 25px 15px;
`;

export default ({ children, disabled, ...props }) => (
  <Container {...props}>
    <ButtonFullWidth disabled={disabled} onClick={props.onClick}>
      {children}
    </ButtonFullWidth>
  </Container>
);
