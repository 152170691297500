import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Heading1 from "../../atoms/headings/Heading1";
import Panel from "../../atoms/panels/Panel";
import FontP from "../../atoms/fonts/FontP";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.organisms.mypage.InsuranceCardComplete;

class InsuranceCardComplete extends Component {
  handleClickButton = () => {
    this.props.history.push("/my-page");
  };

  render() {
    return (
      <React.Fragment>
        <Heading1>{Resources.title}</Heading1>
        <Panel padding="l">
          <FontP align="center">{Resources.text}</FontP>
        </Panel>
        <Panel padding="v0hm">
          <ButtonFullWidth onClick={this.handleClickButton}>
            {Resources.button}
          </ButtonFullWidth>
        </Panel>
      </React.Fragment>
    );
  }
}

export default withRouter(InsuranceCardComplete);
