import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import InsuranceCardComfirm from "../organisms/mypage/InsuranceCardConfirm";
import Titles from "../../Resources/Titles";
import { InsuranceCard } from "../../Utils/InsuranceCard";
import {
  GenerateUploadPresignedUrl,
  UploadInsuranceCard,
} from "../../Utils/GraphQLQueries";
import GraphQLClient from "../../Utils/GraphQLClient";
import axios from "axios";

class InsuranceCardConfirmPage extends Component {
  state = {
    isLoading: false,
  };

  //upload S3
  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(signedRequest, file, options);
  };

  handleUploadInsuranceCard = async (insuranceCard) => {
    this.setState({ isLoading: true });
    const lastModifiedOfFileSelected = this.props.location.state.lastModified;
    try {
      if (lastModifiedOfFileSelected === insuranceCard.lastModified) {
        const response = await GraphQLClient.mutate({
          mutation: GenerateUploadPresignedUrl,
          variables: {
            fileName: insuranceCard.name,
            fileType: insuranceCard.type,
          },
        });

        const {
          putSignedRequest,
          filePath,
        } = response.data.generateUploadPresignedUrl;

        await this.uploadToS3(insuranceCard, putSignedRequest);

        const updatePatient = await GraphQLClient.mutate({
          mutation: UploadInsuranceCard,
          variables: {
            fileName: insuranceCard.name,
            fileType: insuranceCard.type,
            filePath: filePath,
          },
        });
        return Promise.all([response, updatePatient]).then((value) => {
          if (value[1].data.uploadInsuranceCard) {
            return this.props.history.push("/my-page/insurance-card/complete");
          }
        });
      }
    } catch (error) {
      return this.props.history.push("/my-page/insurance-card/error", {
        InsuranceCardErr: ["uploadErr"],
      });
    }
    return this.props.history.push("/my-page/insurance-card/error", {
      InsuranceCardErr: ["localFileModified"],
    });
  };

  handleSelectInsuranceCard = (e) => {
    // TODO file select
    this.setState({ isLoading: true });
    try {
      let fileSelected = e.target.files.item(0);
      let validateInsuranceCard = InsuranceCard.validation(fileSelected);
      if (validateInsuranceCard.length === 0) {
        InsuranceCard.compression(fileSelected).then((res) => {
          this.setState({ isLoading: false });
          return this.props.history.replace("/my-page/insurance-card/confirm", {
            file: res,
            lastModified: res.lastModified,
            numberOfload: 1,
          });
        });
      } else {
        return this.props.history.push("/my-page/insurance-card/error", {
          InsuranceCardErr: validateInsuranceCard,
        });
      }
    } catch (error) {
      return this.props.history.push("/my-page/insurance-card/error", {
        InsuranceCardErr: ["localFileModified"],
      });
    }
  };

  render() {
    const main = (
      <InsuranceCardComfirm
        handleUploadInsuranceCard={this.handleUploadInsuranceCard}
        handleSelectInsuranceCard={this.handleSelectInsuranceCard}
        {...this.props}
      />
    );
    return (
      <AuthenticatedTemplate
        title={Titles.insuranceCardConfirm}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default InsuranceCardConfirmPage;
