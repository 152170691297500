import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Auth, Logger, Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import Typography2 from "../../atoms/fonts/Typography2";
import Button from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import InputTelFull from "../../atoms/forms/InputTelFull";
import InputTextPassword from "../../atoms/forms/InputTextPassword";
import Heading1 from "../../atoms/headings/Heading1";
import Heading3 from "../../atoms/headings/Heading3";
import IvrAuthenticate from "../../organisms/auth/IvrAuthenticate";
import PasswordCaution from "../../organisms/auth/PasswordCaution";
import FontPCenter from "../../atoms/fonts/FontPCenter";
import FontPBold from "../../atoms/fonts/FontPBold";
import { ERRORS } from "../../../Utils/Errors";
import { IVR } from "../../../Utils/Constant";
import { japaneseList } from "../../../Resources/japaneseList";

const errorJapaneseMessage = (errorEnglishMessage) => {
  switch (errorEnglishMessage) {
    case "Username/client id combination not found.":
    case "Code cannot be empty":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j001;
    case "Invalid code provided, please request a code again.":
    case "Invalid verification code provided, please try again.":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j002;
    case "Password cannot be empty":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j003;
    case "Passwords do not match":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j004;
    case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j005;
    case "Cannot reset password for the user as there is no registered/verified email or phone_number":
    case "User password cannot be reset in the current state.":
    case "User is disabled.":
    case "PostConfirmation failed with error The conditional request failed.":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j006;
    case "Attempt limit exceeded, please try after some time.":
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j007;
    default:
      return japaneseList.pages.auth.ForgotPasswordPage.errorMessages.j999;
  }
};

const Container = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};
`;

const ExplanationText = styled(Typography2)`
  padding: 40px 0 20px 0;
  line-height: 1.5;
  text-align: left;
`;

const TitleTop = styled(Typography2)`
  padding: 20px 0;
  line-height: 1.5;
  text-align: left;
`;

const InputLabel = styled(FontPBold)`
  margin-bottom: 2px;
  line-height: 1;
`;

const ButtonSection = styled.div`
  text-align: center;
  padding: 0 15px 20px 15px;
`;

const SendLetterButton = styled.div`
  margin-top: 20px;
`;

const ResendButton = styled(ButtonOutlined)`
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 4px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin: 0;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
`;

const CancelButton = styled(ButtonOutlined)`
  width: 100%;
  margin: 0;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
`;

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.logger = new Logger("ForgotPassword");
    this.inputs = {};
    let locationState = this.props.location.state;
    this.state = {
      phoneNumber: locationState?.phoneNumber ? locationState.phoneNumber : "",
      username: locationState?.username ? locationState.username : "",
      delivery: locationState?.delivery ? locationState.delivery : null,
      disabled: false,
      showPassword: false,
      showPasswordConfirm: false,
      code: "",
      disabledSubmit: true,
    };
  }

  getErrorMessage(error) {
    const originalErrorMessage = error.message ? error.message : error;
    if (originalErrorMessage) return errorJapaneseMessage(originalErrorMessage);
    return japaneseList.pages.auth.ForgotPasswordPage.getErrorMessage.j001;
  }

  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value } = evt.target;
    this.inputs[name] = value;
    if (name === "code") {
      this.setState({
        code: value,
      });
    }

    this.setState({
      disabledSubmit:
        !this.inputs.code ||
        !this.inputs.password ||
        !this.inputs.password_confirm,
    });
  };

  send = () => {
    Sentry.captureMessage("password-send-authcode", Sentry.Severity.Log);
    let self = this;

    this.setState({
      disabled: true,
    });
    const { phone_number } = this.inputs;
    if (!phone_number) {
      Sentry.captureMessage(
        "password-send-authcode-error-phoneNumberNoValue",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: japaneseList.pages.auth.ForgotPasswordPage.send.j001,
            level: "error",
          },
        },
        "ForgotPassword"
      );
      this.setState({
        disabled: false,
      });
      return;
    }

    let username = phone_number;
    if (username.startsWith("0")) {
      username = "+81" + username.substring(1, username.length);
    }
    Auth.forgotPassword(username)
      .then((data) => {
        this.props.history.replace(this.props.location.pathname, {
          ...this.props.location.state,
          username,
          phoneNumber: phone_number,
          delivery: true,
        });
        this.setState({
          delivery: data.CodeDeliveryDetails,
          disabled: false,
          phoneNumber: phone_number,
          username,
        });
        this.props.authcb("FORGOT_PASSWORD");
      })
      .catch((err) => {
        this.logger.error(self.getErrorMessage(err), err);
        Sentry.captureMessage(
          "password-send-authcode-error-" + err.code,
          Sentry.Severity.Log
        );
        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: { message: self.getErrorMessage(err), level: "error" },
          },
          "ForgotPassword"
        );
        this.setState({
          disabled: false,
        });
      });
  };

  resend = () => {
    const self = this;
    Auth.forgotPassword(this.state.username)
      .then((data) => {
        this.setState({ delivery: data.CodeDeliveryDetails });
        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: {
              message:
                japaneseList.pages.auth.ForgotPasswordPage.render.Fragment
                  .Container.Typography.j003,
              level: "error",
            },
          },
          "ForgotPassword"
        );
      })
      .catch((err) => {
        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: { message: self.getErrorMessage(err), level: "error" },
          },
          "ForgotPassword"
        );
      });
  };

  submit = () => {
    Sentry.captureMessage("password-reset", Sentry.Severity.Log);
    const submitResource = japaneseList.pages.auth.ForgotPasswordPage.submit;
    let self = this;

    this.setState({
      disabled: true,
    });
    const phone_number = this.state.phoneNumber;
    const { code, password, password_confirm } = this.inputs;
    if (!password) {
      Sentry.captureMessage(
        "password-reset-error-passwordNoValue",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: { message: submitResource.j001, level: "error" },
        },
        "ForgotPassword"
      );
      this.setState({
        disabled: false,
      });
      return;
    }
    if (password === phone_number) {
      Sentry.captureMessage(
        "password-reset-error-sameAsPhoneNumber",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message: submitResource.j002,
            level: "error",
          },
        },
        "ForgotPassword"
      );
      this.setState({
        disabled: false,
      });
      return;
    }
    if (password !== password_confirm) {
      Sentry.captureMessage(
        "password-reset-error-notSameAsConfirm",
        Sentry.Severity.Log
      );
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: { message: submitResource.j003, level: "error" },
        },
        "ForgotPassword"
      );
      this.setState({
        disabled: false,
      });
      return;
    }
    let username = phone_number;
    if (username.startsWith("0")) {
      username = "+81" + username.substring(1, username.length);
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        this.setState({ delivery: null, disabled: false });
        this.props.showMessage(submitResource.j004);
        this.props.history.replace(this.props.location.pathname, {
          ...this.props.location.state,
          username: null,
          phoneNumber: null,
          delivery: null,
        });
        this.props.authcb("SIGN_IN");
      })
      .catch((err) => {
        let message = self.getErrorMessage(err);

        this.logger.error(message, err);
        Sentry.captureMessage(
          "password-reset-error-" + err.code,
          Sentry.Severity.Log
        );

        if (
          (err.message &&
            err.message.indexOf(ERRORS.INVALID_PASSWORD.LEGNTHDEFAULT.MESSAGE) >
              -1) ||
          err.code === ERRORS.INVALID_PASSWORD.POLICY.CODE
        ) {
          message = ERRORS.INVALID_PASSWORD.MESSAGEJP;
        }

        Hub.dispatch(
          "msg",
          {
            event: "open",
            data: { message: message, level: "error" },
          },
          "ForgotPassword"
        );
        this.setState({
          disabled: false,
        });
      });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPasswordConfirm = () => {
    this.setState((state) => ({
      showPasswordConfirm: !state.showPasswordConfirm,
    }));
  };

  render() {
    const rendertResource = japaneseList.pages.auth.ForgotPasswordPage.render;
    const title = rendertResource.Fragment.Container.ResetPassword.j001.replace(
      /{{patientInfo.phoneNumber}}/g,
      this.state.phoneNumber
    );
    if (!this.state.delivery) {
      return (
        <React.Fragment>
          <Heading1>{rendertResource.Fragment.PrimaryHeading.j001}</Heading1>
          <Container>
            <TitleTop>
              {rendertResource.Fragment.Container.Typography.j001}
              <br />
              {rendertResource.Fragment.Container.Typography.j002}
            </TitleTop>
            <InputLabel>
              {rendertResource.Fragment.Container.PhoneInput.j003}
            </InputLabel>
            <InputTelFull
              name="phone_number"
              onChange={this.handleInputChange}
              placeholder={rendertResource.Fragment.Container.PhoneInput.j001}
            />
            <br />
            <br />
            <ButtonSection>
              <SubmitButton
                onClick={this.send}
                disabled={this.state.disabled}
                id="reset-password-send-button"
              >
                {rendertResource.Fragment.Container.FullWidthButton.j001}
              </SubmitButton>
              <br />
              <br />
              <CancelButton
                onClick={() => this.props.authcb("SIGN_IN")}
                id="reset-password-back-to-login-button"
              >
                {rendertResource.Fragment.Container.FullWidthButton.j002}
              </CancelButton>
            </ButtonSection>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Heading1>パスワード再設定</Heading1>
          <Container>
            <FontPCenter>
              <TitleTop>{title}</TitleTop>
            </FontPCenter>
            <InputLabel>
              {rendertResource.Fragment.Container.PhoneInput.j004}
            </InputLabel>
            <InputTelFull
              value={this.state.code}
              name="code"
              onChange={this.handleInputChange}
              placeholder={rendertResource.Fragment.Container.PhoneInput.j002}
            />
            <ExplanationText>
              {rendertResource.Fragment.Container.ResetPassword.j002}
            </ExplanationText>
          </Container>
          <Container>
            <InputLabel>
              {rendertResource.Fragment.Container.InputTextPassword.j003}
            </InputLabel>
            <InputTextPassword
              placeholder={
                rendertResource.Fragment.Container.InputTextPassword.j001
              }
              name="password"
              onChange={this.handleInputChange}
            />
            <br />
            <br />
            <InputLabel>
              {rendertResource.Fragment.Container.InputTextPassword.j004}
            </InputLabel>
            <InputTextPassword
              placeholder={
                rendertResource.Fragment.Container.InputTextPassword.j002
              }
              name="password_confirm"
              onChange={this.handleInputChange}
            />
            <div style={{ textAlign: "left", marginTop: "10px" }}>
              <PasswordCaution />
            </div>
            <br />
            <ButtonSection>
              <SubmitButton
                onClick={this.submit}
                disabled={this.state.disabledSubmit}
                id="reset-password-submit-button"
              >
                {rendertResource.Fragment.Container.FullWidthButton.j003}
              </SubmitButton>
              <SendLetterButton>
                <ResendButton
                  onClick={this.resend}
                  id="reset-password-resend-button"
                >
                  {rendertResource.Fragment.Container.ButtonOutlined.j001}
                </ResendButton>
              </SendLetterButton>
            </ButtonSection>
          </Container>
          <Heading3>{rendertResource.Fragment.MiddleHeading.j001}</Heading3>
          <IvrAuthenticate
            phoneNumber={this.state.username}
            flowType={IVR.FLOWTYPE.RESET_PASSWORD}
          ></IvrAuthenticate>
        </React.Fragment>
      );
    }
  }
}
export default withRouter(ForgotPasswordPage);
