import React, {Component} from "react";
import styled from "styled-components";

import HeadingForm from "../../molecules/headings/HeadingForm";
import FontP from "../../atoms/fonts/FontP";
import InputTextPassword from "../../atoms/forms/InputTextPassword";
import PanelFormArea from "../../atoms/panels/PanelFormArea";

import PasswordCaution from "./PasswordCaution";

import { japaneseList } from "../../../Resources/japaneseList";
const renderResource = japaneseList.organisms.auth.InputPasswordForm;

const Margin = styled.div`
  margin-bottom: 10px;
`;

class InputNameForm extends Component {
  render(){
    return (
      <React.Fragment>
        <HeadingForm>
          {renderResource.Title}
        </HeadingForm>
        <PanelFormArea column={true}>
          <Margin>
            <PasswordCaution />
          </Margin>
          <Margin>
            <InputTextPassword name="password" placeholder={renderResource.placeholder} onChange={this.props.onChange} />
          </Margin>
          <Margin>
            <FontP>
              {renderResource.text}
            </FontP>
          </Margin>
          <Margin>
            <InputTextPassword name="password_confirm" placeholder={renderResource.placeholder} onChange={this.props.onChangeConfirm}/>
          </Margin>
        </PanelFormArea>
      </React.Fragment>
    )
  }
}

export default InputNameForm;
