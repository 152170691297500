import { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PanelWarningLink from "../molecules/panels/PanelWarningLink";
import { HomeMyNumberInsuranceCard } from "./HomeMyNumberInsuranceCard";

import { japaneseList } from "../../Resources/japaneseList";
import InputFormHeaderNoIcon from "../molecules/InputFormHeaderNoIcon";
const renderResource = japaneseList.organisms.HomePageCheck;

const Container = styled.div`
  margin: 0 15px 25px;
  &.isWarning {
    border: solid 2px ${(p) => p.theme.panel.border.color.note};
  }
`;

const CustomContainerHeader = styled(InputFormHeaderNoIcon)`
  margin: 10px 0px 20px 0px;
  padding: 0 15px;
  height: 44px;
  background-color: #f1f3f3;
  font-family: Hiragino Kaku Gothic ProN;
  font-weight: 600 !important;
  color: #333333 !important;
`;

class HomePageCheck extends Component {
  static defaultProps = {
    postalCode: "",
    organizationId: null,
    insuranceCard: {
      shouldUpload: false,
      hasAppointmentSupportMyNumberCard: false,
    },
  };

  render() {
    const { postalCode, organizationId, insuranceCard } = this.props;
    if (organizationId) {
      return null;
    }

    const classNameForContainer = "isWarning";
    const isWarning = !postalCode || insuranceCard.shouldUpload;

    const shouldShowAddressWarning = !postalCode;
    const shouldShowInsuranceCardWarning =
      insuranceCard.shouldUpload &&
      !insuranceCard.hasAppointmentSupportMyNumberCard;
    const shouldShowMyNumberInsuranceCard =
      insuranceCard.shouldUpload &&
      insuranceCard.hasAppointmentSupportMyNumberCard;
    return (
      <>
        {isWarning && (
          <>
            <CustomContainerHeader>
              {renderResource.err.title}
            </CustomContainerHeader>
            {shouldShowAddressWarning ||
              (shouldShowInsuranceCardWarning && (
                <Container className={classNameForContainer}>
                  <>
                    {shouldShowAddressWarning && (
                      <PanelWarningLink
                        id="register-address-page-link"
                        to={"/my-page/address/update"}
                      >
                        {renderResource.err.address}
                      </PanelWarningLink>
                    )}
                    {shouldShowInsuranceCardWarning && (
                      <PanelWarningLink to={"/my-page/insurance-card"}>
                        {renderResource.err.insuranceCard}
                      </PanelWarningLink>
                    )}
                  </>
                </Container>
              ))}
            {shouldShowMyNumberInsuranceCard && <HomeMyNumberInsuranceCard />}
          </>
        )}
      </>
    );
  }
}

export default withRouter(HomePageCheck);
