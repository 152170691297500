import React, { Component } from "react";
import IconCourse from "../../atoms/icons/IconCourse";
import styled from "styled-components";
import LinkA from "../../atoms/links/LinkA";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import { Phone } from "@material-ui/icons";
import { MyNumberInsuranceCard } from "./MyNumberInsuranceCard";

const HospitalTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: ${(p) => p.theme.color.font};
  padding-bottom: 5px;
`;

const Content = styled.div`
  color: ${(p) => p.theme.color.font};
  padding: 10px 0;
  font-size: 0.9rem;
`;

const MyNumberInsuranceCardWrapper = styled.div`
  margin-bottom: 5px;
`;

const Address = styled(Content)`
  font-size: 0.8rem;
`;

const Container = styled.div`
  padding: 15px;
  line-height: 1.5;
`;

const Link = styled(LinkA)`
  font-size: 0.8rem;
`;

const LinkContainer = styled.div`
  margin: -10px 0 10px 0;
`;

const CourseIconTag = styled(IconCourse)`
  float: left;
  margin: 0 6px 6px 0;
  padding: 2px 9px;
`;

const Tags = styled.div`
  display: block;

  ::after {
    display: block;
    clear: both;
    content: " ";
  }
`;

const CustomFontPSmall = styled(FontPSmall)`
  white-space: pre-wrap;
`;
const TelephoneButton = styled(ButtonOutlined)`
  height: 36px;
  line-height: 30px;
  min-height: auto;
  padding: 0 5px;
  border-radius: 18px;
  margin: 10px 0px 10px 0px;
`;

const IconButtonContainer = styled.div`
  width: 100%;
  line-height: 24px;
  margin: 0px 20px 0px 0px;
`;
const IconButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 2px 0px 2px;
`;

const PhoneCustomIcon = styled(Phone)`
  display: inline-block;
  vertical-align: middle;
  margin: 0px 2px 0px 2px;
`;

class HospitalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { hospital } = this.props;
    return (
      <div>
        <Container>
          <HospitalTitle>{hospital.name}</HospitalTitle>
          {hospital.isSupportMyNumberInsuranceCard && (
            <MyNumberInsuranceCardWrapper>
              <MyNumberInsuranceCard />
            </MyNumberInsuranceCardWrapper>
          )}
          <Tags>
            {hospital.medicalCourses.map((item, index) => {
              return <CourseIconTag key={index}>{item.title}</CourseIconTag>;
            })}
          </Tags>
          <Address>{hospital.address}</Address>
          <LinkContainer>
            {hospital.url && (
              <React.Fragment>
                <Link
                  href={
                    hospital.url +
                    (hospital.url.includes("?") ? "&lnln_ob=1" : "?lnln_ob=1")
                  }
                  target="_blank"
                >
                  {hospital.url}
                </Link>
                <br />
              </React.Fragment>
            )}
            {hospital.tel && (
              <TelephoneButton
                onClick={() => {
                  window.location.href = `tel:${hospital.tel}`;
                }}
              >
                <IconButtonContainer>
                  <PhoneCustomIcon />
                  <IconButtonText>{hospital.tel}</IconButtonText>
                </IconButtonContainer>
              </TelephoneButton>
            )}
          </LinkContainer>
          {hospital.description && (
            <CustomFontPSmall>{hospital.description}</CustomFontPSmall>
          )}
        </Container>
      </div>
    );
  }
}

export default HospitalInfo;
