import React, { useState, useEffect } from "react";
import BasicTemplate from "../templates/BasicTemplate";
import Heading1 from "../atoms/headings/Heading1";
import { japaneseList } from "../../Resources/japaneseList";
import { InterviewSheetForm } from "../organisms/reservation-form/InterviewSheetForm";
import { StepperForm } from "../organisms/reservation-form/StepperForm";
import { RESERVE_STEP } from "../../Utils/Constant";
import {
  InterviewSheet,
  ReleaseTentativeReservation,
} from "../../Utils/GraphQLQueries";
import GraphQLClient from "../../Utils/GraphQLClient";
import _ from "lodash";

const renderResource = japaneseList.pages.ReservationInterviewSheetPage.render;

export const ReservationInterviewSheetPage = (props) => {
  const [interviewSheetAnswer, setInterviewSheetAnswer] = useState(null);
  const reserveData = props.location.state && props.location.state.reserveData;
  const reserveInput =
    props.location.state && props.location.state.reserveInput;
  const reserveInterviewSheetAnswer =
    props.location.state && props.location.state.reserveInterviewSheetAnswer;
  const medicineDelivery =
    props.location.state && props.location.state.medicineDelivery;
  const fileList = props.location.state && props.location.state.fileList;
  const reserveSteps =
    props.location.state && props.location.state.reserveSteps;
  const currentStep =
    reserveSteps &&
    reserveSteps.find((step) => step.reserveStep === RESERVE_STEP.INTERVIEW);
  const { hospitalId } = props.match.params;

  if (!reserveData || !reserveInput) {
    props.history.push(`/hospital/${hospitalId}`);
    return null;
  }

  useEffect(() => {
    if (
      reserveInterviewSheetAnswer &&
      reserveInterviewSheetAnswer.interviewAnswers
    ) {
      setInterviewSheetAnswer(reserveInterviewSheetAnswer);
    } else {
      getData();
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getData = async () => {
    const interviewSheet = await getInterviewSheet();
    const interviewSheetAnswer = {
      ...interviewSheet,
      interviewAnswers: interviewSheet.interviewSheetItems.map((item) => {
        return {
          ...item,
          interviewChoiceNos: [],
          freeText: "",
        };
      }),
    };
    setInterviewSheetAnswer(interviewSheetAnswer);
  };

  const getInterviewSheet = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: InterviewSheet,
      variables: {
        hospitalId: reserveInput.hospitalId,
        interviewSheetId: reserveData.menu.interviewSheetId,
      },
    });
    if (errors) {
      console.error("InterviewSheet errors:", JSON.stringify(errors));
    }
    return data ? data.interviewSheet : null;
  };

  const handleBeforeUnload = async (e) => {
    // It is not always executed due to browser dependence.
    // If it is not executed, the tentative reservation will be deleted after the specified time has passed.
    await GraphQLClient.mutate({
      mutation: ReleaseTentativeReservation,
    });
  };

  const handleRevertClick = () => {
    props.history.push({
      pathname: currentStep && currentStep.previousUrl,
      state: {
        reserveInput: reserveInput,
        reserveData: reserveData,
        reserveInterviewSheetAnswer: interviewSheetAnswer,
        medicineDelivery: medicineDelivery,
        fileList,
        reserveSteps,
      },
    });
  };

  const handleConfirmClick = () => {
    props.history.push({
      pathname: currentStep && currentStep.followingUrl,
      state: {
        reserveData: reserveData,
        reserveInput: reserveInput,
        reserveInterviewSheetAnswer: interviewSheetAnswer,
        medicineDelivery: medicineDelivery,
        fileList,
        reserveSteps,
      },
    });
  };

  const handleInterviewAnwerChange = (value) => {
    setInterviewSheetAnswer(_.cloneDeep(value));
  };

  const main = (
    <React.Fragment>
      <Heading1>{renderResource.Title.j001}</Heading1>
      <StepperForm reserveSteps={reserveSteps} currentStep={currentStep} />
      {interviewSheetAnswer && (
        <InterviewSheetForm
          interviewSheetAnswer={interviewSheetAnswer}
          handleConfirmClick={handleConfirmClick}
          handleRevertClick={handleRevertClick}
          handleInterviewAnwerChange={handleInterviewAnwerChange}
        />
      )}
    </React.Fragment>
  );

  return <BasicTemplate title={renderResource.Title.j001} main={main} />;
};
