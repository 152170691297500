import React, { Component } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import LinkToNoUnder from "../../atoms/links/LinkToNoUnder";
import classNames from "classnames";

const styles = () => ({
  icon: {
    margin: 0,
    color: "#ccc",
  },
  panel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "-1px",
    padding: "10px 8px 10px 15px",
    color: "#ccc",
    lineHeight: "1.5",
    width: "50%",
    borderTop: "thin solid #ccc",
    borderRight: "thin solid #ccc",
    borderBottom: "thin solid #ccc",
    backgroundColor: "#fff",
  },
  panelContent: {
    color: "#393939",
  },
  firstRow: {
    borderTop: "none",
  },
  rightColumn: {
    borderRight: "none",
  },
  disableLink: {
    pointerEvents: "none",
  },
  disableColor: {
    color: "#ccc",
  },
});

class PrefectureAreas extends Component {
  render() {
    const {
      prefecture,
      hospitalCount,
      searchResultPageUrl,
      index,
      classes,
    } = this.props;

    return (
      <LinkToNoUnder
        key={index}
        to={searchResultPageUrl(prefecture.code)}
        className={classNames({
          [classes.panel]: true,
          [classes.firstRow]: index < 2,
          [classes.rightColumn]: index % 2,
          [classes.disableLink]: hospitalCount ? false : true,
        })}
      >
        <div
          className={classNames({
            [classes.panelContent]: true,
            [classes.disableColor]: hospitalCount ? false : true,
          })}
        >
          {prefecture.jpName}({hospitalCount ? hospitalCount.count : 0})
        </div>
        {hospitalCount ? <ChevronRightIcon className={classes.icon} /> : ""}
      </LinkToNoUnder>
    );
  }
}

export default withStyles(styles)(withRouter(PrefectureAreas));
