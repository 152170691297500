import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import { device } from "../device";
import { Auth } from "aws-amplify";
import TitleTag from "../atoms/others/TitleTag";
import Favicon from "../atoms/others/Favicon";
import { IconCameraNG } from "../atoms/icons/IconCameraNG";
import { IconMicNG } from "../atoms/icons/IconMicNG";
import StandbyLoadingBall from "../atoms/StandbyLoadingBall";
import Titles from "../../Resources/Titles";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";
import { isLnln } from "../../Utils/checkLnln";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  StandbyAppointment,
  UpdateLastLoginTime,
} from "../../Utils/GraphQLQueries";
import Heading1 from "../atoms/headings/Heading1";
import PatientDeviceErrorIphoneSafari from "../../images/patient_device_error_iphone_safari.png";
import PatientDeviceErrorWinChrome from "../../images/patient_device_error_win_chrome.png";
import PatientDeviceErrorWinEdge from "../../images/patient_device_error_win_edge.png";
import PatientDeviceErrorMacChrome from "../../images/patient_device_error_mac_chrome.png";
import PatientDeviceErrorAndroidChrome from "../../images/patient_device_error_android_chrome.png";
import PatientDeviceErrorIpadSafari from "../../images/patient_device_error_ipad_safari.png";
import InforIcon from "../../images/standby/infor.svg";
import StandbyPageCard from "../molecules/StandbyPageCard";
import {
  GetMediaDeviceStatus,
  MediaDeviceStatus,
} from "../../Utils/MediaDevice";
import { formatPeriod } from "../../Utils/Utility";
import dateParser from "date-fns/parse";
import differenceInSeconds from "date-fns/difference_in_seconds";
import { deviceOS, DeviceOSName } from "../device";
import { getUnsupportedDeviceInfo } from "../../Utils/UnsupportedDevice";
import * as browserDeviceDetection from "../../Utils/browserDeviceDetection";
import { ErrorDevice } from "../../enums";
import { MEDIA_DEVICE_ERROR } from "../../Utils/Constant";
import * as Sentry from "@sentry/browser";
import Guard from "../../Utils/Guard";

const ErrorMessage = japaneseList.pages.VideoStandbyPage.render.ErrorMessage;
const deviceErrorImageUrl = {
  [ErrorDevice.IphoneSafari]: PatientDeviceErrorIphoneSafari,
  [ErrorDevice.WinChrome]: PatientDeviceErrorWinChrome,
  [ErrorDevice.WinEdge]: PatientDeviceErrorWinEdge,
  [ErrorDevice.MacChrome]: PatientDeviceErrorMacChrome,
  [ErrorDevice.AndroidChrome]: PatientDeviceErrorAndroidChrome,
  [ErrorDevice.IpadSafari]: PatientDeviceErrorIpadSafari,
};
const GET_DEVICESTATUS_INTERVAL = 10000;
const UPDATE_APPOINTMENT_KEY_INTERVAL = 3000;
const PERMISSION_SETTING_DOC_ANDROID =
  "/documents/media-permission-settings/patient-android.pdf";
const PERMISSION_SETTING_DOC_IPHONE =
  "/documents/media-permission-settings/patient-iphone.pdf";
const PERMISSION_SETTING_DOC_WINDOWS =
  "/documents/media-permission-settings/patient-windows.pdf";
const PERMISSION_SETTING_DOC_MAC =
  "/documents/media-permission-settings/patient-mac.pdf";
const PERMISSION_SETTING_DOC_IPAD =
  "/documents/media-permission-settings/patient-ipad.pdf";
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  @media screen and ${device.tb} {
    width: 424px;
    border-right: thin solid #c7c7c7;
    border-left: thin solid #c7c7c7;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.05),
      0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;
const Main = styled.div`
  flex-grow: 1;
`;

//ng css

const MessageContainer = styled.div`
  margin: 21px 15px 0px;
  border: 1.5px solid ${(p) => p.theme.color.primary};
  border-radius: 5px;
  opacity: 1;
  padding-bottom: 19px;
`;

const WarningIcon = styled.div`
  padding-top: 20px;
  height: 60px;
  margin-bottom: 10px;
`;

const Warning = styled.div`
  margin: 16px 18px 0px;
  text-align: center;
  background-color: #fff0f0;
`;

const WarningExplain = styled.div`
  padding: 0 19px;
  color: #393939;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  opacity: 1;
`;

const BottonExplain = styled.div`
  padding: 20px 15px 0px;
  margin-top: 31px;
  font-size: 14px;
  color: #393939;
  line-height: 21px;
  text-align: left;
  font-weight: 300;
  opacity: 1;
  border-top: 0.5px solid ${(p) => p.theme.color.primary};
`;

const Botton = styled.div`
  margin: 20px 15px 0px;
  border: 1.5px solid ${(p) => p.theme.color.primary};
  border-radius: 5px;
  padding: 15px 10px;
  height: 50px;
  font-size: 16px;
  color: ${(p) => p.theme.color.primary};
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  opacity: 1;
  cursor: pointer;
`;

const TypographyTitle = styled.div`
  margin-top: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  font-size: 16px;
  color: ${(p) => p.theme.color.primary};
  line-height: 27px;
  text-align: left;
  font-weight: 600;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
  opacity: 1;
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const FlexWrapIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImgContent = styled.div`
  padding: 20px 19px 0px 19px;
`;

const AppointmentInfo = styled.div`
  padding: 14px 15px 14px 15px;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
  opacity: 1;
`;

const HospitalName = styled.div`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.color.font};
`;

const AppointmentTime = styled.div`
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${(p) => p.theme.color.font};
`;

const WarningText = styled.div`
  color: #393939;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 20px;
`;

const WaitingTimeInfo = styled.div`
  margin: 19px 19px 20px 19px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 63px;
  background: ${(p) => p.theme.color.secondaryVariant2};
  justify-content: center;
`;

const WaitingTimeInfoText = styled.div`
  font-family: "Hiragino Kaku Gothic ProN";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #333333;
`;

const WaitingTime = styled.div`
  margin: 13px 10px 14px 10px;
  font-family: Hiragino Kaku Gothic ProN;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: ${(p) => p.theme.color.primary};
`;

const OverStartTimeText = styled.div`
  margin: 20px 37px 0px 37px;
  font-family: Hiragino Kaku Gothic ProN;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #333333;
`;

const ScreenActiveAnnotation = styled.div`
  margin: 0px 19px 0px 19px;
  font-family: Hiragino Kaku Gothic ProN;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
`;

const WaitingAnnotationContainer = styled.div`
  margin: 20px 14px 20px 14px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 5px 16px 5px 16px;
  background: ${(p) => p.theme.color.warningBackground};
`;

const WaitingAnnotation = styled.div`
  position: static;
  font-family: Hiragino Kaku Gothic ProN;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ef5e5e;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
`;

const Annotation = styled.div`
  margin: 20px 14px;
  font-family: Hiragino Kaku Gothic ProN;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: ${(p) => p.theme.color.fontVariant};
`;

class OnlineChatStandbyPage extends Component {
  state = {
    isMediaDeviceBlocked: false,
    isMediaDeviceAccepted: false,
    standbyAppointmentInfo: null,
    isLoading: true,
    intervalId: 0,
    updateAppointmentKeyIntervalId: null,
    deviceOSName: null,
    mediaDeviceError: "",
    deviceOsBrowserError: "",
    waitingTimeToChat: "",
    isTimeToChat: false,
    isWarningBrowser: false,
    count: 0,
    wakeLock: null,
  };

  async componentDidMount() {
    try {
      const user = await Auth.currentUserPoolUser();
      if (user) {
        await this.loadOnlineChatStandbyInfo();
        const intervalId = setInterval(
          this.updateMediaDeviceStatusState,
          GET_DEVICESTATUS_INTERVAL
        );
        const updateAppointmentKeyIntervalId = setInterval(() => {
          if (this.state.count < 3 && this.state.standbyAppointmentInfo) {
            this.updateLastLoginTime(
              this.state.standbyAppointmentInfo.createTime
            );
            this.setState((prevState) => ({
              count: prevState.count + 1,
            }));
          } else {
            clearInterval(updateAppointmentKeyIntervalId);
          }
        }, UPDATE_APPOINTMENT_KEY_INTERVAL);
        this.setState({
          intervalId: intervalId,
          updateAppointmentKeyIntervalId: updateAppointmentKeyIntervalId,
          deviceOSName: deviceOS(),
          deviceOsBrowserError: this.getSupportDeviceOsBrowser(),
        });
      }
    } catch (err) {
      console.log(err);
    }
    try {
      if ("wakeLock" in navigator) {
        document.addEventListener(
          "visibilitychange",
          this.handleVisibilityChange
        );
        if (this.state.wakeLock === null) {
          const wakeLock = await navigator.wakeLock.request("screen");
          this.setState({ wakeLock: wakeLock });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async componentWillUnmount() {
    try {
      if ("wakeLock" in navigator) {
        document.removeEventListener(
          "visibilitychange",
          this.handleVisibilityChange
        );
        if (this.state.wakeLock !== null) {
          await this.state.wakeLock.release();
          this.setState({ wakeLock: null });
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.restInterval();
  }

  handleVisibilityChange = async () => {
    try {
      if (
        this.state.wakeLock !== null &&
        document.visibilityState === "visible"
      ) {
        const wakeLock = await navigator.wakeLock.request("screen");
        this.setState({ wakeLock: wakeLock });
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadOnlineChatStandbyInfo = async () => {
    await this.loadStandByInfo();
    this.setState({
      isLoading: false,
    });
  };

  reloadStandbyPage = async () => {
    window.location.reload();
  };

  loadStandByInfo = async () => {
    const { data } = await GraphQLClient.query({
      query: StandbyAppointment,
    });
    if (data.standbyAppointment && data.standbyAppointment.isStandby) {
      const startDate = dateParser(data.standbyAppointment.appointment.from);
      const nowDate = dateParser(new Date());
      const diffTimes = differenceInSeconds(startDate, nowDate);
      this.transitionWaitingTimeToChat(diffTimes);

      await this.updateMediaDeviceStatusState();
      this.setState({
        standbyAppointmentInfo: data.standbyAppointment.appointment,
        isWarningBrowser: await this.currentBrowser(),
      });
      if (this.state.isMediaDeviceBlocked) {
        // When Standby Failed, Output To Sentry Log.
        Sentry.captureMessage("video-chat-standby-failed", Sentry.Severity.Log);
      } else {
        // When Standby Success, Output To Sentry Log.
        Sentry.captureMessage(
          "video-chat-standby-success",
          Sentry.Severity.Log
        );
      }
      this.updateLastLoginTime(data.standbyAppointment.appointment.createTime);
    } else {
      this.props.history.push("/");
    }
  };

  transitionWaitingTimeToChat = (diffTimes) => {
    const timer = setInterval(() => {
      if (diffTimes >= 0) {
        const minutes = Math.floor(diffTimes / 60);
        const seconds = Math.floor(diffTimes % 60);
        const timerMinutes = minutes < 10 ? "0" + minutes : minutes;
        const timerSeconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({
          waitingTimeToChat: timerMinutes + " : " + timerSeconds,
        });
        --diffTimes;
      } else {
        clearInterval(timer);
        this.setState({
          isTimeToChat: true,
        });
      }
    }, 1000);
  };

  updateMediaDeviceStatusState = async () => {
    const { mediaDeviceStatus, mediaDeviceError } = await GetMediaDeviceStatus(
      true
    );
    this.setState({
      isMediaDeviceBlocked: mediaDeviceStatus === MediaDeviceStatus.blocked,
      isMediaDeviceAccepted: mediaDeviceStatus === MediaDeviceStatus.accepted,
      mediaDeviceError,
    });
  };
  restInterval() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.updateAppointmentKeyIntervalId);
  }

  async updateLastLoginTime(appointmentKey) {
    const videoStandby = {
      isOpeningStandbyPage: true,
      isMediaDeviceBlocked: this.state.isMediaDeviceBlocked,
      isMediaDeviceAccepted: this.state.isMediaDeviceAccepted,
      isWarningBrowser: this.state.isWarningBrowser,
      appointmentKey: appointmentKey,
    };

    if (this.state.mediaDeviceError !== "") {
      videoStandby.lastMediaDeviceError = this.state.mediaDeviceError;
    }

    GraphQLClient.mutate({
      mutation: UpdateLastLoginTime,
      variables: { videoStandby },
    }).catch((errors) => {
      console.log(errors);
    });
  }

  currentBrowser = async function () {
    let { isBlock, alertArray } = await getUnsupportedDeviceInfo();
    let isWarningBrowser = false;
    if (!isBlock) {
      isWarningBrowser = alertArray.length > 0;
    }
    return isWarningBrowser;
  };

  getSupportDeviceOsBrowser = () => {
    if (
      browserDeviceDetection.isMobile &&
      browserDeviceDetection.isSafari &&
      browserDeviceDetection.isIOS
    ) {
      return ErrorDevice.IphoneSafari;
    } else if (
      browserDeviceDetection.isWindow &&
      browserDeviceDetection.isChrome
    ) {
      return ErrorDevice.WinChrome;
    } else if (
      browserDeviceDetection.isWindow &&
      browserDeviceDetection.isMSEdge
    ) {
      return ErrorDevice.WinEdge;
    } else if (
      browserDeviceDetection.isMacOS &&
      browserDeviceDetection.isChrome
    ) {
      return ErrorDevice.MacChrome;
    } else if (
      browserDeviceDetection.isAndroid &&
      browserDeviceDetection.isChrome
    ) {
      return ErrorDevice.AndroidChrome;
    } else if (
      browserDeviceDetection.isTablet &&
      browserDeviceDetection.isSafari
    ) {
      return ErrorDevice.IpadSafari;
    } else {
      return "";
    }
  };

  renderErrorMessage = () => {
    const { mediaDeviceError, deviceOsBrowserError } = this.state;
    if (mediaDeviceError === MEDIA_DEVICE_ERROR.NOT_ALLOWED_ERROR) {
      return (
        <WarningExplain>
          {deviceOsBrowserError &&
            ErrorMessage.NotAllowedError[deviceOsBrowserError].j001}
          <br />
          {deviceOsBrowserError &&
            ErrorMessage.NotAllowedError[deviceOsBrowserError].j002}
        </WarningExplain>
      );
    } else if (mediaDeviceError === MEDIA_DEVICE_ERROR.NOT_FOUND_ERROR) {
      return (
        <WarningExplain>
          {ErrorMessage.NotFoundError.j001}
          <br />
          {ErrorMessage.NotFoundError.j002}
        </WarningExplain>
      );
    } else if (mediaDeviceError === MEDIA_DEVICE_ERROR.NOT_READABLE_ERROR) {
      return (
        <WarningExplain>
          {ErrorMessage.NotReadableError.j001}
          <br />
          {ErrorMessage.NotReadableError.j002}
        </WarningExplain>
      );
    }
  };

  render() {
    const servicePath = isLnln() ? "lnln" : "carada";
    const { classes } = this.props;
    const Resources = japaneseList.pages.VideoStandbyPage.render;
    const { standbyAppointmentInfo, deviceOSName, mediaDeviceError } =
      this.state;
    return (
      <React.Fragment>
        <Favicon servicePath={servicePath} />
        <TitleTag title={Titles.OnlineChatStandbyPage} />
        <Root>
          <Header />
          <Heading1>{Resources.Title}</Heading1>
          <Main>
            {standbyAppointmentInfo && (
              <AppointmentInfo>
                <HospitalName>
                  {standbyAppointmentInfo.hospitalName}
                </HospitalName>
                <AppointmentTime>
                  {formatPeriod(
                    standbyAppointmentInfo.from,
                    standbyAppointmentInfo.to
                  )}
                  {Resources.ContentForNg.j003}
                </AppointmentTime>
              </AppointmentInfo>
            )}
            {this.state.isMediaDeviceBlocked ? (
              <React.Fragment>
                <MessageContainer>
                  <Warning>
                    <WarningIcon>
                      <img src={InforIcon} alt={""} />
                    </WarningIcon>
                    <WarningText>{Resources.ContentForNg.j001}</WarningText>
                  </Warning>
                  <FlexWrapIcons>
                    <IconCameraNG />
                    <IconMicNG />
                  </FlexWrapIcons>
                  {this.renderErrorMessage()}
                  {mediaDeviceError &&
                    mediaDeviceError ===
                      MEDIA_DEVICE_ERROR.NOT_ALLOWED_ERROR && (
                      <ImgContent>
                        <Img
                          src={
                            deviceErrorImageUrl[this.state.deviceOsBrowserError]
                          }
                        />
                      </ImgContent>
                    )}
                </MessageContainer>
                <BottonExplain>
                  {Resources.ContentForNg.j004[1]}
                  {mediaDeviceError &&
                    mediaDeviceError ===
                      MEDIA_DEVICE_ERROR.NOT_ALLOWED_ERROR && (
                      <React.Fragment>
                        <br />
                        {Resources.ContentForNg.j004[2]}
                      </React.Fragment>
                    )}
                </BottonExplain>
                <Botton onClick={this.reloadStandbyPage}>
                  {Resources.ContentForNg.j005}
                </Botton>
                {mediaDeviceError &&
                  mediaDeviceError === MEDIA_DEVICE_ERROR.NOT_ALLOWED_ERROR && (
                    <React.Fragment>
                      <TypographyTitle>
                        {Resources.ContentForNg.j006}
                      </TypographyTitle>
                      {(deviceOSName === DeviceOSName.Android ||
                        deviceOSName === DeviceOSName.Others) && (
                        <Anchor
                          href={PERMISSION_SETTING_DOC_ANDROID}
                          target="_blank"
                        >
                          <StandbyPageCard>
                            {Resources.ContentForNg.j007}
                          </StandbyPageCard>
                        </Anchor>
                      )}
                      {(deviceOSName === DeviceOSName.iPhone ||
                        deviceOSName === DeviceOSName.Others) && (
                        <Anchor
                          href={PERMISSION_SETTING_DOC_IPHONE}
                          target="_blank"
                        >
                          <StandbyPageCard>
                            {Resources.ContentForNg.j008}
                          </StandbyPageCard>
                        </Anchor>
                      )}
                      {(deviceOSName === DeviceOSName.Windows ||
                        deviceOSName === DeviceOSName.Others) && (
                        <Anchor
                          href={PERMISSION_SETTING_DOC_WINDOWS}
                          target="_blank"
                        >
                          <StandbyPageCard>
                            {Resources.ContentForNg.j009}
                          </StandbyPageCard>
                        </Anchor>
                      )}
                      {(deviceOSName === DeviceOSName.iPad ||
                        deviceOSName === DeviceOSName.Mac ||
                        deviceOSName === DeviceOSName.Others) && (
                        <Anchor
                          href={PERMISSION_SETTING_DOC_MAC}
                          target="_blank"
                        >
                          <StandbyPageCard>
                            {Resources.ContentForNg.j010}
                          </StandbyPageCard>
                        </Anchor>
                      )}
                      {(deviceOSName === DeviceOSName.iPad ||
                        deviceOSName === DeviceOSName.Mac ||
                        deviceOSName === DeviceOSName.Others) && (
                        <Anchor
                          href={PERMISSION_SETTING_DOC_IPAD}
                          target="_blank"
                        >
                          <StandbyPageCard>
                            {Resources.ContentForNg.j011}
                          </StandbyPageCard>
                        </Anchor>
                      )}
                    </React.Fragment>
                  )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MessageContainer>
                  {this.state.isTimeToChat ? (
                    <React.Fragment>
                      <OverStartTimeText>
                        {Resources.ContentForNormal.j004}
                      </OverStartTimeText>
                      <StandbyLoadingBall />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <WaitingTimeInfo>
                        <WaitingTimeInfoText>
                          {Resources.ContentForNormal.j002}
                        </WaitingTimeInfoText>
                        <WaitingTime>
                          {this.state.waitingTimeToChat}
                        </WaitingTime>
                        <WaitingTimeInfoText>
                          {Resources.ContentForNormal.j003}
                        </WaitingTimeInfoText>
                      </WaitingTimeInfo>
                    </React.Fragment>
                  )}
                  <ScreenActiveAnnotation>
                    {Resources.ContentForNormal.j005}
                  </ScreenActiveAnnotation>
                </MessageContainer>
                <WaitingAnnotationContainer>
                  <WaitingAnnotation>
                    {Resources.ContentForNormal.j006}
                    <br />
                    {Resources.ContentForNormal.j007}
                    <br />
                    {Resources.ContentForNormal.j008}
                    <br />
                    {Resources.ContentForNormal.j009}
                    <br />
                    {Resources.ContentForNormal.j010}
                  </WaitingAnnotation>
                </WaitingAnnotationContainer>
                <Annotation>{Resources.ContentForNormal.j011}</Annotation>
              </React.Fragment>
            )}
          </Main>
          <Footer />
          <LoadingScreen isLoading={this.state.isLoading} />
        </Root>
      </React.Fragment>
    );
  }
}

export default withRouter(Guard(OnlineChatStandbyPage));
