import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { IVR, SUBMIT_MODE, RETURN_MODE } from "../../../Utils/Constant";

const IVRConfirmPopupResource = japaneseList.molecules.Popup.IVRConfirmPopup;

const styles = () => ({
  dialogPaper: {
    //overwrite paper mui
    "max-height": "100%",
  },
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    padding: "30px 20px 0 20px",
  },
  dialogAction: {
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "0 20px 30px 20px",
    margin: 0,
  },
  dialogContent: {
    maxWidth: "303px",
    padding: "15px 24px 15px 24px",
  },
  dialogButton: {
    margin: 0,
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
});

const DialogTitleText = styled.span`
  font-size: 18px;
  color: ${(p) => p.theme.color.primary};
  font-weight: 600;
  line-height: 27px;
  text-align: center;
`;

const DialogContentText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #393939;
  line-height: 22px;
`;

const renderGaIdByFlowType = (flowType, mode) => {
  switch (true) {
    case flowType === IVR.FLOWTYPE.MEMBER_REGISTRATION && mode === SUBMIT_MODE:
      return "verification-code-resend-by-phone-call-confirm-button";
    case flowType === IVR.FLOWTYPE.MEMBER_REGISTRATION && mode === RETURN_MODE:
      return "verification-code-resend-by-phone-call-return-button";
    case flowType === IVR.FLOWTYPE.RESET_PASSWORD && mode === SUBMIT_MODE:
      return "reset-password-resend-by-phone-call-submit-button";
    case flowType === IVR.FLOWTYPE.RESET_PASSWORD && mode === RETURN_MODE:
      return "reset-password-resend-by-phone-call-return-button";
    default:
      return "";
  }
};

const popup = (props) => {
  const { classes, isOpen, handleIVRCall, handleClosePopup, flowType } = props;

  //--- Callbacks declaration
  const handleClickConfirm = useCallback((e) => {
    // default event stop
    e.preventDefault();
    handleIVRCall();
  });

  const handleClose = useCallback((e) => {
    // default event stop
    e.preventDefault();
    handleClosePopup();
  });

  return (
    <React.Fragment>
      <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>{IVRConfirmPopupResource.j001}</DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>{IVRConfirmPopupResource.j002}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            className={classes.dialogButton}
            onClick={handleClickConfirm}
            id={renderGaIdByFlowType(flowType, SUBMIT_MODE)}
          >
            {IVRConfirmPopupResource.j003}
          </Button>
          <ButtonOutlined
            className={classes.dialogButton}
            onClick={handleClose}
            id={renderGaIdByFlowType(flowType, RETURN_MODE)}
          >
            {IVRConfirmPopupResource.j004}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const IVRConfirmPopup = withStyles(styles)(popup);
