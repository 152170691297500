import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import ConsumerPolicy from "../organisms/ConsumerPolicy";
import { withRouter } from "react-router-dom";
import Titles from "../../Resources/Titles";

class ConsumerPolicyPage extends Component {
  render() {
    return <AuthenticatedTemplate title={Titles.consumerPolicy} main={<ConsumerPolicy />} />;
  }
}

export default withRouter(ConsumerPolicyPage);
