import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconLoading from "../../atoms/icons/IconLoading";

const styles = () => ({
  iconWrapper: {
    margin: "20px 0"
  }
});

class InfiniteScroller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 0
    };
  }

  onScrollWindow = () => {
    const { isFetching, advanceLength = 0 } = this.props;
    if (isFetching) return;
    const offsetHeight = document.body.offsetHeight;
    const windowBottom = window.pageYOffset + this.state.windowHeight;

    if (offsetHeight - windowBottom <= advanceLength) {
      this.props.fetchMore();
    }
  }

  onResizeWindow = () => {
    this.setState({ windowHeight: window.innerHeight });
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
    window.addEventListener('scroll', this.onScrollWindow);
    window.addEventListener("resize", this.onResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollWindow);
    window.removeEventListener("resize", this.onResizeWindow);
  }

  render() {
    const { classes } = this.props;
    return this.props.isFetching ? (
      <div className={classes.iconWrapper}>
        <IconLoading />
      </div>
    ) : "";
  }
}

export default withStyles(styles)(InfiniteScroller);
