import React from "react";
import styled from "styled-components";

import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontP from "../../atoms/fonts/FontP";

import { japaneseList } from "../../../Resources/japaneseList";

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentDetailContent.render
    .ConsentRegistration.Create;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 10px;
`;

const SubText = styled(FontP)`
  color: ${(p) => p.theme.color.primary};
  margin-top: 4px;
`;

interface Props {
  handleCreateClicked: () => Promise<void>;
}
export const ConsentRegistrationFormToCreate: React.FC<Props> = (props) => {
  const { handleCreateClicked } = props;
  return (
    <>
      <FontP>{renderResource.content}</FontP>
      <ButtonWrapper>
        <ButtonFullWidth
          onClick={handleCreateClicked}
          id="register-consent-button"
        >
          {renderResource.createButton.title}
        </ButtonFullWidth>
        <SubText size="ss">{renderResource.createButton.subText}</SubText>
      </ButtonWrapper>
    </>
  );
};
