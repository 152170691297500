import { format } from "date-fns";

import GraphQLClient from "./GraphQLClient";
import {
  CreateConsentStatus,
  UpdateConsentStatusToProgress,
  UpdateConsentStatusToComplete,
} from "./GraphQLQueries";
import { isLnln } from "./checkLnln";

const CREATE_EXTERNAL_URL = `${process.env.REACT_APP_ONLINE_QUALIFICATION_SYSTEM}/online-care/index.html`;
const UPDATE_EXTERNAL_URL = `${process.env.REACT_APP_ONLINE_QUALIFICATION_SYSTEM}/online-care/withdraw.html`;
// ドメインによってアプリ固有番号が異なる
const APP_ID = isLnln()
  ? process.env.REACT_APP_ONLINE_QUALIFICATION_APP_ID_LNLN
  : process.env.REACT_APP_ONLINE_QUALIFICATION_APP_ID_CARADA;
const REQ_TYPE = "1";

interface Appointment {
  createTime: number;
  hospitalId: string;
}
interface Result {
  succeed: boolean;
}

// 今開いているページを外部ブラウザで開きなおす
// newUrlが存在する場合は指定したUrlを外部ブラウザで開きなおす
const reopenDefaultBrowser = (newUrl?: string) => {
  const url = new URL(newUrl ?? window.location.href);
  url.searchParams.set("lnln_ob", "1");

  window.location.href = url.toString();
  return;
};

const createConsentStatus = async ({ createTime, hospitalId }: Appointment) => {
  try {
    // @ts-ignore ToDo: Since errors cannot be defined, it is forcibly defined using "@ts-ignore".
    const { data, errors } = await GraphQLClient.mutate<
      Record<"createConsentStatus", Result>
    >({
      mutation: CreateConsentStatus,
      variables: { createTime, hospitalId },
    });
    // 予期せぬエラーの場合は処理を継続させる
    if (errors) {
      console.error(errors);
      return true;
    }
    // バリデーションエラーの場合、処理を継続させない
    if (!data?.createConsentStatus?.succeed) {
      return false;
    }
  } catch (error) {
    console.error(error);
    // ネットワークなどのエラーの場合、処理を継続させる
    return true;
  }
  return true;
};

const updateConsentStatusToProgress = async ({
  createTime,
  hospitalId,
}: Appointment) => {
  try {
    // @ts-ignore ToDo: Since errors cannot be defined, it is forcibly defined using "@ts-ignore".
    const { data, errors } = await GraphQLClient.mutate<
      Record<"updateConsentStatusToProgress", Result>
    >({
      mutation: UpdateConsentStatusToProgress,
      variables: { createTime, hospitalId },
    });
    // 予期せぬエラーの場合は処理を継続させる
    if (errors) {
      console.error(errors);
      return true;
    }
    // バリデーションエラーの場合、処理を継続させない
    if (!data?.updateConsentStatusToProgress.succeed) {
      return false;
    }
  } catch (error) {
    console.error(error);
    // ネットワークなどのエラーの場合、処理を継続させる
    return true;
  }
  return true;
};

// 外部サイトから帰ってきたとき、ステータスを登録済にする
const updateConsentStatusToComplete = async (
  { hospitalId }: Appointment,
  externalResultCode: string,
  externalErrorCode?: string
) => {
  try {
    // @ts-ignore ToDo: Since errors cannot be defined, it is forcibly defined using "@ts-ignore".
    const { data, errors } = await GraphQLClient.mutate<
      Record<"updateConsentStatusToComplete", Result>
    >({
      mutation: UpdateConsentStatusToComplete,
      variables: { hospitalId, externalResultCode, externalErrorCode },
    });
    // 予期せぬエラーの場合は処理を継続させる
    if (errors) {
      console.error(errors);
      return true;
    }
    // バリデーションエラーの場合、処理を継続させない
    if (!data?.updateConsentStatusToComplete.succeed) {
      return false;
    }
  } catch (error) {
    console.error(error);
    // ネットワークなどのエラーの場合、処理を継続させる
    return true;
  }
  return true;
};

const buildExternalUrlToCreate = (
  onlineQualificationSystemCode: string,
  appointmentTo: string,
  currentUrl: string
) => {
  const externalUrl = _appendQueryParameters(
    CREATE_EXTERNAL_URL,
    onlineQualificationSystemCode,
    appointmentTo,
    currentUrl
  );
  return externalUrl;
};

const buildExternalUrlToUpdate = (
  onlineQualificationSystemCode: string,
  appointmentTo: string,
  currentUrl: string
) => {
  const externalUrl = _appendQueryParameters(
    UPDATE_EXTERNAL_URL,
    onlineQualificationSystemCode,
    appointmentTo,
    currentUrl
  );
  return externalUrl;
};

const _urlToBase64 = (url: string) => {
  // https://developer.mozilla.org/ja/docs/Glossary/Base64
  // urlに2バイト文字が入ることはないため以下の実装とする
  return window.btoa(url);
};

const _appendQueryParameters = (
  baseUrl: string,
  onlineQualificationSystemCode: string,
  appointmentTo: string,
  currentUrl: string
): string => {
  const externalUrl = new URL(baseUrl);

  const mi_code = onlineQualificationSystemCode;
  const rtn_url = _urlToBase64(currentUrl);
  const clinic_date = format(new Date(appointmentTo), "YYYYMMDD");

  externalUrl.searchParams.append("mi_code", mi_code);
  externalUrl.searchParams.append("app_id", APP_ID || "");
  externalUrl.searchParams.append("rtn_url", rtn_url);
  externalUrl.searchParams.append("req_type", REQ_TYPE);
  externalUrl.searchParams.append("clinic_date", clinic_date);
  return externalUrl.toString();
};

export {
  reopenDefaultBrowser,
  createConsentStatus,
  updateConsentStatusToProgress,
  updateConsentStatusToComplete,
  buildExternalUrlToCreate,
  buildExternalUrlToUpdate,
};
