import React, { Component } from "react";
import styled from "styled-components";
import Heading1 from "../atoms/headings/Heading1";
import BasicTemplate from "../templates/BasicTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";

const Container = styled.div`
  padding: ${p => p.theme.space.normal};
  line-height: 1.5;
`;

const Title = styled.div`
  padding: 40px 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
`;

class NotFound extends Component {
  render() {
    const renderResource = japaneseList.pages.NotFound.render;
    const main = (
      <React.Fragment>
        <Heading1>404 Not found</Heading1>
        <Container>
          <Title>
            {renderResource.Fragment.Container.Title.j001}
            <br />
            {renderResource.Fragment.Container.Title.j002}
          </Title>
          {renderResource.Fragment.Container.j001}
        </Container>
      </React.Fragment>
    );

    return <BasicTemplate title={Titles.notFound} main={main} />;
  }
}

export default NotFound;
