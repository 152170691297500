import React from "react";
import styled from "styled-components";

import FontP from "../../atoms/fonts/FontP";
import Panel from "../../atoms/panels/Panel";

import { isLnln } from "../../../Utils/checkLnln";
import IconFile from "../../../images/carada/icon/icon-file.svg";
import IconFileLnln from "../../../images/lnln/icon/icon-file.svg";

import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.molecules.FileUploadDetail;

const icon = isLnln() ? IconFileLnln : IconFile;

const CustomFontP = styled(FontP)`
  display: flex;
  padding-bottom: 5px;
  font-size: 14px;
`;

const Img = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 3px;
  margin-top: 2px;
`;

const FileUploadDetailItemDeleteByDoctor = () => {
  return (
    <Panel padding={"ts"}>
      <CustomFontP size={"s"}>
        <Img src={icon} />
        {Resource.deleteByDoctor}
      </CustomFontP>
    </Panel>
  );
};
FileUploadDetailItemDeleteByDoctor.defaultProps = {
  status: "deleteDoctor"
};

export default FileUploadDetailItemDeleteByDoctor;
