import styled from "styled-components";

const IconRequired = styled.div`
  color: ${p => p.theme.color.fontInvert};
  background-color: ${p => p.theme.color.warning};
  text-align: center;
  height: 15px;
  padding: 0 4px;
  display: inline-block;
  font-size: 0.6rem;
  line-height: 15px;
  font-weight: normal;
  margin-left: 3px;
  border-radius: 4px;
`;

export default IconRequired;
