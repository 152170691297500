import React, { Component } from "react";
import Guard from "../../Utils/Guard";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import InsuranceCardComplete from "../organisms/mypage/InsuranceCardComplete";
import Titles from "../../Resources/Titles";

class InsuranceCardCompletePage extends Component {
  render() {
    const main = <InsuranceCardComplete />;
    return (
      <AuthenticatedTemplate title={Titles.insuranceCardComplete} main={main} />
    );
  }
}

export default Guard(InsuranceCardCompletePage);
