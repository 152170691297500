import React, { Component } from "react";
import SiteContext from "../../../SiteContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import { Hub, Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import format from "date-fns/format";
import startOfMonth from "date-fns/start_of_month";
import endOfMonth from "date-fns/end_of_month";
import startOfDay from "date-fns/start_of_day";
import isAfter from "date-fns/is_after";
import dateParser from "date-fns/parse";
import diffInMinutes from "date-fns/difference_in_minutes";
import addMinutes from "date-fns/add_minutes";
import subMinutes from "date-fns/sub_minutes";
import isPast from "date-fns/is_past";
import subHours from "date-fns/sub_hours";
import Button from "../../atoms/buttons/Button";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import InputText from "../../atoms/forms/InputText";
import Panel from "../../atoms/panels/Panel";
import InputFormHeaderVariant2 from "../../molecules/InputFormHeaderVariant2";
import WarningMessageTopPage from "../../molecules/WarningMessageTopPage";
import InfoMessage from "../../molecules/InfoMessage";
import GraphQLClient from "../../../Utils/GraphQLClient";
import {
  GetSlots,
  RegisterAppointment,
  ListAppointmentsOfPatient,
  ListAppointmentsOfDoctor,
} from "../../../Utils/GraphQLQueries";
import Calendar from "../../molecules/calendar/Calendar";
import {
  HUB_EVENT_SOURCE,
  APPOINTMENT_SIDEEFFECT,
  APPOINTMENT_STATUS,
  CONTENT_TYPE,
  RESERVE_STEP,
} from "../../../Utils/Constant";
import SelectTimeReservation from "./SelectTimeReservation";
import ReservationFormHeading from "./ReservationFormHeading";
import ApiWrapper from "../../../Utils/ApiWrapper";
import { ReservationNotificationSettings } from "./ReservationNotificationSettings";
import { isSmsReceivablePhoneNumber } from "../../../Utils/PhoneNumber";

import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";
import { StepperForm } from "./StepperForm";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import { Phone } from "@material-ui/icons";
import FontPCenter from "../../atoms/fonts/FontPCenter";
import InputFormTitle from "../../molecules/calendar/InputFormTitle";
import { isLnln } from "../../../Utils/checkLnln";
import TagManager from "react-gtm-module";

const Container = styled.div`
  background-color: ${(p) => p.theme.color.fontInvert};
`;

const TimeBox = styled.div`
  width: auto;
`;

const InputTextarea = styled.textarea`
  width: 100%;
  border: 1px solid ${(p) => p.theme.color.fontVariant};
  border-radius: 5px;
  padding: 10px;
  height: 100px;
  font-size: 16px;
  margin-top: 15px;
`;

const InputTextStyle = styled(InputText)`
  width: 100%;
`;

const WarningMessage = styled(WarningMessageTopPage)`
  font-size: 0.9rem;
  text-align: center;
  margin: 0 40px;
`;

const SecondaryInfoMessage = styled(InfoMessage)`
  font-size: 0.8em;
  color: ${(p) => p.theme.color.fontVariant};
  margin-top: 5px;
`;

const CheckEntriesButton = styled(FullWidthButton)`
  margin-bottom: 15px;
`;

const FormDevided = styled.div`
  margin-bottom: 16px;
`;

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short};
`;

const InputFormHeaderNote = styled.span`
  font-weight: normal;
  font-size: 1em;
`;

const Margin = styled.div`
  padding-bottom: 16px;
`;

const DialogContentCustom = styled(DialogContent)``;

const DialogTitleText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.color.primary};
  line-height: 23px;
`;

const DialogContentCustomText = styled.span`
  font-size: 14px;
  color: #393939;
  line-height: 22px;
`;

const ContentItem = styled.div``;

const IconButtonContainer = styled.div`
  width: 100%;
  line-height: 24px;
`;
const IconButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin: 0px 2px 0px 2px;
`;
const PhoneCustomIcon = styled(Phone)`
  display: inline-block;
  vertical-align: middle;
  margin: 0px 2px 0px 2px;
`;

const CustomFontPCenter = styled(FontPCenter)`
  margin-bottom: 4px;
`;

const FormBorderTopSpace = styled.div`
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
  padding: ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short} 0;
`;

const CustomInputFormHeader = styled(InputFormHeaderVariant2)`
  margin: 0px 0px 10px 0px;
`;

const styles = () => ({
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    padding: "15px 21px 15px 21px",
    "font-size": "17px",
    "max-width": "303px",
  },
  dialogAction: {
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "15px 21px 30px 21px",
    margin: 0,
  },
  dialogContent: {
    "white-space": "pre-wrap",
    padding: "15px 21px 15px 21px",
    "text-align": "justify",
    "font-size": "12px",
    "max-width": "303px",
  },
  dialogButton: {
    margin: 0,
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
  img: {
    width: "21px",
    height: "14px",
    marginTop: "2px",
  },
});

// TODO: Buttonの共通化ができたら必要情報をpropsで渡す形に修正し、このスタイルは削除
const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
`;

class ReservationForm extends Component {
  inputs = {};
  state = { open: false };

  constructor(props) {
    super(props);

    this.currentComp = React.createRef();
    let selectedReserve = {};
    let content = "";
    let companyName = "";
    let optionsTime = [];

    this.reserveData =
      this.props.location.state && this.props.location.state.reserveData;
    var reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    this.reserveInterviewSheetAnswer =
      this.props.location.state &&
      this.props.location.state.reserveInterviewSheetAnswer;
    this.medicineDelivery =
      this.props.location.state && this.props.location.state.medicineDelivery;
    this.fileList =
      this.props.location.state && this.props.location.state.fileList;
    this.reserveSteps = this.createReserveSteps(this.reserveData.menu);
    this.currentStep =
      this.reserveSteps &&
      this.reserveSteps.find((step) => step.reserveStep === RESERVE_STEP.INPUT);
    if (reserveInput) {
      const { hospitalId, menuId } = this.props.match.params;
      if (
        reserveInput.hospitalId === hospitalId &&
        reserveInput.menuId === menuId
      ) {
        selectedReserve = reserveInput.selectedReserve;
        content = reserveInput.content;
        companyName = reserveInput.companyName;
        optionsTime = reserveInput.optionsTime;
      }
    }

    this.state = {
      selectedReserve,
      content,
      companyName,
      optionsTime: optionsTime,
      isSubmitting: false,
      slots: {},
      appointmentSetting: {},
      sendSms: reserveInput ? reserveInput.sendSms : false,
      sendMail: reserveInput ? reserveInput.sendMail : false,
      mailRegistered: false,
      phoneNumberRegistered: false,
      user: null,
      reconfirmDialogIsOpen: false,
      registerPassingDialogIsOpen: false,
    };
  }

  createReserveSteps(menu) {
    const { hospitalId, menuId } = this.props.match.params;
    const hospitalUrl = `/hospital/${this.props.match.params.hospitalId}`;
    const inputUrl = `/reserve/input/${hospitalId}/${menuId}`;
    const interviewUrl = `/reserve/interview/${hospitalId}/${menuId}`;
    const medicineDeliveryUrl = `/reserve/medicine-delivery/${hospitalId}/${menuId}`;
    const uploadFileUrl = `/reserve/upload-file/${hospitalId}/${menuId}`;
    const confirmUrl = `/reserve/confirm/${hospitalId}/${menuId}`;
    const completeUrl = "/reserve/complete";
    const existsInterview = menu.interviewSheetId ? true : false;
    const enableMedicineDelivery = menu.enableMedicineDelivery ? true : false;

    if (existsInterview && enableMedicineDelivery) {
      // full step
      return [
        {
          reserveStep: RESERVE_STEP.INPUT,
          previousUrl: hospitalUrl,
          followingUrl: interviewUrl,
        },
        {
          reserveStep: RESERVE_STEP.INTERVIEW,
          previousUrl: inputUrl,
          followingUrl: medicineDeliveryUrl,
        },
        {
          reserveStep: RESERVE_STEP.MEDICINE_DELIVERY,
          previousUrl: interviewUrl,
          followingUrl: uploadFileUrl,
        },
        {
          reserveStep: RESERVE_STEP.UPLOAD_FILE,
          previousUrl: medicineDeliveryUrl,
          followingUrl: confirmUrl,
        },
        {
          reserveStep: RESERVE_STEP.CONFIRM,
          previousUrl: uploadFileUrl,
          followingUrl: completeUrl,
        },
      ];
    }
    if (existsInterview) {
      // not exits medicine delivery step
      return [
        {
          reserveStep: RESERVE_STEP.INPUT,
          previousUrl: hospitalUrl,
          followingUrl: interviewUrl,
        },
        {
          reserveStep: RESERVE_STEP.INTERVIEW,
          previousUrl: inputUrl,
          followingUrl: uploadFileUrl,
        },
        {
          reserveStep: RESERVE_STEP.UPLOAD_FILE,
          previousUrl: interviewUrl,
          followingUrl: confirmUrl,
        },
        {
          reserveStep: RESERVE_STEP.CONFIRM,
          previousUrl: uploadFileUrl,
          followingUrl: completeUrl,
        },
      ];
    }
    if (enableMedicineDelivery) {
      // not exits interview step
      return [
        {
          reserveStep: RESERVE_STEP.INPUT,
          previousUrl: hospitalUrl,
          followingUrl: medicineDeliveryUrl,
        },
        {
          reserveStep: RESERVE_STEP.MEDICINE_DELIVERY,
          previousUrl: inputUrl,
          followingUrl: uploadFileUrl,
        },
        {
          reserveStep: RESERVE_STEP.UPLOAD_FILE,
          previousUrl: medicineDeliveryUrl,
          followingUrl: confirmUrl,
        },
        {
          reserveStep: RESERVE_STEP.CONFIRM,
          previousUrl: uploadFileUrl,
          followingUrl: completeUrl,
        },
      ];
    }
    // not exits interview and medicine delivery step
    return [
      {
        reserveStep: RESERVE_STEP.INPUT,
        previousUrl: hospitalUrl,
        followingUrl: uploadFileUrl,
      },
      {
        reserveStep: RESERVE_STEP.UPLOAD_FILE,
        previousUrl: inputUrl,
        followingUrl: confirmUrl,
      },
      {
        reserveStep: RESERVE_STEP.CONFIRM,
        previousUrl: uploadFileUrl,
        followingUrl: completeUrl,
      },
    ];
  }

  // Life cycle
  async componentDidMount() {
    const currentUser = await Auth.currentUserInfo();

    if (currentUser) {
      this.setState({ user: currentUser });

      if (this.state.user.attributes.email_verified) {
        this.setState({ mailRegistered: true });
        this.setState({ sendMail: true });
      }

      // Patient can select SMS when patient have registered a phone number
      // that is verified and capable of sending SMS.
      if (this.state.user.attributes.phone_number_verified) {
        this._checkPhoneNumber();
      }

      if (this.state.mailRegistered && !this.state.phoneNumberRegistered) {
        this.setState({ sendMail: true });
      } else if (
        !this.state.mailRegistered &&
        this.state.phoneNumberRegistered
      ) {
        this.setState({ sendSms: true });
      }
    }

    await this.getSlots();
  }

  _checkPhoneNumber() {
    const originalPhoneNumber = this.state.user.attributes.phone_number;
    const phoneNumber =
      "0" + originalPhoneNumber.substring(3, originalPhoneNumber.length);
    if (isSmsReceivablePhoneNumber(phoneNumber)) {
      this.setState({ phoneNumberRegistered: true });
    }
  }
  static contextType = SiteContext;
  componentWillUnmount() {
    if (!this.props.login) {
      const contextValue = this.context;
      const { hospitalId, menuId } = this.props.match.params;
      let {
        selectedReserve,
        content,
        companyName,
        optionsTime,
        sendSms,
        sendMail,
      } = this.state;
      const reserveInput = {
        selectedReserve,
        hospitalId,
        menuId,
        content,
        companyName,
        optionsTime,
        sendSms,
        sendMail,
      };
      contextValue.reserveData = this.reserveData;
      contextValue.reserveInput = reserveInput;
    }

    Hub.dispatch(
      "reserve",
      { event: "close", data: null },
      HUB_EVENT_SOURCE.RESERVE_TIME_UPDATE
    );
  }
  // End Life cycle

  // Events
  handleInputChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value,
    });
  };

  handleTimeChange = (selectedReserve) => {
    this.setState(
      (prevState) => {
        return {
          selectedReserve,
        };
      },
      () => {
        Hub.dispatch(
          "reserve",
          { event: "open", data: selectedReserve },
          HUB_EVENT_SOURCE.RESERVE_TIME_UPDATE
        );
      }
    );
  };

  checkInput = () => {
    const checkInputResource =
      japaneseList.organisms.reservationForm.ReservationForm.checkInput;
    const { content, companyName, selectedReserve } = this.state;
    if (
      !selectedReserve ||
      !selectedReserve.from ||
      (!content.trim() &&
        this.reserveData.menu.contentType ===
          CONTENT_TYPE.CONSULTATION_CONTENT) ||
      (!companyName.trim() && this.reserveData.menu.requiredCompanyName)
    ) {
      this.snackBar(checkInputResource.j001);
      return false;
    }
    if (this.reserveData.menu.requiredCompanyName && companyName.length > 25) {
      this.snackBar(checkInputResource.j002a);
      return false;
    }
    if (
      this.reserveData.menu.contentType === CONTENT_TYPE.CONSULTATION_CONTENT &&
      content.length > 99
    ) {
      this.snackBar(checkInputResource.j002);
      return false;
    }
    return true;
  };

  onSubmit = async (e) => {
    if (this.registerRequiredInformation()) {
      return;
    }

    if (this.state.isSubmitting) {
      return;
    }
    this.setState({ isSubmitting: true });
    const onSubmitResource =
      japaneseList.organisms.reservationForm.ReservationForm.onSubmit;
    if (!this.checkInput()) {
      this.setState({ isSubmitting: false });
      return;
    }
    const { hospitalId, menuId } = this.props.match.params;
    let {
      selectedReserve,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    } = this.state;
    const value = {
      selectedReserve,
      hospitalId,
      menuId,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    };

    if (
      format(selectedReserve.from, "YYYY-MM-DD HH:mm:ss") <
      format(new Date(), "YYYY-MM-DD HH:mm:ss")
    ) {
      this.snackBar(onSubmitResource.j003);
      this.setState({ isSubmitting: false });
      return false;
    }

    if (!this.state.user) {
      this.setState({
        open: true,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var fromOfSlot = selectedReserve.fromOfSlot;
    const body = {
      fromOfSlot,
      from: selectedReserve.from,
      to: selectedReserve.to,
      hospitalId,
      menuId,
      content,
      companyName,
      sideEffect: APPOINTMENT_SIDEEFFECT.REGISTER_TENTATIVE_RESERVATION,
      sendSms,
      sendMail,
      doctorId: selectedReserve.doctorId,
    };

    const { errors } = await GraphQLClient.mutate({
      mutation: RegisterAppointment,
      variables: body,
    });
    if (errors) {
      this.setState({ isSubmitting: false });
      var msg = "";
      switch (errors[0].errorType) {
        case "E01":
          msg = onSubmitResource.j001;
          break;
        case "E02":
          msg = onSubmitResource.j002;
          break;
        case "E03":
          msg = onSubmitResource.j002;
          break;
        case "E04":
          msg = onSubmitResource.j002;
          break;
        case "E05":
          msg = onSubmitResource.j003;
          break;
        case "E06":
          msg = onSubmitResource.j004;
          break;
        case "E07":
          msg = onSubmitResource.j005;
          break;
        case "E08":
          msg = onSubmitResource.j006;
          break;
        case "E10":
          msg = onSubmitResource.j004;
          break;
        case "E100":
          msg = onSubmitResource.j008;
          break;
        case "E101":
          msg = onSubmitResource.j008;
          break;
        default:
          msg = onSubmitResource.j007;
          break;
      }
      this.snackBar(msg);
    } else {
      this.props.history.push({
        pathname: this.currentStep && this.currentStep.followingUrl,
        state: {
          reserveData: this.reserveData,
          reserveInput: value,
          reserveInterviewSheetAnswer: this.reserveInterviewSheetAnswer,
          medicineDelivery: this.medicineDelivery,
          fileList: this.fileList,
          reserveSteps: this.reserveSteps,
        },
      });
    }
  };

  handleAgreeSendSMS = (event) => {
    this.setState({ sendSms: event.target.checked });
  };

  handleAgreeSendMail = (event) => {
    this.setState({ sendMail: event.target.checked });
  };

  popupRedirect = (path) => {
    const { hospitalId, menuId } = this.props.match.params;
    let {
      selectedReserve,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    } = this.state;
    const reserveInput = {
      selectedReserve,
      hospitalId,
      menuId,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    };
    this.props.history.push({
      pathname: path,
      state: {
        from: "reserve",
        reserveData: this.reserveData,
        reserveInput: reserveInput,
      },
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      registerPassingDialogIsOpen: false,
    });
  };

  handleDialogBackButton = () => {
    this.setState({
      reconfirmDialogIsOpen: false,
    });
    this.props.history.push(this.currentStep && this.currentStep.previousUrl);
  };

  handleBackButtonClick() {
    const canTelephoneAppointment = this.canTelephoneAppoint(this.reserveData);
    // Dialog display when telephone appointment is available, return to the previous page when it's not unavailable
    if (canTelephoneAppointment) {
      if (!this.state.reconfirmDialogIsOpen) {
        this.setState({ reconfirmDialogIsOpen: true });
      }
      return;
    }
    this.props.history.push(this.currentStep && this.currentStep.previousUrl);
  }
  // End Events

  // API Methods
  getSlots = async (selectedDate = new Date()) => {
    const { hospitalId, menuId } = this.props.match.params;
    const monthStart = startOfMonth(selectedDate);
    const monthEnd = endOfMonth(monthStart);
    let startDate = new Date();
    if (isAfter(selectedDate, startDate)) {
      startDate = monthStart;
    }

    const start = startOfDay(startDate).toISOString();
    const end = monthEnd.toISOString();

    var params = {
      hospitalId,
      menuId,
      doctorId: this.reserveData.menu.doctorId,
      medicalMenu: this.reserveData.menu.medicalMenu,
      medicalMethod: this.reserveData.menu.medicalMethod,
    };
    const appointmentSetting = await this.getAppointmentSetting(params);

    params = {
      hospitalId,
      start,
      end,
      medicalMethod: this.reserveData.menu.medicalMethod,
    };
    const data = await this.getSlotsFromAppSync(params);
    let slots = data && data.slots && data.slots.items;
    const minimumTimeFrame = appointmentSetting.minimumTimeframeLength;
    const activityTime = appointmentSetting.activityTime || 0;
    const intervalTime = appointmentSetting.intervalTime || 0;
    const slotExpMinutes =
      appointmentSetting.defaultAppointmentSlotExpiration || 0;

    if (this.reserveData.menu.doctorId) {
      slots =
        slots &&
        slots.filter(
          (item) =>
            item.menu.doctorId === this.reserveData.menu.doctorId &&
            dateParser(item.to).getTime() - activityTime * 60 * 1000 >
              new Date().getTime()
        );
    }

    let newOption = await this.getLoadTimeOfSelectedDate(
      slots,
      intervalTime,
      activityTime,
      slotExpMinutes
    );
    let newSlots = [];
    for (let index = 0; index < slots.length; index++) {
      const element = slots[index];
      let newFromOfSlot = newOption.filter(
        (i) => i.fromOfSlot === element.fromOfSlot
      );
      for (
        let indexOption = 0;
        indexOption < newFromOfSlot.length;
        indexOption++
      ) {
        const elementOption = newFromOfSlot[indexOption];
        if (element.fromOfSlot === elementOption.fromOfSlot) {
          newSlots.push(element);
          break;
        }
      }
    }
    slots =
      newOption &&
      newOption.length > 0 &&
      newSlots.filter(
        (item) =>
          diffInMinutes(dateParser(item.to), dateParser(item.from)) >=
          Math.max(minimumTimeFrame, activityTime || 0)
      );

    if (this.reserveData.menu.doctorId) {
      slots =
        slots &&
        slots.filter(
          (item) =>
            item.menu && item.menu.doctorId === this.reserveData.menu.doctorId
        );
    }

    this.setState({
      slots,
      appointmentSetting,
    });
    this.props.checkLoading(false);
  };

  getSlotsFromAppSync = async (body) => {
    const { data, errors } = await GraphQLClient.query({
      query: GetSlots,
      variables: body,
    });

    if (errors) {
      Sentry.addBreadcrumb({
        message:
          "GetSlots errorType:" + (errors.length ? errors[0].errorType : ""),
        level: Sentry.Severity.Log,
      });
      return null;
    }
    return data;
  };

  getAppointmentSetting = async (body) => {
    const params = Object.keys(body)
      .filter((key) => body[key])
      .map((key) => `${key}=${body[key]}`);
    const query = params.join("&");
    try {
      let data = await ApiWrapper.Get(
        "dev-patient",
        `/getAppointmentSetting?${query}`
      );
      return data;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  getListAppointmentsOfDoctor = async (body) => {
    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsOfDoctor,
      variables: body,
    });
    if (errors) {
      Sentry.addBreadcrumb({
        message:
          "ListAppointmentsOfDoctor errorType:" +
          (errors.length ? errors[0].errorType : ""),
        level: Sentry.Severity.Log,
      });
      return errors;
    }
    return data;
  };

  getListAppointmentsOfPatient = async (body) => {
    if (!this.state.user) return null;

    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsOfPatient,
      variables: body,
    });

    if (errors) {
      Sentry.addBreadcrumb({
        message:
          "ListAppointmentsOfPatient errorType:" +
          (errors.length ? errors[0].errorType : ""),
        level: Sentry.Severity.Log,
      });
      return null;
    }
    return data;
  };

  // End API Methods

  // Other methods
  getPossibleConflictAppointments = async (start, end) => {
    const doctorParams = {
      hospitalId: this.props.match.params.hospitalId,
      doctorId: this.reserveData.menu.doctorId,
      medicalMethod: this.reserveData.menu.medicalMethod,
      start,
      end,
      status: [
        APPOINTMENT_STATUS.BEFORE_EXAM,
        APPOINTMENT_STATUS.UNAPPROVED,
        APPOINTMENT_STATUS.TENTATIVE_RESERVATION,
      ],
    };

    const patientParams = {
      start,
      end,
      status: [APPOINTMENT_STATUS.BEFORE_EXAM, APPOINTMENT_STATUS.UNAPPROVED],
    };
    let doctorAppointments, patientAppointments;
    await Promise.all([
      this.getListAppointmentsOfDoctor(doctorParams),
      this.getListAppointmentsOfPatient(patientParams),
    ])
      .then((val) => {
        doctorAppointments = { ...val[0] };
        patientAppointments = { ...val[1] };
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });

    return {
      doctorAppointments,
      patientAppointments,
    };
  };

  isTimeframesOverlap = (item1, item2) => {
    return !(item1.to <= item2.from || item1.from >= item2.to);
  };

  isTimeframeConflictWithOther = (timeframe, otherTimeframes) => {
    return otherTimeframes.some((item) => {
      return this.isTimeframesOverlap(item, timeframe);
    });
  };

  minStr = (lst) => {
    var min = lst[0];
    lst.forEach((item) => {
      if (min > item) {
        min = item;
      }
    });
    return min;
  };

  maxStr = (lst) => {
    var max = lst[0];
    lst.forEach((item) => {
      if (max < item) {
        max = item;
      }
    });
    return max;
  };

  getLoadTimeOfSelectedDate = async (
    slotsOfSelectedDate,
    intervalTime,
    activityTime,
    slotExpMinutes
  ) => {
    if (slotsOfSelectedDate && slotsOfSelectedDate.length > 0) {
      var optionsTime = [];
      if (intervalTime && activityTime) {
        var posibleConflictAppointments = null;
        var minFrom = this.minStr(slotsOfSelectedDate.map((item) => item.from));
        var maxTo = this.maxStr(slotsOfSelectedDate.map((item) => item.to));
        await this.getPossibleConflictAppointments(
          subHours(dateParser(minFrom), 24).toISOString(),
          maxTo
        )
          .then((val) => {
            posibleConflictAppointments = [
              ...val.doctorAppointments.doctorAppointments.items,
              ...(val.patientAppointments.appointments
                ? val.patientAppointments.appointments.items
                : []),
            ];
          })
          .catch((err) => {
            console.log("API Error" + err);
            Sentry.captureException(err);
            return;
          });
        slotsOfSelectedDate.forEach((item) => {
          var fromDate = dateParser(item.from);
          var toDate = dateParser(item.to);
          var slotMinutes = diffInMinutes(toDate, fromDate);
          //Condition: numTimeframes*invervalTime - intervalTime + activityTime <= slotMinutes
          var numTimeframes =
            (slotMinutes + intervalTime - activityTime) / intervalTime;
          for (var i = 1; i <= numTimeframes; i++) {
            toDate = addMinutes(fromDate, activityTime);
            var from = fromDate.toISOString();
            var to = toDate.toISOString();
            if (
              !isPast(subMinutes(fromDate, slotExpMinutes)) &&
              (!posibleConflictAppointments ||
                !this.isTimeframeConflictWithOther(
                  { from, to },
                  posibleConflictAppointments
                ))
            ) {
              optionsTime.push({
                doctorId: item.menu.doctorId,
                fromOfSlot: item.fromOfSlot,
                from,
                to,
                label: `${format(from, "HH:mm")}～${format(to, "HH:mm")}`,
              });
            }
            fromDate = addMinutes(fromDate, intervalTime);
          }
        });

        // Calculate if time frame can block all other time frames of selected slot
        // If yes, lock the slot after patient book this time frame
        var preItem = null;
        optionsTime.forEach((item) => {
          // Item will overlap itself
          item.numberOfConflict = 1;
          // Item can overlap previous item
          if (preItem && this.isTimeframesOverlap(item, preItem)) {
            item.numberOfConflict++;
            preItem.numberOfConflict++;
          }

          preItem = item;
        });

        optionsTime = optionsTime.map((item) => {
          // TODO: check and replace to ===
          // eslint-disable-next-line eqeqeq
          let lockSlot = item.numberOfConflict == optionsTime.length;
          return { ...item, lockSlot };
        });
      } else {
        slotsOfSelectedDate.forEach((item) => {
          optionsTime.push({
            doctorId: item.menu.doctorId,
            fromOfSlot: item.fromOfSlot,
            from: item.from,
            to: item.to,
            label: `${format(item.from, "HH:mm")}～${format(item.to, "HH:mm")}`,
          });
        });
      }
      return optionsTime;
    }
  };

  loadTimeOfSelectedDate = async (selectedDate) => {
    const slotsOfSelectedDate = this.state.slots.filter(
      (item) =>
        format(item.from, "YYYY-MM-DD") === format(selectedDate, "YYYY-MM-DD")
    );
    if (slotsOfSelectedDate && slotsOfSelectedDate.length > 0) {
      var optionsTime = [];
      var intervalTime = this.state.appointmentSetting.intervalTime;
      var activityTime = this.state.appointmentSetting.activityTime;
      var slotExpMinutes =
        this.state.appointmentSetting.defaultAppointmentSlotExpiration;
      if (intervalTime && activityTime) {
        var posibleConflictAppointments = null;
        var minFrom = this.minStr(slotsOfSelectedDate.map((item) => item.from));
        var maxTo = this.maxStr(slotsOfSelectedDate.map((item) => item.to));
        const { doctorAppointments, patientAppointments } =
          await this.getPossibleConflictAppointments(
            subHours(dateParser(minFrom), 24).toISOString(),
            maxTo
          );
        posibleConflictAppointments = [
          ...doctorAppointments.doctorAppointments.items,
          ...(patientAppointments.appointments
            ? patientAppointments.appointments.items
            : []),
        ];
        slotsOfSelectedDate.forEach((item) => {
          var fromDate = dateParser(item.from);
          var toDate = dateParser(item.to);
          var slotMinutes = diffInMinutes(toDate, fromDate);
          //Condition: numTimeframes*invervalTime - intervalTime + activityTime <= slotMinutes
          var numTimeframes =
            (slotMinutes + intervalTime - activityTime) / intervalTime;
          for (var i = 1; i <= numTimeframes; i++) {
            toDate = addMinutes(fromDate, activityTime);
            var from = fromDate.toISOString();
            var to = toDate.toISOString();
            if (
              !isPast(subMinutes(fromDate, slotExpMinutes)) &&
              (!posibleConflictAppointments ||
                !this.isTimeframeConflictWithOther(
                  { from, to },
                  posibleConflictAppointments
                ))
            ) {
              optionsTime.push({
                doctorId: item.menu.doctorId,
                fromOfSlot: item.fromOfSlot,
                from,
                to,
                label: `${format(from, "HH:mm")}～${format(to, "HH:mm")}`,
              });
            }
            fromDate = addMinutes(fromDate, intervalTime);
          }
        });

        // Calculate if time frame can block all other time frames of selected slot
        // If yes, lock the slot after patient book this time frame
        var preItem = null;
        optionsTime.forEach((item) => {
          // Item will overlap itself
          item.numberOfConflict = 1;
          // Item can overlap previous item
          if (preItem && this.isTimeframesOverlap(item, preItem)) {
            item.numberOfConflict++;
            preItem.numberOfConflict++;
          }

          preItem = item;
        });

        optionsTime = optionsTime.map((item) => {
          // TODO: check and replace to ===
          // eslint-disable-next-line eqeqeq
          let lockSlot = item.numberOfConflict == optionsTime.length;
          return { ...item, lockSlot };
        });
      } else {
        slotsOfSelectedDate.forEach((item) => {
          optionsTime.push({
            doctorId: item.menu.doctorId,
            fromOfSlot: item.fromOfSlot,
            from: item.from,
            to: item.to,
            label: `${format(item.from, "HH:mm")}～${format(item.to, "HH:mm")}`,
          });
        });
      }
      this.setState({ optionsTime });
    }
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "Reservation"
    );
  };

  // Register page transition when user's address or credit card info is unregisterd
  registerRequiredInformation = () => {
    const { cardStatus, addressStatus, login } = this.props;
    const { hospitalId, menuId } = this.props.match.params;
    let {
      selectedReserve,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    } = this.state;
    const value = {
      selectedReserve,
      hospitalId,
      menuId,
      content,
      companyName,
      optionsTime,
      sendSms,
      sendMail,
    };
    if (login) {
      if (!cardStatus && this.requiredCardRegister()) {
        this.props.checkCardStatus(this.reserveData, value);
        return true;
      }

      if (!addressStatus && this.reserveData.menu.requiredAddressRegister) {
        this.props.checkAddress(this.reserveData, value);
        return true;
      }
    }
    return false;
  };

  doTelephoneAppointment = () => {
    const { cardStatus, addressStatus } = this.props;
    // Dialog display if not authenticated
    if (!this.state.user) {
      this.setState({
        open: true,
      });
      this.setState({ isSubmitting: false });
      return;
    }
    // Dialog display if unregisterd credit number or address

    if (!cardStatus && this.requiredCardRegister()) {
      this.setState({
        registerPassingDialogIsOpen: true,
        isSubmitting: false,
        reconfirmDialogIsOpen: false,
      });
      return;
    }
    if (!addressStatus && this.reserveData.menu.requiredAddressRegister) {
      this.setState({
        registerPassingDialogIsOpen: true,
        isSubmitting: false,
        reconfirmDialogIsOpen: false,
      });
      return;
    }
    window.location.href = `tel:${this.reserveData.menu.telephoneAppointmentNumber}`;
    const tagManagerArgs = {
      dataLayer: {
        event: "telephoneAppointment",
        telephoneAppointmentLabel: `${this.reserveData.hospital.name}/${this.reserveData.hospital.hospitalId}`,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  canTelephoneAppoint = (reserveData) => {
    return (
      reserveData &&
      reserveData.menu &&
      reserveData.menu.telephoneAppointmentNumber
    );
  };

  requiredCardRegister = () => {
    if (this.props.account && this.props.account.organizationId) {
      return this.props.checkOrganizationRequireCreditCardRegister;
    }

    if (
      this.checkSelfFundedAmount(
        this.props.account?.enterprise?.contractPlans,
        this.reserveData.menu
      )
    ) {
      return true;
    }

    return this.reserveData.menu.requiredCreditCardRegister;
  };

  checkSelfFundedAmount = (contractPlans, menu) => {
    return Boolean(
      contractPlans?.find(
        (contract) =>
          contract.selfFundedAmount &&
          contract.contractPlanId === menu?.contractPlanId
      )?.selfFundedAmount
    );
  };

  // End methods

  // Render views
  RenderDialog = () => {
    const RenderDialogResource =
      japaneseList.organisms.reservationForm.ReservationForm.RenderDialog;
    const { classes } = this.props;
    const { open } = this.state;

    const dialog = (
      <Dialog
        id="account-dialog"
        open={!!open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          style={{ maxWidth: "none" }}
        >
          <DialogTitleText>
            {RenderDialogResource.Dialog.DialogTitle.j001}
          </DialogTitleText>
        </DialogTitle>
        <DialogContentCustom className={classes.dialogContent}>
          <DialogContentCustomText>
            {isLnln()
              ? RenderDialogResource.Dialog.DialogContent.LnlnDiaLogContentText
                  .j001
              : RenderDialogResource.Dialog.DialogContent.DialogContentText
                  .j001}
          </DialogContentCustomText>
        </DialogContentCustom>
        <DialogActions className={classes.dialogAction}>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <Button
            id="account-dialog-signup-button"
            className={classes.dialogButton}
            onClick={() => this.popupRedirect("/auth/signup")}
          >
            {RenderDialogResource.Dialog.DialogActions.ButtonSubmit.j001}
          </Button>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <Button
            id="account-dialog-signin-button"
            className={classes.dialogButton}
            onClick={() => this.popupRedirect("/login")}
          >
            {RenderDialogResource.Dialog.DialogActions.ButtonSubmit.j002}
          </Button>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <ButtonOutlined
            id="account-dialog-close-button"
            className={classes.dialogButton}
            onClick={() => this.handleClose()}
          >
            {RenderDialogResource.Dialog.DialogActions.ButtonSubmit.j003}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    );

    return dialog;
  };

  RenderSelectForm = () => {
    const RenderSelectFormResource =
      japaneseList.organisms.reservationForm.ReservationForm.RenderSelectForm;
    const { content, companyName, optionsTime, selectedReserve } = this.state;
    const displayNotificationSettings =
      this.state.phoneNumberRegistered || this.state.mailRegistered;
    const canTelephoneAppointment = this.canTelephoneAppoint(this.reserveData);
    const requiredCompanyName =
      this.reserveData &&
      this.reserveData.menu &&
      this.reserveData.menu.requiredCompanyName;
    const isConsultationContent =
      this.reserveData &&
      this.reserveData.menu &&
      this.reserveData.menu.contentType === CONTENT_TYPE.CONSULTATION_CONTENT;
    return (
      <React.Fragment>
        <FormDevided>
          <InputFormTitle titleNumber={2}>
            {RenderSelectFormResource.Fragment.FormDevided.InputFormTitle.j001}
          </InputFormTitle>
          <FormGroup>
            <TimeBox>
              <SelectTimeReservation
                id="time-frame-select"
                onChange={(selected) =>
                  this.handleTimeChange({
                    doctorId: selected.doctorId,
                    from: selected.from,
                    to: selected.to,
                    fromOfSlot: selected.fromOfSlot,
                    lockSlot: selected.lockSlot,
                  })
                }
                selectedReserve={selectedReserve}
                options={optionsTime}
              />
              <SecondaryInfoMessage>
                {
                  RenderSelectFormResource.Fragment.FormDevided.FormGroup
                    .TimeBox.SecondaryInfoMessage.j001
                }
              </SecondaryInfoMessage>
            </TimeBox>
            {!canTelephoneAppointment && <FormBorderTopSpace />}
          </FormGroup>
        </FormDevided>
        {/* A phone number exists if the patient can make a phone appintment with this menu */}
        {canTelephoneAppointment ? (
          <FormGroup>
            <CustomFontPCenter size="s">
              {
                RenderSelectFormResource.Fragment.TelephoneAppointment
                  .FormDevided.FormGroup.Message.j001
              }
            </CustomFontPCenter>
            {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
            <FullWidthOutlinedButton
              id="call-reservation-reservable-button"
              onClick={() => {
                this.doTelephoneAppointment();
              }}
            >
              <IconButtonContainer style={{ pointerEvents: "none" }}>
                <PhoneCustomIcon style={{ pointerEvents: "none" }} />
                <IconButtonText style={{ pointerEvents: "none" }}>
                  {
                    RenderSelectFormResource.Fragment.TelephoneAppointment
                      .FormDevided.FormGroup.Button.j001
                  }
                </IconButtonText>
              </IconButtonContainer>
            </FullWidthOutlinedButton>
            <FormBorderTopSpace />
          </FormGroup>
        ) : null}

        {requiredCompanyName && (
          <React.Fragment>
            <InputFormTitle titleNumber={3} required={true}>
              {RenderSelectFormResource.Fragment.Fragment.InputFormTitle.j001a}
            </InputFormTitle>
            <FormGroup>
              <InputFormHeaderNote>
                {
                  RenderSelectFormResource.Fragment.Fragment.FormGroup
                    .InputFormHeaderNote.j001a
                }
              </InputFormHeaderNote>
              <Margin>
                <InputTextStyle
                  maxLength={25}
                  name="companyName"
                  onChange={this.handleInputChange}
                  defaultValue={companyName || ""}
                />
              </Margin>
              <FormBorderTopSpace />
            </FormGroup>
          </React.Fragment>
        )}
        {isConsultationContent && (
          <React.Fragment>
            <InputFormTitle
              titleNumber={requiredCompanyName ? 4 : 3}
              required={true}
            >
              {RenderSelectFormResource.Fragment.Fragment.InputFormTitle.j001}
            </InputFormTitle>
            <FormGroup>
              <InputFormHeaderNote>
                {
                  RenderSelectFormResource.Fragment.Fragment.FormGroup
                    .InputFormHeaderNote.j001
                }
              </InputFormHeaderNote>
              <Margin>
                <InputTextarea
                  id="consultation-details-input"
                  placeholder={
                    RenderSelectFormResource.Fragment.Fragment.FormGroup.Margin
                      .InputTextarea.j001
                  }
                  maxLength={100}
                  rows={10}
                  name="content"
                  onChange={this.handleInputChange}
                  defaultValue={content || ""}
                />
              </Margin>
              <FormBorderTopSpace />
            </FormGroup>
          </React.Fragment>
        )}

        {displayNotificationSettings && (
          <React.Fragment>
            <ReservationNotificationSettings
              handleAgreeSendSMS={this.handleAgreeSendSMS}
              handleAgreeSendMail={this.handleAgreeSendMail}
              mailRegistered={this.state.mailRegistered}
              phoneNumberRegistered={this.state.phoneNumberRegistered}
              sendSms={this.state.sendSms}
              sendMail={this.state.sendMail}
              requiredCompanyName={requiredCompanyName}
              isConsultationContent={isConsultationContent}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  // End Render views

  //Render ReconfirmTelephoneAppointmentDialog
  ReconfirmTelephoneAppointmentDialog = () => {
    const { classes } = this.props;
    const ReconfirmDialogResource =
      japaneseList.organisms.reservationForm.ReservationForm
        .ReconfirmTelephoneAppointmentDialog;
    const { reconfirmDialogIsOpen } = this.state;
    return (
      <Dialog open={reconfirmDialogIsOpen}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>
            {ReconfirmDialogResource.Dialog.DialogTitle.j001}
          </DialogTitleText>
        </DialogTitle>
        <DialogContentCustom className={classes.dialogContent}>
          <DialogContentCustomText>
            {
              ReconfirmDialogResource.Dialog.DialogContent.DialogContentText
                .j001
            }
          </DialogContentCustomText>
        </DialogContentCustom>
        <DialogActions className={classes.dialogAction}>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <Button
            id="reconfirm-dialog-call-button"
            className={classes.dialogButton}
            onClick={() => {
              this.doTelephoneAppointment();
            }}
          >
            <IconButtonContainer style={{ pointerEvents: "none" }}>
              <PhoneCustomIcon style={{ pointerEvents: "none" }} />
              <IconButtonText style={{ pointerEvents: "none" }}>
                {ReconfirmDialogResource.Dialog.DialogActions.ButtonSubmit.j001}
              </IconButtonText>
            </IconButtonContainer>
          </Button>
          <ButtonOutlined
            className={classes.dialogButton}
            onClick={() => {
              this.handleDialogBackButton();
            }}
          >
            {ReconfirmDialogResource.Dialog.DialogActions.ButtonSubmit.j002}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    );
  };

  RegisterPassingDialog = () => {
    const { classes } = this.props;
    const RegisterPassingDialogResource =
      japaneseList.organisms.reservationForm.ReservationForm
        .RegisterPassingDialog;
    const { registerPassingDialogIsOpen } = this.state;
    return (
      <Dialog open={registerPassingDialogIsOpen}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>
            {this.reserveData.menu &&
            this.requiredCardRegister() &&
            this.reserveData.menu.requiredAddressRegister
              ? RegisterPassingDialogResource.Dialog.DialogTitle.j001
              : this.reserveData.menu.requiredAddressRegister
              ? RegisterPassingDialogResource.Dialog.DialogTitle.j002
              : RegisterPassingDialogResource.Dialog.DialogTitle.j003}
          </DialogTitleText>
        </DialogTitle>
        <DialogContentCustom className={classes.dialogContent}>
          <ContentItem>
            <DialogContentCustomText>
              {this.reserveData.menu &&
              this.requiredCardRegister() &&
              this.reserveData.menu.requiredAddressRegister
                ? RegisterPassingDialogResource.Dialog.DialogContent
                    .DialogContentText.j001
                : this.reserveData.menu.requiredAddressRegister
                ? RegisterPassingDialogResource.Dialog.DialogContent
                    .DialogContentText.j002
                : RegisterPassingDialogResource.Dialog.DialogContent
                    .DialogContentText.j003}
            </DialogContentCustomText>
          </ContentItem>
        </DialogContentCustom>
        <DialogActions className={classes.dialogAction}>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              this.registerRequiredInformation();
            }}
          >
            {
              RegisterPassingDialogResource.Dialog.DialogActions.ButtonSubmit
                .j001
            }
          </Button>
          <ButtonOutlined
            className={classes.dialogButton}
            onClick={() => {
              this.handleClose();
            }}
          >
            {
              RegisterPassingDialogResource.Dialog.DialogActions.ButtonSubmit
                .j002
            }
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const renderResource =
      japaneseList.organisms.reservationForm.ReservationForm.render;
    const {
      selectedReserve,
      slots,
      sendMail,
      sendSms,
      isSubmitting,
      mailRegistered,
    } = this.state;
    const canTelephoneAppointment = this.canTelephoneAppoint(this.reserveData);

    return (
      <Container ref={this.currentComp}>
        {/* first input */}
        <React.Fragment>
          <ReservationFormHeading menu={this.reserveData.menu} />
          <StepperForm
            reserveSteps={this.reserveSteps}
            currentStep={this.currentStep}
          />
          <CustomInputFormHeader>
            {renderResource.Container.Fragment.InputFormHeaderVariant2.j001}
          </CustomInputFormHeader>
        </React.Fragment>

        <React.Fragment>
          <InputFormTitle titleNumber={1}>
            {renderResource.Container.Fragment.InputFormTitle.j001}
          </InputFormTitle>
          <FormGroup>
            <Calendar
              slots={slots}
              getSlots={this.getSlots}
              loadTimeOfSelectedDate={this.loadTimeOfSelectedDate}
              selectedDate={selectedReserve.from || null}
              canTelephoneAppointment={canTelephoneAppointment}
            />
          </FormGroup>
        </React.Fragment>

        {(slots && slots.length > 0) ||
        (selectedReserve && selectedReserve.from) ? (
          <React.Fragment>
            <FormGroup>
              <FormBorderTopSpace />
            </FormGroup>
            <FormDevided>{this.RenderSelectForm()}</FormDevided>
          </React.Fragment>
        ) : !canTelephoneAppointment ? (
          <FormDevided>
            <Panel>
              <WarningMessage>
                {renderResource.Container.FormDevided.Panel.WarningMessage.j001}
                <br />
                {renderResource.Container.FormDevided.Panel.WarningMessage.j002}
              </WarningMessage>
            </Panel>
          </FormDevided>
        ) : null}

        <FormDevided>
          <FormGroup>
            {selectedReserve && selectedReserve.from ? (
              <CheckEntriesButton
                id="select-appointment-date-confirm-button"
                onClick={this.onSubmit}
                disabled={
                  (mailRegistered && !sendMail && !sendSms) || isSubmitting
                }
              >
                {
                  renderResource.Container.FormDevided.FormGroup
                    .CheckEntriesButton.j001
                }
              </CheckEntriesButton>
            ) : !(slots && slots.length > 0) && canTelephoneAppointment ? (
              <React.Fragment>
                <FormDevided />
                {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
                <FullWidthButton
                  id="call-reservation-no-reservable-button"
                  onClick={() => {
                    this.doTelephoneAppointment();
                  }}
                >
                  <IconButtonContainer style={{ pointerEvents: "none" }}>
                    <PhoneCustomIcon style={{ pointerEvents: "none" }} />
                    <IconButtonText style={{ pointerEvents: "none" }}>
                      {
                        renderResource.Container.TelephoneAppointment
                          .FullWidthButton.j001
                      }
                    </IconButtonText>
                  </IconButtonContainer>
                </FullWidthButton>
                <FormDevided />
              </React.Fragment>
            ) : null}

            <FullWidthOutlinedButton
              id="select-appointment-date-back-button"
              onClick={() => {
                this.handleBackButtonClick();
              }}
            >
              {
                renderResource.Container.FormDevided.FormGroup
                  .FullWidthButtonSub.j001
              }
            </FullWidthOutlinedButton>
          </FormGroup>
        </FormDevided>
        {this.RenderDialog()}
        {this.ReconfirmTelephoneAppointmentDialog()}
        {this.RegisterPassingDialog()}
      </Container>
    );
  }
}

export default withStyles(styles)(withRouter(ReservationForm));
