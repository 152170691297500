import styled from "styled-components";

const PanelFormArea = styled.div`
  display: flex;
  flex-direction:${p => p.column? "column": "row"};
  flex-grow: 0;
  padding:${p => p.theme.panel.padding.s3} ${p => p.theme.panel.padding.s} ${p => p.theme.panel.padding.s} ${p => p.theme.panel.padding.s};

`;

export default PanelFormArea;
