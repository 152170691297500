import { Hub } from "aws-amplify";
import eaw from "eastasianwidth";
import moji from "moji";
import jconv from "jconv";
import { format, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import { japaneseList } from "../Resources/japaneseList";

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder("utf-8").encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");
  return hashHex;
}
const jaLocale = {
  headerFormat: "YYYY年M月",
  locale: require("date-fns/locale/ja"),
  todayLabel: {
    long: "本日",
  },
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  weekStartsOn: 0, // Start the week on Sunday
};

const jaLocaleWithoutChosen = {
  blank: "日時が選択されていません",
  headerFormat: "YYYY年M月",
  locale: require("date-fns/locale/ja"),
  todayLabel: {
    long: "本日",
  },
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  weekStartsOn: 0, // Start the week on Sunday
};

const jaLocaleWithoutDay = {
  blank: "予約可能な日時がありません",
  headerFormat: "YYYY年M月",
  locale: require("date-fns/locale/ja"),
  todayLabel: {
    long: "本日",
  },
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  weekStartsOn: 0, // Start the week on Sunday
};

const formatDate = (date, formatString) => {
  return format(parse(date), formatString, {
    locale: ja,
  });
};

// 「<年>/<月>/<日>(<曜日>) <時刻>」の形式にフォーマットする
const formatTime = (data) => {
  const formatString = japaneseList.Utils.dateFormat.time;
  return formatDate(data, formatString);
};

// 「<年>/<月>/<日>(<曜日>) <時刻>~<時刻>」の形式にフォーマットする
const formatPeriod = (start, end) => {
  const startFormatString = japaneseList.Utils.dateFormat.period.start;
  const endFormatString = japaneseList.Utils.dateFormat.period.end;
  return (
    formatDate(start, startFormatString) + formatDate(end, endFormatString)
  );
};

const isFullWidthCharacter = (char) => eaw.characterLength(char) === 2;

const isFullWidthCharacters = (input) => {
  if (input && input.length > 0) {
    for (let i = 0; i < input.length; i++) {
      if (input[i] === " ") continue;
      if (!isFullWidthCharacter(input[i])) return false;
    }
  }
  return true;
};

const convertToFullWidthCharacters = (input) => {
  if (!input) return input;
  return moji(input)
    .convert("HE", "ZE")
    .convert("HK", "ZK")
    .convert("HS", "ZS")
    .toString();
};

const isKatakanaCharacters = (input) => {
  const inputConverted = input.replace("ー", "");
  const inputOnlyKana = moji(inputConverted).filter("KK").toString();
  return inputConverted === inputOnlyKana;
};

/**
 * available characters definition for patient name
 * This definition is taken into the yamato-api's validation.
 * @type {RegExp}
 */
const availableNameCharactersBase =
  /[0-9a-zA-Z０-９ａ-ｚＡ-Ｚあ-んゔぁ-ょア-ンヴァ-ョヶヵー]/g;
const availableNameCharactersException =
  /[贒琇氿增倞濵桒瀨硺德栁彅昮羽埈淸曻福侚暲悊褜俉哿冝棈伃﨑昤賴埇髙鉎精杦祥ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ㈱㈲々ゝゞヽヾ〃\-.・]/g;

/**
 * Check if inputted word consists of only available characters by patient name,
 *   According to yamato-api's validation.
 * available characters definitions:
 * - Alphanumeric, Kana
 * - JIS(ISO-2022-JP) code Kanji 1~2 level,
 * - Some exception symbol and Kanji.
 * @param {String} input inputted word
 * @return {Boolean}
 */
const isValidName = (input) => {
  if (!input) return false;

  const convertedInput = input
    .replace(availableNameCharactersBase, "")
    .replace(availableNameCharactersException, "");

  // check JIS code Kanji level
  const hasTarget = Array.from(convertedInput).some((str) => {
    const buffer = jconv.encode(str, "ISO-2022-JP");

    return buffer.length !== 8 || buffer[3] < 48 || buffer[3] > 116;
  });

  return !hasTarget;
};

/**
 * available characters definition for patient name
 * This definition is taken into the yamato-api's validation.
 * @type {RegExp}
 */
const availableAddressCharactersBase =
  /[0-9a-zA-Z０-９ａ-ｚＡ-Ｚあ-んゔぁ-ょア-ンヴァ-ョヶヵー]/g;
const availableAddressCharactersException =
  /[贒琇氿增倞濵桒瀨硺德栁彅昮羽埈淸曻福侚暲悊褜俉哿冝棈伃﨑昤賴埇髙鉎精杦祥ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ㈱㈲々ゝゞヽヾ〃\-.・ 　‐－─━]/g;

/**
 * Check if inputted word consists of only available characters by patient address,
 *   According to yamato-api's validation.
 * available characters definitions:
 * - Alphanumeric, Kana
 * - JIS(ISO-2022-JP) code Kanji 1~2 level,
 * - Some exception symbol and Kanji.
 * 注意：こちらは全角半角スペースが許容されている
 * @param {String} input inputted word
 * @return {Boolean}
 */
const isValidAddress = (input) => {
  if (!input) return false;

  const convertedInput = input
    .replace(availableAddressCharactersBase, "")
    .replace(availableAddressCharactersException, "");

  // check JIS code Kanji level
  const hasTarget = Array.from(convertedInput).some((str) => {
    const buffer = jconv.encode(str, "ISO-2022-JP");

    return buffer.length !== 8 || buffer[3] < 48 || buffer[3] > 116;
  });

  return !hasTarget;
};

function br2nl(str, replaceMode) {
  let replaceStr = replaceMode ? "\n" : "";
  // Includes <br>, <BR>, <br />, </br>
  return str.replace(/<\s*\/?br\s*\/?>/gi, replaceStr);
}

const toastError = (errorMessage) => {
  Hub.dispatch("msg", {
    event: "open",
    data: {
      message: errorMessage,
      level: "error",
    },
  });
};

const formatPostalCode = (postalCode) => {
  return postalCode && `${postalCode.slice(0, 3)}-${postalCode.slice(3, 7)}`;
};

export {
  sha256,
  jaLocale,
  jaLocaleWithoutChosen,
  jaLocaleWithoutDay,
  formatTime,
  formatPeriod,
  isFullWidthCharacters,
  convertToFullWidthCharacters,
  isKatakanaCharacters,
  isValidName,
  isValidAddress,
  br2nl,
  toastError,
  formatPostalCode,
};
