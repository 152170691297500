import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { ErrorOutline } from "@material-ui/icons";
import LinkTo from "../../atoms/links/LinkTo";

const styles = theme => ({
  icon: {
    display: "block",
    width: "16px",
    height: "16px",
    marginRight: "10px"
  }
});

const FlexLinkTo = styled(LinkTo)`
  display: flex;
  justfy-content: center;
  align-items: center;
  position: relative;
  padding: 15px 10px;
  font-size: ${p => p.theme.font.size.ss};
  color: ${p => p.theme.font.color.default};
  text-decoration: none;
  background-color: ${p => p.theme.panel.background.color.note};
  border-top: solid 1px ${p => p.theme.panel.border.color.note};
  &:first-child {
    border-top: none;
  }
  &:before {
    content: "";
    position: absolute;
    top: 22px;
    right: 15px;
    display: block;
    height: 1px;
    width: 6px;
    background-color: ${p => p.theme.panel.border.color.note};
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 26px;
    right: 15px;
    display: block;
    height: 1px;
    width: 6px;
    background-color: ${p => p.theme.panel.border.color.note};
    transform: rotate(-45deg);
  }
  &hover: ;
`;
const FlexItemIcon = styled.div`
  line-height: 1;
  color: ${p => p.theme.font.color.note};
`;

const FlexItem = styled.div``;

const PanelWarning = props => {
  const { to, id, classes, children } = props;
  return (
    <FlexLinkTo id={id} to={to}>
      <FlexItemIcon>
        <ErrorOutline className={classes.icon} />
      </FlexItemIcon>
      <FlexItem>{children}</FlexItem>
    </FlexLinkTo>
  );
};

PanelWarning.defaultProps = {
  to: ""
};

export default withStyles(styles)(PanelWarning);
