import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";

const fontNames = [
  '"Hiragino Kaku Gothic ProN W3"',
  '"Hiragino Kaku Gothic Pro"',
  "Meiryo",
  "Osaka",
  '"MS PGothic"',
  "sans-serif"
].join(",");

export default createMuiTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        fontFamily: fontNames
      }
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: fontNames
  },
  palette: {
    primary: {
      light: "#fdb1b6",
      main: "#fdb1b6",
      contrastText: "#fff"
    },
    secondary: {
      light: "#f0f0f0",
      main: "#d4d4d4",
      dark: "#bbb",
      contrastText: "#fff"
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: "#fff8fa"
    }
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  shadows: Array(25).fill("none")
});
