import React, { Component } from "react";
import styled from "styled-components";

import {isLnln} from "../../Utils/checkLnln";
import Favicon from "../atoms/others/Favicon";
import TitleTag from "../atoms/others/TitleTag";
import Footer from "../organisms/Footer";
import { japaneseList } from "../../Resources/japaneseList";

const Container = styled.div`
  flex-grow: 1;
  padding: ${p => p.theme.space.normal};
`;

class NotSupport extends Component {
  render() {
    const renderResource = japaneseList.pages.NotSupport.render;
    const service_name = japaneseList.service.name;
    const servicePath = isLnln()? "lnln": "carada";
    return (
      <React.Fragment>
        <Favicon servicePath={servicePath} />
        <TitleTag title={service_name} />
        <Container>
          {renderResource.Fragment.Container.j001}
          <br />
          <br />
          {renderResource.Fragment.Container.j002}
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default NotSupport;
