import React from "react";
import styled from "styled-components";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import PanelPadding from "../../atoms/panels/PanelPadding";


const CustomFontPSmall = styled(FontPSmall)`
  white-space: pre-wrap;
  word-break: break-word;
`;

const AreaFont = ({children, ...props}) => (
  <PanelPadding>
    <CustomFontPSmall>{children}</CustomFontPSmall>
  </PanelPadding>
);

export default AreaFont;

