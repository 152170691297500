import React, { Component } from "react";

import HeadingForm from "../../molecules/headings/HeadingForm";
import PanelFormArea from "../../atoms/panels/PanelFormArea";
import InputTelFull from "../../atoms/forms/InputTelFull";

import { japaneseList } from "../../../Resources/japaneseList";
import styled from "styled-components";
import InfoMessage from "../../molecules/InfoMessage";
import FontP from "../../atoms/fonts/FontP";
const renderResource = japaneseList.organisms.auth.InputTelForm;

const WarningMessage = styled(InfoMessage)`
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${(p) => p.theme.color.backgroundVariant1};
`;

class InputTelForm extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadingForm>{renderResource.Title}</HeadingForm>
        <PanelFormArea column={true}>
          <WarningMessage>
            <FontP size="sss">{renderResource.note.text.j001}</FontP>
            <FontP size="sss">{renderResource.note.text.j002}</FontP>
          </WarningMessage>
          <InputTelFull
            name="phone_number"
            placeholder={renderResource.placeholder}
            {...this.props}
          />
        </PanelFormArea>
      </React.Fragment>
    );
  }
}

export default InputTelForm;
