import styled from "styled-components";

const Heading3 = styled.div`
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: ${p => p.theme.color.font};
  background-color: ${p => p.theme.color.secondaryVariant};
`;

export default Heading3;
