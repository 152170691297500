import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";

const Title = styled.div`
  height: 50px;
  margin-top: 20px;
  padding: 0 14px;
  border-bottom: 1px solid ${(p) => p.theme.heading.h1.bg};
`;

const TitleContainer = styled.div`
  height: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  column-gap: 5px;
`;

const ReservationContentShow = styled.div`
  height: 50px;
  padding: 15px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 2px solid #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
`;

const ReservationContentHide = styled.div`
  height: 45px;
  margin-top: 5px;
  padding: 12px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: 0.3;
`;

const InterviewSheetTitleShow = styled.div`
  height: 50px;
  padding: 15px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 2px solid #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
`;

const InterviewSheetTitleHide = styled.div`
  height: 45px;
  margin-top: 5px;
  padding: 12px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: 0.3;
`;

const FileSharingTitleShow = styled.div`
  height: 50px;
  padding: 15px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 2px solid #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
`;

const FileSharingTitleHide = styled.div`
  height: 45px;
  margin-top: 5px;
  padding: 12px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(p) => p.theme.heading.h1.bg};
  text-align: center;
  border: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-bottom: 1px solid ${(p) => p.theme.heading.h1.bg};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: 0.3;
`;

const renderResource = japaneseList.pages.AppointmentDetailPage.render;

export const TabTitles = (props) => {
  const {
    showAppointmentDetail,
    showInterrogation,
    showFileSharing,
    title,
    isShowInterrogation,
    content,
  } = props;

  const showTitle = () => {
    switch (title) {
      case content.APPOINTMENT_DETAIL:
        return (
          <React.Fragment>
            <ReservationContentShow>
              {renderResource.Title.j001}
            </ReservationContentShow>
            {isShowInterrogation && (
              <InterviewSheetTitleHide onClick={showInterrogation}>
                {renderResource.Title.j002}
              </InterviewSheetTitleHide>
            )}
            <FileSharingTitleHide onClick={showFileSharing}>
              {renderResource.Title.j003}
            </FileSharingTitleHide>
          </React.Fragment>
        );
      case content.INTERVIEW_SHEET:
        return (
          <React.Fragment>
            <ReservationContentHide onClick={showAppointmentDetail}>
              {renderResource.Title.j001}
            </ReservationContentHide>
            {isShowInterrogation && (
              <InterviewSheetTitleShow>
                {renderResource.Title.j002}
              </InterviewSheetTitleShow>
            )}
            <FileSharingTitleHide onClick={showFileSharing}>
              {renderResource.Title.j003}
            </FileSharingTitleHide>
          </React.Fragment>
        );
      case content.FILE_SHARING:
        return (
          <React.Fragment>
            <ReservationContentHide onClick={showAppointmentDetail}>
              {renderResource.Title.j001}
            </ReservationContentHide>
            {isShowInterrogation && (
              <InterviewSheetTitleHide onClick={showInterrogation}>
                {renderResource.Title.j002}
              </InterviewSheetTitleHide>
            )}
            <FileSharingTitleShow>
              {renderResource.Title.j003}
            </FileSharingTitleShow>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <Title>
      <TitleContainer
        style={
          !isShowInterrogation ? { gridTemplateColumns: "repeat(2, 1fr)" } : {}
        }
      >
        {showTitle()}
      </TitleContainer>
    </Title>
  );
};
