import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import InquiryList from "../organisms/InquiryList";
import Titles from "../../Resources/Titles";

class InquiryPage extends Component {
  render() {
    return <AuthenticatedTemplate title={Titles.inquiry} main={<InquiryList />} />;
  }
}

export default withRouter(InquiryPage);
