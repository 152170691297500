import React, { useState, useEffect, useCallback } from "react";
import Heading1 from "../../atoms/headings/Heading1";
import styled from "styled-components";
import FontP from "../../atoms/fonts/FontP";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { CancelAccount } from "../../../Utils/GraphQLQueries";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import InfoMessage from "../../molecules/InfoMessage";
import CheckboxLabel from "../../molecules/checkboxLabels/CheckboxLabel";
import { japaneseList } from "../../../Resources/japaneseList";
import { useAuthContext } from "../../../contexts/AuthContext";
import { convertToDomestic } from "../../../Utils/PhoneNumber";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ConfirmationPopup } from "../../molecules/popup/ConfirmationPopup";
import { InformationPopup } from "../../molecules/popup/InformationPopup";
import { useHistory } from "react-router-dom";
import { some } from "lodash";

const styles = (theme) => ({
  title: {
    padding: "15px 0",
    fontWeight: 600,
    fontSize: "0.9rem",
    lineHeight: "21px",
  },
  subTitle: {
    padding: "0px 0 20px 0",
    fontWeight: 600,
    fontSize: "0.9rem",
    lineHeight: "21px",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  checkbox: {
    justifyContent: "center",
    "& span": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
});

const Container = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};
`;

const WarningMessage = styled(InfoMessage)`
  align-items: center;
  padding: 20px;
  background-color: ${(p) => p.theme.cancelPanel.backgroundColor};
`;

const WrapperContent = styled.ul`
  padding: 0 ${(p) => p.theme.space.normal};
  li::marker {
    font-size: 10px;
  }
`;

const ContentMessage = styled.li`
  line-height: 21px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const WrapperSubContent = styled.ul`
  padding-left: ${(p) => p.theme.space.normal};
  list-style-type: none;
`;

const SubContentMessage = styled.li`
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const AcceptCheckboxLabel = styled(CheckboxLabel)`
  margin-top: 20px;
`;

const CheckboxText = styled(FontP)`
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  padding: 0 30px;
  margin-top: 31px;
`;

const SubmitButton = styled(ButtonFullWidth)`
  height: 50px;
`;

const Cancellation = (props) => {
  const { classes } = props;
  // states
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isOpenInformationPopup, setIsOpenInformationPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  // hooks
  const authContext = useAuthContext();
  const renderResource = japaneseList.organisms.account.Cancellation.render;

  useEffect(() => {
    authContext.user &&
      setPhoneNumber(
        convertToDomestic(authContext.user.attributes.phone_number)
      );
  }, [authContext.user]);

  const patientPhoneNumber = renderResource.Container.Title.j001.replace(
    /{{patientInfo.phoneNumber}}/g,
    phoneNumber
  );

  const handleAgreement = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleOpenPopup = () => {
    setIsOpenConfirmPopup(true);
  };

  const handleClosePopup = useCallback(() => {
    setIsOpenConfirmPopup(false);
    setIsAgreed(false);
  });

  const handleCloseInfoPopup = useCallback(() => {
    setIsOpenInformationPopup(false);
    history.push("/sign-out");
  });

  const handleConfirm = async () => {
    setIsLoading(true);
    setIsOpenConfirmPopup(false);
    try {
      const irregularErrors = ["E127", "E128", "E129", "E130"];
      const response = await GraphQLClient.mutate({
        mutation: CancelAccount,
      });
      if (!response.data) {
        const { errorType, errorInfo } = response.errors[0];
        if (some(irregularErrors, (e) => e === errorType)) {
          history.push(`/cancellation-member-error?error=${errorType}`, {
            appointments: errorInfo?.appointments,
          });
        } else if (errorType === "E99" || errorType === "Unauthorized") {
          history.push("/sign-out");
        } else {
          history.push("/system-error");
        }
      } else {
        setIsOpenInformationPopup(true);
      }
    } catch (err) {
      history.push("/system-error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Heading1>{renderResource.Heading.j001}</Heading1>
      <Container>
        <Typography className={classes.title}>{patientPhoneNumber}</Typography>
        <Typography className={classes.subTitle}>
          {renderResource.Container.Title.j002}
        </Typography>
        <WarningMessage>
          <Typography className={classes.content}>
            {renderResource.Container.Content.j001}
          </Typography>
          <WrapperContent>
            <ContentMessage>
              {renderResource.Container.Content.j002}
            </ContentMessage>
            <ContentMessage>
              {renderResource.Container.Content.j003}
            </ContentMessage>
            <ContentMessage>
              {renderResource.Container.Content.j004}
            </ContentMessage>
            <ContentMessage>
              {renderResource.Container.Content.j005}
            </ContentMessage>
            <WrapperSubContent>
              <SubContentMessage>
                {renderResource.Container.SubContent.j001}
              </SubContentMessage>
              <SubContentMessage>
                {renderResource.Container.SubContent.j002}
              </SubContentMessage>
              <SubContentMessage>
                {renderResource.Container.SubContent.j003}
              </SubContentMessage>
            </WrapperSubContent>
          </WrapperContent>
        </WarningMessage>
        <AcceptCheckboxLabel
          className={classes.checkbox}
          onChange={handleAgreement}
          checked={isAgreed}
          label={
            <CheckboxText>
              {renderResource.Container.CheckboxLabel.j001}
            </CheckboxText>
          }
        />
        <ButtonContainer>
          <SubmitButton
            disabled={!isAgreed}
            onClick={handleOpenPopup}
            id="cancel-member-submit-button"
          >
            {renderResource.Container.FullWidthButton.j001}
          </SubmitButton>
        </ButtonContainer>
        <ConfirmationPopup
          isOpen={isOpenConfirmPopup}
          handleClickConfirm={handleConfirm}
          handleClosePopup={handleClosePopup}
          confirmResource={
            japaneseList.molecules.Popup.CancelMemberConfirmPopup
          }
          buttonGAid={{
            confirmButtonId: "cancel-member-confirm-button",
            closeBtnId: "cancel-member-return-button",
          }}
        />
        <InformationPopup
          isOpen={isOpenInformationPopup}
          handleClosePopup={handleCloseInfoPopup}
          confirmResource={
            japaneseList.molecules.Popup.CancelMemberInformationPopup
          }
          buttonGAid={{
            closeBtnId: "cancel-member-complete-button",
          }}
        />
        <LoadingScreen isLoading={isLoading} />
      </Container>
    </React.Fragment>
  );
};

export default withStyles(styles)(Cancellation);
