import React from "react";
import styled from "styled-components";

import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontP from "../../atoms/fonts/FontP";

import { japaneseList } from "../../../Resources/japaneseList";

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentDetailContent.render
    .ConsentRegistration.Cancel;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 10px;
`;

const SubText = styled(FontP)`
  color: ${(p) => p.theme.color.primary};
  margin-top: 4px;
`;

interface Props {
  handleCancelClicked: () => Promise<void>;
}
export const ConsentRegistrationFormToCancel: React.FC<Props> = (props) => {
  const { handleCancelClicked } = props;
  return (
    <>
      <FontP>{renderResource.content}</FontP>
      <ButtonWrapper>
        <ButtonFullWidth
          onClick={handleCancelClicked}
          id="update-consent-button"
        >
          {renderResource.cancelButton.title}
        </ButtonFullWidth>
        <SubText size="ss">{renderResource.cancelButton.subText}</SubText>
      </ButtonWrapper>
    </>
  );
};
