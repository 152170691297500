import imageCompression from "browser-image-compression";
const _ = require("lodash");

export const InsuranceCard = (function() {
  let validateInsuranceCard = [];
  const maxFileSize = 10485760; //Mb to B
  const validExtensions = ["image/png", "image/jpg", "image/jpeg"];

  // Check file size > 10MB
  function sizeMax10MB(insuranceCard) {
    if (insuranceCard.size > maxFileSize) {
      validateInsuranceCard.push("tooBigFileSize");
      return "tooBigFileSize";
    }
    return "";
  }

  // Check file type. Valid values are jpg, jpeg, png
  function checkFileExtension(insuranceCard) {
    if (validExtensions.includes(insuranceCard.type)) {
      return "";
    }
    validateInsuranceCard.push("invalidExtension");
    return "invalidExtension";
  }

  function validation(insuranceCard) {
    validateInsuranceCard = [];
    if (insuranceCard.size <= 0) {
      validateInsuranceCard.push("localFileModified");
    }
    checkFileExtension(insuranceCard);
    sizeMax10MB(insuranceCard);
    return _.uniqBy(validateInsuranceCard);
  }

  async function compression(insuranceCard) {
    // Compress insurance card > 3Mb
    if (insuranceCard.size > 3145728) {
      let options = { maxSizeMB: 3, maxIteration: 15, useWebWorker: false }; //Max number of iteration to compress the image is 15
      insuranceCard = await imageCompression(insuranceCard, options);
    }
    return insuranceCard;
  }

  return {
    validation,
    compression
  };
})();
