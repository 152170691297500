import { Component } from "react";

import HeadingEnterprise from "../../atoms/headings/HeadingEnterprise";
import FontP from "../../atoms/fonts/FontP";

import { japaneseList } from "../../../Resources/japaneseList";

class EnterpriseMenuTitle extends Component {
  render() {
    const renderResource = japaneseList.molecules.MenuTitle.render;
    const { menu, hospital } = this.props;
    const doctor = hospital.doctors.find(
      (doctor) => doctor.doctorId === menu.doctorId
    );

    return (
      <>
        <HeadingEnterprise>{menu.displayName}</HeadingEnterprise>
        <FontP size="s" weight="bold" color="gray">
          {doctor && doctor.doctorName
            ? doctor.doctorName + renderResource.div.Container.Title.Text.j001
            : ""}
        </FontP>
      </>
    );
  }
}

export default EnterpriseMenuTitle;
