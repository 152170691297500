import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";

const Container = styled.div`
  background-color: ${(p) => p.theme.color.primaryVariant3};
  border-radius: 5px;
  padding: 30px 0;
`;

const Content = styled.div`
  color: ${(p) => p.theme.color.font};
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.5em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  padding: 24px 40px 0 40px;
`;

class RegisterMail extends Component {
  render() {
    const renderResource = japaneseList.molecules.RegisterMail.render;
    return (
      <Container>
        <Content>
          {renderResource.Container.Content.j001}
          <br />
          {renderResource.Container.Content.j002}
        </Content>
        <ButtonContainer>
          <ButtonFullWidth
            onClick={() => this.props.history.push(`/my-page/email/register`)}
          >
            {renderResource.Container.ButtonContainer.FullWidthButton.j001}
          </ButtonFullWidth>
        </ButtonContainer>
      </Container>
    );
  }
}

export default withRouter(RegisterMail);
