import React, { Component } from "react";
import styled from "styled-components";
import InputText from "./InputText";
import Button from "../buttons/Button";
import { japaneseList } from "../../../Resources/japaneseList";

const Root = styled.div`
  display: inline-flex;
  position: relative;
`;

const InputPassWord = styled(InputText)`
  flex-grow: 1;
  padding-right: 65px;
`;

const ShowButton = styled(Button)`
  border: 0;
  width: 51px;
  height: 23px;
  display: block;
  max-height: 2em;
  padding: 0;
  text-align: center;
  font-size: 0.9rem;
  position: absolute;
  right: 8px;
  top: 6px;
  :active {
    outline: none;
  }

  &[data-visible="false"] {
    background-color: ${(p) => p.theme.color.secondary};
  }
`;

class InputTextPassword extends Component {
  state = {
    visible: false,
  };

  toggleVisible = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    return (
      <Root style={{ width: "100%" }} {...this.props}>
        <InputPassWord
          style={{ width: "100%" }}
          {...this.props}
          type={this.state.visible ? "url" : "password"}
        />
        <ShowButton
          data-visible={this.state.visible.toString()}
          onClick={this.toggleVisible}
        >
          {japaneseList.comp.atoms.InputTextPassword.j001}
        </ShowButton>
      </Root>
    );
  }
}
export default InputTextPassword;
