import React, { Component } from "react";
import styled from "styled-components";
import {isLnln} from "../../Utils/checkLnln";
import Heading2 from "../atoms/headings/Heading2";
import Image from "../atoms/others/Image";
import { japaneseList } from "../../Resources/japaneseList";

import ImgPath from "../../images/carada/06.png";
import ImgPathLnln from "../../images/lnln/06.png";
const imgPath = isLnln()? ImgPathLnln: ImgPath;

const ImageContainer = styled.div`
  margin: 15px 0 0 0;
  > img {
    display: block;
  }
`;

class Benefits extends Component {
  render() {
    const renderResource = japaneseList.organisms.Benefits.render;
    return (
      <div>
        <Heading2>
          {renderResource.div.HomeHeadingAlignCenter.j001}
        </Heading2>
        <ImageContainer>
          <Image src={imgPath} />
        </ImageContainer>
      </div>
    );
  }
}

export default Benefits;
