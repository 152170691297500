import React from "react";
import FontPSmallCenter from "../../atoms/fonts/FontPSmallCenter";
import PanelPadding from "../../atoms/panels/PanelPadding";

const AreaFont = ({children, ...props}) => (
  <PanelPadding>
    <FontPSmallCenter>{children}</FontPSmallCenter>
  </PanelPadding>
);

export default AreaFont;

