import React, { Component } from "react";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import ButtonFullWidth from "../atoms/buttons/ButtonFullWidth";
import Typography1 from "../atoms/fonts/Typography1";
import Heading1 from "../atoms/headings/Heading1";

const Row = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};

  :nth-child(even) {
    padding-top: ${(p) => p.theme.space.medium};
  }

  :nth-child(odd) {
    padding-top: ${(p) => p.theme.space.short};
  }
`;

const Typography = styled(Typography1)`
  font-weight: normal;
`;

const MarginWrapper = styled.div`
  margin-top: ${(p) => p.theme.space.short};
  margin-bottom: ${(p) => p.theme.space.long};
  text-align: center;
`;

class AddressDetail extends Component {
  goToAddressPage = (event) => {
    this.props.history.push("/my-page/address/update");
  };

  render() {
    const renderResource = japaneseList.organisms.AddressDetail.render;
    const {
      address: {
        postalCode,
        prefectureName,
        addressCity,
        addressStreetNumber,
        addressBuilding,
      },
      goToUpdatePage,
    } = this.props;

    return (
      <div>
        <Heading1>{renderResource.PrimaryHeading.j001}</Heading1>
        {postalCode ? (
          <React.Fragment>
            <Row>
              <Typography>
                {renderResource.Fragment.Row.Typography.j001}
              </Typography>
            </Row>
            <Row>
              <Typography>{postalCode}</Typography>
            </Row>
            <Row>
              <Typography>
                {renderResource.Fragment.Row.Typography.j002}
              </Typography>
            </Row>
            <Row>
              <Typography>{prefectureName}</Typography>
            </Row>
            <Row>
              <Typography>
                {renderResource.Fragment.Row.Typography.j003}
              </Typography>
            </Row>
            <Row>
              <Typography>{addressCity}</Typography>
            </Row>
            <Row>
              <Typography>
                {renderResource.Fragment.Row.Typography.j004}
              </Typography>
            </Row>
            <Row>
              <Typography>{addressStreetNumber}</Typography>
            </Row>
            <Row>
              <Typography>
                {renderResource.Fragment.Row.Typography.j005}
              </Typography>
            </Row>
            <Row>
              <Typography>{addressBuilding}</Typography>
            </Row>
            <Row>
              <ButtonFullWidth
                id="update-address-confirm-button"
                onClick={goToUpdatePage}
              >
                {renderResource.Fragment.Row.FullWidthButton.j001}
              </ButtonFullWidth>
            </Row>
          </React.Fragment>
        ) : (
          <Row>
            <MarginWrapper>
              <Typography>
                {renderResource.Row.MarginWrapper.Typography.j001}
              </Typography>
            </MarginWrapper>
            <ButtonFullWidth
              id="register-address-confirm-button"
              onClick={this.goToAddressPage}
            >
              {renderResource.Row.FullWidthButton.j001}
            </ButtonFullWidth>
          </Row>
        )}
      </div>
    );
  }
}

export default AddressDetail;
