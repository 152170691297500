import React from "react";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import { InquirySignupSmsCompleted } from "../../organisms/auth/InquirySignupSmsCompleted";
import Titles from "../../../Resources/Titles";

export const InquirySignupSmsCompletedPage = () => {
  return (
    <AuthenticatedTemplate
      title={Titles.inquirySignupSmsCompleted}
      main={<InquirySignupSmsCompleted />}
    />
  );
};
