import React from "react";
import { japaneseList } from "../../../Resources/japaneseList";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { RESERVE_STEP } from "../../../Utils/Constant";

const styles = {
  label: {
    fontSize: "12px",
    marginTop: "10px !important",
  },
};

const renderResource = japaneseList.organisms.reservationForm.StepperForm;

const QontoConnector = withStyles({
  line: {
    borderColor: "#cccccc",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const ShadowBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f7f7f7;
  box-shadow: 0px 15px 14px -15px #00000033 inset;
`;

const StepperFormCustom = (props) => {
  const { reserveSteps, currentStep, classes } = props;
  const steps = reserveSteps.map((item) => {
    switch (item.reserveStep) {
      case RESERVE_STEP.INPUT:
        return renderResource.j001;
      case RESERVE_STEP.INTERVIEW:
        return renderResource.j002;
      case RESERVE_STEP.MEDICINE_DELIVERY:
        return renderResource.j006;
      case RESERVE_STEP.UPLOAD_FILE:
        return renderResource.j003;
      case RESERVE_STEP.CONFIRM:
        return renderResource.j004;
      case RESERVE_STEP.COMPLETE:
        return renderResource.j005;
      default:
        return "";
    }
  });
  const activeStep =
    reserveSteps &&
    currentStep &&
    reserveSteps.findIndex(
      (step) => step.reserveStep === currentStep.reserveStep
    );

  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        nonLinear
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.label }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ShadowBar />
    </React.Fragment>
  );
};

export const StepperForm = withStyles(styles)(StepperFormCustom);
