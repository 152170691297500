import { ReactNode } from "react";
import styled from "styled-components";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const Container = styled.div`
  padding: 20px 30px 20px;
`;

const Button = styled(ButtonFullWidth)`
  background-color: ${(p) => p.theme.button.background.color.cvbutton};
  height: 50px;
`;

export default ({ children, ...props }: Props) => (
  <Container {...props}>
    <Button onClick={props.onClick}>{children}</Button>
  </Container>
);
