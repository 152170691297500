import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";
import { Card, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { isNil } from "lodash";
import fullWidth from "fullwidth";
import LinkTo from "../../atoms/links/LinkTo";

const styles = (theme) => ({
  root: {
    padding: "10px 0",
    margin: "20px 0",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
  },
  rootContent: {
    paddingTop: "10px !important",
    paddingBottom: "0px !important",
  },
  contentHeader: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
});

const Container = styled.div`
  padding: 0 ${(p) => p.theme.space.normal};
`;

const Divider = styled.hr`
  border: 1px solid ${(p) => p.theme.color.secondary};
`;

const ContentTitle = styled(FontP)`
  padding: 5px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${(p) => p.theme.irregularAppointment.color};
`;

const ContentDescription = styled(FontP)`
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const IrregularAppointment = ({
  classes,
  appointment,
  numberOrder,
  ...props
}) => {
  const renderResource =
    japaneseList.molecules.account.IrregularAppointment.render;

  const renderHeader = !isNil(numberOrder)
    ? `${renderResource.Description.j001} ${fullWidth(
        (numberOrder + 1).toString()
      )}`
    : `${renderResource.Description.j001}`;

  const paymentTime = appointment?.payment?.paymentTime;
  const cancelDate = appointment?.cancelDate;

  return (
    <React.Fragment>
      <Container>
        <Card key={appointment.patientId} className={classes.root}>
          <CardContent className={classes.rootContent}>
            <Typography className={classes.contentHeader}>
              {renderHeader}
            </Typography>
            <Divider />
            <ContentTitle>{renderResource.Description.j002}</ContentTitle>
            <ContentDescription>
              {format(appointment.from, renderResource.DateTimeFormat.j001, {
                locale: ja,
              })}
              ～
              {format(appointment.to, renderResource.DateTimeFormat.j002, {
                locale: ja,
              })}
            </ContentDescription>
            <ContentTitle>{renderResource.Description.j003}</ContentTitle>
            {props.hospitalURL ? (
              <LinkTo to={props.hospitalURL} target="_blank">
                <ContentDescription>
                  {appointment?.hospitalName}
                </ContentDescription>
              </LinkTo>
            ) : (
              <ContentDescription>
                {appointment?.hospitalName}
              </ContentDescription>
            )}
            <ContentTitle>{renderResource.Description.j004}</ContentTitle>
            <ContentDescription>
              {appointment?.menu?.displayName}
            </ContentDescription>
            {!isNil(paymentTime) && (
              <div>
                <ContentTitle>{renderResource.Description.j005}</ContentTitle>
                <ContentDescription>
                  {format(paymentTime, renderResource.DateTimeFormat.j003, {
                    locale: ja,
                  })}
                </ContentDescription>
              </div>
            )}
            {!isNil(cancelDate) && (
              <div>
                <ContentTitle>{renderResource.Description.j006}</ContentTitle>
                <ContentDescription>
                  {format(cancelDate, renderResource.DateTimeFormat.j003, {
                    locale: ja,
                  })}
                </ContentDescription>
              </div>
            )}
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default withStyles(styles)(IrregularAppointment);
