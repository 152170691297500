import React, { useContext } from "react";
import SiteContext from "../../../SiteContext";
import "./shared/Content/css/style.css";
import "./Content/css/medico_lp/style.css";
import title01 from "./Content/img/medico_lp/title01.png";
import img01 from "./Content/img/medico_lp/img01.png";
import img02 from "./Content/img/medico_lp/img02.png";
import { japaneseList } from "../../../Resources/japaneseList";

const root = {
  fontFamily:
    "'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', Verdana, Roboto, 'Droid Sans', '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif",
  fontSize: "14px",
  lineHeight: "1.42857",
  height: "100%",
  backgroundColor: "#ffe8ee"
};

/**
 * After Reservation page for lnln app user.
 */
const LnlnInfo = () => {
  const LnlnInfoResource = japaneseList.pages.lnln.LnlnInfo;
  const { lnln_clinicId } = useContext(SiteContext);
  const lnlnBackUrl =
    process.env.REACT_APP_LNLN_URL +
    (lnln_clinicId ? `clinic/detail?clinicId=${lnln_clinicId}` : "clinic/");

  return (
    <div id="lnln-root">
      <div style={root} class="body">
        <div role="main" class="content">
          <div class="lnln-main">
            <div class="lnln-container">
              <div class="section-01">
                <div class="h1-wrap p-b-30">
                  <h1 class="medico_lp_1-hero-title">
                    <img
                      src={title01}
                      alt={LnlnInfoResource.j001}
                      width="260"
                    />
                  </h1>
                </div>

                <p class="cpt-lead-A03">
                  {LnlnInfoResource.j002}
                  <br />
                  {LnlnInfoResource.j012}
                </p>
                <p class="point-note m-t-10">{LnlnInfoResource.j003}</p>
                <p class="m-t-25 t-a-c">
                  <em class="cpt-emp-B01">
                    {LnlnInfoResource.j004}
                    <br />
                    {LnlnInfoResource.j005}
                  </em>
                </p>
                <figure class="point-pict m-t-10">
                  <img src={img01} alt={LnlnInfoResource.j006} />
                </figure>
                <p class="cpt-notice-B01 m-t-10">{LnlnInfoResource.j007}</p>
                <h4 class="cpt-heading-C03 m-t-30">{LnlnInfoResource.j008}</h4>
                <p class="cpt-lead-A03 m-t-10">{LnlnInfoResource.j009}</p>
                <figure class="point-pict m-t-15">
                  <img src={img02} alt="" />
                </figure>
                <p class="cpt-lead-A01 error m-t-20">{LnlnInfoResource.j010}</p>
                <div class="m-t-10 p-b-20 t-a-c">
                  <a href={lnlnBackUrl} class="cpt-btn-A01">
                    {LnlnInfoResource.j011}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LnlnInfo;
