import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../Resources/japaneseList";
import FontP from "../atoms/fonts/FontP";

interface Props {
  value: number;
  isShowInterrogation: boolean;
  handleChange: (value: number) => void;
}

const renderResource = japaneseList.organisms.SearchHospital.render;
const tab = {
  HOSPITAL: 0,
  PHARMACY: 1,
};

const Title = styled.div`
  height: 47px;
  border-bottom: 1px solid #d8d8d8;
  background: #fcfcfc;
`;

const TitleContainer = styled.div`
  height: 47px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  column-gap: 5px;
`;

const Tab = styled.div`
  position: relative;
`;

const Selected = styled.div`
  padding-top: 12px;
  text-align: center;
  border-bottom: 3px solid ${(p) => p.theme.color.primary};
  cursor: pointer;
`;

const SelectedFontPCustom = styled(FontP)`
  font-weight: 700;
  font-size: 16px;
  color: ${(p) => p.theme.color.primary};
  margin-bottom: 8px;
`;

const NotSelected = styled.div`
  padding-top: 12px;
  text-align: center;
  cursor: pointer;
`;

const NotSelectedFontPCustom = styled(FontP)`
  font-weight: 400;
  font-size: 16px;
  color: #666666;
`;

const SplitLine = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  text-align: center;
  line-height: 44px;
  color: #d8d8d8;
`;

export const TopTabTitles = (props: Props) => {
  const { value, isShowInterrogation, handleChange } = props;

  const showTitle = () => {
    switch (value) {
      case tab.HOSPITAL:
        return (
          <React.Fragment>
            <Tab>
              <Selected>
                <SelectedFontPCustom>
                  {renderResource.div.SearchTabs.j001}
                </SelectedFontPCustom>
              </Selected>
              <SplitLine>{renderResource.div.SplitLine.j001}</SplitLine>
            </Tab>
            <NotSelected onClick={(e) => handleChange(tab.PHARMACY)}>
              <NotSelectedFontPCustom>
                {renderResource.div.SearchTabs.j002}
              </NotSelectedFontPCustom>
            </NotSelected>
          </React.Fragment>
        );
      case tab.PHARMACY:
        return (
          <React.Fragment>
            <Tab>
              <NotSelected onClick={(e) => handleChange(tab.HOSPITAL)}>
                <NotSelectedFontPCustom>
                  {renderResource.div.SearchTabs.j001}
                </NotSelectedFontPCustom>
              </NotSelected>
              <SplitLine>{renderResource.div.SplitLine.j001}</SplitLine>
            </Tab>
            <Selected>
              <SelectedFontPCustom>
                {renderResource.div.SearchTabs.j002}
              </SelectedFontPCustom>
            </Selected>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <Title>
      <TitleContainer
        style={
          !isShowInterrogation ? { gridTemplateColumns: "repeat(2, 1fr)" } : {}
        }
      >
        {showTitle()}
      </TitleContainer>
    </Title>
  );
};
