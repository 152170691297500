import React, { Component } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import Heading1 from "../atoms/headings/Heading1";
import Heading3 from "../atoms/headings/Heading3";
import AreaFont from "../molecules/area/AreaFont";
import LinkAnoUnder from "../atoms/links/LinkAnoUnder";
import { japaneseList } from "../../Resources/japaneseList";

const Container = styled.div`
  margin-bottom: 30px;
`;

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.setState({
          user: user,
        });
      })
      .catch((err) => {});
  }

  render() {
    const renderResource =
      japaneseList.organisms.ApplicationPrivacyPolicy.render;
    const user = this.state.user;
    let name = "";
    const newLineCode = "%0D%0A";
    name =
      user !== null ? renderResource.j001 + user.username + newLineCode : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:privacy@caradamedica.co.jp?subject=" +
      renderResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      renderResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      renderResource.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode;

    return (
      <Container>
        <div>
          <Heading1>
            {renderResource.Container.div.PrimaryHeading.j001}
          </Heading1>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j030}
            {renderResource.Container.div.Paragraph.j031}
            <br />
            {renderResource.Container.div.Paragraph.j032}
          </AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j001}
          </Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j001}
            <br />
            {renderResource.Container.div.Paragraph.j002}
            <br />
            {renderResource.Container.div.Paragraph.j003}
            <br />
            {renderResource.Container.div.Paragraph.j004}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j007}
            <br />
            {renderResource.Container.div.Paragraph.j008}
            <br />
            {renderResource.Container.div.Paragraph.j010}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j011}
            <br />
            {renderResource.Container.div.Paragraph.j012}
          </AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j002}
          </Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j013}
            <br />
            {renderResource.Container.div.Paragraph.j014}
            <br />
            <br />
            2.1. Google Analytics
            <br />
            {renderResource.Container.div.Paragraph.j015}
            <br />
            {renderResource.Container.div.Paragraph.j016}
            <br />
            {renderResource.Container.div.Paragraph.j017}
            <br />
            {renderResource.Container.div.Paragraph.j018}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j019}
            <br />
            {renderResource.Container.div.Paragraph.j020}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j021}
            <br />
            {renderResource.Container.div.Paragraph.j022}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j023}
            <br />
            {renderResource.Container.div.Paragraph.j024}
            <br />
            {renderResource.Container.div.Paragraph.j025}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j026}
            <br />
            <LinkAnoUnder
              href="https://policies.google.com/privacy?hl=ja"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=ja
            </LinkAnoUnder>
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j027}
            <br />
            {renderResource.Container.div.Paragraph.j028}
            <br />
            <LinkAnoUnder
              href="https://tools.google.com/dlpage/gaoptout?hl=ja"
              target="_blank"
            >
              (https://tools.google.com/dlpage/gaoptout?hl=ja)
            </LinkAnoUnder>
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j029}
            <br />
            <br />
            2.2. User Insight
            <br />
            {renderResource.Container.div.Paragraph.j033}
            <br />
            {renderResource.Container.div.Paragraph.j034}
            <br />
            {renderResource.Container.div.Paragraph.j035}
            <br />
            {renderResource.Container.div.Paragraph.j036}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j037}
            <br />
            {renderResource.Container.div.Paragraph.j038}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j039}
            <br />
            {renderResource.Container.div.Paragraph.j040}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j041}
            <br />
            {renderResource.Container.div.Paragraph.j042}
            <br />
            {renderResource.Container.div.Paragraph.j043}
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j044}
            <br />
            <LinkAnoUnder
              href="https://www.userlocal.jp/privacy/"
              target="_blank"
            >
              https://www.userlocal.jp/privacy/
            </LinkAnoUnder>
            <br />
            <br />
            {renderResource.Container.div.Paragraph.j045}
            <br />
            {renderResource.Container.div.Paragraph.j046}
            <br />
            <LinkAnoUnder
              href="https://info.userlocal.jp/data_policy/"
              target="_blank"
            >
              https://info.userlocal.jp/data_policy/
            </LinkAnoUnder>
            <br />
            {renderResource.Container.div.Paragraph.j047}
          </AreaFont>

          <Heading3>{renderResource.Container.div.Paragraph.j048}</Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j049}
            <br />
            {renderResource.Container.div.Paragraph.j050}
            <br />
            {renderResource.Container.div.Paragraph.j051}
            <br />

            <LinkAnoUnder
              href="https://caradamedica.co.jp/privacy"
              target="_blank"
            >
              https://caradamedica.co.jp/privacy
            </LinkAnoUnder>
          </AreaFont>

          <Heading3>{renderResource.Container.div.Paragraph.j053}</Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j054}
            <br />
            <LinkAnoUnder href={msg}>privacy@caradamedica.co.jp </LinkAnoUnder>
          </AreaFont>

          <Heading3>{renderResource.Container.div.Paragraph.j055}</Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j056}
            {renderResource.Container.div.Paragraph.j057}
            <br />
            {renderResource.Container.div.Paragraph.j058}
          </AreaFont>
        </div>
      </Container>
    );
  }
}

export default PrivacyPolicy;
