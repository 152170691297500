import styled from "styled-components";

const Heading2 = styled.div`
  padding: 8px 15px;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: ${p => p.theme.heading.h2.color};
  background-color: ${p => p.theme.heading.h2.bg};
`;

export default Heading2;
