import React from "react";
import { withRouter } from "react-router-dom";
import Heading1 from "../atoms/headings/Heading1";
import { japaneseList } from "../../Resources/japaneseList";
import styled from "styled-components";
import qs from "querystring";
import { prefectureCodes } from "../../Utils/PrefectureCode";
import ListHospital from "./ListHospital";
import InfiniteScroller from "../molecules/others/InfiniteScroller";

const PrefectureName = styled.div`
  border-bottom: 2px solid ${(p) => p.theme.color.primary};
  padding: 8px 15px;
  font-size: 1rem;
`;

class SearchResult extends React.Component {
  render() {
    const { hospitals, hospitalCount, onMore, isLoading, isFetching } = this.props;
    const renderResource = japaneseList.organisms.SearchResult.render;
    const { prefecture } = qs.parse(window.location.search.substr(1));
    const prefectureData =
      prefectureCodes.find((p) => p.code === prefecture) || "";
    return (
      <div>
        <Heading1>{renderResource.div.Title.j001}</Heading1>
        <PrefectureName>
          {prefectureData.jpName + ":"}
          {hospitalCount}
          {renderResource.div.Content.j001}
        </PrefectureName>
        <ListHospital hospitals={hospitals} />
        {isLoading ? "" : (
          <InfiniteScroller
            fetchMore={onMore}
            isFetching={isFetching}
            advanceLength={100}
          />
        )}
      </div>
    );
  }
}

export default withRouter(SearchResult);
