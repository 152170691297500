import styled from "styled-components";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import qs from "querystring";
import Card from "../../molecules/Card";
import FontPBold from "../../atoms/fonts/FontPBold";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import { FACILITY_TYPE } from "../../../Utils/Constant";
import { formatPeriod } from "../../../Utils/Utility";
import { japaneseList } from "../../../Resources/japaneseList";

interface Props {
  appointment: Appointment;
}

interface Appointment {
  hospitalName: string;
  from: string;
  to: string;
  canCreateAppointment: boolean;
}

const renderResource = japaneseList.organisms.AppointmentHistoryPage.render;

const WarningMessage = styled(FontPSmall)`
  color: #ff4d4d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  height: 21px;
  line-height: 21px;
`;

const FontPBoldCustom = styled(FontPBold)`
  color: ${(p) => p.theme.font.color.darkGray};
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 24px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPSmallCustom = styled(FontPSmall)`
  color: ${(p) => p.theme.font.color.darkGray};
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  line-height: 21px;
`;

const CardCustom = styled(Card)`
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
  padding: 10px 9px 9px 10px;
`;

export const SearchFromHospitalHistoriesCard = (props: Props) => {
  const { appointment } = props;
  const facilityType = qs.parse(window.location.search.substr(1)).type;

  return (
    <CardCustom disabled={!appointment.canCreateAppointment}>
      {!appointment.canCreateAppointment && (
        <WarningMessage>
          {facilityType === FACILITY_TYPE.HOSPITAL && renderResource.j002}
          {facilityType === FACILITY_TYPE.PHARMACY && renderResource.j003}
        </WarningMessage>
      )}
      <FontPBoldCustom>{appointment.hospitalName}</FontPBoldCustom>
      <FontPSmallCustom>
        {formatPeriod(appointment.from, appointment.to)}
        {renderResource.j007}
      </FontPSmallCustom>
    </CardCustom>
  );
};
