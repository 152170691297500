import React, { Component } from "react";
import EmailForm from "../organisms/EmailForm";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Titles from "../../Resources/Titles";
import Guard from "../../Utils/Guard";

class EmailPage extends Component {
  state = {
    email: null,
    loaded: false,
  };

  async componentDidMount() {
    try {
      var user = await Auth.currentUserInfo();
      if (user)
        this.setState({
          email: user.attributes && user.attributes.email,
          loaded: true,
        });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const main = this.state.loaded && (
      <EmailForm {...this.props} email={this.state.email} />
    );
    return <AuthenticatedTemplate title={Titles.email} main={main} />;
  }
}

export default withRouter(Guard(EmailPage));
