//--------------------------------------------------
//
// Basic template
// ベーシックテンプレート
// レスポンシブ 未
//
//-------------------------------------------------

// import
//-------------------------------------------------
import React from "react";
import styled from "styled-components";

import { device } from "../device";
import { isLnln } from "../../Utils/checkLnln";
import { japaneseList } from "../../Resources/japaneseList";
import { Helmet } from "react-helmet";

import Favicon from "../atoms/others/Favicon";
import TitleTag from "../atoms/others/TitleTag";
import Header from "../organisms/Header";
import QRCode from "../organisms/QRCode";
import Footer from "../organisms/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";

// const
//-------------------------------------------------
const subStyleTheme = {
  default: { backgroundColor: "#fff" },
  /* easy-to-read color scheme for operations with different purposes */
  multiAction: { backgroundColor: "#f7f7f7" },
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: ${(p) => subStyleTheme[p.subStyleTheme].backgroundColor};
  @media screen and ${device.tb} {
    width: 424px;
    border-right: thin solid #c7c7c7;
    border-left: thin solid #c7c7c7;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.05),
      0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;
const Main = styled.div`
  flex-grow: 1;
`;

// export
// memo: if isNoIndex is true, set it so that it will not be searched by search engines.
//-------------------------------------------------
const Robots = () => (
  <Helmet>
    <meta name="robots" content="noindex"></meta>
  </Helmet>
);

// export
//-------------------------------------------------
export default ({
  main,
  isTop = false,
  title = "",
  isLoading = false,
  isNoIndex = false,
  subStyleTheme = "default",
}) => {
  const service_name = japaneseList.service.name;
  const _title = title ? (isTop ? service_name + " | " + title : title + " | " + service_name)
                       : service_name;
  const servicePath = isLnln() ? "lnln" : "carada";
  return (
    <React.Fragment>
      <Favicon servicePath={servicePath} />
      <TitleTag title={_title} />
      {isNoIndex && <Robots />}
      <Root subStyleTheme={subStyleTheme}>
        <Header />
        <QRCode />
        <Main>{main}</Main>
        <Footer />
        <LoadingScreen isLoading={isLoading} />
      </Root>
    </React.Fragment>
  );
};
