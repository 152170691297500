import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import PrivacyPolicy from "../organisms/PrivacyPolicy";
import { withRouter } from "react-router-dom";
import Titles from "../../Resources/Titles";

class PrivacyPolicyPage extends Component {
  render() {
    return <AuthenticatedTemplate title={Titles.privacyPolicy} main={<PrivacyPolicy />} />;
  }
}

export default withRouter(PrivacyPolicyPage);
