import React, { Component } from "react";
import styled from "styled-components";
import NoHeaderTemplate from "../templates/NoHeaderTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";
import * as Sentry from "@sentry/browser";

const Container = styled.div`
  padding: ${(p) => p.theme.space.normal};
  line-height: 1.5;
`;

const Title = styled.div`
  padding: 40px 0;
  font-size: 1.5rem;
  font-weight: bold;
  /* text-align: center; */
  line-height: 1;
`;

class NotAccess extends Component {
  componentDidMount() {
    Sentry.captureMessage("display-not-access-page", Sentry.Severity.Log);
  }

  render() {
    const renderResource = japaneseList.pages.NotAccess.render;

    const main = (
      <React.Fragment>
        <Container>
          <Title>{renderResource.Fragment.Container.Title.j001}</Title>
          {renderResource.Fragment.Container.j001}
          <br />
          <b>{renderResource.Fragment.Container.j002}</b>
          <br />
          {renderResource.Fragment.Container.j003}
          <br />
          {renderResource.Fragment.Container.j004}
          <br />
          {renderResource.Fragment.Container.j005}
          <br />
          {renderResource.Fragment.Container.j006}
        </Container>
      </React.Fragment>
    );

    return <NoHeaderTemplate title={Titles.notAccess} main={main} />;
  }
}

export default NotAccess;
