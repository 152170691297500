import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { Auth, Hub, Logger } from "aws-amplify";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import Typography1 from "../../atoms/fonts/Typography1";
import Button from "../../atoms/buttons/Button";
import InputTextPassword from "../../atoms/forms/InputTextPassword";
import { japaneseList } from "../../../Resources/japaneseList";

const ContentBox = styled.div`
  padding: ${(p) => p.theme.space.normal};
`;

const FormGroup = styled.div`
  margin-bottom: ${(p) => p.theme.space.medium};
`;

const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-top: ${(p) => p.theme.space.short};
`;

const FormGroupHeading = styled(Typography1)`
  margin: 10px 0px 5px 0px;
  font-size: 0.8rem;
`;

const Section = ({ title, children }) => (
  <Fragment>
    <Heading1>{title}</Heading1>
    <ContentBox>{children}</ContentBox>
  </Fragment>
);

class NewPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.logger = new Logger("NewPassword");
    this.inputs = {};
    this.state = {
      disabled: false,
    };
  }

  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value } = evt.target;
    this.inputs[name] = value;
  };

  change = () => {
    Sentry.captureMessage("password-setnew", Sentry.Severity.Log);
    this.setState({
      disabled: true,
    });

    const { password, password_confirm } = this.inputs;
    if (password !== password_confirm) {
      Sentry.captureMessage(
        "password-setnew-error-notSameAsConfirm",
        Sentry.Severity.Log
      );
      this.snackBar(japaneseList.pages.auth.NewPasswordPage.change.j001);
      this.setState({
        disabled: false,
      });
      return;
    }

    const { user } = this.props;
    const { requiredAttributes } = user.challengeParam;
    Auth.completeNewPassword(user, password, requiredAttributes).catch(
      (err) => {
        this.logger.error(err.message, err);
        Sentry.captureMessage(
          "password-setnew-error-" + err.code,
          Sentry.Severity.Log
        );
        this.snackBar(err.message);
        this.setState({
          disabled: false,
        });
      }
    );
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "NewPassword"
    );
  };

  render() {
    const renderResource = japaneseList.pages.auth.NewPasswordPage.render;
    return (
      <Fragment>
        <Section title={renderResource.Fragment.Section.j001}>
          <FormGroup>
            <FormGroupHeading>
              {renderResource.Fragment.Section.FormGroup.FormGroupHeading.j001}
            </FormGroupHeading>
            <InputTextPassword
              name="password"
              placeholder={
                renderResource.Fragment.Section.FormGroup.InputTextPassword.j001
              }
              onChange={this.handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <FormGroupHeading>
              {renderResource.Fragment.Section.FormGroup.FormGroupHeading.j002}
            </FormGroupHeading>
            <InputTextPassword
              name="password_confirm"
              placeholder={
                renderResource.Fragment.Section.FormGroup.InputTextPassword.j002
              }
              onChange={this.handleInputChange}
            />
          </FormGroup>
          <ButtonSubmit onClick={this.change} disabled={this.state.disabled}>
            {renderResource.Fragment.Section.ButtonSubmit.j001}
          </ButtonSubmit>
        </Section>
      </Fragment>
    );
  }
}
export default withRouter(NewPasswordPage);
