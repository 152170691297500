// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "7wpf0kvue2",
      name: "dev-patient",
      description: "",
      endpoint: process.env.REACT_APP_PATIENT_AWS_API_GATEWAY,
      region: "ap-northeast-1",
      paths: ["/listhospital", "register", "slots"],
    },
    {
      id: "xk8c6sufxk",
      name: "lnln-integration",
      description: "",
      endpoint: process.env.REACT_APP_PATIENT_AWS_API_GATEWAY,
      region: "ap-northeast-1",
      paths: ["/verifyToken"],
    },
    {
      id: "j8cufwpnq6",
      name: "dev-payment",
      description: "",
      endpoint: process.env.REACT_APP_PATIENT_AWS_API_GATEWAY,
      region: "ap-northeast-1",
      paths: [
        "/cards/list",
        "/cards/register",
        "/credit",
        "/members",
        "/members/create",
        "/members/delete",
      ],
    },
  ],
  aws_cognito_identity_pool_id:
    "ap-northeast-1:cbd98332-6616-4d52-97b2-10dd076565a6",
  aws_cognito_region: "ap-northeast-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "patient-hosting-mobilehub-1235753152",
  aws_content_delivery_bucket_region: "ap-northeast-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d3kifcm5zl87ib.cloudfront.net",
  aws_project_id: "d89421d8-f3bc-4b07-b24c-f57696789e20",
  aws_project_name: "patient",
  aws_project_region: "ap-northeast-1",
  aws_resource_name_prefix: "patient-mobilehub-1235753152",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-northeast-1_Use9Ck9J3",
  aws_user_pools_web_client_id: "4a3pgf9v8mhdpbs0e9p6i25alv",
};

export default awsmobile;
