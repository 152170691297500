import React from "react";
import Button from "../atoms/buttons/Button";
import Heading2 from "../atoms/headings/Heading2";
import styled from "styled-components";
import { MedicineDeliveryError } from "../molecules/patient-update-medicineDelivery/medicineDeliveryError";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource = japaneseList.pages.ReservationUploadFileErrorPage.render;

interface Props {
  errorType: string;
  handleErrorGoBack: () => void;
}

const FullWidthOutlinedButton = styled(Button)`
  margin: 1px 14px;
  width: calc(100% - 28px);
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
  font-weight: bold;
`;

const Heading2Custom = styled(Heading2)`
  height: 48px;
  background-color: transparent;
  border-bottom: 1.5px solid ${(p) => p.theme.color.primary};
  display: flex;
  align-items: center;
  color: #393939;
  padding-left: 18px;
`;

const ErrorContainer = styled.div`
  margin: 20px 0;
`;

export const PatientMedicineDeliveryError = (props: Props) => {
  const { errorType, handleErrorGoBack } = props;

  return (
    <React.Fragment>
      <Heading2Custom>{renderResource.Titles.j002}</Heading2Custom>
      <ErrorContainer>
        <MedicineDeliveryError errorType={errorType}></MedicineDeliveryError>
        <FullWidthOutlinedButton onClick={handleErrorGoBack}>
          {renderResource.Buttons.j002}
        </FullWidthOutlinedButton>
      </ErrorContainer>
    </React.Fragment>
  );
};
