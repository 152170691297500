import React, { Component } from "react";
import styled from "styled-components";
import { Hub } from "aws-amplify";
import { HUB_EVENT_SOURCE } from "../../Utils/Constant";
import { jaLocale } from "../../Utils/Utility";
import dateFns from "date-fns";
import { device } from "../device";
import { japaneseList } from "../../Resources/japaneseList";

const Root = styled.footer`
  color: ${(p) => p.theme.color.fontInvert};
  background-color: ${(p) => p.theme.color.fontVariant};
  font-size: ${(p) => p.theme.fontSize.small};
  text-align: center;
  margin-top: 20px;
  > p {
    padding: 10px 0;
  }
  @media screen and (orientation: landscape) and ${device.mobile} {
    line-height: 0px;
  }
`;

const Anchor = styled.a`
  color: ${(p) => p.theme.color.fontInvert};
  text-decoration: none;
`;

const BottomDate = styled.div`
  position: sticky;
  background-color: ${(p) => p.theme.rgbaColor.exDark};
  margin: 0;
  color: white;
  text-align: center;
  vertical-align: middle;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9.5px 0;
  z-index: 10;
  min-height: 65px;
`;

const BottomDay = styled.span`
  line-height: 1;
  font-weight: bold;
  margin-top: 3px;
  display: block;
  font-size: 1rem;
`;

const BottomDescription = styled.span`
  display: block;
  font-size: 1rem;
`;

const Block = styled.div`
  display: block;

  @media screen and (orientation: landscape) and ${device.mobile} {
    display: inline-block;
    padding-left: 10px;
  }
`;

const TextParagrap = styled.div`
  padding: 10px 0;
  margin: 1em 0;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    Hub.listen("reserve", this.hubAuthListener);

    this._isMounted = false;
    this.state = {
      selectedReserve: null,
      isOpenBottomDate: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hubAuthListener = (data) => {
    if (
      data.source === HUB_EVENT_SOURCE.RESERVE_TIME_UPDATE &&
      data.payload.event === "open"
    ) {
      if (this._isMounted) {
        this.setState({
          isOpenBottomDate: true,
          selectedReserve: data.payload.data,
        });
      } else if (
        data.payload.event === "close" &&
        this.state.isOpenBottomDate &&
        this._isMounted
      ) {
        this.setState({ isOpenBottomDate: false, selectedReserve: null });
      }
    }
  };

  RenderBottomDate = (selectedReserve) => {
    const RenderBottomDateResource =
      japaneseList.organisms.Footer.RenderBottomDate;
    const dayOfWeekNumber = dateFns.format(selectedReserve.from, "d");
    const dayOfWeek = jaLocale.weekdays[dayOfWeekNumber];

    const bottomDate = selectedReserve && selectedReserve.from && (
      <React.Fragment>
        <BottomDate>
          <BottomDescription>
            {
              RenderBottomDateResource.Fragment.BottomDate.BottomDescription
                .j001
            }
          </BottomDescription>
          <BottomDay>
            {dateFns.format(selectedReserve.from, "YYYY")}
            {RenderBottomDateResource.Fragment.BottomDate.BottomDay.j001}
            {dateFns.format(selectedReserve.from, "MM")}
            {RenderBottomDateResource.Fragment.BottomDate.BottomDay.j002}
            {dateFns.format(selectedReserve.from, "DD")}
            {RenderBottomDateResource.Fragment.BottomDate.BottomDay.j003}(
            {dayOfWeek}) {dateFns.format(selectedReserve.from, "HH:mm")}～
            {dateFns.format(selectedReserve.to, "HH:mm")}
          </BottomDay>
        </BottomDate>
      </React.Fragment>
    );

    return bottomDate;
  };

  render() {
    const renderResource = japaneseList.organisms.Footer.render;
    const { selectedReserve, isOpenBottomDate } = this.state;
    return (
      <React.Fragment>
        <Root>
          {this.props.children}
          <TextParagrap>
            <Block>
              <Anchor href="https://caradamedica.co.jp" target="_blank">
                &copy;{" "}
                {renderResource.Fragment.Root.TextParagrap.Blook.Anchor.j001}
              </Anchor>
            </Block>
          </TextParagrap>
        </Root>
        {isOpenBottomDate ? this.RenderBottomDate(selectedReserve) : null}
      </React.Fragment>
    );
  }
}

export default Footer;
