import React from "react";
import styled, { keyframes } from "styled-components";

import imgPath from "../../../images/loading/loadingCapsule.svg";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Root = styled.div`
  animation: ${rotate} 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

const Img = styled.img`
  display: inline-block;
  width: 28px;
  height: 28px;
`;

const LoadingCapsule = () => (
  <Root>
    <Img src={imgPath}/>
  </Root>
);

export default LoadingCapsule;
