import React, { Component } from "react";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import TermsOfService from "../organisms/TermsOfService";
import Titles from "../../Resources/Titles";

class TermsOfServicePage extends Component {
  render() {
    return <AuthenticatedTemplate title={Titles.termsOfService} main={<TermsOfService />} />;
  }
}

export default TermsOfServicePage;
