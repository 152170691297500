import { Component } from "react";
import styled from "styled-components";
import Heading from "../atoms/headings/Heading";
import CostDetail from "./CostDetail";
import { isNumber } from "lodash";
import FontP from "../atoms/fonts/FontP";
import { japaneseList } from "../../Resources/japaneseList";

const Container = styled.div`
  border: 1.5px solid ${(p) => p.theme.color.primary};
  padding: 12px 15px;
  border-radius: 10px;
  margin: 15px;
`;
const Header = styled(Heading)`
  color: ${(p) => p.theme.color.primary};
  background-color: transparent;
  text-align: center;
  padding: 0;
`;

const Panel = styled.div`
  padding: 0 15px;
  margin-bottom: 15px;
`;

class PaymentDetailAppointmentDetail extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.PaymentDetailAppointmentDetail.render;
    const { appointment, isLnlnOfficeMenu } = this.props;
    
    const isSelfFundedAppoimtment =
      appointment.payment && isNumber(appointment.payment.selfFundedFee) ? true : false;

    return (
      <div>
        {isLnlnOfficeMenu && !isSelfFundedAppoimtment ? (
          <Panel>
            <FontP color="accent" weight="bold">
              {renderResource.div.Container.Header.j001}
            </FontP>
            <FontP>{renderResource.div.Message.BillingEnterprise.j001}</FontP>
          </Panel>
        ) : (
          <Container>
            <Header>{renderResource.div.Container.Header.j001}</Header>
            <CostDetail appointment={appointment} />
          </Container>
        )}
      </div>
    );
  }
}
export default PaymentDetailAppointmentDetail;
