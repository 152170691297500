import styled from "styled-components";

const Heading = styled.div`
  padding: 8px 15px;
  color: ${p => p.theme.color.fontInvert};
  background-color: ${p => p.theme.color.primary};
  font-size: 1rem;
  font-weight: 700;
  box-sizing: border-box;
  line-height: 1.5;
`;

export default Heading;
