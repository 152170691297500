import styled from "styled-components";

export default styled.div`
  display: inline;
  padding: 2px 10px;
  text-align: center;
  font-size: 0.7rem;
  color: ${(p) => p.theme.font.color.gray};
  background-color: ${(p) => p.theme.icon.insurance.bg};
  border: solid 1px ${(p) => p.theme.icon.border.color};
`;
