import React from "react";
import styled from "styled-components";
import IconRequired from "../../atoms/icons/IconRequired";
import { japaneseList } from "../../../Resources/japaneseList";
import FontPBold from "../../atoms/fonts/FontPBold";
const renderResource =
  japaneseList.molecules.InputFormHeader.export.InputFormHeader;

const InputFormTitle = styled.h3`
  display: flex;
  align-items: center;
  height: 35px;
  margin: 10px 0;
  padding: 0 15px;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
`;

const TitleNumberText = styled(FontPBold)`
  font-size: 20px;
  color: ${(p) => p.theme.color.primary};
`;
const TitleText = styled(FontPBold)``;

export default ({ children, ...props }) => {
  return (
    <InputFormTitle>
      <TitleNumberText>{props.titleNumber}.</TitleNumberText>
      <TitleText>{children}</TitleText>
      {props.required && (
        <IconRequired>{renderResource.WarningLabel.j001}</IconRequired>
      )}
    </InputFormTitle>
  );
};
