import React, { useState } from "react";
import { withRouter } from "react-router";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "../../atoms/forms/Checkbox";
import Heading3 from "../../atoms/headings/Heading3";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import SendMailHeading from "../../molecules/headings/SendMailHeading";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { AddInquirySignupSms } from "../../../Utils/GraphQLQueries";
import { IVR } from "../../../Utils/Constant";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";
import { Hub } from "aws-amplify";
import IvrAuthenticate from "./IvrAuthenticate";

const Line = styled.div`
  height: 1px;
  background-color: #ccc;
`;

const HeadingSpacing = styled.div`
  height: 11px;
`;

const AreaWrapper = styled.div`
  padding: 0 15px 0;
`;

const AreaName = styled.div`
  color: ${(p) => p.theme.color.font};
`;

const Content = styled.div`
  color: ${(p) => p.theme.color.font};
  size: 0.9rem;
  line-height: 1.5em;
  padding-top: 20px;
  width: 100%;
  font-size: 14px;
`;

const EmailConfirmLabel = styled.div`
  margin: 20px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`;

const Error = styled.div`
  color: ${(p) => p.theme.color.warning};
  size: 0.8rem;
  line-height: 1.5em;
  padding: 0 0 14px 0;
`;

const InputText = styled.input`
  height: 35px;
  width: 100%;
  color: ${(p) => p.theme.color.font};
  border: 1px solid ${(p) => p.theme.color.fontVariant};
  padding: 6px 5px;
  font-size: 16px;
  background-color: #ffffff;
  border: solid 1px #c3c3c3;
  border-radius: 0;
  -webkit-appearance: none;
  :focus {
    background-color: #fff;
    outline: 0;
  }
`;

const Policy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  float: left;
`;

const LabelPolicy = styled.div`
  text-align: left;
  padding-left: 10px;
  font-size: 12px;
`;

const AreaPanel = withStyles({
  root: {
    border: "none",
    borderTop: "thin solid #ccc",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(ExpansionPanel);

const Icon = withStyles({
  margin: "0",
  color: "#ccc",
  expanded: {},
})(ExpandMoreIcon);

const AreaPanelSummary = withStyles({
  root: {
    minHeight: "auto",
    padding: "17px 0 17px 15px",
    color: "#ccc",
    "&$expanded": {
      minHeight: "auto",
    },
  },
  content: {
    margin: "auto",
    padding: 0,
    "&$expanded": {
      margin: "auto",
    },
  },
  expandIcon: {
    width: "auto",
  },
  expanded: {},
})(ExpansionPanelSummary);

const AreaPrefectures = withStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0",
  },
}))(ExpansionPanelDetails);

const SmsExansionPanel = (props) => {
  const [openArea, setOpenArea] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailConfirm, setEmailConfirm] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [error, setError] = useState(null);
  const [backgroundColorError, setBackgroundColorError] = useState("#ffffff");
  const [agreement, setAgreement] = useState(false);
  const [isInquired, setIsInquired] = useState(false);

  const InquirySignupSmsFromResource =
    japaneseList.organisms.InquirySignupSmsFrom;

  const handleInputChange = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "emailConfirm") {
      setEmailConfirm(event.target.value);
    }
  };

  const handleAgreementChange = (event) => {
    setAgreement(event.target.checked);
  };

  const sendMail = async (sendMail) => {
    if (sendMail) {
      const userAgent = window.navigator.userAgent;
      if (!validateEmail()) {
        return;
      }

      setIsInquired(true);

      const response = await GraphQLClient.mutate({
        mutation: AddInquirySignupSms,
        variables: {
          phoneNumber: props.username,
          ua: userAgent,
          email: email,
        },
      });

      if (response.errors) {
        let msg = "";
        switch (response.errors[0].errorType) {
          case "E01":
            msg = InquirySignupSmsFromResource.errorMessage.j001;
            break;
          case "E02":
            msg = InquirySignupSmsFromResource.errorMessage.j001;
            break;
          default:
            msg = InquirySignupSmsFromResource.errorMessage.j001;
            break;
        }
        snackBar(msg);
      } else {
        props.history.push("/auth/inquiry-completed");
      }

      Sentry.captureMessage(
        "signup-inquirySignupSmsCompleted",
        Sentry.Severity.Log
      );
    }
  };

  const validateEmail = () => {
    setDisplayError(false);
    setBackgroundColorError("#ffffff");
    if (!email.includes("@")) {
      setError(InquirySignupSmsFromResource.checkInput.j001);
      setDisplayError(true);
      setBackgroundColorError("#fff0f0");
      return false;
    }
    if (email !== emailConfirm) {
      setError(InquirySignupSmsFromResource.checkInput.j002);
      setDisplayError(true);
      setBackgroundColorError("#fff0f0");
      return false;
    }

    return true;
  };

  const snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "InquirySignupSms"
    );
  };

  const handleChange = () => {
    setOpenArea(!openArea);
  };

  return (
    <React.Fragment>
      <IvrAuthenticate
        phoneNumber={props.username}
        flowType={IVR.FLOWTYPE.MEMBER_REGISTRATION}
      ></IvrAuthenticate>
      <AreaPanel
        square={true}
        expanded={openArea}
        onChange={() => handleChange()}
      >
        <Line></Line>
        <AreaPanelSummary aria-controls="panel2d-content" expandIcon={<Icon />}>
          <AreaName>
            {
              InquirySignupSmsFromResource.render.Fragment.div.AreaPanel
                .AreaPanelSummary.AreaName.j001
            }
          </AreaName>
        </AreaPanelSummary>
        <HeadingSpacing />
        <Heading3>
          {
            InquirySignupSmsFromResource.render.Fragment.div.AreaPanel
              .AreaPrefectures.AreaPrefecturesHeading.j001
          }
        </Heading3>
        <AreaWrapper>
          <AreaPrefectures>
            <Content>
              {InquirySignupSmsFromResource.render.Fragment.tip.j002}
            </Content>
            <SendMailHeading>
              {
                InquirySignupSmsFromResource.render.Fragment.div.AreaPanel
                  .AreaPrefectures.Content.j001
              }
            </SendMailHeading>
            {displayError && (
              <Error>
                <span>{error}</span>
              </Error>
            )}
            <InputText
              placeholder="XXXXX@XXXX.com"
              name="email"
              value={email}
              style={{
                backgroundColor: backgroundColorError,
              }}
              onChange={handleInputChange}
            />
            <EmailConfirmLabel>
              {
                InquirySignupSmsFromResource.render.Fragment.div.AreaPanel
                  .AreaPrefectures.EmailConfirmLabel.j001
              }
            </EmailConfirmLabel>
            <InputText
              placeholder="XXXXX@XXXX.com"
              name="emailConfirm"
              value={emailConfirm}
              onChange={handleInputChange}
              style={{
                backgroundColor: backgroundColorError,
              }}
            />
          </AreaPrefectures>

          <Policy>
            <Checkbox onChange={handleAgreementChange} value={agreement} />
            <LabelPolicy>
              {
                InquirySignupSmsFromResource.render.Fragment.div.div.FormArea
                  .PanelWellCenter.Checkbox
              }
            </LabelPolicy>
          </Policy>
          <FullWidthButton
            onClick={sendMail}
            disabled={!(agreement && emailConfirm && email && !isInquired)}
            id="email-inquiry-confirm-button"
          >
            {
              InquirySignupSmsFromResource.render.Fragment.div.AreaPanel
                .AreaPrefectures.FullWidthButton.j001
            }
          </FullWidthButton>
        </AreaWrapper>
      </AreaPanel>
      {!openArea && <Line></Line>}
    </React.Fragment>
  );
};

export default withRouter(SmsExansionPanel);
