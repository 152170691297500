import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { EnterpriseContractPlanList } from "./EnterpriseContractPlanList";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import { japaneseList } from "../../../Resources/japaneseList";

const ButtonContainer = styled.div`
  padding: 20px 30px;
`;

const Button = styled(ButtonOutlined)`
  height: 50px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
`;

export const EnterpriseTopContent = withRouter((props) => {
  const handleAddPlanButtonClick = () => {
    props.history.push("/enterprise/authorize");
  };

  const renderResource = japaneseList.organisms.enterprise.EnterpriseTopContent;

  const enterprise = (props.patient && props.patient.enterprise) || {};
  const employeeNumber = (props.patient && props.patient.employeeNumber) || "";

  return (
    <React.Fragment>
      <EnterpriseContractPlanList
        enterpriseName={enterprise.enterpriseName}
        contractPlans={enterprise.contractPlans}
        employeeNumber={employeeNumber}
      />
      <ButtonContainer>
        <Button
          id="enterprise-registration-page-link"
          onClick={handleAddPlanButtonClick}
        >
          {renderResource.render.Buttons.j001}
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
});
