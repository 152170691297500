import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HospitalDetail from "../organisms/hospital/HospitalDetail";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Account, Hospital } from "../../Utils/GraphQLQueries";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import SiteContext from "../../SiteContext";
class HospitalDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital: null,
      cardStatus: false,
      addressStatus: false,
    };
  }

  async componentDidMount() {
    let from = "carada";
    const hospitalId = this.props.match.params.hospitalId;
    let value = this.context;
    if (value.ui === "lnln") {
      from = "lnln";
    }

    try {
      let res = await GraphQLClient.query({
        query: Hospital,
        variables: { hospitalId, from },
      });

      //hide when hospital is deleted,disabled,limited.
      if (res.errors) {
        res.errors.forEach((error) => {
          if (error.errorType === "E101") {
            this.props.history.push("/404Page");
          }
        });
      }

      if (res.errors && res.errors[0].errorType === "E03") {
        this.props.history.push("/");
      }
      let hospital = res.data.hospital;
      if (hospital) {
        this.setState({
          hospital,
        });
      }
    } catch (e) {
      console.log("e:", e);
    }

    this.checkCardStatusAndAddress();
  }

  checkCardStatusAndAddress = async () => {
    const {
      data: { account },
    } = await GraphQLClient.query({ query: Account });
    if (account && account.cardStatus === "registered") {
      this.setState({
        cardStatus: true,
      });
    }
    if (account && account.address && account.address.postalCode) {
      this.setState({
        addressStatus: true,
      });
    }
  };

  render() {
    if (!this.state.hospital) {
      return null;
    }
    const hospital_name = this.state.hospital.name;
    return (
      <AuthenticatedTemplate
        title={hospital_name}
        main={
          <HospitalDetail
            hospital={this.state.hospital}
            cardStatus={this.state.cardStatus}
            addressStatus={this.state.addressStatus}
          />
        }
      />
    );
  }
}
HospitalDetailPage.contextType = SiteContext;

export default withRouter(HospitalDetailPage);
