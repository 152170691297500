import { useState, useEffect } from "react";
import styled from "styled-components";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import Heading1 from "../atoms/headings/Heading1";
import { japaneseList } from "../../Resources/japaneseList";
import { device } from "../device";
import { InterviewSheetAnswerForm } from "../organisms/interviewSheet/InterviewSheetAnswerForm";
import { INTERVIEW_TYPE } from "../../Utils/Constant";
import {
  UpdateInterviewSheetAnswer,
  InterviewSheetAnswer,
  ReadPersonalNotification,
} from "../../Utils/GraphQLQueries";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Hub } from "aws-amplify";
import Guard from "../../Utils/Guard";

const _ = require("lodash");

const Heading = styled(Heading1)`
  @media screen and ${device.tb} {
    width: 424px;
    border-right: thin solid #c7c7c7;
    border-left: thin solid #c7c7c7;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.05),
      0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  width: 100%;
`;

const WhiteDiv = styled.div`
  height: 20px;
  background-color: #ffffff;
  @media screen and ${device.tb} {
    width: 423px;
    border-right: thin solid #c7c7c7;
  }
  @media screen and ${device.pc} {
    width: 423px;
  }
`;

const PreservationButton = styled.div`
  float: right;
  height: 25px;
  width: 84px;
  font-size: 14px;
  font-weight: 600;
  color: ${(p) => p.theme.heading.h1.bg};
  line-height: 25px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
`;

const Container = styled.div`
  padding-top: 40px;
  position: relative;
`;

const ContainerTop = styled.div`
  position: absolute;
  top: -20px;
`;

const ContainerLowest = styled.div`
  position: relative;
`;

const renderResource = japaneseList.pages.InterviewSheetAnswerUpdatePage.render;

export const InterviewSheetAnswerUpdatePage = Guard((props) => {
  const [interviewSheetAnswer, setInterviewSheetAnswer] = useState(null);
  const [isValidationError, setIsValidationError] = useState(false);
  const { createTime } = props.match.params;

  useEffect(() => {
    if (
      props.location.state &&
      (props.location.state.fromAppointmentDetail ||
        props.location.state.fromNotificationList)
    ) {
      loadInterviewSheetAnswer();
    } else {
      props.history.push(`/appointment/${createTime}`);
    }

    if (
      props.location.state &&
      props.location.state.isRead &&
      props.location.state.isUnreadNotification
    ) {
      // TODO: 受診側の header.js がクラスコンポーネントで読み込みが遅いため遅延実行している。
      setTimeout(() => {
        readPersonalNotification(
          props.location.state.notificationId,
          props.location.state.notificationType
        );
      }, 1000);
    }
  }, []);

  const readPersonalNotification = async (notificationId, notificationType) => {
    const { data, errors } = await GraphQLClient.mutate({
      mutation: ReadPersonalNotification,
      variables: { notificationId, notificationType },
    });
    if (errors) {
      console.error("ReadPersonalNotification errors:", JSON.stringify(errors));
    }

    if (data && data.readPersonalNotification) {
      Hub.dispatch("auth", { event: "updateNotificationNumber" });
    }
  };

  const loadInterviewSheetAnswer = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: InterviewSheetAnswer,
      variables: {
        createTime: createTime,
      },
    });
    if (errors) {
      console.error("InterviewSheetAnswer errors:", JSON.stringify(errors));
    } else {
      setInterviewSheetAnswer(_.cloneDeep(data.interviewSheetAnswer));
    }
  };

  const handleInterviewAnwerChange = (interviewSheetAnswer) => {
    setInterviewSheetAnswer(_.cloneDeep(interviewSheetAnswer));
  };

  const handleUpdateInterviewAnswers = async () => {
    const { interviewAnswers } = interviewSheetAnswer;
    const checkError = interviewAnswers.some((item) => {
      return isRequiredAnswer(item) || isRequiredReduceCharacters(item);
    });
    if (checkError) {
      const anchorElement = document.getElementsByClassName(
        "validationErrorElement"
      );
      if (anchorElement && anchorElement.length > 0) {
        anchorElement[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
      setIsValidationError(true);
      return;
    }

    const answers = interviewAnswers.map((item) => {
      return {
        order: item.order,
        idNotation: item.idNotation,
        interviewSheetItemId: item.interviewSheetItemId,
        interviewSheetItemVersion: item.interviewSheetItemVersion,
        requiredAnswer: item.requiredAnswer,
        interviewChoiceNos: item.interviewChoiceNos,
        freeText: item.freeText,
      };
    });

    const { errors } = await GraphQLClient.mutate({
      mutation: UpdateInterviewSheetAnswer,
      variables: {
        createTime: createTime,
        interviewAnswers: answers,
      },
    });

    if (errors) {
      console.error(
        "UpdateInterviewSheetAnswer errors:",
        JSON.stringify(errors)
      );
      let msg = "";
      switch (errors[0].errorType) {
        case "E01":
          msg = renderResource.Preservation.Error.j001;
          break;
        case "E02":
          msg = renderResource.Preservation.Error.j002;
          break;
        default:
          msg = renderResource.Preservation.Error.j003;
          break;
      }
      snackBar(msg);
    } else {
      props.history.push({
        pathname: `/appointment/${createTime}`,
        state: {
          isShowAppointmentDetail: false,
        },
      });
    }
  };

  const isRequiredAnswer = ({
    requiredAnswer,
    interviewType,
    interviewChoiceNos,
    freeText,
  }) => {
    const isRequiredSelectChoices =
      requiredAnswer &&
      interviewType !== INTERVIEW_TYPE.FREE_TEXT &&
      !(interviewChoiceNos && interviewChoiceNos.length);
    const isRequiredInputText =
      requiredAnswer &&
      interviewType === INTERVIEW_TYPE.FREE_TEXT &&
      !(freeText && freeText.trim());
    if (isRequiredSelectChoices || isRequiredInputText) {
      return true;
    } else {
      return false;
    }
  };

  const isRequiredReduceCharacters = ({ freeText }) => {
    if (freeText && freeText.length > 100) {
      return true;
    } else {
      return false;
    }
  };

  const snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "UpdateInterviewSheetAnswer"
    );
  };

  const main = (
    <Container>
      <ContainerLowest>
        {interviewSheetAnswer && (
          <InterviewSheetAnswerForm
            interviewSheetAnswer={interviewSheetAnswer}
            isValidationError={isValidationError}
            onInterViewAnswerChange={handleInterviewAnwerChange}
          />
        )}
      </ContainerLowest>
      <ContainerTop>
        <FixedContainer>
          <WhiteDiv></WhiteDiv>
          <Heading>
            {renderResource.Title.j001}
            {
              <PreservationButton onClick={handleUpdateInterviewAnswers}>
                {renderResource.Button.j001}
              </PreservationButton>
            }
          </Heading>
        </FixedContainer>
      </ContainerTop>
    </Container>
  );

  return (
    <AuthenticatedTemplate title={renderResource.Title.j001} main={main} />
  );
});
